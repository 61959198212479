import {
  Component,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import {
  LayerViewTypeModel,
  MapCompositionService,
  PatchLayerViewRequestModel,
} from '@ui/data-access-carto-map-production'
import { MapFacade, WFS_MAX_FEATURES } from '@ui/feature/mapstate'
import {
  BusinessRulesService,
  isLayerExternal,
  isLayerExternalWMS,
  isLayerInternal,
  isLayerQgis,
  Layer,
  MapElement,
  parseApiMapElement,
} from '@ui/feature/shared'
import { ModalComponent } from '@ui/ui/layout'
import { merge, Observable, of, Subscription, throwError } from 'rxjs'
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import { ProjectionOption, projectionOptions } from '../../../../../projections'
import { NotificationFacade } from '../../../../store'
import {
  minMaxScaleNoEmptyValidator,
  minMaxScaleValidator,
} from '../min-max-scale-group/min-max-scale-group.component'

export const VIEW_CHARSET_ENCODINGS = new InjectionToken<string>(
  'viewCharsetEncodings'
)

@Component({
  selector: 'ui-edit-layer',
  templateUrl: './edit-layer.component.html',
  styleUrls: ['./edit-layer.component.scss'],
})
export class EditLayerComponent implements OnInit, OnDestroy {
  @Input() currentLayer$: Observable<MapElement>
  @ViewChild('modal') modal: ModalComponent

  minScaleMapValue$: Observable<number>
  maxScaleMapValue$: Observable<number>
  layer: Layer
  projectionOptions = projectionOptions()
  editlayerForm = this.fb.group({
    title: [],
    name: ['name', [Validators.required, Validators.maxLength(64)]],
    customizableName: [false],
    customizableScale: [false],
    minScale: new FormControl<number>(null, {
      validators: null,
      updateOn: 'blur',
    }),
    maxScale: new FormControl<number>(null, {
      validators: null,
      updateOn: 'blur',
    }),
    enableEncoding: [false],
    characterEncoding: [],
    wmsQueryable: [],
    optimizeRequestProjection: [],
    requestProjection: [],
  })
  errorMsg: string
  subscription_ = new Subscription()
  projectionTip = `Projection utilisée par le moteur de rendu pour générer les images WMS.
Afin d'accélérer l'affichage des couches (en particulier celles s'appuyant sur des données volumineuses), il est conseillé d'utiliser la projection native des données.
Attention : cela peut provoquer une dégradation du rendu final`

  constructor(
    private mapFacade_: MapFacade,
    private mapComposition_: MapCompositionService,
    private notificationFacade_: NotificationFacade,
    private fb: FormBuilder,
    private businessRulesService: BusinessRulesService,
    @Optional() @Inject(WFS_MAX_FEATURES) public wfsMaxFeatures,
    @Optional() @Inject(VIEW_CHARSET_ENCODINGS) public viewCharsetEncodings
  ) {}

  get f() {
    return this.editlayerForm.controls
  }

  get view() {
    return (this.layer && this.layer.views[0]) || {}
  }

  get infoMessage() {
    return this.view.serviceType === LayerViewTypeModel.WFS
      ? `L'affichage des couches WFS est limité à ${this.wfsMaxFeatures} objets
    géographiques.`
      : null
  }

  get origin() {
    return this.view.origin || ''
  }

  get relativeOrigin() {
    return this.view.relativeOrigin || this.origin
  }

  get isLayerInternal() {
    return isLayerInternal(this.layer)
  }

  get isLayerExternal() {
    return isLayerExternal(this.layer)
  }

  get isLayerExternalWMS() {
    return isLayerExternalWMS(this.layer)
  }

  get isLayerNameEditable() {
    return isLayerInternal(this.layer) && !isLayerQgis(this.layer)
  }

  get isFormInvalid() {
    return this.editlayerForm.invalid
  }

  ngOnInit() {
    ;['minScale', 'maxScale'].forEach((inputName) => {
      this.editlayerForm
        .get(inputName)
        .setValidators([
          minMaxScaleValidator(this.editlayerForm),
          minMaxScaleNoEmptyValidator(this.editlayerForm),
          Validators.min(1),
        ])
    })

    this.minScaleMapValue$ = this.mapFacade_.info$.pipe(
      map((info) => info.minScale)
    )
    this.maxScaleMapValue$ = this.mapFacade_.info$.pipe(
      map((info) => info.maxScale)
    )

    this.subscription_.add(
      this.currentLayer$
        .pipe(
          filter((layer) => !!layer),
          withLatestFrom(this.mapFacade_.info$),
          switchMap(([layer, mapInfo]) =>
            this.mapComposition_.findLayerById(mapInfo.id, layer.id)
          )
        )
        .subscribe({
          next: (fetchedLayer) => {
            this.layer = fetchedLayer as Layer

            const title = this.layer.title
            const name = this.view.layerName || ''
            const characterEncoding =
              this.view.characterEncoding || this.viewCharsetEncodings[0]
            const optimizeRequestProjection =
              this.view.requestProjection !== 'EPSG:3857'

            this.editlayerForm.reset({
              title,
              name,
              customizableName: false,
              customizableScale: !(!this.view.minScale && !this.view.maxScale),
              minScale: this.view.minScale,
              maxScale: this.view.maxScale,
              enableEncoding: false,
              characterEncoding,
              wmsQueryable: this.view.wmsQueryable,
              optimizeRequestProjection,
              requestProjection: this.view.requestProjection,
            })

            this.f['title'].disable()
            this.f['characterEncoding'].disable()
            if (this.canChooseRequestProjection()) {
              this.f['requestProjection'].enable()
            } else {
              this.f['requestProjection'].disable()
            }
            this.modal.open()
          },
          error: () => {
            this.notificationFacade_.showNotification(
              'danger',
              "Les paramètres avancés de la couche n'ont pas pu être chargés",
              5000
            )
          },
        })
    )

    this.subscription_.add(
      merge(of(true), this.f['customizableName'].valueChanges).subscribe(() => {
        this.toggleNameCustomization()
      })
    )
    this.subscription_.add(
      this.f['enableEncoding'].valueChanges.subscribe((enableEncoding) => {
        enableEncoding
          ? this.f['characterEncoding'].enable()
          : this.f['characterEncoding'].disable()
      })
    )
    this.subscription_.add(
      this.f['optimizeRequestProjection'].valueChanges.subscribe((optimized) =>
        this.setProjectionOptimization(optimized)
      )
    )
    this.subscription_.add(
      this.f['name'].valueChanges.subscribe((value) => {
        const normalizedName = this.businessRulesService.normalizeLayerName(
          value,
          this.layer.views[0].originType
        )
        if (normalizedName !== value) {
          this.f['name'].setValue(normalizedName)
        }
      })
    )
  }

  toggleNameCustomization() {
    const controls = this.f
    controls['customizableName'].value
      ? controls['name'].enable()
      : controls['name'].disable()
  }

  setProjectionOptimization(optimized: boolean) {
    if (optimized) {
      if (!this.canChooseRequestProjection()) {
        this.f['requestProjection'].setValue(this.view.crsCode)
      } else {
        this.f['requestProjection'].enable()
      }
    } else {
      this.f['requestProjection'].disable()
      this.f['requestProjection'].setValue('EPSG:3857')
    }
  }

  // this is to avoid inconsistent UX when inputting a forbidden char
  // (ie: cursor jumps to the end of the line)
  handleNameKeyDown(event) {
    let name = event.target.value
    // here we compute the name as it will look like with the inserted char
    name =
      name.substring(0, event.target.selectionStart) +
      event.key +
      name.substring(event.target.selectionEnd)
    const normalized = this.businessRulesService.normalizeLayerName(
      name,
      this.layer.views[0].originType
    )
    if (normalized.length !== name.length) {
      event.preventDefault()
    }
  }

  sortProjectionOrder(options: ProjectionOption[]) {
    return options.sort((a, b) => a.order - b.order)
  }

  filterProjectionValue(options: ProjectionOption[]) {
    return options.filter((option) =>
      this.view.possibleRenderingProjections
        ? this.view.possibleRenderingProjections.includes(option.value)
        : true
    )
  }

  canChooseRequestProjection() {
    return (
      !this.isNativeProjectionKnown() &&
      this.f['optimizeRequestProjection'].value
    )
  }

  isNativeProjectionKnown() {
    return this.view.crsCode && this.view.crsCode !== 'UNKNOWN'
  }

  isNativeProjectionSupported() {
    return (
      this.view.possibleRenderingProjections &&
      this.view.possibleRenderingProjections.includes(this.view.crsCode)
    )
  }

  isNativeProjectionKnownAndNotSupported() {
    return this.isNativeProjectionKnown() && !this.isNativeProjectionSupported()
  }

  onSubmit() {
    const rawValues = this.editlayerForm.getRawValue()
    const viewPatch: PatchLayerViewRequestModel = {
      ...(this.layer.views[0].originType === 'QGIS'
        ? {}
        : { layerName: rawValues.name }),
      minScale: rawValues.minScale || null,
      maxScale: rawValues.maxScale || null,
      wmsQueryable: rawValues.wmsQueryable,
      requestProjection: rawValues.requestProjection,
    }
    if (!this.editlayerForm.controls['characterEncoding'].pristine) {
      viewPatch.characterEncoding = rawValues.characterEncoding
    }

    return this.mapFacade_.info$.pipe(
      take(1),
      map((info) => info.id),
      switchMap((mapId) =>
        this.mapComposition_.updateLayerView(
          mapId,
          this.layer.views[0].id,
          viewPatch
        )
      ),
      map(parseApiMapElement),
      tap((layerSpec) => {
        this.mapFacade_.updateLayerView({
          layerId: this.layer.id,
          viewId: this.layer.views[0].id,
          ...layerSpec,
        })

        this.notificationFacade_.showNotification(
          'success',
          'La couche a été sauvegardée',
          2000
        )
      }),
      catchError((error) =>
        throwError(
          error.status === 409
            ? 'Une couche avec ce nom existe déjà'
            : 'L’enregistrement de la couche a échoué'
        )
      )
    )
  }

  ngOnDestroy() {
    this.subscription_.unsubscribe()
  }
}
