<div
  class="d-flex flex-row align-items-center pr-2 pl-0 py-2 rule-header"
  (click)="toggle()"
  [ngClass]="{ active: active }"
>
  <i
    class="fa fa-ellipsis-v fa-fw drag-handle flex-shrink-0"
    aria-hidden="true"
    *ngIf="draggable"
    cdkDragHandle
  ></i>
  <div class="p-1 flex-shrink-0" aria-hidden="true" *ngIf="!draggable"></div>
  <img
    class="d-flex flex-column mr-2 card bg-light toggle p-0"
    style="width: 2.5rem; height: 2.5rem; flex-shrink: 0"
    [src]="legendUrl"
  />
  <div
    class="d-flex flex-column align-items-start overflow-hidden"
    [title]="rule.name"
  >
    <div class="rule-name text-truncate w-100 text-secondary">
      {{ rule.name }}
    </div>
    <div class="rule-preview">
      <span class="rule-index">#{{ index }}</span>
      <span
        class="fa fa-fw fa-filter ml-1"
        [ngClass]="hasFilter() ? 'enabled' : ''"
      >
      </span>
      <span
        class="fa fa-fw fa-ruler-combined ml-1"
        [ngClass]="hasScale() ? 'enabled' : ''"
      >
      </span>
      <span
        class="fa fa-fw fa-fill-drip ml-1"
        [ngClass]="hasFill() ? 'enabled' : ''"
      >
      </span>
      <span
        class="fa fa-fw fa-paint-brush ml-1"
        [ngClass]="hasLine() ? 'enabled' : ''"
      >
      </span>
      <span
        class="fa fa-fw fa-map-marker-alt ml-1"
        [ngClass]="hasMark() ? 'enabled' : ''"
      >
      </span>
      <span
        class="fa fa-fw fa-font ml-1"
        [ngClass]="hasText() ? 'enabled' : ''"
      >
      </span>
    </div>
  </div>
  <div class="ml-1 flex-shrink-0 flex-grow-1"></div>
  <button
    class="btn btn-outline-danger btn-sm ml-1 delete"
    *ngIf="deletable"
    (click)="delete()"
    title="Supprimer la règle"
  >
    <i class="fa fa-trash-alt"></i>
  </button>
</div>

<div class="d-flex flex-row" *ngIf="active">
  <div class="symbolizers-indent pl-3"></div>
  <div class="list-group list-group-flush w-100">
    <div class="list-group-item px-3" *ngIf="nameEditable">
      <div class="font-weight-bold small">NOM</div>
      <input
        class="form-control form-control-sm mt-1 rule-name-input"
        [value]="rule.name"
        (input)="handleNameChange($event.target.value)"
        placeholder="Entrer un nom pour la règle"
      />
    </div>
  </div>
</div>

<!--Filter-->
<div class="d-flex flex-row" *ngIf="active && dataSchema">
  <div class="symbolizers-indent pl-3"></div>
  <div class="list-group list-group-flush w-100">
    <div
      *ngIf="rule.filter"
      class="list-group-item align-items-center py-2 px-0"
    >
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row align-items-center pr-2 pl-3">
          <div class="font-weight-bold small">FILTRE</div>
          <div class="flex-grow-1 flex-shrink-1"></div>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm ml-2 delete-symbolizer"
            (click)="deleteFilter()"
          >
            <span class="fa fa-trash-alt"></span>
          </button>
        </div>
        <div class="px-3">
          <ui-rule-filter
            [filter]="rule.filter"
            [schema]="dataSchema"
            (filterChange)="handleFilterChange($event)"
          >
          </ui-rule-filter>
        </div>
      </div>
    </div>

    <div
      *ngIf="rule.scaleDenominator"
      class="list-group-item align-items-center py-2 px-0"
    >
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row align-items-center pr-2 pl-3">
          <div class="font-weight-bold small">ECHELLE</div>
          <div class="flex-grow-1 flex-shrink-1"></div>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm ml-2 delete-scale"
            (click)="deleteScale()"
          >
            <span class="fa fa-trash-alt"></span>
          </button>
        </div>
        <div class="px-3">
          <ui-rule-scale-input
            [viewMin]="layerView.minScale"
            [viewMax]="layerView.maxScale"
            [min]="rule.scaleDenominator.min"
            [max]="rule.scaleDenominator.max"
            (scaleChange)="handleScaleChange($event)"
          ></ui-rule-scale-input>
        </div>
      </div>
    </div>

    <div
      *ngIf="(!hasFilter() && filterable) || !hasScale()"
      class="list-group-item d-flex flex-row align-items-center pl-3 pr-0"
    >
      <span class="fa fa-plus-circle mr-2"></span>
      <div class="btn-group btn-group-sm">
        <button
          *ngIf="!hasFilter() && filterable"
          type="button"
          class="btn btn-outline-dark add-filter"
          (click)="addFilter()"
        >
          <span class="fa fa-filter"></span> Filtre
        </button>
        <button
          *ngIf="!hasScale()"
          type="button"
          class="btn btn-outline-dark add-scale"
          (click)="addScale()"
        >
          <span class="fa fa-ruler-combined"></span> Echelle
        </button>
      </div>
    </div>
  </div>
</div>
<!--Symbolizers-->
<div class="d-flex flex-row" *ngIf="active">
  <div class="symbolizers-indent pl-3"></div>
  <div
    class="list-group list-group-flush w-100"
    cdkDropList
    (cdkDropListDropped)="
      handleSymbolizerOrderChange(
        symbolizers.length - 1 - $event.item.data,
        symbolizers.length - 1 - $event.currentIndex
      )
    "
  >
    <div
      *ngFor="
        let symbolizer of symbolizers | reverse;
        index as i;
        trackBy: symbolizerTracker
      "
      cdkDrag
      [cdkDragData]="i"
      [cdkDragDisabled]="!draggable"
      class="list-group-item symbolizer flex-row align-items-center py-2 px-0"
      style="display: flex"
    >
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row align-items-center pr-2">
          <i
            class="fa fa-ellipsis-v fa-fw symbolizer-handle"
            aria-hidden="true"
            *ngIf="!fixedSymbolizers"
            cdkDragHandle
          ></i>
          <div class="p-1" aria-hidden="true" *ngIf="fixedSymbolizers"></div>
          <div class="font-weight-bold small">
            {{ symbolizerKinds[symbolizer.kind].label | uppercase }}
          </div>
          <div class="flex-grow-1 flex-shrink-1"></div>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm ml-2 delete-symbolizer"
            *ngIf="!fixedSymbolizers"
            (click)="deleteSymbolizer(getIndex(symbolizer))"
          >
            <span class="fa fa-trash-alt"></span>
          </button>
        </div>
        <div class="px-3">
          <ui-symbolizer-field
            *ngIf="symbolizer.kind === symbolizerKinds.Line.value"
            label="couleur"
            [fieldType]="fieldTypes.COLOR"
            [value]="symbolizer.color"
            [locked]="colorsLocked"
            (fieldUpdated)="
              handleSymbolizerPropertyChange(
                getIndex(symbolizer),
                'color',
                $event
              )
            "
          >
          </ui-symbolizer-field>
          <ui-symbolizer-field
            *ngIf="symbolizer.kind === symbolizerKinds.Line.value"
            label="épaisseur"
            [fieldType]="fieldTypes.WIDTH"
            [value]="symbolizer.width"
            (fieldUpdated)="
              handleSymbolizerPropertyChange(
                getIndex(symbolizer),
                'width',
                $event
              )
            "
          >
          </ui-symbolizer-field>
          <ui-symbolizer-field
            *ngIf="symbolizer.kind === symbolizerKinds.Line.value"
            label="opacité"
            [fieldType]="fieldTypes.OPACITY"
            [value]="symbolizer.opacity"
            (fieldUpdated)="
              handleSymbolizerPropertyChange(
                getIndex(symbolizer),
                'opacity',
                $event
              )
            "
          >
          </ui-symbolizer-field>
          <ui-symbolizer-field
            *ngIf="symbolizer.kind === symbolizerKinds.Line.value"
            label="type"
            [fieldType]="fieldTypes.DASH_ARRAY"
            [value]="symbolizer.dasharray"
            (fieldUpdated)="
              handleSymbolizerPropertyChange(
                getIndex(symbolizer),
                'dasharray',
                $event
              )
            "
          >
          </ui-symbolizer-field>

          <ui-fill-symbolizer
            *ngIf="symbolizer.kind === symbolizerKinds.Fill.value"
            [symbolizer]="symbolizer"
            [colorsLocked]="colorsLocked"
            [activeImageGeometryPolygon]="activeImageGeometryPolygon"
            (symbolizerUpdated)="
              handleSymbolizerPropertyChange(
                getIndex(symbolizer),
                $event.name,
                $event.value
              )
            "
          ></ui-fill-symbolizer>

          <ui-mark-symbolizer
            *ngIf="symbolizer.kind === symbolizerKinds.Mark.value"
            [symbolizer]="symbolizer"
            [colorsLocked]="colorsLocked"
            [activeImageGeometryPolygon]="activeImageGeometryPolygon"
            (symbolizerUpdated)="
              handleSymbolizerPropertiesChange(getIndex(symbolizer), $event)
            "
          ></ui-mark-symbolizer>

          <ui-text-symbolizer
            *ngIf="symbolizer.kind === symbolizerKinds.Text.value"
            [symbolizer]="symbolizer"
            [schema]="dataSchema"
            (symbolizerUpdated)="
              handleSymbolizerPropertiesChange(getIndex(symbolizer), $event)
            "
          >
          </ui-text-symbolizer>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-row" *ngIf="active && !fixedSymbolizers">
  <div class="symbolizers-indent pl-3"></div>
  <div class="list-group list-group-flush w-100">
    <div class="list-group-item d-flex flex-row align-items-center px-3">
      <span class="fa fa-plus-circle mr-2"></span>
      <div class="btn-group btn-group-sm">
        <button
          *ngIf="elementType === elementTypes.LINE"
          type="button"
          class="btn btn-outline-dark add-symbolizer"
          (click)="addSymbolizer(symbolizerKinds.Line.value)"
        >
          <span class="fa fa-paint-brush"></span> Trait
        </button>
        <button
          *ngIf="elementType === elementTypes.POLYGON"
          type="button"
          class="btn btn-outline-dark add-symbolizer"
          (click)="addSymbolizer(symbolizerKinds.Fill.value)"
        >
          <span class="fa fa-fill-drip"></span> Remplissage
        </button>
        <button
          *ngIf="elementType === elementTypes.POINT"
          type="button"
          class="btn btn-outline-dark add-symbolizer"
          (click)="addSymbolizer(symbolizerKinds.Mark.value)"
        >
          <span class="fa fa-map-marker-alt"></span> Symbole
        </button>
        <button
          type="button"
          class="btn btn-outline-dark"
          (click)="addSymbolizer(symbolizerKinds.Text.value)"
        >
          <span class="fa fa-font"></span> Texte
        </button>
      </div>
    </div>
  </div>
</div>
