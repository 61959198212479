<div class="modal-header">
  <h5 class="modal-title pull-left">Éditer «{{ info.title }}»</h5>
</div>
<alert *ngIf="errorMsg" type="danger"> {{ errorMsg }} </alert>
<form [formGroup]="editmapForm" (ngSubmit)="onSubmit()">
  <div class="modal-body pb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label
              >Titre
              <input
                type="text"
                class="form-control"
                formControlName="title"
                [ngClass]="{ 'is-invalid': f.title.errors }"
              />
            </label>
          </div>
          <div class="form-group">
            <label
              >Nom
              <input
                type="text"
                class="form-control"
                formControlName="name"
                [ngClass]="{ 'is-invalid': f.name.errors }"
              />
            </label>
            <label class="form-check form-check-label text-muted">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="customName"
              />
              Personnaliser le nom de la carte
            </label>
          </div>
          <div class="form-group">
            <label
              >Description
              <textarea
                rows="6"
                class="form-control"
                formControlName="description"
                [ngClass]="{ 'is-invalid': f.description.errors }"
              ></textarea>
            </label>
          </div>
          <ui-password-input [parentForm]="editmapForm"></ui-password-input>
        </div>
        <div class="col">
          <div class="form-group">
            <label
              >Extent
              <input
                type="text"
                class="form-control"
                formControlName="bounds"
                readonly
                [ngClass]="{ 'is-invalid': f.bounds.errors }"
              />
            </label>
            <button type="button" class="btn btn-info" (click)="setExtent()">
              Depuis l'étendue courante
            </button>
          </div>
          <div>
            <ui-min-max-scale-group
              [parentForm]="editmapForm"
              tipMin="Echelle minimale en deçà de laquelle l'utilisateur ne pourra plus dézoomer"
              tipMax="Echelle maximale au delà le laquelle l'utilisateur ne pourra plus zoomer"
            ></ui-min-max-scale-group>
            <ui-projection-select
              [parentForm]="editmapForm"
            ></ui-projection-select>
          </div>
          <div class="form-group">
            <label>Diffusion</label>
            <div class="row">
              <div class="col">
                <label class="form-check form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input exposed-intranet"
                    [checked]="isExposedToIntranet$ | async"
                    (change)="toggleExposeToIntranet()"
                  />Intranet</label
                >
              </div>
              <div class="col">
                <label class="form-check form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input exposed-internet"
                    [checked]="isExposedToInternet$ | async"
                    (change)="toggleExposeToInternet()"
                  />Internet</label
                >
              </div>
            </div>
            <div class="row mt-2">
              <div
                class="col py-2 alert-success"
                *ngIf="isActuallyExposed(); else notExposed"
              >
                La carte est diffusée et accessible à l'adresse
                suivante&nbsp;:<br />
                <small
                  ><a target="_blank" [href]="getExposedMapUrl()">{{
                    getExposedMapUrl()
                  }}</a></small
                >
                <div *ngIf="getExposedMapOGCServiceUrl()">
                  Le service WMS exposant les couches métiers de la carte est
                  diponible à l'adresse suivante&nbsp;:<br />
                  <small
                    ><code>{{ getExposedMapOGCServiceUrl() }}</code></small
                  >
                </div>
                <div *ngIf="getExposedMapQGisOwsServiceUrl()">
                  Le service WMS exposant les couches QGIS de la carte est
                  disponible à l'adresse suivante :<br />
                  <small
                    ><code>{{ getExposedMapQGisOwsServiceUrl() }}</code></small
                  >
                </div>
                <div class="text-warning">
                  <small>
                    Attention : les services externes OGC ne sont pas cascadés.
                  </small>
                </div>
              </div>
              <ng-template #notExposed>
                <div class="col py-2 alert-danger">
                  La carte n'est pas encore diffusée.
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Annuler
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="editmapForm.invalid"
    >
      Enregistrer
    </button>
  </div>
</form>
