import { Injectable } from '@angular/core'
import { MapCompositionService } from '@ui/data-access-carto-map-production'
import { MapFacade } from '@ui/feature/mapstate'
import { BehaviorSubject, Observable } from 'rxjs'
import { switchMap, take, map } from 'rxjs/operators'
import { MAP_PRODUCTION_ROUTE } from '../routes'
import { NotificationFacade, RouterFacade } from '../store/facade'
import { ResourceFacade } from '@ui/ui/resources'
import { SseService } from '@ui/data-access-carto-async'

@Injectable({
  providedIn: 'root',
})
export class DuplicateMapService {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(
    private routerFacade_: RouterFacade,
    private mapFacade_: MapFacade,
    private notificationFacade_: NotificationFacade,
    private mapComposition_: MapCompositionService,
    private resourceFacade_: ResourceFacade,
    private sseService_: SseService
  ) {}

  duplicateMap() {
    this.mapFacade_.info$
      .pipe(
        take(1),
        switchMap((info) => {
          this.loading$.next(true)
          return this.sseService_
            .wrapApiCall('END_DUPLICATE', () =>
              this.mapComposition_.duplicateMap(info.id)
            )
            .pipe(map((message) => message.targetMapId))
        })
      )
      .subscribe({
        next: (newMapId) => {
          this.loading$.next(false)
          this.resourceFacade_.refreshMaps()
          this.routerFacade_.go({
            path: [`${MAP_PRODUCTION_ROUTE}/${newMapId}`],
          })
          this.notificationFacade_.showNotification(
            'success',
            'La carte a bien été dupliquée',
            3000
          )
        },
        error: () => {
          this.loading$.next(false)
          this.notificationFacade_.showNotification(
            'danger',
            'La duplication de la carte a échoué',
            5000
          )
        },
      })
  }

  isDisabled(): Observable<boolean> {
    return this.mapFacade_.isDefault$
  }
}
