import { Injectable } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import {
  isMapDefault,
  Layer,
  LayerPublicationStatus,
  LayerView,
  Map,
  MapExpositionStatus,
  PublicationReport,
  ViewUtilsService,
} from '@ui/feature/shared'
import { isEqual } from 'lodash'
import { from, Observable, of, Subject } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pairwise,
} from 'rxjs/operators'
import {
  GroupFolding,
  GroupPatch,
  Info,
  LayerAttributes,
  LayerError,
  LayerMove,
  LayerOpacity,
  LayerViewStyle,
  MapElementAdd,
  MapElementUpdate,
  MapElementVisibility,
  PositionedMapElement,
  Style,
  Styles,
  View,
  ViewPatch,
} from '../map.model'
import { UtilsService } from '../service/utils.service'
import {
  AddMapElement,
  MoveLayer,
  REFRESH_LAYER,
  RefreshLayer,
  RemoveGroup,
  RemoveLayer,
  SetActualCenter,
  SetActualExtent,
  SetActualZoom,
  SetFromSpec,
  SetGroupFolding,
  SetGroupVisibility,
  SetLayerAttributes,
  SetLayerError,
  SetLayerOpacity,
  SetLayerPublicationStatus,
  SetLayerVisibility,
  SetExpositionStatus,
  SetView,
  UpdateGroup,
  UpdateLayerView,
  UpdateMap,
  UpdateVectorStyle,
  UPDATE_LAYER_ON_DUPLICATED_STYLE,
  SetMapQgisInfo,
  SetMapPublicationReport,
  SetExpositionStatusPublished,
  UpdateMapElement,
} from './map.actions'
import { MapPartialState } from './map.reducer'
import {
  getAncestorElementVisible,
  getLayerPublicationStatuses,
  getVectorLayerStyles,
  getMapActualCenter,
  getMapActualExtent,
  getMapActualZoom,
  getMapBounds,
  getMapElementDescendantsCount,
  getMapElements,
  getMapExpositionScope,
  getMapInfo,
  getMapRequestedCenter,
  getMapRequestedZoom,
  getMapSpec,
  getOrderedMapElements,
  getExpositionStatus,
  getRootMapElementId,
  getPublicationReport,
  getMapQgisInfo,
  getMapSpecLayersInOriginError,
} from './map.selectors'
import { GeoserverSldStyleParser as SldStyleParser } from '@ui/feature/shared'
import { QGisProjectInfoModel } from '@ui/data-access-carto-map-production'

@Injectable()
export class MapFacade {
  center$ = this.store.pipe(select(getMapActualCenter))
  requestedCenter$ = this.store.pipe(select(getMapRequestedCenter))
  zoom$ = this.store.pipe(select(getMapActualZoom))
  extent$ = this.store.pipe(select(getMapActualExtent))
  resolution$ = this.zoom$.pipe(map(this.viewUtils.getResolutionFromZoom))
  spec$ = this.store.pipe(select(getMapSpec))
  qgisInfo$ = this.store.pipe(select(getMapQgisInfo))
  scaleDenominator$ = this.resolution$.pipe(
    map(this.viewUtils.getScaleDenominatorFromResolution)
  )
  requestedZoom$ = this.store.pipe(select(getMapRequestedZoom))
  info$ = this.store.pipe(
    select(getMapInfo),
    distinctUntilChanged<Info>(isEqual)
  )
  bounds$ = this.store.pipe(select(getMapBounds), distinctUntilChanged(isEqual))
  mapSpecLayersInOriginError$ = this.store.pipe(
    select(getMapSpecLayersInOriginError),
    distinctUntilChanged<Layer[]>(isEqual)
  )

  mapElements$ = this.store.pipe(select(getMapElements))
  addedMapElement$: Observable<PositionedMapElement> = this.mapElements$.pipe(
    pairwise(),
    mergeMap(([prevMapElements, newMapElements]) => {
      const values = []
      for (const id in newMapElements) {
        if (!(id in prevMapElements)) {
          const elementId = parseInt(id, 10)
          const parent = this.utils.findParent(elementId, newMapElements)

          // no parent means it's the root element
          if (!parent) {
            continue
          }
          values.push({
            ...newMapElements[id],
            _parentId: parent.id,
            _position: this.utils.findPositionInParent(
              elementId,
              newMapElements
            ),
          })
        }
      }
      return of(...values)
    })
  )
  removedMapElement$: Observable<PositionedMapElement> = this.mapElements$.pipe(
    pairwise(),
    mergeMap(([prevMapElements, newMapElements]) => {
      const values = []
      for (const id in prevMapElements) {
        if (!(id in newMapElements)) {
          const elementId = parseInt(id, 10)
          const parent = this.utils.findParent(elementId, prevMapElements)
          values.push({
            ...prevMapElements[id],
            _parentId: parent.id,
            _position: this.utils.findPositionInParent(
              elementId,
              prevMapElements
            ),
          })
        }
      }
      return of(...values)
    })
  )
  updatedMapElement$: Observable<PositionedMapElement> = this.mapElements$.pipe(
    pairwise(),
    mergeMap(([prevMapElements, newMapElements]) => {
      const values = []
      for (const id in newMapElements) {
        if (
          id in prevMapElements &&
          (prevMapElements[id]._version || 0) <
            (newMapElements[id]._version || 0)
        ) {
          const elementId = parseInt(id, 10)
          const parent = this.utils.findParent(elementId, newMapElements)
          values.push({
            ...newMapElements[id],
            _parentId: parent.id,
            _position: this.utils.findPositionInParent(
              elementId,
              newMapElements
            ),
          })
        }
      }
      return of(...values)
    })
  )
  movedMapElement$: Observable<PositionedMapElement> = this.mapElements$.pipe(
    pairwise(),
    mergeMap(([prevMapElements, newMapElements]) => {
      const values = []
      for (const id in newMapElements) {
        if (id in prevMapElements) {
          const newMapElement = newMapElements[id]
          const prevMapElement = prevMapElements[id]
          if (
            'childrenId' in newMapElement &&
            'childrenId' in prevMapElement &&
            newMapElement.childrenId !== prevMapElement.childrenId &&
            Array.isArray(newMapElement.childrenId) &&
            Array.isArray(prevMapElement.childrenId)
          ) {
            this.utils.findMovedElements(
              prevMapElement.childrenId,
              newMapElement.childrenId,
              (layerId) => {
                const elementId = parseInt(layerId, 10)
                const parent = this.utils.findParent(elementId, newMapElements)
                values.push({
                  ...newMapElements[layerId],
                  _parentId: parent.id,
                  _position: this.utils.findPositionInParent(
                    elementId,
                    newMapElements
                  ),
                })
              }
            )
          }
        }
      }
      return of(...values)
    })
  )
  rootMapElementId$ = this.store.pipe(
    select(getRootMapElementId),
    distinctUntilChanged()
  )

  updateLayerOnDuplicatedStyle$ = this.actions$.pipe(
    ofType(UPDATE_LAYER_ON_DUPLICATED_STYLE)
  )
  refreshLayer$ = this.actions$.pipe(ofType(REFRESH_LAYER))

  vectorLayerStyles$ = this.store.pipe(select(getVectorLayerStyles))
  updatedVectorStyle$ = new Subject<LayerViewStyle>()
  removedVectorStyle$ = new Subject<number>()

  private _sldParser: SldStyleParser

  expositionScope$ = this.store.pipe(
    select(getMapExpositionScope),
    distinctUntilChanged()
  )
  expositionStatus$ = this.store.pipe(select(getExpositionStatus))
  publicationReport$ = this.store.pipe(select(getPublicationReport))

  // TODO: put this business rule to a more appropriate place?
  isDefault$ = this.spec$.pipe(map((spec) => isMapDefault(spec)))

  layerPublicationStatuses$ = this.store.pipe(
    select(getLayerPublicationStatuses)
  )

  constructor(
    private store: Store<MapPartialState>,
    private actions$: Actions,
    private utils: UtilsService,
    private viewUtils: ViewUtilsService
  ) {
    let prevStyles: Styles
    this.vectorLayerStyles$.subscribe((styles) => {
      for (const id in styles) {
        if (!styles.hasOwnProperty(id)) continue
        const prevVersion =
          !prevStyles || !prevStyles[id] || !prevStyles[id]._version
            ? 0
            : prevStyles[id]._version
        const style = styles[id]
        const version =
          '_version' in style && style._version ? style._version : 1
        if (version > prevVersion) {
          this.updatedVectorStyle$.next({ viewId: parseInt(id, 10), style })
        }
      }
      for (const id in prevStyles) {
        if (!prevStyles.hasOwnProperty(id)) continue
        if (!styles[id]) {
          this.removedVectorStyle$.next(parseInt(id, 10))
        }
      }
      prevStyles = styles
    })

    this._sldParser = new SldStyleParser()
  }

  getOrderedMapElements(parentId?: number) {
    return this.store.pipe(select(getOrderedMapElements, parentId || null))
  }

  getMapElementDescendantsCount(elementId: number) {
    return this.store.pipe(select(getMapElementDescendantsCount, elementId))
  }

  getAncestorElementVisible(elementId: number) {
    return this.store.pipe(select(getAncestorElementVisible, elementId))
  }

  setView(view: View) {
    this.store.dispatch(new SetView(view))
  }

  setActualCenter(center: [number, number]) {
    this.store.dispatch(new SetActualCenter(center))
  }

  setActualZoom(zoom: number) {
    this.store.dispatch(new SetActualZoom(zoom))
  }

  setActualExtent(extent: number[]) {
    this.store.dispatch(new SetActualExtent(extent))
  }

  fromSpec(spec: Map) {
    this.store.dispatch(new SetFromSpec(spec))
  }

  setLayerVisibility(layerVisibility: MapElementVisibility) {
    this.store.dispatch(new SetLayerVisibility(layerVisibility))
  }

  setGroupVisibility(groupVisibility: MapElementVisibility) {
    this.store.dispatch(new SetGroupVisibility(groupVisibility))
  }

  setLayerOpacity(layerOpacity: LayerOpacity) {
    this.store.dispatch(new SetLayerOpacity(layerOpacity))
  }

  setLayerAttributes(layerAttributes: LayerAttributes) {
    this.store.dispatch(new SetLayerAttributes(layerAttributes))
  }

  updateGroup(patch: GroupPatch) {
    this.store.dispatch(new UpdateGroup(patch))
  }

  setGroupFolding(groupFolding: GroupFolding) {
    this.store.dispatch(new SetGroupFolding(groupFolding))
  }

  updateLayerView(view: ViewPatch) {
    this.store.dispatch(new UpdateLayerView(view))
  }

  setLayerError(layerError: LayerError) {
    this.store.dispatch(new SetLayerError(layerError))
  }

  removeLayer(layerId: number) {
    this.store.dispatch(new RemoveLayer(layerId))
  }

  removeGroup(groupId: number) {
    this.store.dispatch(new RemoveGroup(groupId))
  }

  moveLayer(layerMove: LayerMove) {
    this.store.dispatch(new MoveLayer(layerMove))
  }

  addMapElement(mapElementAdd: MapElementAdd) {
    this.store.dispatch(new AddMapElement(mapElementAdd))
  }

  updateMapElement(mapElementUpdate: MapElementUpdate) {
    this.store.dispatch(new UpdateMapElement(mapElementUpdate))
  }

  updateMap(patch: Info) {
    this.store.dispatch(new UpdateMap(patch))
  }

  updateStyle(layerViewStyle: LayerViewStyle): void {
    this.store.dispatch(new UpdateVectorStyle(layerViewStyle))
  }

  refreshLayer(viewId: number): void {
    this.store.dispatch(new RefreshLayer(viewId))
  }

  setExpositionStatus(status: MapExpositionStatus) {
    this.store.dispatch(new SetExpositionStatus(status))
  }

  setExpositionStatusPublished(published: boolean) {
    this.store.dispatch(new SetExpositionStatusPublished(published))
  }

  getViewById(viewId: number): Observable<LayerView> {
    return this.mapElements$.pipe(
      map((layers) => {
        let view = null
        for (const id in layers) {
          if (layers.hasOwnProperty(id)) {
            const layer = layers[id]
            if ('views' in layer && layer.views[0].id === viewId) {
              view = layer.views[0]
              break
            }
          }
        }
        return view
      })
    )
  }

  getStyleByViewId(viewId: number): Observable<Style> {
    return this.vectorLayerStyles$.pipe(
      map((styles) => styles[viewId]),
      distinctUntilChanged()
    )
  }

  getStyleAsSldByViewId(viewId: number): Observable<string> {
    return this.getStyleByViewId(viewId).pipe(
      filter((style) => !!style),
      mergeMap((style) => from(this._sldParser.writeStyle(style))),
      // Remove empty spaces between XML tags
      map(({ output: sld }) => sld.replace(/>\s+</g, '><'))
    )
  }

  setLayerPublicationStatus(status: LayerPublicationStatus) {
    this.store.dispatch(new SetLayerPublicationStatus(status))
  }

  getPublicationStatusByLayerId(
    layerId: number
  ): Observable<LayerPublicationStatus> {
    return this.layerPublicationStatuses$.pipe(
      filter((statuses) => !!statuses[layerId]),
      map((statuses) => statuses[layerId]),
      distinctUntilChanged()
    )
  }
  setMapQgisInfo(qgisInfo: QGisProjectInfoModel) {
    this.store.dispatch(new SetMapQgisInfo(qgisInfo))
  }

  setPublicationReport(report: PublicationReport) {
    this.store.dispatch(new SetMapPublicationReport(report))
  }
}
