<ul class="nav nav-tabs nav-justified px-1 pt-1 pb-0 bg-light">
  <li class="nav-item position-relative" *ngIf="isGeometryPolygon()">
    <a
      class="nav-link px-4"
      href="#"
      [ngClass]="{ active: (currentTab$ | async) === 'builtin_shapes' }"
      (click)="switchTab('builtin_shapes'); $event.preventDefault()"
    >
      <span
        class="far fa-check-circle fa-sm text-primary tab-selected"
        *ngIf="selectedTab === 'builtin_shapes'"
      ></span>
      Hachures
    </a>
  </li>
  <li class="nav-item position-relative">
    <a
      class="nav-link px-4"
      href="#"
      [ngClass]="{ active: (currentTab$ | async) === 'builtin' }"
      (click)="switchTab('builtin'); $event.preventDefault()"
    >
      <span
        class="far fa-check-circle fa-sm text-primary tab-selected"
        *ngIf="selectedTab === 'builtin'"
      ></span>
      Géométrique
    </a>
  </li>
  <li class="nav-item position-relative" *ngIf="showImageTab()">
    <a
      class="nav-link px-4"
      href="#"
      [ngClass]="{ active: (currentTab$ | async) === 'images' }"
      (click)="switchTab('images'); $event.preventDefault()"
    >
      <span
        class="far fa-check-circle fa-sm text-primary tab-selected"
        *ngIf="selectedTab === 'images'"
      ></span>
      Image
    </a>
  </li>
  <li class="nav-item position-relative">
    <a class="nav-link px-4 disabled" href="#">Graphique</a>
  </li>
</ul>

<div>
  <div class="row">
    <div class="col-6">
      <ui-symbolizer-field
        class="mt-2"
        label="couleur"
        [fieldType]="fieldTypes.COLOR"
        [value]="currentColor"
        [locked]="colorsLocked"
        (fieldUpdated)="setColor($event)"
      >
      </ui-symbolizer-field>
    </div>
    <div class="col-6">
      <select
        class="form-control form-control-sm mt-1 font-selection"
        *ngIf="(currentTab$ | async) === 'images'"
        (change)="selectedFont$.next($event.target.value)"
      >
        <option
          *ngFor="let font of symbolFonts"
          [selected]="font === (selectedFont$ | async)"
        >
          {{ font }}
        </option>
      </select>
    </div>
  </div>
  <ui-item-grid
    [data]="symbolsList$ | async"
    colCount="6"
    rowCount="3"
    itemHeightCss="70px"
    itemWidthCss="70px"
    [itemTemplate]="symbolTemplate"
    [sharedContext]="this"
  >
  </ui-item-grid>
</div>

<ng-template #symbolTemplate let-symbol="item" let-component="shared">
  <button
    type="button"
    class="btn btn-light"
    style="padding: 2px"
    [ngClass]="{ 'border-primary': component.isSameAsInput(symbol) }"
    (click)="component.selectSymbol(symbol)"
  >
    <ui-symbol-preview
      [symbol]="symbol"
      [color]="component.currentColor"
    ></ui-symbol-preview>
  </button>
</ng-template>
