/**
 * Map Production API
 * This API covers functions related to cartographic composition (creation of maps, styles)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * indicates where the layer view comes from (i.e. a local service, a remote service, or boc -back-office-common-)
 */
export type LayerViewOriginTypeModel = 'BOC' | 'LOCAL' | 'REMOTE' | 'QGIS'

export const LayerViewOriginTypeModel = {
  BOC: 'BOC' as LayerViewOriginTypeModel,
  LOCAL: 'LOCAL' as LayerViewOriginTypeModel,
  REMOTE: 'REMOTE' as LayerViewOriginTypeModel,
  QGIS: 'QGIS' as LayerViewOriginTypeModel,
}
