import { createFeatureSelector, createSelector } from '@ngrx/store'
import { MAP_FEATURE_KEY, MapState } from './map.reducer'
import { Info, MapElementDescendants } from '../map.model'
import {
  BoundingBox,
  getParentElement,
  Group,
  hasMapElementErrors,
  isMapElementGroup,
  isRootElement,
  Layer,
  Map,
  MapElement,
  MapExpositionStatus,
} from '@ui/feature/shared'

export const getMapState = createFeatureSelector<MapState>(MAP_FEATURE_KEY)

export const getMapRequestedCenter = createSelector(
  getMapState,
  (state: MapState) => state.view.center
)

export const getMapRequestedZoom = createSelector(
  getMapState,
  (state: MapState) => state.view.zoom
)

export const getMapActualCenter = createSelector(
  getMapState,
  (state: MapState) => state.actualView.center
)

export const getMapActualZoom = createSelector(
  getMapState,
  (state: MapState) => state.actualView.zoom
)

export const getMapActualExtent = createSelector(
  getMapState,
  (state: MapState) => state.actualView.extent
)

export const getMapSpec = createSelector(
  getMapState,
  (state: MapState): Map => state.spec
)

export const getMapInfo = createSelector(getMapSpec, (spec: Map): Info => {
  return {
    id: spec.id,
    name: spec.name,
    title: spec.title,
    description: spec.description,
    creationDate: spec.creationDate,
    crsCode: spec.crsCode,
    minScale: spec.minScale,
    maxScale: spec.maxScale,
    bounds: spec.bounds,
    protected: spec.protected,
    owsUrl: spec.expositionStatus && spec.expositionStatus.owsUrl,
    qGisOwsUrl: spec.expositionStatus && spec.expositionStatus.qgisOwsUrl,
  }
})

export const getMapBounds = createSelector(
  getMapInfo,
  (spec: Info): BoundingBox => spec.bounds
)

export const getMapElements = createSelector(
  getMapState,
  getMapSpec,
  (state: MapState, spec: Map) => spec.elementsById
)

// if given null as parentId, the first level of elements are returned
export const getOrderedMapElements = createSelector(
  getMapState,
  getMapSpec,
  (state: MapState, spec: Map, parentId: number | null): MapElement[] => {
    const parent = spec.elementsById[parentId || spec.rootElementId]
    if (!parent || !isMapElementGroup(parent)) return []
    return (parent as Group).childrenId.map((id) => spec.elementsById[id])
  }
)

export const getMapElementDescendantsCount = createSelector(
  getMapState,
  getMapSpec,
  (state: MapState, spec: Map, elementId: number): MapElementDescendants => {
    const element = spec.elementsById[elementId || spec.rootElementId]

    let layerCount = 0
    let groupCount = 0

    if (!element || !isMapElementGroup(element))
      return { layerCount, groupCount }

    function count(el) {
      if (isMapElementGroup(el)) {
        groupCount++
        el.childrenId.map((id) => spec.elementsById[id]).forEach(count)
      } else layerCount++
    }
    groupCount-- // to account for the current group
    count(element)

    return {
      layerCount,
      groupCount,
    }
  }
)

export const getAncestorElementVisible = createSelector(
  getMapState,
  getMapSpec,
  (state: MapState, spec: Map, elementId: number): boolean => {
    let parent = getParentElement(spec, elementId)
    if (!parent) {
      return true
    }
    while (!isRootElement(parent) && parent.visible) {
      parent = getParentElement(spec, parent.id)
    }
    return parent.visible
  }
)

export const getRootMapElementId = createSelector(
  getMapState,
  getMapSpec,
  (state: MapState, spec: Map) => spec.rootElementId
)

export const getVectorLayerStyles = createSelector(
  getMapState,
  (state: MapState) => state.vectorLayerStyles
)

export const getExpositionStatus = createSelector(
  getMapSpec,
  (spec: Map) => spec.expositionStatus
)

export const getMapExpositionScope = createSelector(
  getExpositionStatus,
  (status: MapExpositionStatus) => status.expositionScope
)

export const getLayerPublicationStatuses = createSelector(
  getMapState,
  (state: MapState) => state.layerPublicationStatuses
)

export const getPublicationReport = createSelector(
  getMapState,
  (state: MapState) => state.mapPublicationReport
)
export const getMapQgisInfo = createSelector(
  getMapState,
  (state: MapState) => state.qgisInfo
)

export const getMapSpecLayersInOriginError = createSelector(
  getMapSpec,
  (mapSpec: Map): Layer[] => {
    return <Layer[]>(
      Object.values(mapSpec.elementsById).filter(hasMapElementErrors)
    )
  }
)
