import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { Observable } from 'rxjs'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  @Input() title: string
  @Input() infoMessage: string = null
  @Input() successMessage: string = null
  @Input() confirmLabel = 'Enregistrer'
  @Input() confirmDisabled = false
  @Input() confirmButtonVisible = true
  @Input() cancelLabel = 'Annuler'
  @Input() large = false

  @ViewChild('template') templateRef: TemplateRef<any>

  modalRef: BsModalRef
  loading = false
  errorMessage: string

  // this callback should return a short-lived observable
  // once this observable completes, the modal closes; if it errors, the error
  // message will be shown on top of the modal
  @Input() confirmHandler: () => Observable<any>
  @Input() cancelHandler: () => void = () => {}

  constructor(private modalService_: BsModalService) {}

  onCancel() {
    this.cancelHandler()
    this.close()
  }

  onConfirm() {
    if (this.confirmDisabled) {
      return
    }

    this.loading = true
    this.errorMessage = null
    this.confirmHandler().subscribe({
      next: () => {},
      error: (e) => {
        this.loading = false
        this.errorMessage = e.message || e
      },
      complete: () => {
        this.close()
      },
    })
  }

  open() {
    this.modalRef = this.modalService_.show(this.templateRef, {
      ignoreBackdropClick: true,
      class:
        (this.large ? 'modal-lg' : undefined) + ' ' + 'modal-dialog-scrollable',
    })
    const sub = this.modalService_.onHidden.subscribe(() => {
      this.loading = false
      this.errorMessage = null
      sub.unsubscribe()
    })
  }

  close() {
    if (!this.modalRef) return
    this.modalRef.hide()
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onConfirm()
    }
  }
}
