import { Injectable } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { Location } from '@angular/common'
import { LoadingFacade } from '../store'

@Injectable({
  providedIn: 'root',
})
export class QGisProjectUploadService {
  currentFile: File = null
  errorMsg: string

  constructor(
    private location: Location,
    private loadingFacade: LoadingFacade
  ) {}

  selectFile(event: any): void {
    const fileName = event.target.files[0].name
    const fileSize = event.target.files[0].size

    const regexp = new RegExp('^\\w+.gpkg$')
    if (this.getFileExtension(fileName) !== 'gpkg') {
      this.errorMsg = 'Le fichier doit être au format .gpkg'
      this.currentFile = null
      return
    }
    if (!regexp.test(fileName)) {
      this.errorMsg =
        'Le nom du fichier ne doit contenir que des caractères alphanumériques ou _'
      this.currentFile = null
      return
    }
    if (fileSize > 10e8) {
      this.errorMsg = 'La taille maximale autorisée est de 1GO'
      this.currentFile = null
      return
    }
    this.currentFile = event.target.files[0]
  }

  getFileExtension(fileName: string) {
    if (typeof fileName !== 'string') return null
    return fileName.split('.')[fileName.split('.').length - 1]
  }

  resetForm(form: FormGroup) {
    this.loadingFacade.updateProgressValue(0)
    form.reset()
  }
}
