import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  CreateMapRequestModel,
  MapCompositionService,
  MapFullModel,
} from '@ui/data-access-carto-map-production'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { map } from 'rxjs/operators'
import { MAP_PRODUCTION_ROUTE } from '../../../routes'
import { RouterFacade } from '../../../store/facade/router.facade'
import { CreateMapModalComponent } from './create-map-modal/create-map-modal.component'
import { Observable, Subscription } from 'rxjs'
import { ResourceFacade } from '@ui/ui/resources'

@Component({
  selector: 'ui-create-map',
  template: '',
})
export class CreateMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<void>
  modalRef: BsModalRef
  sub = new Subscription()

  constructor(
    private modalService_: BsModalService,
    private mapComposition_: MapCompositionService,
    public routerFacade_: RouterFacade,
    private resourceFacade_: ResourceFacade
  ) {}

  ngOnInit() {
    this.sub.add(this.showModal$.subscribe(() => this.openModal()))
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  openModal() {
    this.modalRef = this.modalService_.show(CreateMapModalComponent, {
      ignoreBackdropClick: true,
    })
    // save observable is long lived; handle unsubscribe using the Subscription object
    this.sub.add(
      this.modalRef.content.save.subscribe(
        (formValues: CreateMapRequestModel) => this.createMap(formValues)
      )
    )
  }

  closeModal() {
    this.modalRef.hide()
  }

  dispatchError(error: any): void {
    let message = 'La création de la carte a échoué'
    if (error.status === 409) {
      message = 'Une carte avec ce nom existe déjà'
    }
    this.modalRef.content.errorMsg = message
  }

  createMap(createMapRequest: CreateMapRequestModel) {
    this.modalRef.content.showLoading = true
    this.mapComposition_
      .createMap(createMapRequest)
      .pipe(map((mapSpecs: MapFullModel) => mapSpecs.id))
      .subscribe({
        next: (id) => {
          this.closeModal()
          this.resourceFacade_.refreshMaps()
          this.routerFacade_.go({
            path: [`${MAP_PRODUCTION_ROUTE}/${id}`],
          })
        },
        error: (error) => this.dispatchError(error),
      })
  }
}
