import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  DataSchema,
  GETLEGENDGRAPHIC_LAYER,
  GETLEGENDGRAPHIC_URL,
  LayerView,
  GeoserverSldStyleParser as SldStyleParser,
} from '@ui/feature/shared'
import { Filter, ScaleDenominator, SymbolizerKind } from 'geostyler-style'
import { cloneDeep } from 'lodash'

import {
  ELEMENT_TYPE,
  FillSymbolizer,
  LineSymbolizer,
  MarkSymbolizer,
  Rule,
  Symbolizer,
  TextSymbolizer,
} from '../style.model'
import { SYMBOLIZER_FIELD_TYPE } from '../symbolizer-field/symbolizer-field.component'

const defaultSymbolizers = {
  Mark: {
    kind: 'Mark',
    wellKnownName: 'circle',
    radius: 8,
    color: '#0000FF',
    fillOpacity: 1,
    strokeWidth: 1,
    strokeColor: '#0000FF',
    strokeOpacity: 1,
  } as MarkSymbolizer,
  Line: {
    kind: 'Line',
    width: 1,
    color: '#0000FF',
    opacity: 1,
  } as LineSymbolizer,
  Fill: {
    kind: 'Fill',
    color: '#0000FF',
    'graphic-margin': '0',
    fillOpacity: 0.5,
    outlineColor: '#0000FF',
    outlineOpacity: 1,
    outlineWidth: 1,
  } as FillSymbolizer,
  Text: {
    kind: 'Text',
    label: '',
    color: '#000000',
    size: 15,
    opacity: 1,
    haloColor: '#ffffff',
    haloWidth: 3,
    offset: [0, 0],
  } as TextSymbolizer,
}

const sldParser = new SldStyleParser()

@Component({
  selector: 'ui-style-editor-rule',
  templateUrl: './style-editor-rule.component.html',
  styleUrls: ['./style-editor-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleEditorRuleComponent implements OnInit, OnChanges {
  @Input() rule: Rule
  @Input() index: number
  @Input() active = false
  @Input() elementType: ELEMENT_TYPE
  @Input() dataSchema: DataSchema
  @Input() deletable = true
  @Input() filterable = true
  @Input() draggable = true
  @Input() fixedSymbolizers = false // if true, prevents symbolizers from being deleted, created and ordered
  @Input() nameEditable = true
  @Input() colorsLocked = false
  @Input() activeImageGeometryPolygon = true
  @Input() layerView: LayerView
  @Output() toggled = new EventEmitter()
  @Output() changed = new EventEmitter<Rule>()
  @Output() deleted = new EventEmitter()

  private elementTypes = ELEMENT_TYPE
  private fieldTypes = SYMBOLIZER_FIELD_TYPE

  legendUrl = ''

  symbolizerKinds = {
    Mark: { label: 'symbole', value: 'Mark' },
    Line: { label: 'trait', value: 'Line' },
    Fill: { label: 'remplissage', value: 'Fill' },
    Text: { label: 'texte', value: 'Text' },
  }

  get symbolizers() {
    return this.rule.symbolizers
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    @Optional() @Inject(GETLEGENDGRAPHIC_URL) private getLegendGraphicUrl,
    @Optional() @Inject(GETLEGENDGRAPHIC_LAYER) private getLegendGraphicLayer
  ) {}

  ngOnInit() {
    this.addMissingId()
    this.computeLegendUrl(this.rule)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rule) {
      this.addMissingId()
      this.computeLegendUrl(changes.rule.currentValue)
    }
  }

  addMissingId() {
    if (
      this.rule.symbolizers.some((symbolizer) => symbolizer._id === undefined)
    ) {
      this.rule = {
        ...this.rule,
        symbolizers: this.rule.symbolizers.map((symbolizer) =>
          symbolizer._id !== undefined
            ? symbolizer
            : ({
                ...symbolizer,
                _id: Math.floor(Math.random() * 100000).toString(),
              } as Symbolizer)
        ),
      }
    }
  }

  computeLegendUrl(rule: Rule) {
    sldParser
      .writeStyle({ name: 'preview', rules: [rule as any] }) // FIXME: remove 'as any'
      .then(({ output: sld }) => {
        const url = new URL(this.getLegendGraphicUrl, window.location.origin)
        url.searchParams.set('SERVICE', 'WMS')
        url.searchParams.set('REQUEST', 'GetLegendGraphic')
        url.searchParams.set('FORMAT', 'image/png')
        url.searchParams.set('LAYER', this.getLegendGraphicLayer)
        url.searchParams.set('HEIGHT', '40') // size of the <img> element
        url.searchParams.set('WIDTH', '40')
        url.searchParams.set('SLD_BODY', sld)
        this.legendUrl = url.toString()
        this.changeDetector.detectChanges() // manual change detection
      })
  }

  toggle() {
    this.toggled.emit()
  }
  emitNewRule(rule: Rule) {
    this.changed.emit(rule)
  }
  delete() {
    this.deleted.emit()
  }

  addSymbolizer(kind: SymbolizerKind) {
    this.emitNewRule({
      ...this.rule,
      symbolizers: [
        cloneDeep(defaultSymbolizers[kind]),
        ...this.rule.symbolizers,
      ],
    })
  }

  deleteSymbolizer(index) {
    this.emitNewRule({
      ...this.rule,
      symbolizers: this.rule.symbolizers.filter((symb, i) => i !== index),
    })
  }

  handleSymbolizerPropertyChange(index, property, value) {
    this.handleSymbolizerPropertiesChange(index, { [property]: value })
  }

  handleSymbolizerPropertiesChange(index, changes) {
    this.emitNewRule({
      ...this.rule,
      symbolizers: this.rule.symbolizers.map((symb, i) =>
        i !== index
          ? symb
          : {
              ...symb,
              ...changes,
            }
      ),
    })
  }

  handleSymbolizerOrderChange(index: number, newIndex: number) {
    const symbolizers = this.rule.symbolizers.filter((sym, i) => i !== index)
    symbolizers.splice(newIndex, 0, this.rule.symbolizers[index])
    this.emitNewRule({
      ...this.rule,
      symbolizers,
    })
  }

  handleNameChange(name: string) {
    this.emitNewRule({
      ...this.rule,
      name,
    })
  }

  symbolizerTracker(index: number, symbolizer: Symbolizer) {
    return symbolizer._id
  }

  addFilter() {
    let attribute = ''
    // Set an attribute so that comparator will be correctly initialized
    if (this.dataSchema && 'properties' in this.dataSchema) {
      attribute = Object.keys(this.dataSchema.properties)[0] || ''
    }
    this.emitNewRule({
      ...this.rule,
      filter: ['==', attribute, ''],
    })
  }

  addScale() {
    const scaleDenominator: ScaleDenominator = {}
    if (this.layerView?.minScale) scaleDenominator.min = this.layerView.minScale
    if (this.layerView?.maxScale) scaleDenominator.max = this.layerView.maxScale
    this.emitNewRule({
      ...this.rule,
      scaleDenominator,
    })
  }

  deleteFilter() {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { filter, ...rule } = this.rule
    this.emitNewRule(rule)
  }

  deleteScale() {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { scaleDenominator, ...rule } = this.rule
    this.emitNewRule(rule)
  }

  handleFilterChange(filter: Filter) {
    this.emitNewRule({
      ...this.rule,
      filter: filter,
    })
  }

  handleScaleChange(scaleDenominator: ScaleDenominator) {
    this.emitNewRule({
      ...this.rule,
      scaleDenominator,
    })
  }

  hasFilter() {
    return !!this.rule.filter
  }
  hasScale() {
    return !!this.rule.scaleDenominator
  }
  hasFill() {
    return this.rule.symbolizers.some((s) => s.kind === 'Fill')
  }
  hasLine() {
    return this.rule.symbolizers.some((s) => s.kind === 'Line')
  }
  hasMark() {
    return this.rule.symbolizers.some((s) => s.kind === 'Mark')
  }
  hasText() {
    return this.rule.symbolizers.some((s) => s.kind === 'Text')
  }

  getIndex(symbolizer) {
    return this.symbolizers.indexOf(symbolizer)
  }
}
