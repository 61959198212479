import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CreateRemoteServerRequestModel } from '@ui/data-access-carto-map-production'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { BsModalRef } from 'ngx-bootstrap/modal'
import 'whatwg-fetch'
import ServerTypeModelEnum = RemoteServerModel.ServerTypeModelEnum
import { UtilsService } from '@ui/feature/mapstate'

export const URL_REGEXP =
  "^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"

@Component({
  selector: 'ui-remote-service-modal',
  templateUrl: './remote-service-modal.component.html',
  styleUrls: ['./remote-service-modal.component.scss'],
})
export class RemoteServerModalComponent implements OnInit {
  form: FormGroup
  errorMsg: string
  serverTypes: ServerTypeModelEnum[] = [
    ServerTypeModelEnum.WMS,
    ServerTypeModelEnum.WFS,
    ServerTypeModelEnum.WMTS,
  ]
  versionLoading = false

  model: RemoteServerModel
  @Output() submitCreate = new EventEmitter<CreateRemoteServerRequestModel>()
  @Output() submitUpdate = new EventEmitter<RemoteServerModel>()
  @Output() cancel = new EventEmitter<boolean>()

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private utilsService: UtilsService
  ) {
    this.form = this.fb.group({
      serverName: ['', [Validators.required, Validators.maxLength(64)]],
      serverURL: ['', [Validators.required, Validators.pattern(URL_REGEXP)]],
      serverType: ['', Validators.required],
      serverVersion: ['', Validators.required],
    })
  }

  ngOnInit() {}

  get f() {
    return this.form.controls
  }

  get creation() {
    return !this.model
  }

  set remoteServer(value: RemoteServerModel) {
    const { serverName, serverURL, serverType, serverVersion } = value
    this.form.setValue({
      serverName,
      serverURL,
      serverType,
      serverVersion,
    })
    this.model = value
  }

  isRequestDisabled() {
    const formValues = this.form.getRawValue()
    const formNotOk = !formValues.serverType || !formValues.serverURL
    return formNotOk || this.versionLoading
  }

  clearVersion() {
    this.f.serverVersion.reset()
  }

  async requestVersion() {
    this.clearVersion()
    this.versionLoading = true

    const response = await this.utilsService.tryVersions(
      this.f.serverURL.value,
      this.f.serverType.value
    )
    if (response.stringVersion) {
      this.f.serverVersion.setValue(response.stringVersion)
    }
    if (response.errorMsg) {
      this.f.serverVersion.setErrors({ msg: response.errorMsg })
    }

    this.versionLoading = false
  }

  onSubmit() {
    if (this.creation) {
      this.submitCreate.emit(this.form.getRawValue())
    } else {
      const id = this.model.id
      this.submitUpdate.emit({ ...this.form.getRawValue(), id })
    }
  }

  onCancel() {
    this.cancel.emit()
    this.modalRef.hide()
  }
}
