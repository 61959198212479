import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { LoadingState } from '../reducers/loading.reducer'
import {
  getprogressStartDate,
  getProgressValue,
  isProcessRunning,
} from '../selectors/loading.selector'
import {
  SetIsProcessRunning,
  SetProgressStartDate,
  UpdateProgressValue,
} from '../actions/loading.actions'

@Injectable({
  providedIn: 'root',
})
export class LoadingFacade {
  progressValue$ = this.store.pipe(select(getProgressValue))
  progressStartDate$ = this.store.pipe(select(getprogressStartDate))
  isProcessRunning$ = this.store.pipe(select(isProcessRunning))

  constructor(private store: Store<LoadingState>) {}

  updateProgressValue(progressValue: number) {
    this.store.dispatch(new UpdateProgressValue(progressValue))
  }

  setIsProcessRunning(value: boolean) {
    this.store.dispatch(new SetIsProcessRunning(value))
  }
  setProgressStartDate(value: number) {
    this.store.dispatch(new SetProgressStartDate(value))
  }
}
