import { createFeatureSelector, createSelector } from '@ngrx/store'
import { LoadingState } from '../reducers/loading.reducer'

export const getLoadingState = createFeatureSelector<LoadingState>('loading')

export const getProgressValue = createSelector(
  getLoadingState,
  (state: LoadingState) => state.progressValue
)
export const isProcessRunning = createSelector(
  getLoadingState,
  (state: LoadingState) => state.isProcessRunning
)

export const getprogressStartDate = createSelector(
  getLoadingState,
  (state: LoadingState) => state.progressStartDate
)
