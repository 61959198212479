import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { cloneDeep } from 'lodash'
import { ELEMENT_TYPE, Rule, Style } from '../style.model'
import { StyleService } from '../style.service'
import { DataSchema, LayerView, isLayerExternalWFS } from '@ui/feature/shared'

/**
 * The component initializes using the given `input` style if any.
 * When outputting styles, the objects are full clones
 */
@Component({
  selector: 'ui-style-editor',
  templateUrl: './style-editor.component.html',
  styleUrls: ['./style-editor.component.scss'],
})
export class StyleEditorComponent implements OnInit, OnChanges {
  @Input() style: Style
  @Input() dataSchema: DataSchema
  @Input() layerView: LayerView
  @Output() outputGeostyler$ = new EventEmitter<Style>()
  activeRule = null
  elementTypes = ELEMENT_TYPE
  activeImageGeometryPolygon = true

  get rules() {
    return this.style.rules
  }

  get geometryType() {
    return this.style._elementType
  }

  set geometryType(value: ELEMENT_TYPE) {
    this.handleElementTypeChange(value)
  }

  constructor(private styleService: StyleService) {}

  ngOnInit() {
    this.sanitizeInput()
    this.addMissingId()
    this.outputGeostyler$.emit(this.style)

    // Deactivate Symbol Images pattern for WFS as it is not supported
    this.activeImageGeometryPolygon = !isLayerExternalWFS(this.layerView)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.style) {
      this.sanitizeInput()
      this.addMissingId()
    }
  }

  addMissingId() {
    if (this.style.rules.some((rule) => rule._id === undefined)) {
      this.style = {
        ...this.style,
        rules: this.style.rules.map((rule) =>
          rule._id !== undefined
            ? rule
            : ({
                ...rule,
                _id: Math.floor(Math.random() * 100000).toString(),
              } as Rule)
        ),
      }
    }
  }

  sanitizeInput() {
    this.style = {
      _elementType: ELEMENT_TYPE.POLYGON,
      name: '',
      rules: [],
      ...this.style,
    }
    this.style.rules = this.style.rules
      .map(this.styleService.getRuleElementTypeMapper(this.style._elementType))
      .map((rule) => this.styleService.ruleCorrector(rule))
  }

  toggleActiveRule(ruleId: string) {
    this.activeRule = this.activeRule !== ruleId ? ruleId : null
  }

  handleRuleDelete(index: number) {
    this.emitOutput({
      ...this.style,
      rules: this.style.rules.filter((e, i) => i !== index),
    })
  }

  handleRuleAdd() {
    this.emitOutput({
      ...this.style,
      rules: [
        {
          name: '',
          symbolizers: [],
        },
        ...this.style.rules,
      ],
    })
  }

  handleRuleChange(index: number, updated: Rule) {
    this.emitOutput({
      ...this.style,
      rules: this.style.rules.map((rule, i) => (i === index ? updated : rule)),
    })
  }

  handleRuleOrderChange(index: number, newIndex: number) {
    const rules = this.style.rules.filter((rule, i) => i !== index)
    rules.splice(newIndex, 0, this.style.rules[index])
    this.emitOutput({
      ...this.style,
      rules,
    })
  }

  handleNameChange(name: string) {
    this.emitOutput({
      ...this.style,
      name,
    })
  }

  // this will also filter out symbolizers of the wrong type
  handleElementTypeChange(elType: ELEMENT_TYPE) {
    this.emitOutput({
      ...this.style,
      rules: this.style.rules.map(
        this.styleService.getRuleElementTypeMapper(elType)
      ),
      _elementType: elType,
    })
  }

  emitOutput(style: Style) {
    this.outputGeostyler$.emit(cloneDeep(style))
  }

  ruleTracker(index: number, rule: Rule) {
    return rule._id
  }

  getIndex(rule) {
    return this.rules.indexOf(rule)
  }

  hasSchemaError() {
    return this.dataSchema && 'error' in this.dataSchema
  }
}
