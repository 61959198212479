import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  InjectionToken,
  Optional,
} from '@angular/core'
import { Router } from '@angular/router'
import {
  OrganizationModel,
  UserManagementService,
} from '@ui/data-access-carto-functional-administration'
import { MAP_PRODUCTION_ROUTE } from '../../../routes'
import { DuplicateMapService } from '../../../services/duplicate-map.service'
import { ModalEventService } from '../../../services/modal-event.service'
import SETTINGS from '../../../../settings'

export const USER_NAME = new InjectionToken<string>('userName')
export const LOGOUT_URL = new InjectionToken<string>('logoutUrl')
export const ORGANIZATION_NAME = new InjectionToken<string>('organizationName')
export const ORGANIZATIONS = new InjectionToken<object>('organizations')

@Component({
  selector: 'ui-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  urls = SETTINGS.externalUrls
  disableImportQgis = SETTINGS.disableQgisMapImport
  public organizations: OrganizationModel[]
  public dropdownContainer = 'body' // will be unset for tests

  constructor(
    @Optional() @Inject(USER_NAME) public userName: string,
    @Optional() @Inject(LOGOUT_URL) public logoutUrl: string,
    @Optional() @Inject(ORGANIZATION_NAME) public organizationName: string,
    @Optional()
    @Inject(ORGANIZATIONS)
    public organizations_: { [key: string]: OrganizationModel },
    public modalEventService_: ModalEventService,
    public userManagement_: UserManagementService,
    public duplicateMapService: DuplicateMapService,
    private _router: Router
  ) {
    this.organizations = Object.values(this.organizations_ || {})
  }

  setCurrentOrganization(orgId: string) {
    this.userManagement_
      .selectOrganizationByBusinessId(orgId)
      .subscribe(() =>
        this._router
          .navigate([MAP_PRODUCTION_ROUTE])
          .then(() => window.location.reload())
      )
  }
}
