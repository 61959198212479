import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'
import 'whatwg-fetch'
import SETTINGS from './settings'

export interface projection {
  code: string
  proj4Def: string
}

export function registerCustomProjections() {
  if (proj4) {
    if (typeof SETTINGS.projections !== 'undefined') {
      for (const key in SETTINGS.projections) {
        proj4.defs(
          SETTINGS.projections[key].code,
          SETTINGS.projections[key].proj4Def
        )
      }
      register(proj4)
    } else {
      registerPredefinedCustomProjections
    }
  }
}

function registerPredefinedCustomProjections() {
  proj4.defs(
    'EPSG:4258',
    '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'
  )
  proj4.defs(
    'EPSG:4171',
    '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'
  )
  // Systèmes de projection
  // Monde
  proj4.defs(
    'EPSG:3395',
    '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'
  )
  // France Métropolitaine
  proj4.defs(
    'EPSG:2154',
    '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3942',
    '+proj=lcc +lat_1=41.25 +lat_2=42.75 +lat_0=42 +lon_0=3 +x_0=1700000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3943',
    '+proj=lcc +lat_1=42.25 +lat_2=43.75 +lat_0=43 +lon_0=3 +x_0=1700000 +y_0=2200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3944',
    '+proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3945',
    '+proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3946',
    '+proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3947',
    '+proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3948',
    '+proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3949',
    '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3950',
    '+proj=lcc +lat_1=49.25 +lat_2=50.75 +lat_0=50 +lon_0=3 +x_0=1700000 +y_0=9200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs('EPSG:32630', '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs')
  proj4.defs('EPSG:32631', '+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs')
  proj4.defs('EPSG:32632', '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs')
  proj4.defs(
    'EPSG:27572',
    '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs'
  )
  // DOM TOM
  proj4.defs(
    'EPSG:4559',
    '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:5490',
    '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:2972',
    '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:2975',
    '+proj=utm +zone=40 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:4471',
    '+proj=utm +zone=38 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:4467',
    '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3296',
    '+proj=utm +zone=5 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3297',
    '+proj=utm +zone=6 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3298',
    '+proj=utm +zone=7 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs'
  )
  proj4.defs(
    'EPSG:3299',
    '+proj=utm +zone=8 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs'
  )
  register(proj4)
}

export interface ProjectionOption {
  order: number
  label: string
  value?: string
  children?: ProjectionOption[]
}

export function projectionOptions() {
  if (typeof SETTINGS.projectionOptions !== 'undefined') {
    return SETTINGS.projectionOptions
  } else {
    return PROJECTION_OPTIONS
  }
}

export const PROJECTION_OPTIONS: ProjectionOption[] = [
  {
    order: 1,
    label: 'Systèmes de coordonnées géographiques',
    children: [
      {
        order: 1,
        label: '4326 - WGS84 (Monde)',
        value: 'EPSG:4326',
      },
      {
        order: 2,
        label: '4258 - ETRS89 (Europe)',
        value: 'EPSG:4258',
      },
      {
        order: 3,
        label: '4171 - RGF93 (France)',
        value: 'EPSG:4171',
      },
    ],
  },
  {
    order: 2,
    label: 'Systèmes de projection - Monde',
    children: [
      {
        order: 1,
        label: '3857 - Pseudo-Mercator Sphérique (Monde)',
        value: 'EPSG:3857',
      },
      {
        order: 2,
        label: '3395 - Mercator (Monde - Marine)',
        value: 'EPSG:3395',
      },
    ],
  },
  {
    order: 3,
    label: 'Systèmes de projection - France Métropolitaine',
    children: [
      {
        order: 1,
        label: '2154 - Lambert 93 (France)',
        value: 'EPSG:2154',
      },
      {
        order: 2,
        label: '3942 - Lambert 9 Zones 1 (France)',
        value: 'EPSG:3942',
      },
      {
        order: 3,
        label: '3943 - Lambert 9 Zones 2 (France)',
        value: 'EPSG:3943',
      },
      {
        order: 4,
        label: '3944 - Lambert 9 Zones 3 (France)',
        value: 'EPSG:3944',
      },
      {
        order: 5,
        label: '3945 - Lambert 9 Zones 4 (France)',
        value: 'EPSG:3945',
      },
      {
        order: 6,
        label: '3946 - Lambert 9 Zones 5 (France)',
        value: 'EPSG:3946',
      },
      {
        order: 7,
        label: '3947 - Lambert 9 Zones 6 (France)',
        value: 'EPSG:3947',
      },
      {
        order: 8,
        label: '3948 - Lambert 9 Zones 7 (France)',
        value: 'EPSG:3948',
      },
      {
        order: 9,
        label: '3949 - Lambert 9 Zones 8 (France)',
        value: 'EPSG:3949',
      },
      {
        order: 10,
        label: '3950 - Lambert 9 Zones 9 (France)',
        value: 'EPSG:3950',
      },
      {
        order: 11,
        label: '32630 - UTM 30N (France)',
        value: 'EPSG:32630',
      },
      {
        order: 12,
        label: '32631 - UTM 31N (France)',
        value: 'EPSG:32631',
      },
      {
        order: 13,
        label: '32632 - UTM 32N (France)',
        value: 'EPSG:32632',
      },
      {
        order: 14,
        label: '27572 - Lambert II Etendu (France - obsolète)',
        value: 'EPSG:27572',
      },
    ],
  },
  {
    order: 4,
    label: 'Systèmes de projection - France Outre-Mer',
    children: [
      {
        order: 1,
        label: '4559 - RRAF91 UTM 20N (Martinique/Guadeloupe - obsolète)',
        value: 'EPSG:4559',
      },
      {
        order: 2,
        label: '5490 - RGAF09 UTM 20N (Martinique/Guadeloupe)',
        value: 'EPSG:5490',
      },
      {
        order: 3,
        label: '2972 - RGFG95 UTM 22N (Guyanne)',
        value: 'EPSG:2972',
      },
      {
        order: 4,
        label: '2975 - RGR92 UTM 40S (Réunion)',
        value: 'EPSG:2975',
      },
      {
        order: 5,
        label: '4471 - RGM04 UTM 38S (Mayotte)',
        value: 'EPSG:4471',
      },
      {
        order: 6,
        label: '4467 - RGSPM06U UTM 21N (St-Pierre-et-Miquelon)',
        value: 'EPSG:4467',
      },
      {
        order: 7,
        label: '3296 - RGPF UTM 5S (Polynésie Française)',
        value: 'EPSG:3296',
      },
      {
        order: 8,
        label: '3297 - RGPF UTM 6S (Polynésie Française)',
        value: 'EPSG:3297',
      },
      {
        order: 9,
        label: '3298 - RGPF UTM 7S (Polynésie Française)',
        value: 'EPSG:3298',
      },
      {
        order: 10,
        label: '3299 - RGPF UTM 8S (Polynésie Française)',
        value: 'EPSG:3299',
      },
    ],
  },
]

export function projectionFormats() {
  if (
    typeof SETTINGS.projectionFormats !== 'undefined' &&
    SETTINGS.projectionFormats.size > 0
  ) {
    return SETTINGS.projectionFormats
  } else {
    return FORMAT_BY_PROJECTION
  }
}

export const FORMAT_BY_PROJECTION = new Map([
  ['EPSG:4326', 'dmsCoordinates:5:{x} {y}'],
  ['EPSG:4258', 'dmsCoordinates:5:{x} {y}'],
  ['EPSG:4171', 'dmsCoordinates:5:{x} {y}'],
])
