<ng-template
  *ngIf="currentStyle$ | async; then styleEditor; else styleLoading"
></ng-template>
<ng-template #styleEditor>
  <ui-style-editor
    [style]="currentStyle$ | async"
    [layerView]="layerViewSpec$ | async"
    [dataSchema]="styleFacade_.currentDataSchema$ | async"
    (outputGeostyler$)="handleStyle($event)"
  ></ui-style-editor>
</ng-template>
<ng-template #styleLoading>
  <div
    class="w-100 h-100 d-flex align-items-center justify-content-center loading"
  >
    <span class="fa fa-spinner fa-spin fa-4x mr-1" style="opacity: 10%"></span>
  </div>
</ng-template>
