import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { LayerModel } from '@ui/data-access-carto-map-production'
import { Observable } from 'rxjs'
import { ModalComponent } from '@ui/ui/layout'
import { RepairMapService } from '../../../services/repair-map.service'

@Component({
  selector: 'ui-uncleanable-layers-modal',
  templateUrl: './uncleanable-layers-modal.component.html',
  styleUrls: ['./uncleanable-layers-modal.component.css'],
})
export class UncleanableLayersModalComponent implements OnInit {
  @Input() showModal$: Observable<any>
  @ViewChild('modal') modal: ModalComponent

  uncleanableLayers: LayerModel[]

  constructor(private repairMapService: RepairMapService) {}

  ngOnInit(): void {
    this.showModal$.subscribe(() => {
      this.uncleanableLayers = this.repairMapService.getUncleanableLayers()
      this.modal.open()
    })
  }

  refreshPage() {
    return () => location.reload()
  }
}
