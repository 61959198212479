import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'
import OLParser from 'geostyler-openlayers-parser'
import { Style as GsStyle } from 'geostyler-style'
import { isEqual } from 'lodash'
import GML from 'ol/format/GML'
import ImageLayer from 'ol/layer/Image'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import LayerGroup from 'ol/layer/Group'
import BaseLayer from 'ol/layer/Base'
import OlLayer from 'ol/layer/Layer'
import OlLayerGroup from 'ol/layer/Group'
import { LayerViewModel } from '@ui/data-access-carto-map-production'
import { bbox as bboxStrategy } from 'ol/loadingstrategy'
import OlMap from 'ol/Map'
import ImageSource from 'ol/source/ImageWMS'
import ImageWMS from 'ol/source/ImageWMS'
import TileWMS from 'ol/source/TileWMS'
import VectorSource from 'ol/source/Vector'
import WMTSSource, { optionsFromCapabilities } from 'ol/source/WMTS'
import { Circle, Fill, Stroke, Style } from 'ol/style'
import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, take } from 'rxjs/operators'
import { toOlExtent } from '../service/bounds-mapper'
import {
  BoundingBox,
  buildWfsGetFeatureUrl,
  getViewServiceUrl,
  Group,
  isLayerInternal,
  Layer,
  LayerViewType,
  MapElement,
  ProxyService,
  ViewUtilsService,
} from '@ui/feature/shared'
import {
  MAPELEMENT_ID_KEY,
  MAPELEMENT_VERSION_KEY,
  UtilsService,
} from './utils.service'
import { EventService } from './event.service'
import { EventTypes } from 'ol/Observable'
import ImageWMSWithErrorHandler, {
  IMAGE_WMS_LOAD_ERROR,
  ImageLoadErrorCustomEventType,
  ImageLoadErrorEvent,
} from './openlayers-image-wms-load-error'

export const WFS_MAX_FEATURES = new InjectionToken<string>('wfsMaxFeatures')
export const GPF_PRIVATE_PATTERN_URL = new InjectionToken<string>(
  'geoplateformePrivatePatternUrl'
)

const fill = new Fill({
  color: 'rgba(255,255,255,0.4)',
})
const stroke = new Stroke({
  color: '#3399CC',
  width: 1.25,
})
const defaultStyle = new Style({
  image: new Circle({
    fill,
    stroke,
    radius: 5,
  }),
  fill,
  stroke,
})

const olParser = new OLParser()

const layersLoadingStatusObservables: Map<
  number,
  Observable<IMAGE_WMS_LOAD_ERROR>
> = new Map()

@Injectable({
  providedIn: 'root',
})
export class OpenlayersService {
  constructor(
    private viewUtils: ViewUtilsService,
    private utils: UtilsService,
    private proxy: ProxyService,
    private event: EventService,
    @Optional() @Inject(WFS_MAX_FEATURES) private wfsMaxFeatures,
    @Optional()
    @Inject(GPF_PRIVATE_PATTERN_URL)
    public gpfPrivatePatternUrl: string
  ) {}

  setCenter(map: OlMap, center: [number, number]) {
    map.getView().setCenter(center)
  }

  setZoom(map: OlMap, zoom: number) {
    map.getView().setZoom(zoom)
  }

  fitExtent(map: OlMap, bounds: BoundingBox) {
    map.getView().fit(toOlExtent(bounds))
  }

  getCenterObservable(map: OlMap) {
    return new Observable<[number, number]>((observer) => {
      map.getView().on('change:center', () => {
        observer.next(map.getView().getCenter() as [number, number])
      })
    }).pipe(distinctUntilChanged(isEqual))
  }

  getZoomObservable(map: OlMap) {
    return new Observable<number>((observer) => {
      map.getView().on('change:resolution', () => {
        observer.next(map.getView().getZoom())
      })
    }).pipe(distinctUntilChanged())
  }

  getExtentObservable(map: OlMap) {
    return new Observable<[number, number, number, number]>((observer) => {
      map.getView().on(['change:resolution', 'change:center'], () => {
        observer.next(
          map.getView().calculateExtent() as [number, number, number, number]
        )
      })
    }).pipe(distinctUntilChanged())
  }

  getLayerLoadingStatusObservable(layerId: number) {
    return layersLoadingStatusObservables.get(layerId)
  }

  removeLayerLoadingStatusObservable(layerId: number) {
    layersLoadingStatusObservables.delete(layerId)
  }

  /**
   *
   * @param map
   * @param layer
   * @param position
   */
  addLayer(map: OlMap, layer: Layer, position: number, parentId: number) {
    if (layer.views.length === 0) return
    const view = layer.views[0]
    let olLayer
    let version: string

    switch (view.serviceType) {
      case LayerViewType.WMS:
        olLayer = new ImageLayer({
          source: this._createWMSSource(view, layer),
          [MAPELEMENT_ID_KEY]: layer[MAPELEMENT_ID_KEY],
          [MAPELEMENT_VERSION_KEY]: layer[MAPELEMENT_VERSION_KEY],
        } as any)
        break
      case LayerViewType.WFS:
        version = view.serviceVersion || '1.1.0'
        olLayer = new VectorLayer({
          style: defaultStyle,
          source: new VectorSource({
            format: new GML(),
            url: (extent) => {
              const url = buildWfsGetFeatureUrl({
                url: getViewServiceUrl(view),
                typeName: view.layerName,
                version,
                extent,
                outputFormat: 'GML3',
                maxFeatures: this.wfsMaxFeatures,
              })
              return this.proxy.getProxiedUrl(url)
            },
            strategy: bboxStrategy,
          }),
          [MAPELEMENT_ID_KEY]: layer[MAPELEMENT_ID_KEY],
          [MAPELEMENT_VERSION_KEY]: layer[MAPELEMENT_VERSION_KEY],
        } as any)
        break
      case LayerViewType.WMTS:
        olLayer = new TileLayer({
          source: null,
          [MAPELEMENT_ID_KEY]: layer[MAPELEMENT_ID_KEY],
          [MAPELEMENT_VERSION_KEY]: layer[MAPELEMENT_VERSION_KEY],
        } as any)
        this.utils
          .queryGetCapabilities(view)
          .subscribe((getCapabilitiesResponse) => {
            const options = optionsFromCapabilities(getCapabilitiesResponse, {
              layer: view.layerName,
              projection: 'EPSG:3857',
            })

            // workaround for inverted x/y in TopLeftCorner elements
            if (options !== null) {
              ;(options.tileGrid as any).origins_.forEach((origin) => {
                if (origin[0] > origin[1]) {
                  origin.reverse()
                }
              })
              options.urls = view.origin?.includes(this.gpfPrivatePatternUrl)
                ? [view.origin]
                : options.urls
              olLayer.setSource(new WMTSSource(options))
            } else {
              console.warn('error during getCap for layer', layer)
            }
          })
        break
      default:
        console.warn('unhandled layer type', layer)
        return
    }

    this._addToMapLayersIndex(map, olLayer)
    this.setMapElementPosition(map, layer, position, parentId)
    this.updateLayer(map, layer)
  }

  /**
   * @param map
   * @param group
   * @param position
   */
  addGroup(map: OlMap, group: Group, position: number, parentId: number) {
    // the group may already exist as a placeholder; do not recreate
    if (!this.getMapElementById(map, group.id)) {
      const olGroup = new LayerGroup({
        [MAPELEMENT_ID_KEY]: group[MAPELEMENT_ID_KEY],
        [MAPELEMENT_VERSION_KEY]: group[MAPELEMENT_VERSION_KEY],
      } as any)
      this._addToMapLayersIndex(map, olGroup)
    }
    this.setMapElementPosition(map, group, position, parentId)
    this.updateGroup(map, group)
  }

  getMapElementById(map: OlMap, id: number) {
    if (map.get('rootElementId') === id) {
      return map.getLayerGroup()
    }
    return this._getMapLayersIndex(map)[id]
  }

  getLayerByViewId(map: OlMap, viewId: number) {
    const layer = this.findLayerByViewIdFromOlObject(map, viewId)
    if (!layer) {
      console.warn(`Layer not found, viewId: ${viewId}`)
    }
    return layer
  }

  private findLayerByViewIdFromOlObject(
    olObject: OlMap | BaseLayer,
    viewId: number
  ) {
    if (olObject instanceof OlLayer) {
      if (olObject.get('viewId') === viewId) {
        return olObject
      }
    } else if (olObject instanceof OlMap || olObject instanceof OlLayerGroup) {
      const olLayers = olObject.getLayers().getArray()
      for (const layer of olLayers) {
        const match = this.findLayerByViewIdFromOlObject(layer, viewId)
        if (match) {
          return match
        }
      }
    }
  }

  updateLayer(map: OlMap, layer: Layer) {
    const olLayer = this.getMapElementById(map, layer.id)
    if (!olLayer) return
    olLayer.setOpacity(layer.opacity)
    olLayer.setVisible(layer.visible)

    // FIXME: layers in OL should correspond to *views* and not *layers* in the Carto model
    if (!('views' in layer) || layer.views.length === 0) return
    const view = layer.views[0]
    if (view.minScale) {
      olLayer.setMinResolution(
        this.viewUtils.getResolutionFromScaleDenominator(
          this.viewUtils.getLayerViewMinScaleDenominator(view)
        )
      )
    } else {
      olLayer.setMinResolution(0)
    }
    if (view.maxScale) {
      olLayer.setMaxResolution(
        this.viewUtils.getResolutionFromScaleDenominator(
          this.viewUtils.getLayerViewMaxScaleDenominator(view)
        )
      )
    } else {
      olLayer.setMaxResolution(Infinity)
    }

    olLayer.set('viewId', view.id)
    olLayer.set('styleId', view.defaultStyleId)

    if (view.serviceType !== LayerViewType.WMS) return

    const olLayerProjCode = olLayer.getSource().getProjection()?.getCode()

    if (view.requestProjection !== olLayerProjCode) {
      olLayer.setSource(this._createWMSSource(view, layer))
    }
  }

  updateGroup(map: OlMap, group: Group) {
    const olGroup = this.getMapElementById(map, group.id)
    if (!olGroup) return
    olGroup.setVisible(group.visible)
  }

  removeMapElement(map: OlMap, mapElement: MapElement) {
    const olMapElement = this.getMapElementById(map, mapElement.id)
    if (!olMapElement) return
    this._removeFromMapLayersIndex(map, olMapElement)
    const parent =
      this.getMapElementById(map, olMapElement.get('parentId')) ||
      map.getLayerGroup()
    parent.getLayers().remove(olMapElement)

    this.removeLayerLoadingStatusObservable(mapElement.id)
  }

  setMapElementPosition(
    map: OlMap,
    mapElement: MapElement,
    position: number,
    parentId: number
  ) {
    const olMapElement = this.getMapElementById(map, mapElement.id)
    if (!olMapElement) return

    olMapElement.set('positionInParent', position)

    const currentParentId = olMapElement.get('parentId')
    const hasParentAlready = currentParentId !== undefined

    // remove from current parent
    if (hasParentAlready) {
      const currParent = this.getMapElementById(map, currentParentId)
      if (currParent) currParent.getLayers().remove(olMapElement)
    }

    // add to new (or same) parent
    let newParent = this.getMapElementById(map, parentId)

    // create new parent if non existent
    if (!newParent) {
      newParent = this._addPlaceholderGroup(map, parentId)
    }

    newParent.getLayers().push(olMapElement)

    // reorder parent
    newParent
      .getLayers()
      .getArray()
      .sort((l1, l2) =>
        Math.sign(l1.get('positionInParent') - l2.get('positionInParent'))
      )

    olMapElement.set('parentId', parentId)
  }

  setMapScaleBounds(map: OlMap, minScale: number, maxScale: number) {
    const view = map.getView()
    if (minScale) {
      const res = this.viewUtils.getResolutionFromScaleDenominator(minScale)
      view.setMaxZoom(view.getZoomForResolution(res))
    } else {
      view.setMaxZoom(36)
    }
    if (maxScale) {
      const res = this.viewUtils.getResolutionFromScaleDenominator(maxScale)
      view.setMinZoom(view.getZoomForResolution(res))
    } else {
      view.setMinZoom(0)
    }
  }

  updateLayerViewStyle(map: OlMap, viewId: number, style: GsStyle) {
    const layer = this.getLayerByViewId(map, viewId)

    // updating style on anything but vector layers will have no effect
    if (layer && layer instanceof VectorLayer) {
      olParser
        .writeStyle(style as any)
        .then(({ output: olStyle }) => layer.setStyle(olStyle)) // FIXME: remove 'as any'
    }
  }

  removeStyle(map: OlMap, viewId: number) {
    const layer = this.getLayerByViewId(map, viewId)

    if (!layer) {
      return
    }

    if (layer instanceof VectorLayer) {
      layer.setStyle(defaultStyle)
    } else {
      this.refreshLayer(layer)
    }
  }

  warnOnDuplicatedStyleError(layer) {
    const olLayerLoadingObservable$ = this.getLayerLoadingStatusObservable(
      layer.get('id')
    )

    if (olLayerLoadingObservable$) {
      olLayerLoadingObservable$
        .pipe(
          take(1),
          filter((value) => value === IMAGE_WMS_LOAD_ERROR.GeoserverErrorStyle)
        )
        .subscribe(() => this.event.warnOnDuplicatedStyleError())
    }
  }

  refreshLayer(layer) {
    const source = layer.getSource()
    if (source instanceof TileWMS || source instanceof ImageWMS) {
      layer.getSource().updateParams({
        _refresh: Math.random(),
      })
    }

    const olLayerLoadingObservable$ = this.getLayerLoadingStatusObservable(
      layer.get('id')
    )

    if (olLayerLoadingObservable$) {
      olLayerLoadingObservable$
        .pipe(
          take(1),
          filter((value) => value === IMAGE_WMS_LOAD_ERROR.GeoserverErrorStyle)
        )
        .subscribe(() => this.event.warnOnDuplicatedStyleError())
    }

    layer.getSource().changed()
  }

  setRootMapElementId(olMap, id: number) {
    olMap.set('rootElementId', id)
  }

  private _getMapLayersIndex(map: OlMap) {
    if (!map.get('layersIndex')) {
      map.set('layersIndex', {})
    }
    return map.get('layersIndex')
  }

  private _addToMapLayersIndex(map: OlMap, layer) {
    this._getMapLayersIndex(map)[layer.get(MAPELEMENT_ID_KEY)] = layer
  }

  private _removeFromMapLayersIndex(map: OlMap, layer) {
    delete this._getMapLayersIndex(map)[layer.get(MAPELEMENT_ID_KEY)]
  }

  private _addPlaceholderGroup(map, id: number) {
    this.addGroup(
      map,
      {
        id,
        childrenId: [],
        _version: -1,
      },
      0,
      map.get('rootElementId')
    )
    return this.getMapElementById(map, id)
  }

  private _createWMSSource(view: LayerViewModel, layer: Layer) {
    let source: ImageSource
    const options = {
      url: getViewServiceUrl(view),
      params: { LAYERS: view.layerName, VERSION: view.serviceVersion },
      projection: view.requestProjection || 'EPSG:3857',
    }

    if (!isLayerInternal(layer)) {
      source = new ImageWMS(options)
    } else {
      source = new ImageWMSWithErrorHandler(options)
      this._attachImageSourceLoadEvents(source, layer)
    }

    return source
  }

  private _attachImageSourceLoadEvents(source: ImageSource, layer: Layer) {
    layersLoadingStatusObservables.set(
      layer.id,
      new Observable<IMAGE_WMS_LOAD_ERROR>((observer) => {
        source.on(
          <EventTypes>ImageLoadErrorCustomEventType,
          (event: ImageLoadErrorEvent) => observer.next(event.errorType)
        )
      }).pipe(distinctUntilChanged())
    )
  }
}
