import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  QGisProjectService,
  MapFullModel,
} from '@ui/data-access-carto-map-production'

import { LoadingFacade, NotificationFacade } from '../../../store'
import { Observable, Subscription, throwError } from 'rxjs'
import { catchError, filter, tap } from 'rxjs/operators'
import { HttpEventType, HttpResponse } from '@angular/common/http'
import { FormBuilder, Validators } from '@angular/forms'
import { BusinessRulesService } from '@ui/feature/shared'
import { ModalComponent } from '@ui/ui/layout'
import { MAP_PRODUCTION_ROUTE } from '../../../routes'
import { Location } from '@angular/common'
import { QGisProjectUploadService } from '../../../services/q-gis-project-upload.service'

@Component({
  selector: 'ui-import-qgis-map',
  templateUrl: './import-qgis-map.component.html',
  styleUrls: ['./import-qgis-map.component.scss'],
})
export class ImportQgisMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<any>
  @ViewChild('modal') modal: ModalComponent

  importQgisForm = this.formBuilder.group({
    file: ['', [Validators.required]],
    name: ['', [Validators.required, Validators.maxLength(64)]],
    title: ['', [Validators.required]],
  })
  sub = new Subscription()

  get f() {
    return this.importQgisForm.controls
  }

  constructor(
    protected qGisProjectService: QGisProjectService,
    protected location: Location,
    protected loadingFacade: LoadingFacade,
    protected notificationFacade: NotificationFacade,
    protected formBuilder: FormBuilder,
    protected businessRulesService: BusinessRulesService,
    protected qgisMapModalService: QGisProjectUploadService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$.subscribe(() => {
        this.resetForm()
        this.modal.open()
      })
    )
    const controls = this.importQgisForm.controls
    controls['title'].valueChanges.subscribe((value) =>
      controls['name'].setValue(
        this.businessRulesService.normalizeMapName(value)
      )
    )
    controls['name'].valueChanges.subscribe((value) => {
      const normalizedName = this.businessRulesService.normalizeMapName(value)
      if (normalizedName !== value) {
        controls['name'].setValue(normalizedName)
      }
    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  createMap() {
    if (!this.qgisMapModalService.currentFile)
      return throwError(this.qgisMapModalService.errorMsg)

    this.loadingFacade.setProgressStartDate(Date.now())
    this.modal.close()
    this.loadingFacade.setIsProcessRunning(true)

    return this.qGisProjectService
      .importQGisProject(
        this.qgisMapModalService.currentFile,
        this.importQgisForm.get('name').value,
        this.importQgisForm.get('title').value,
        'events',
        true
      )
      .pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.loadingFacade.updateProgressValue(
              Math.round((100 * event.loaded) / event.total)
            )
          }
        }),
        filter((eventOrResponse) => eventOrResponse instanceof HttpResponse),
        tap<HttpResponse<MapFullModel>>((response) => {
          if (!response.ok) {
            throw new Error()
          } else {
            this.handleSuccessfulUpload(response.body.id)
          }
        }),
        catchError(
          (e) =>
            new Observable(() => {
              this.loadingFacade.setIsProcessRunning(false)
              this.loadingFacade.updateProgressValue(0)
              this.notificationFacade.showNotification(
                'danger',
                e.error ||
                  e.message ||
                  'Erreur lors du téléversement du fichier',
                5000
              )
            })
        )
      )
  }

  resetForm() {
    this.loadingFacade.updateProgressValue(0)
    this.importQgisForm.reset()
  }

  handleSuccessfulUpload(importedMapId) {
    const mapUrl = this.location.prepareExternalUrl(
      `${MAP_PRODUCTION_ROUTE}/${importedMapId}`
    )
    this.loadingFacade.setIsProcessRunning(false)
    this.loadingFacade.updateProgressValue(0)
    this.notificationFacade.showNotification(
      'success',
      `La carte a été chargée avec succès. <a href= "${mapUrl}">Ouvrir la carte</a>`,
      null
    )
  }

  selectFile(event: Event) {
    return this.getQgisMapModalService().selectFile(event)
  }

  get errorMsg() {
    return this.getQgisMapModalService().errorMsg
  }

  get currentFile() {
    return this.getQgisMapModalService().currentFile
  }

  getQgisMapModalService() {
    return this.qgisMapModalService
  }
}
