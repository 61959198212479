<button
  class="btn btn-outline-dark shadow-sm w-100"
  (click)="toggle()"
  title="{{
    !enabled ? 'Activer le plein écran' : 'Désactiver le plein écran'
  }}"
>
  <span
    class="fa"
    [ngClass]="!enabled ? 'fa-expand-arrows-alt' : 'fa-compress-arrows-alt'"
  ></span>
</button>
