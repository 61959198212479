import { TreeItemModel } from '@ui/data-access-carto-resource-management'

export const geoplateformeServices = [
  {
    parentPath: '/geoplateforme',
    name: 'Géoplateforme TMS - Vector Tiles',
    title: null,
    folder: false,
    contentId: 'https://data.geopf.fr/tms',
    resourceType: 'Service',
    dataType: null,
    voluminous: null,
    complexity: -1,
    links: null,
    nativeSrsCode: null,
  },
  {
    parentPath: '/geoplateforme',
    name: 'Géoplateforme WFS',
    title: null,
    folder: false,
    contentId: 'https://data.geopf.fr/wfs/ows',
    resourceType: 'Service',
    dataType: null,
    voluminous: null,
    complexity: -1,
    links: null,
    nativeSrsCode: null,
  },
  {
    parentPath: '/geoplateforme',
    name: 'Géoplateforme WMS - Raster',
    title: null,
    folder: false,
    contentId: 'https://data.geopf.fr/wms-r/wms',
    resourceType: 'Service',
    dataType: null,
    voluminous: null,
    complexity: -1,
    links: null,
    nativeSrsCode: null,
  },
  {
    parentPath: '/geoplateforme',
    name: 'Géoplateforme WMS - Vecteur',
    title: null,
    folder: false,
    contentId: 'https://data.geopf.fr/wms-v/ows',
    resourceType: 'Service',
    dataType: null,
    voluminous: null,
    complexity: -1,
    links: null,
    nativeSrsCode: null,
  },
  {
    parentPath: '/geoplateforme',
    name: 'Géoplateforme WMTS',
    title: null,
    folder: false,
    contentId: 'https://data.geopf.fr/wmts',
    resourceType: 'Service',
    dataType: null,
    voluminous: null,
    complexity: -1,
    links: null,
    nativeSrsCode: null,
  },
] as TreeItemModel[]

export const geoplateformeResourceInfo = {
  'https://data.geopf.fr/wmts': {
    id: 0,
    uuid: 'https://data.geopf.fr/wmts',
    resourceType: 'Service',
    dataType: null,
    style: null,
    dataSource: null,
    service: {
      id: 'https://data.geopf.fr/wmts',
      serverName: 'https://data.geopf.fr/wmts',
      serverURL: 'https://data.geopf.fr/wmts',
      serverType: 'WMTS',
      serverVersion: '1.0.0',
    },
    map: null,
    links: null,
  },
  'https://data.geopf.fr/tms': {
    id: 0,
    uuid: 'https://data.geopf.fr/tms',
    resourceType: 'Service',
    dataType: null,
    style: null,
    dataSource: null,
    service: {
      id: 'https://data.geopf.fr/tms',
      serverName: 'https://data.geopf.fr/tms',
      serverURL: 'https://data.geopf.fr/tms',
      serverType: 'VECTOR_TILES',
      serverVersion: '1.0.0',
    },
    map: null,
    links: null,
  },
  'https://data.geopf.fr/wms-r/wms': {
    id: 0,
    uuid: 'https://data.geopf.fr/wms-r/wms',
    resourceType: 'Service',
    dataType: null,
    style: null,
    dataSource: null,
    service: {
      id: 'https://data.geopf.fr/wms-r/wms',
      serverName: 'https://data.geopf.fr/wms-r/wms',
      serverURL: 'https://data.geopf.fr/wms-r/wms',
      serverType: 'WMS',
      serverVersion: '1.3.0',
    },
    map: null,
    links: null,
  },
  'https://data.geopf.fr/wms-v/ows': {
    id: 0,
    uuid: 'https://data.geopf.fr/wms-v/ows',
    resourceType: 'Service',
    dataType: null,
    style: null,
    dataSource: null,
    service: {
      id: 'https://data.geopf.fr/wms-v/ows',
      serverName: 'https://data.geopf.fr/wms-v/ows',
      serverURL: 'https://data.geopf.fr/wms-v/ows',
      serverType: 'WMS',
      serverVersion: '1.3.0',
    },
    map: null,
    links: null,
  },
  'https://data.geopf.fr/wfs/ows': {
    id: 0,
    uuid: 'https://data.geopf.fr/wfs/ows',
    resourceType: 'Service',
    dataType: null,
    style: null,
    dataSource: null,
    service: {
      id: 'https://data.geopf.fr/wfs/ows',
      serverName: 'https://data.geopf.fr/wfs/ows',
      serverURL: 'https://data.geopf.fr/wfs/ows',
      serverType: 'WFS',
      serverVersion: '2.0.0',
    },
    map: null,
    links: null,
  },
}

export function getGeoplateformeResourceInfo(contentId: string) {
  return geoplateformeResourceInfo[contentId]
}
