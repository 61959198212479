import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { ScaleDenominator } from 'geostyler-style'

@Component({
  selector: 'ui-rule-scale-input',
  templateUrl: './rule-scale-input.component.html',
  styleUrls: ['./rule-scale-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleScaleInputComponent {
  @Input() viewMin: number
  @Input() viewMax: number
  @Input() min: number
  @Input() max: number
  @Output() scaleChange = new EventEmitter<ScaleDenominator>()

  valueStep = 5000

  get minOutsideRange() {
    return this.viewMin && this.min < this.viewMin
  }
  get maxOutsideRange() {
    return this.viewMax && this.max > this.viewMax
  }

  get minLowerBound() {
    return this.viewMin
      ? Math.max(
          this.valueStep,
          Math.ceil(this.viewMin / this.valueStep) * this.valueStep
        )
      : this.valueStep
  }
  get maxHigherBound() {
    return this.viewMax
      ? Math.floor(this.viewMax / this.valueStep) * this.valueStep
      : Infinity
  }
  get maxLowerBound() {
    return Math.max(
      this.valueStep,
      Math.ceil(this.minModel / this.valueStep) * this.valueStep
    )
  }
  get minHigherBound() {
    return Math.floor(this.maxModel / this.valueStep) * this.valueStep
  }

  get minModel() {
    return this.min !== undefined ? this.min : this.viewMin
  }
  set minModel(value: number) {
    this.min = value
    this.onValueChange()
  }
  get maxModel() {
    return this.max !== undefined ? this.max : this.viewMax
  }
  set maxModel(value: number) {
    this.max = value
    this.onValueChange()
  }

  private onValueChange() {
    const min = this.min
    const max = this.max
    this.scaleChange.emit({ min, max })
  }
}
