import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { delay, filter, map, mergeMap } from 'rxjs/operators'
import {
  ADD_NOTIFICATION,
  AddNotification,
  RemoveNotification,
} from '../actions'
import { of } from 'rxjs'

@Injectable()
export class NotificationEffects {
  constructor(private actions$: Actions) {}

  cleanup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ADD_NOTIFICATION),
      map((action: AddNotification) => action.payload),
      filter((notification) => !!notification.timeout),
      mergeMap((notification) =>
        of(notification).pipe(delay(notification.timeout))
      ),
      map((notification) => new RemoveNotification(notification.id))
    )
  )
}
