import { Component } from '@angular/core'
import { LoadingFacade } from '../../../store'

@Component({
  selector: 'ui-loading-progress',
  templateUrl: './loading-progress.component.html',
  styleUrls: ['./loading-progress.component.css'],
})
export class LoadingProgressComponent {
  constructor(public loadingFacade_: LoadingFacade) {}
}
