<div class="modal-header">
  <h5 class="modal-title pull-left">Supprimer une carte</h5>
</div>
<alert *ngIf="errorMsg" type="danger"> {{ errorMsg }} </alert>

<div class="filter-search-input">
  <ui-filter-search-input
    [filterValue]="filterValue"
    (filterValueChange)="onFilterValueChange($event)"
  ></ui-filter-search-input>
</div>

<div class="modal-body">
  <div class="list-group">
    <button
      *ngFor="let map of filteredMapList"
      class="list-group-item list-group-item-action"
      (click)="onSelect(map)"
      [class.active]="map === selection"
    >
      <ui-map-preview [mapSummary]="map"></ui-map-preview>
    </button>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" type="button" (click)="onCancel()">
    Annuler
  </button>
  <button
    class="btn btn-primary"
    type="button"
    (click)="onSubmit()"
    [disabled]="!selection || deletePending"
  >
    <span class="fa fa-spinner fa-spin mr-1" *ngIf="deletePending"></span>
    Supprimer
  </button>
</div>
