import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { Back, Go, RouterGoActionPayload } from '../actions'
import { RouterStateUrl } from '../reducers'
import { getFullUrl, getPathParams, getQueryParams } from '../selectors'

@Injectable({
  providedIn: 'root',
})
export class RouterFacade {
  pathParams$ = this.store.pipe(select(getPathParams))
  queryParams$ = this.store.pipe(select(getQueryParams))
  fullUrl$ = this.store.pipe(select(getFullUrl))

  constructor(private store: Store<RouterStateUrl>) {}

  go(payload: RouterGoActionPayload) {
    this.store.dispatch(new Go(payload))
  }

  back() {
    this.store.dispatch(new Back())
  }
}
