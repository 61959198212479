import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ui-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input() message = 'Message'
  constructor() {}

  ngOnInit() {}
}
