<div #mapTarget class="map"></div>

<div class="toolbar-container d-flex flex-column p-2">
  <div class="d-flex flex-column"><ui-zoom [map]="map"></ui-zoom></div>
  <div class="d-flex flex-column mt-2"><ui-fullscreen></ui-fullscreen></div>
  <div class="d-flex flex-column mt-2"><!-- measure tools --></div>
  <div class="d-flex flex-column mt-2"><!-- misc tools --></div>
</div>

<div class="map-container-footer container-fluid small">
  <div class="row py-2">
    <div class="col d-flex flex-row align-items-center">
      <ui-scale-line [map]="map"></ui-scale-line>
    </div>
    <div class="col d-flex flex-row justify-content-center align-items-center">
      Position du curseur :
      <ui-mouse-position
        [map]="map"
        [projection]="(facade.info$ | async).crsCode"
        [formatByProjection]="formatByProjection"
        class="ml-2"
      >
      </ui-mouse-position>
    </div>
    <div class="col d-flex flex-row"><!-- mini map --></div>
  </div>
</div>
