<ui-symbolizer-field
  label="attribut"
  [fieldType]="fieldTypes.LABEL"
  [schema]="schema"
  [value]="symbolizer.label"
  (fieldUpdated)="handlePropChange('label', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="taille"
  [fieldType]="fieldTypes.SIZE"
  [value]="symbolizer.size"
  (fieldUpdated)="handlePropChange('size', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="couleur"
  [fieldType]="fieldTypes.COLOR"
  [value]="symbolizer.color"
  (fieldUpdated)="handlePropChange('color', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  [fieldType]="fieldTypes.OFFSET"
  [value]="symbolizer.offset"
  (fieldUpdated)="handlePropChange('offset', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="rotation"
  [fieldType]="fieldTypes.ROTATE"
  [value]="symbolizer.rotate"
  (fieldUpdated)="handlePropChange('rotate', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  *ngIf="symbolizer.haloColor"
  label="couleur halo"
  [fieldType]="fieldTypes.HALO_COLOR"
  [value]="symbolizer.haloColor"
  (fieldUpdated)="handlePropChange('haloColor', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="taille halo"
  [fieldType]="fieldTypes.HALO_WIDTH"
  [value]="symbolizer.haloWidth"
  (fieldUpdated)="handlePropChange('haloWidth', $event)"
>
</ui-symbolizer-field>
