<div class="small d-flex flex-row align-items-center m-1">
  1 /
  <input
    type="number"
    class="form-control form-control-sm ml-2"
    [step]="valueStep"
    [min]="maxLowerBound"
    [max]="maxHigherBound"
    [(ngModel)]="maxModel"
    [ngClass]="{ 'border-danger': maxOutsideRange }"
    [attr.title]="
      maxOutsideRange
        ? 'Valeur hors de la plage d\'échelle définie pour la couche'
        : undefined
    "
  />
  <span class="text-muted text-nowrap ml-2">Echelle min</span>
</div>
<div class="small d-flex flex-row align-items-center m-1">
  1 /
  <input
    type="number"
    class="form-control form-control-sm ml-2"
    [step]="valueStep"
    [min]="minLowerBound"
    [max]="minHigherBound"
    [(ngModel)]="minModel"
    [ngClass]="{ 'border-danger': minOutsideRange }"
    [attr.title]="
      minOutsideRange
        ? 'Valeur hors de la plage d\'échelle définie pour la couche'
        : undefined
    "
  />
  <span class="text-muted text-nowrap ml-2">Echelle max</span>
</div>
