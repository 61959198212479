import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {
  ClassificationMethod,
  ELEMENT_TYPE,
  FillSymbolizer,
  LineSymbolizer,
  MarkSymbolizer,
  Rule,
  Symbolizer,
  SymbolizerInterpolationSet,
} from '../style.model'
import { StyleService } from '../style.service'

type SymbolizerDict = { [type in ELEMENT_TYPE]: Symbolizer }

const DEFAULT_SYMBOLIZERS: SymbolizerDict = {
  [ELEMENT_TYPE.POINT]: {
    kind: 'Mark',
    wellKnownName: 'circle',
    radius: 8,
    color: '#ff0000',
    fillOpacity: 1,
  } as MarkSymbolizer,
  [ELEMENT_TYPE.LINE]: {
    kind: 'Line',
    width: 1,
    color: '#0000ff',
    opacity: 1,
  } as LineSymbolizer,
  [ELEMENT_TYPE.POLYGON]: {
    kind: 'Fill',
    color: '#AAAAAA',
    fillOpacity: 0.5,
    outlineColor: '#000000',
    outlineOpacity: 1,
    outlineWidth: 1,
  } as FillSymbolizer,
}

const defaultSteps: [any, any] = [
  {
    color: '#FF0000',
  },
  {
    color: '#00FF00',
  },
]

type ClassesRuleNameGenerator = (min: number, max: number) => string
type ValuesRuleNameGenerator = (value: number | string) => string

/**
 * This component shows a UI for generating an array of style rules
 * based on several parameters, with interpolated render parameters
 * and dynamic filtering on a specified field.
 * To be used with numeric values
 */
@Component({
  selector: 'ui-ruleset-generator-interpolation',
  templateUrl: './ruleset-generator-interpolation.component.html',
  styleUrls: ['./ruleset-generator-interpolation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetGeneratorInterpolationComponent
  implements OnInit, OnChanges
{
  @Input() elementType: ELEMENT_TYPE

  baseSymbolizers: SymbolizerDict = { ...DEFAULT_SYMBOLIZERS }
  interpolationProps: [any, any] = { ...defaultSteps }

  get baseSymbolizer() {
    return this.baseSymbolizers[this.elementType]
  }

  set baseSymbolizer(value) {
    this.baseSymbolizers[this.elementType] = value
  }

  // this is only used to render the rules in the UI, not for generating the ruleset
  get rules(): Rule[] {
    return [
      {
        name: 'Début',
        symbolizers: [
          {
            ...this.baseSymbolizer,
            ...this.interpolationProps[0],
            _id: 'interpolation-start',
          },
        ],
      },
      {
        name: 'Fin',
        symbolizers: [
          {
            ...this.baseSymbolizer,
            ...this.interpolationProps[1],
            _id: 'interpolation-end',
          },
        ],
      },
    ]
  }

  activeRule: number

  constructor(private service: StyleService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    // reset interpolation steps when changing element type
    if (changes['elementType']) {
      this.interpolationProps = { ...defaultSteps }
    }
  }

  handleRuleChange(index: number, rule: Rule) {
    const symbolizer = rule.symbolizers[0]
    const props = Object.keys(symbolizer).filter((name) => name !== '_id')

    // apply interpolatable properties
    const interpolatableProps = props
      .filter((prop) =>
        this.service.getIsSymbolizerPropertyInterpolatable(prop)
      )
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: symbolizer[curr],
        }),
        {}
      )
    this.interpolationProps[index] = {
      ...this.interpolationProps[index],
      ...interpolatableProps,
    }

    // apply non-interpolatable properties
    const nonInterpolatableProps = props
      .filter(
        (prop) => !this.service.getIsSymbolizerPropertyInterpolatable(prop)
      )
      .reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: symbolizer[curr],
        }),
        {}
      )
    this.baseSymbolizer = {
      ...this.baseSymbolizer,
      ...nonInterpolatableProps,
    }
  }

  ruleTracker(index: number, rule: Rule) {
    return rule.name
  }

  getSymbolizerInterpolationSet(): SymbolizerInterpolationSet {
    const interpolatedPropNames = [].concat(
      Object.keys(this.interpolationProps[0]),
      Object.keys(this.interpolationProps[1])
    )
    return interpolatedPropNames.reduce((prev, curr) => {
      const startValue = this.interpolationProps[0][curr]
      const endValue = this.interpolationProps[1][curr]
      return {
        ...prev,
        [curr]: {
          start:
            startValue !== undefined ? startValue : this.baseSymbolizer[curr],
          end: endValue !== undefined ? endValue : this.baseSymbolizer[curr],
        },
      }
    }, {})
  }

  generateUsingClasses(
    fieldName: string,
    classesCount: number,
    classificationType: ClassificationMethod,
    values: number[],
    ruleNameGenerator: ClassesRuleNameGenerator,
    rounding?: number
  ): Rule[] {
    const properties = this.getSymbolizerInterpolationSet()
    return this.service.generateRulesFromClassesInterpolationSet({
      classCount: classesCount,
      method: classificationType,
      baseSymbolizer: this.baseSymbolizer,
      fieldName: fieldName,
      ruleName: ruleNameGenerator,
      data: values,
      properties,
      rounding,
    })
  }

  generateUsingValues(
    fieldName: string,
    values: number[],
    ruleNameGenerator: ValuesRuleNameGenerator
  ): Rule[] {
    const properties = this.getSymbolizerInterpolationSet()
    return this.service.generateRulesFromValuesInterpolationSet({
      baseSymbolizer: this.baseSymbolizer,
      fieldName: fieldName,
      ruleName: ruleNameGenerator,
      data: values,
      properties,
    })
  }
}
