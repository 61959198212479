import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ui-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss'],
})
export class IconTooltipComponent implements OnInit {
  @Input() tooltip: string

  constructor() {}

  ngOnInit() {}
}
