import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WMSCapabilities, WMTSCapabilities } from 'ol/format'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import {
  buildGetCapabilitiesUrl,
  Group,
  isMapElementGroup,
  LayerView,
  LayerViewType,
  MapElementsById,
  ProxyService,
} from '@ui/feature/shared'
import { LayerViewTypeModel } from '@ui/data-access-carto-map-production'

export const MAPELEMENT_ID_KEY = 'id'
export const MAPELEMENT_VERSION_KEY = '_version'

type ArrayComparisonCallback = (movedElement: any) => void

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  capabilitiesCache: { [index: string]: Observable<any> } = {}
  typesVersions = {
    WMS: ['1.0.0', '1.1.0', '1.1.1', '1.3.0'],
    WMTS: ['1.0.0'],
    WFS: ['1.0.0', '1.1.0', '1.1.3', '2.0.0', '2.0.2'],
  }

  constructor(
    private http: HttpClient,
    private proxy: ProxyService
  ) {}

  /**
   * Analyzes the difference between oldList and newList and provide
   * callbacks for each type of change.
   * Notes:
   * * `moved` is called when an element of newList also exists
   *   in layer1 but at a different index, or when an element of
   *   newList does not exist in the old list (so the element was
   *   added)
   */
  findMovedElements(
    oldList: any[],
    newList: any[],
    moved: ArrayComparisonCallback
  ) {
    for (let i = 0; i < newList.length; i++) {
      const prevIndex = oldList.indexOf(newList[i])
      if (prevIndex === -1 || (prevIndex > -1 && i !== prevIndex)) {
        moved(newList[i])
      }
    }
  }

  findParent(elementId: number, elements: MapElementsById): Group {
    for (const id in elements) {
      if (elements.hasOwnProperty(id)) {
        const mapElement = elements[id]
        if (isMapElementGroup(mapElement)) {
          const group = mapElement as Group
          if (group.childrenId.indexOf(elementId) > -1) {
            return group
          }
        }
      }
    }
    return null
  }

  findPositionInParent(
    elementId: number,
    elements: MapElementsById
  ): number | null {
    const parent = this.findParent(elementId, elements)
    if (parent) {
      const pos = parent.childrenId.indexOf(elementId)
      if (pos > -1) return pos
    }
    return null
  }

  /**
   * Will query the GetCapabilities document
   * @param layerView Layer view
   * @return a JSON object representing the GetCapabilities response (TODO: add typing?)
   */
  queryGetCapabilities(layerView: LayerView): Observable<any> {
    const url = buildGetCapabilitiesUrl(layerView)

    // return cached observable if any
    if (this.capabilitiesCache[url]) {
      return this.capabilitiesCache[url]
    }

    const proxiedUrl = this.proxy.getProxiedUrl(url)
    this.capabilitiesCache[url] = this.http
      .get(proxiedUrl, { responseType: 'text' })
      .pipe(
        map((responseText) => {
          const parser =
            layerView.serviceType === LayerViewType.WMTS
              ? new WMTSCapabilities()
              : new WMSCapabilities()
          return parser.read(responseText)
        }),
        shareReplay()
      )
    return this.capabilitiesCache[url]
  }

  getWxsLayerLegendUrl(
    capabilitiesResponse: any,
    layerName: string,
    serviceType: LayerViewTypeModel
  ): string {
    if (serviceType === LayerViewTypeModel.WMS) {
      return this.getWmsLayerLegendUrl(capabilitiesResponse, layerName)
    } else if (serviceType === LayerViewTypeModel.WMTS) {
      return this.getWmtsLayerLegendUrl(capabilitiesResponse, layerName)
    }
    return null
  }

  /**
   * This will look for the layer in the capabilities document and return its
   * legend url for the default style.
   * Returns null if not found.
   * @param capabilitiesResponse
   * @param layerName
   */
  getWmtsLayerLegendUrl(
    capabilitiesResponse: any,
    layerName: string
  ): string | null {
    let legendUrl = null

    // parse node to look for legend url
    function lookupNode(node) {
      if (node.Identifier === layerName) {
        if (node.Style) {
          const style = Array.isArray(node.Style) ? node.Style[0] : node.Style
          if (style.LegendURL) {
            const legend = Array.isArray(style.LegendURL)
              ? style.LegendURL[0]
              : style.LegendURL
            legendUrl = legend.href
          }
        }
      }
      if (node.Layer && Array.isArray(node.Layer)) {
        for (let i = 0; i < node.Layer.length; i++) {
          lookupNode(node.Layer[i])
        }
      } else if (node.Layer) {
        lookupNode(node.Layer)
      }
    }
    lookupNode(capabilitiesResponse.Contents)
    return legendUrl
  }

  /**
   * This will look for the layer in the capabilities document and return its
   * legend url for the default style.
   * Returns null if not found.
   * @param capabilitiesResponse
   * @param layerName
   */
  getWmsLayerLegendUrl(
    capabilitiesResponse: any,
    layerName: string
  ): string | null {
    let legendUrl = null
    function lookupNode(node) {
      if (node.Name === layerName && node.Style) {
        const style = Array.isArray(node.Style) ? node.Style[0] : node.Style
        if (style.LegendURL) {
          const legend = Array.isArray(style.LegendURL)
            ? style.LegendURL[0]
            : style.LegendURL
          if (legend.OnlineResource) {
            legendUrl = legend.OnlineResource
            return
          }
        }
      }
      if (node.Layer && Array.isArray(node.Layer)) {
        for (const layer of node.Layer) {
          lookupNode(layer)
        }
      } else if (node.Layer) {
        lookupNode(node.Layer)
      }
    }
    lookupNode(capabilitiesResponse.Capability)
    return legendUrl
  }

  async tryVersions(
    serverURL: string,
    serverType: string
  ): Promise<{ stringVersion?: string; errorMsg?: string }> {
    let result: { stringVersion?: string; errorMsg?: string }
    const url = new URL(serverURL)
    url.searchParams.append('SERVICE', serverType)
    url.searchParams.append('REQUEST', 'GetCapabilities')

    for (const currentVersion of this.typesVersions[serverType].toReversed()) {
      try {
        url.searchParams.set('VERSION', currentVersion)
        const res = await fetch(url)
        if (res.ok) {
          const text = await res.text()
          result = this.parseResponse(text, serverType)
          if (result.stringVersion) return result
        } else {
          result = {
            errorMsg:
              'La récupération du GetCapabilities a rencontré une erreur.',
          }
        }
      } catch (error) {
        return {
          errorMsg:
            'La récupération du GetCapabilities a rencontré une erreur.',
        }
      }
    }

    return result
  }

  parseResponse(
    text: string,
    serverType: string
  ): { stringVersion?: string; errorMsg?: string } {
    const parser = new DOMParser()
    try {
      const xmlDoc = parser.parseFromString(text, 'text/xml')
      const fetchedVersion = xmlDoc.documentElement.getAttribute('version')

      if (!fetchedVersion) {
        return {
          errorMsg:
            'Le GetCapabilities reçu ne permet pas de récupérer une version.',
        }
      }

      const filteredVersions = this.typesVersions[serverType]
      if (filteredVersions.includes(fetchedVersion)) {
        return { stringVersion: fetchedVersion }
      } else {
        return {
          errorMsg:
            'La ou les versions proposée.s par le serveur ne sont pas compatibles avec Carto2.',
        }
      }
    } catch {
      return {
        errorMsg:
          'Le GetCapabilities reçu ne permet pas de récupérer une version.',
      }
    }
  }
}
