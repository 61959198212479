<div
  cdkDropList
  [cdkDropListConnectedTo]="connectedDropLists"
  (cdkDropListDropped)="onDrop($event)"
>
  <ui-layertree-element
    *ngFor="
      let layer of layers$ | async | reverse;
      trackBy: layerId;
      index as i
    "
    [layer]="layer"
    [depth]="depth + 1"
    cdkDrag
    [cdkDragData]="{ layer: layer, parentId: this.parentId, originalIndex: i }"
    [cdkDragDisabled]="(readonly$ | async) === true"
    [readonly]="readonly$ | async"
  >
    <i
      *ngIf="(readonly$ | async) === false"
      class="fa fa-ellipsis-v fa-fw drag-handle"
      aria-hidden="true"
      cdkDragHandle
    ></i>
  </ui-layertree-element>
  <div
    class="empty-warning text-black-50 py-2 px-3"
    *ngIf="(layersCount$ | async) === 0"
  >
    <span class="fa fa-exclamation-circle mr-1"></span>Ce groupe est vide.
  </div>
</div>
