<div class="d-flex flex-row align-items-center" [title]="mapSummary.title">
  <div *ngIf="!mapSummary.expositionStatus" class="mr-1" title="Carte">
    <span class="fa fa-globe-europe"></span>
  </div>
  <ui-map-exposition-status
    *ngIf="mapSummary.expositionStatus"
    class="mr-1"
    [synchronized]="mapSummary.expositionStatus.synchronised"
    [exposed]="mapSummary.expositionStatus.exposed"
  ></ui-map-exposition-status>
  <ui-map-publication-status
    *ngIf="mapSummary.expositionStatus"
    class="mr-1"
    [published]="mapSummary.expositionStatus.published"
  ></ui-map-publication-status>
  <div class="ml-2 text-truncate text-left" style="line-height: 1.1em">
    <span>{{ mapSummary.title }}</span
    ><br />
    <small class="text-monospace" style="opacity: 85%">{{
      mapSummary.name
    }}</small>
  </div>
</div>
