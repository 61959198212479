import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor() {}

  isThematicFormValid$ = new BehaviorSubject<boolean>(true)

  isThematicFormValid(isValid: boolean) {
    this.isThematicFormValid$.next(isValid)
  }
}
