import 'whatwg-fetch'
import SETTINGS from './settings'
import {
  AuthenticatedUserResponseModel,
  UserModel,
} from '@ui/data-access-carto-functional-administration'

class UserInfo {
  user: UserModel
  logoutUrl: string

  init() {
    return fetch(SETTINGS.cartoApiPath + '/User', {
      credentials: 'include',
    })
      .catch(() => {
        // the fetch operation failed:
        // let's assume the user is not logged in, mask error and return null
        return null
      })
      .then((response) => {
        const contentType = response && response.headers.get('content-type')

        // response is empty or not json: we are not authenticated!
        // return null userinfo so that the user is redirected to login form
        if (!contentType || contentType.indexOf('application/json') === -1) {
          return {
            user: null,
            logoutUrl: null,
          }
        }

        return response.json()
      })
      .then((json: AuthenticatedUserResponseModel) => {
        this.user = json.user
        this.logoutUrl = json.logoutUrl
      })
  }
}

const USER_INFO = new UserInfo()
export default USER_INFO
