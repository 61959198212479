import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import {
  ProjectionOption,
  projectionOptions,
} from '../../../../../../../projections'

@Component({
  selector: 'ui-projection-select',
  templateUrl: './projection-select.component.html',
  styleUrls: ['./projection-select.component.scss'],
})
export class ProjectionSelectComponent implements OnInit {
  retrievedProjectionOptions: ProjectionOption[]

  @Input() parentForm: FormGroup

  constructor() {}

  ngOnInit() {
    this.retrievedProjectionOptions = projectionOptions()
  }

  isInvalid() {
    return this.parentForm.controls.crsCode.errors
  }

  sortOrder(options: ProjectionOption[]) {
    return options.sort((a, b) => a.order - b.order)
  }
}
