/**
 * Resource Management API
 * This API covers the functions used by the Map Production and Map Publishing domains for the management of \"cartographic\" resources and the \"basic\" resources that are useful for the map production and publication process (data series → cataloged data and services).
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ResourceTypeModel =
  | 'Map'
  | 'Service'
  | 'LocalDatasource'
  | 'Style'
  | 'Process'
  | 'ProcessResult'

export const ResourceTypeModel = {
  Map: 'Map' as ResourceTypeModel,
  Service: 'Service' as ResourceTypeModel,
  LocalDatasource: 'LocalDatasource' as ResourceTypeModel,
  Style: 'Style' as ResourceTypeModel,
  Process: 'Process' as ResourceTypeModel,
  ProcessResult: 'ProcessResult' as ResourceTypeModel,
}
