/* eslint-disable  @typescript-eslint/no-unused-vars */
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import {
  MapCompositionService,
  MapExpositionScopeModel,
  MapExpositionStatusModel,
} from '@ui/data-access-carto-map-production'
import {
  fromString as bboxFromString,
  Info,
  MapFacade,
} from '@ui/feature/mapstate'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Observable, of, Subscription, throwError } from 'rxjs'
import {
  catchError,
  finalize,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { NotificationFacade } from '../../../../store'
import { EditMapModalComponent } from './edit-map-modal/edit-map-modal.component'
import { ResourceFacade } from '@ui/ui/resources'
import { DialogsService } from '@ui/ui/layout'
import { HttpErrorResponse } from '@angular/common/http'
import { SseService } from '@ui/data-access-carto-async'
import {
  Layer,
  MapElementsById,
  MapExpositionStatus,
  parseApiMap,
} from '@ui/feature/shared'
import { getExposedMapUrl } from '../../../../helpers/map.helpers'

@Component({
  selector: 'ui-edit-map',
  template: '',
})
export class EditMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<undefined>
  modalRef: BsModalRef
  sub: Subscription = new Subscription()

  constructor(
    private modalService_: BsModalService,
    private mapComposition_: MapCompositionService,
    private mapFacade_: MapFacade,
    private notificationFacade_: NotificationFacade,
    private resourceFacade_: ResourceFacade,
    private dialogsService_: DialogsService,
    private sseService_: SseService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$
        .pipe(
          withLatestFrom(
            this.mapFacade_.info$,
            this.mapFacade_.extent$,
            this.mapFacade_.expositionScope$,
            this.mapFacade_.expositionStatus$,
            this.mapFacade_.mapElements$
          )
        )
        .subscribe(([_, info, extent, scope, expositionStatus, mapElements]) =>
          this.openModal(
            info,
            this.roundExtent(extent),
            scope,
            expositionStatus,
            mapElements
          )
        )
    )
  }

  clearError(): void {
    this.modalRef.content.errorMsg = null
  }

  dispatchErrorInModal(error: any): void {
    let message = 'L’enregistrement de la carte a échoué'
    if (error.status === 409) {
      message = 'Une carte avec ce nom existe déjà'
    }
    this.modalRef.content.errorMsg = message
  }

  dispatchErrorAfterModal(error: {
    password?: boolean
    expositionStatus?: string | boolean
  }): void {
    let message = 'Une erreur inattendue est survenue'
    let sticky = false
    if (error.password) {
      message =
        "La carte a été sauvegardée mais la modification du mot de passe a échoué; le statut de diffusion de la carte n'a pas été modifié en conséquence"
    } else if (typeof error.expositionStatus === 'string') {
      sticky = true
      message = `La carte a été sauvegardée mais le statut de diffusion de la carte n'a pas pu être modifié pour la raison suivante : ${error.expositionStatus}`
    } else if (error.expositionStatus) {
      message =
        "La carte a été sauvegardée mais le statut de diffusion de la carte n'a pas pu être modifié"
    }
    this.notificationFacade_.showNotification(
      'warning',
      message,
      sticky ? 0 : 5000
    )
  }

  openModal(
    info: Info,
    extent: number[],
    expositionScope: MapExpositionScopeModel,
    expositionStatus: MapExpositionStatus,
    mapElements: MapElementsById
  ) {
    const layers = Object.values(mapElements)
    const hasBOCLayers = layers.some(
      (el: Layer) => el.views && el.views[0].originType === 'BOC'
    )
    info.owsUrl =
      hasBOCLayers && expositionStatus.synchronised ? info.owsUrl : null

    this.modalRef = this.modalService_.show(EditMapModalComponent, {
      ignoreBackdropClick: true,
      initialState: { info, extent, expositionScope },
      class: 'modal-lg',
    })
    this.modalRef.content.save.subscribe((form: FormGroup) => {
      this.clearError()
      this.saveMap(info.id, form, expositionStatus)
    })
    this.modalRef.content.cancel.subscribe(() => this.modalRef.hide())
  }

  saveMap(
    mapId: string,
    form: FormGroup,
    expositionStatusInitial: MapExpositionStatus
  ) {
    let bounds
    const { expositionScope, password, ...formValues } = form.getRawValue()
    if (formValues.bounds) {
      bounds = bboxFromString(formValues.bounds)
    }

    const mapInfo = {
      ...formValues,
      bounds,
    }
    const modifyMapParams = {
      ...mapInfo,
    }
    delete modifyMapParams.protected

    const expositionStatus = {
      ogcServicesExposed: true,
      expositionScope,
    }

    const modifyMapPassword$ = () =>
      (form.controls.protected.dirty
        ? form.controls.password.value
          ? this.mapComposition_.setMapPassword(
              mapId,
              form.controls.password.value
            )
          : this.mapComposition_.removeMapPassword(mapId)
        : of(true)
      ).pipe(catchError(() => of(new Error())))

    const modifyExpositionStatus$ = () =>
      this.sseService_
        .wrapApiCall('END_EXPOSITION', () =>
          this.mapComposition_.setExpositionStatus(mapId, expositionStatus)
        )
        .pipe(
          switchMap(() => this.mapComposition_.findMap(mapId)),
          map((map) => parseApiMap(map).expositionStatus),
          catchError((error) =>
            of(
              new Error(
                error instanceof HttpErrorResponse && error.status === 409
                  ? error.error
                  : ''
              )
            )
          )
        )

    // api queries:
    // modify map
    //    |--> close modal
    //    |
    //    |--> save password --> save exposition --> show success message
    //
    this.mapComposition_
      .modifyMap(mapId, modifyMapParams)
      .pipe(
        tap(() => {
          this.modalRef.hide()
          this.dialogsService_.showLoadingModal(
            'Sauvegarde de la carte en cours...'
          )
          this.resourceFacade_.refreshMaps()
          this.mapFacade_.updateMap(mapInfo)
        }),
        catchError((error) => {
          this.dispatchErrorInModal(error)
          return throwError({})
        }),
        switchMap(() => modifyMapPassword$()),
        tap((passwordResult) => {
          if (passwordResult instanceof Error) {
            this.dispatchErrorAfterModal({
              password: true,
            })
            throw {}
          }
        }),
        switchMap(() => modifyExpositionStatus$()),
        tap((expositionStatusResult) => {
          if (expositionStatusResult instanceof Error) {
            this.dispatchErrorAfterModal({
              expositionStatus: expositionStatusResult.message || true,
            })
            throw {}
          }
        }),
        finalize(() => this.dialogsService_.hideLoadingModal())
      )
      .subscribe({
        next: (expositionStatusResult: MapExpositionStatusModel) => {
          this.mapFacade_.setExpositionStatus(expositionStatusResult)

          const oldExposed = expositionStatusInitial.exposed
          const newExposed = expositionStatusResult.exposed
          const message =
            newExposed !== oldExposed && newExposed
              ? `La carte a été sauvegardée et diffusée : <a href="${getExposedMapUrl(
                  mapId
                )}" target="_blank">Ouvrir la carte diffusée</a>`
              : 'La carte a été sauvegardée'

          this.notificationFacade_.showNotification('success', message, 5000)
        },
        error: () => {},
      })
  }

  // this will round the extent to a slightly smaller one, to allow
  // fitting on the extent correctly instead of jumping to the upper
  // zoom level
  roundExtent(extent: number[]): number[] {
    return [
      Math.floor(extent[0] + 1),
      Math.floor(extent[1] + 1),
      Math.ceil(extent[2] - 1),
      Math.ceil(extent[3] - 1),
    ]
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
