<div class="btn-group" dropdown [isDisabled]="disabled || null">
  <button
    title="Ouvrir le menu de la carte"
    dropdownToggle
    class="btn btn-sm btn-light open-menu"
  >
    <i class="fa fa-cog"></i>
  </button>
  <ul class="dropdown-menu" *dropdownMenu>
    <li role="menuitem">
      <button
        type="button"
        (click)="openSettings.emit()"
        class="dropdown-item"
        title="Ouvre le menu de paramètres avancés de la carte."
      >
        Paramètres avancés
      </button>
      <button
        type="button"
        (click)="openExpositionOptions.emit()"
        class="dropdown-item"
        title="Ouvre le menu de saisie des options de diffusion de la carte."
      >
        Options de diffusion
      </button>
      <button
        type="button"
        (click)="openMetadata.emit()"
        class="dropdown-item"
        title="Ouvre le menu de saisie des métadonnées de publication."
      >
        Métadonnées de publication
      </button>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <button
        type="button"
        (click)="syncExposedWithCurrent.emit()"
        class="dropdown-item sync-with-working"
        title="Applique les dernières modifications à la version diffusée de la carte."
        [ngClass]="{ disabled: !outOfSync }"
      >
        Diffuser les modifications
      </button>
    </li>
    <li role="menuitem">
      <button
        type="button"
        (click)="syncCurrentWithExposed.emit()"
        class="dropdown-item sync-with-exposed"
        title="Ecrase la version courante de la carte avec sa dernière version diffusée."
        [ngClass]="{ disabled: !outOfSync }"
      >
        Réaligner avec la version diffusée
      </button>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <button
        type="button"
        (click)="publish.emit()"
        class="dropdown-item publish"
        [title]="
          (published ? 'Rep' : 'P') +
          'ublie la carte et ses métadonnées dans GI Catalogue.'
        "
        [ngClass]="{ disabled: !publishable }"
      >
        {{ published ? 'Rep' : 'P' }}ublier la carte
      </button>
    </li>
    <li role="menuitem">
      <button
        type="button"
        (click)="unpublish.emit()"
        class="dropdown-item unpublish"
        title="Dépublie la carte et ses métadonnées dans GI Catalogue."
        [ngClass]="{ disabled: !published }"
      >
        Dépublier la carte
      </button>
    </li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem">
      <button
        type="button"
        class="dropdown-item repair"
        title="Reconstruit l'espace de travail associé à la carte"
        (click)="repair.emit()"
      >
        Réparer la carte
      </button>
    </li>
    <li *ngIf="!disableImportQgis" class="divider dropdown-divider"></li>
    <li role="menuitem">
      <button
        *ngIf="!disableImportQgis"
        type="button"
        class="dropdown-item"
        title="Met à jour les données QGIS de la carte grâce à un nouveau gpkg"
        (click)="updateQGIS.emit()"
      >
        {{
          isMapBasedOnGpkg
            ? 'Mettre à jour les couches QGIS'
            : 'Importer un projet QGIS'
        }}
      </button>
    </li>
    <li *ngIf="isMapBasedOnGpkg" role="menuitem">
      <button
        type="button"
        class="dropdown-item"
        title="Réinitialise une carte basée sur un projet QGIS à partir du fichier GPKG initial"
        (click)="resetQGIS.emit()"
      >
        Réinitialiser les couches QGIS
      </button>
    </li>
  </ul>
</div>
