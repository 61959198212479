import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms'
import {
  buildWfsGetFeatureUrl,
  DataSchema,
  ProxyService,
  WfsReadParams,
} from '@ui/feature/shared'
import {
  AnalysisDataField,
  ClassificationMethod,
  ELEMENT_TYPE,
  ELEMENT_TYPE_LABELS,
  FIELD_ANALYSIS_REJECT_REASON,
  Style,
  THEMATIC_STYLE_TYPE,
  WfsServiceInfo,
} from '../style.model'
import { HttpClient } from '@angular/common/http'
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  fromEvent,
  merge,
  Observable,
  of,
} from 'rxjs'
import { Feature, GeoJSON } from 'geojson'
import {
  catchError,
  filter,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { EventService } from '../event.service'
import { StyleService } from '../style.service'
import { RulesetGeneratorInterpolationComponent } from '../ruleset-generator-interpolation/ruleset-generator-interpolation.component'
import { RulesetGeneratorRandomComponent } from '../ruleset-generator-random/ruleset-generator-random.component'

export const DEFAULT_CLASSES_NAME_TEMPLATE = `$ATTR ($MIN_VALUE - $MAX_VALUE)`
export const DEFAULT_VALUES_NAME_TEMPLATE = `$ATTR = $VALUE`

const MAX_INTERPOLATION_STEPS = 45

@Component({
  selector: 'ui-thematic-style-editor',
  templateUrl: './thematic-style-editor.component.html',
  styleUrls: ['./thematic-style-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThematicStyleEditorComponent implements OnInit {
  @Input() serviceInfo$: Observable<WfsServiceInfo>
  @Input() dataSchema$: Observable<DataSchema>
  @Output() generatedStyle$ = new EventEmitter<Style>()

  @ViewChild('thematicTypeSelect', { static: true })
  thematicTypeSelect: ElementRef
  @ViewChild('elementTypeSelect', { static: true })
  elementTypeSelect: ElementRef
  @ViewChild('ruleNameTemplate', { static: true }) ruleNameTemplate: ElementRef
  @ViewChild('ruleNameCustomized', { static: true })
  ruleNameCustomized: ElementRef
  @ViewChild('rulesetGenerator', { static: false })
  rulesetGenerator:
    | RulesetGeneratorInterpolationComponent
    | RulesetGeneratorRandomComponent

  type$: Observable<THEMATIC_STYLE_TYPE>
  types = THEMATIC_STYLE_TYPE

  elementType$: Observable<ELEMENT_TYPE>
  elementTypesArray: ELEMENT_TYPE[] = [
    ELEMENT_TYPE.POLYGON,
    ELEMENT_TYPE.LINE,
    ELEMENT_TYPE.POINT,
  ]
  elementTypeLabels = ELEMENT_TYPE_LABELS

  ruleNameCustomized$: Observable<boolean>
  ruleNameTemplate$: Observable<string>

  classificationType = ClassificationMethod.EQUAL
  classificationTypes = ClassificationMethod

  classesCount = 10

  thematicStyleEditorForm = this.fb.group({
    roundingClasses: new FormControl<number>(null, {
      validators: [Validators.required, this.roundingValidator],
    }),
  })

  features$: Observable<Feature[]>
  featuresLoading$: Observable<boolean>
  featuresError$: Observable<boolean>

  availableFields$: Observable<AnalysisDataField[]>
  unavailableFields$: Observable<AnalysisDataField[]>
  selectedField$: Observable<AnalysisDataField>
  selectedFieldName$: Observable<string>
  selectFieldEmitter$ = new EventEmitter<AnalysisDataField>()
  maxRulesCount$: Observable<number>
  usesInterpolation$: Observable<boolean>

  generateStyleRequest$ = new EventEmitter()

  public dropdownContainer = 'body' // will be unset for tests

  roundingClasses = 1
  roundingClassesActive$ = new BehaviorSubject<boolean>(false)

  set roundingClassesActive(value: boolean) {
    this.roundingClassesActive$.next(value)
  }

  get roundingClassesActive() {
    return this.roundingClassesActive$.getValue()
  }

  constructor(
    private http: HttpClient,
    private service: StyleService,
    private proxy: ProxyService,
    private fb: FormBuilder,
    private event: EventService
  ) {}

  ngOnInit() {
    // this will emit a value every time the thematic style type is changed, and emits
    // a default value of CLASSIFICATION
    this.type$ = fromEvent<Event>(
      this.thematicTypeSelect.nativeElement,
      'change'
    ).pipe(
      map(
        (evt) =>
          (evt.currentTarget as HTMLSelectElement).value as THEMATIC_STYLE_TYPE
      ),
      startWith(THEMATIC_STYLE_TYPE.CLASSIFICATION)
    )

    // this will emit a value every time the element type is changed, and emits
    // a default value of POLYGON
    this.elementType$ = fromEvent<Event>(
      this.elementTypeSelect.nativeElement,
      'change'
    ).pipe(
      map(
        (evt) => (evt.currentTarget as HTMLSelectElement).value as ELEMENT_TYPE
      ),
      startWith(ELEMENT_TYPE.POLYGON),
      shareReplay()
    )

    // gather values from inputs used for rule name template edition
    const ruleNameTemplateInput$ = fromEvent<Event>(
      this.ruleNameTemplate.nativeElement,
      'input'
    ).pipe(map((evt) => (evt.currentTarget as HTMLInputElement).value))
    const customTemplateCheckbox$ = fromEvent<Event>(
      this.ruleNameCustomized.nativeElement,
      'change'
    ).pipe(map((evt) => (evt.currentTarget as HTMLInputElement).checked))

    // this will emit a value when the rule template changes (either the default value or a custom one)
    this.ruleNameTemplate$ = this.type$.pipe(
      switchMap((type) => {
        const defaultValue =
          type === THEMATIC_STYLE_TYPE.CLASSIFICATION
            ? DEFAULT_CLASSES_NAME_TEMPLATE
            : DEFAULT_VALUES_NAME_TEMPLATE
        return combineLatest([
          ruleNameTemplateInput$,
          customTemplateCheckbox$,
        ]).pipe(
          startWith([defaultValue, false] as [string, boolean]),
          scan<[string, boolean], string>(
            (acc, [value, customized]) => (customized ? value : defaultValue),
            ''
          )
        )
      })
    )

    // this will emit values when the rule template changes
    this.ruleNameCustomized$ = this.type$.pipe(
      switchMap(() => customTemplateCheckbox$.pipe(startWith(false)))
    )

    this.features$ = this.dataSchema$.pipe(
      withLatestFrom(this.serviceInfo$),
      switchMap(([dataSchema, serviceInfo]) => {
        if ('error' in dataSchema) {
          return of([])
        }

        const propertyNames = Object.keys(dataSchema.properties)
        const wfsParams: WfsReadParams = {
          ...serviceInfo,
          outputFormat: 'application/json',
          propertyName: propertyNames.filter(
            (name) =>
              (dataSchema.properties[name].type === 'number' ||
                dataSchema.properties[name].type === 'string') &&
              name.indexOf('geom') === -1
          ),
        }

        const allFeaturesUrl = buildWfsGetFeatureUrl(wfsParams)
        const sampleFeaturesUrl = buildWfsGetFeatureUrl({
          ...wfsParams,
          maxFeatures: 1,
          propertyName: propertyNames.filter(
            (name) => name.indexOf('geom') === -1
          ),
        })

        // helper to create an observable that will fail if the resulting JSON
        // is not a feature collection, and otherwise emit the features array
        const getFeatures = (requestUrl) => {
          return this.http
            .get<GeoJSON>(this.proxy.getProxiedUrl(requestUrl))
            .pipe(
              map((response: GeoJSON) => {
                if (response.type === 'FeatureCollection') {
                  return response.features
                } else {
                  console.warn(
                    `Réponse à la requête GetFeature invalide: ${JSON.stringify(
                      response
                    )})`
                  )
                  return []
                }
              })
            )
        }

        // This makes an observable that will emit only one array of all features,
        // with the sample one (having all properties instead of only numerical
        // ones) being there as well.
        // This is essentially the equivalent of a Promise.all() with some
        // processing of the return values
        return forkJoin([
          getFeatures(allFeaturesUrl),
          getFeatures(sampleFeaturesUrl),
        ]).pipe(
          map(([all, sample]) =>
            []
              .concat(all, sample)
              .filter(
                (feature, index, array) => array.lastIndexOf(feature) === index
              )
          ),
          catchError(() => of([]))
        )
      }),
      shareReplay()
    )
    this.featuresLoading$ = merge(
      of(true),
      this.features$.pipe(map(() => false))
    )
    this.featuresError$ = this.features$.pipe(
      map((features) => features && features.length === 0)
    )

    // this observable wil re-emit available and unavailable fields
    // whenever the thematic style type changes, because the limitation
    // on unique value count is not present for class-based thematic style
    const analyzedFields$ = combineLatest([this.type$, this.features$]).pipe(
      map(([type, features]) =>
        this.service.analyzeFields(
          features,
          type === THEMATIC_STYLE_TYPE.CLASSIFICATION,
          type === THEMATIC_STYLE_TYPE.VALUES
            ? MAX_INTERPOLATION_STEPS
            : undefined
        )
      ),
      shareReplay()
    )
    this.availableFields$ = analyzedFields$.pipe(
      map((fields) =>
        Object.keys(fields.available).map((name) => ({
          name,
          ...fields.available[name],
        }))
      )
    )
    this.unavailableFields$ = analyzedFields$.pipe(
      map((fields) =>
        Object.keys(fields.unavailable).map((name) => ({
          name,
          ...fields.unavailable[name],
        }))
      )
    )

    // select first field available and merge with select events
    this.selectedField$ = merge(
      this.availableFields$.pipe(
        filter((fields) => fields.length > 0),
        map((fields) => fields[0])
      ),
      this.selectFieldEmitter$
    ).pipe(shareReplay()) as Observable<AnalysisDataField>
    this.selectedFieldName$ = this.selectedField$.pipe(
      map((field) => field.name),
      startWith('(aucun champ)'),
      shareReplay()
    )

    this.maxRulesCount$ = this.selectedField$.pipe(
      map((field) =>
        Math.min(field.uniqueValues.length, MAX_INTERPOLATION_STEPS)
      ),
      tap((max) => {
        if (this.classesCount > max) this.classesCount = max
      })
    )

    // this is used to determine which kind of ruleset generator
    // we show (random- or interpolation-based)
    this.usesInterpolation$ = this.selectedField$.pipe(
      map((field) => typeof field.uniqueValues[0] === 'number')
    )

    const roundingClassesControl =
      this.thematicStyleEditorForm.controls.roundingClasses

    this.roundingClassesActive$.subscribe((active) => {
      active
        ? roundingClassesControl.enable()
        : roundingClassesControl.disable()
    })

    this.thematicStyleEditorForm.controls.roundingClasses.valueChanges.subscribe(
      (value) => {
        if (!value || value === this.roundingClasses) return // valueChanges also emitted when enabled()/disabled() is called

        this.onRoundingClassesChange(value)

        // patchValue on control will prevent triggering valueChange
        this.thematicStyleEditorForm.patchValue(
          { roundingClasses: this.roundingClasses },
          { onlySelf: true, emitEvent: false }
        )
      }
    )

    this.thematicStyleEditorForm.statusChanges.subscribe(() =>
      this.event.isThematicFormValid(this.isThematicFormValid())
    )

    this.generateStyleRequest$
      .pipe(
        withLatestFrom(
          this.type$,
          this.selectedField$,
          this.elementType$,
          this.ruleNameTemplate$,
          this.roundingClassesActive$
        ),
        map(
          ([
            ,
            thematicType,
            field,
            elementType,
            ruleNameTemplate,
            roundingClassesActive,
          ]) => {
            const values = field.uniqueValues as any[]
            const rules =
              thematicType === THEMATIC_STYLE_TYPE.CLASSIFICATION
                ? this.rulesetGenerator.generateUsingClasses(
                    field.name,
                    this.classesCount,
                    this.classificationType,
                    values,
                    this.service.getClassesRuleNameGenerator(
                      ruleNameTemplate,
                      field.name
                    ),
                    roundingClassesActive
                      ? this.thematicStyleEditorForm.getRawValue()
                          .roundingClasses
                      : undefined
                  )
                : this.rulesetGenerator.generateUsingValues(
                    field.name,
                    values,
                    this.service.getValuesRuleNameGenerator(
                      ruleNameTemplate,
                      field.name
                    )
                  )

            return {
              name: 'Analyse thématique générée',
              _elementType: elementType,
              rules,
            }
          }
        )
      )
      .subscribe((style) => this.generatedStyle$.emit(style))
  }

  getDisplayFieldInfo(field: AnalysisDataField) {
    const uniqueCount = field.uniqueValues.length
    const plural = uniqueCount > 1 ? 's' : ''
    switch (field.type) {
      case 'integer':
      case 'number':
        return `nombre (${uniqueCount} valeur${plural} unique${plural})`
      case 'string':
        return `chaîne de caractère (${uniqueCount} valeur${plural} unique${plural})`
      case 'boolean':
        return `booléen`
      default:
        return `type non reconnu`
    }
  }

  getDisplayRejectReason(reason: FIELD_ANALYSIS_REJECT_REASON) {
    switch (reason) {
      case FIELD_ANALYSIS_REJECT_REASON.TOO_MANY_VALUES:
        return 'nombre de valeurs uniques trop élevé'
      case FIELD_ANALYSIS_REJECT_REASON.INCOMPATIBLE_TYPE:
        return 'type incompatible'
      default:
        return `champ invalide`
    }
  }

  generate() {
    this.generateStyleRequest$.emit()
  }

  onRoundingClassesChange(rounding: number) {
    if (rounding === 1) {
      // Case when reseting manually the input to '1'
      this.roundingClasses = 1
      return
    }

    if (rounding > this.roundingClasses) {
      this.roundingClasses *= 10
    } else {
      this.roundingClasses /= 10
    }

    if (this.roundingClasses < 1) {
      this.roundingClasses = 1
    }
  }

  onPasteRoundingClasses(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text')

    if (!/10*/.test(pastedText)) {
      event.preventDefault()
    }
  }

  onKeydownRoundingClasses(event: KeyboardEvent) {
    if (
      ![
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Delete',
        'Backspace',
        'Enter',
        'Tab',
      ].includes(event.code) &&
      !/[10]/.test(event.key)
    ) {
      event.preventDefault()
    }
  }

  isThematicFormValid() {
    return (
      !this.roundingClassesActive ||
      (this.roundingClassesActive && this.thematicStyleEditorForm.valid)
    )
  }

  roundingValidator(control: AbstractControl): ValidationErrors | null {
    const rounding = control.value
    return !/10*/.test(rounding) ? { errorValue: rounding } : null
  }
}
