import {
  LayerGroupModel,
  LayerModel,
} from '@ui/data-access-carto-map-production'
import {
  Layer,
  LayerView,
  Group,
  MapExpositionStatus,
  PublicationReport,
} from '../../model'
import { parseApiMap } from '../../helpers/carto-api.helpers'

const zico: LayerModel = {
  mapElementType: 'Layer',
  id: 2841,
  title: 'N_ZICO_ZINF_S_R24',
  description: null,
  opacity: 1,
  visible: true,
  isGroup: false,
  rank: 6,
  groupId: null,
  queryable: false,
  minScale: null,
  maxScale: null,
  views: [
    {
      id: 2846,
      dataSourceId: '6a1f4dee-9d96-4fac-9b48-234deb373197',
      layerName: 'N_ZICO_ZINF_S_R24',
      title: 'N_ZICO_ZINF_S_R24',
      origin:
        '/mnt/geobase/DEPT041A/CONSULTATION/NATURE_PAYSAGE_BIODIVERSITE/N_INVENTAIRE_NATURE_BIODIVERSITE/N_ZICO_ZINF_S_R24.shp',
      relativeOrigin:
        '/DEPT041A/CONSULTATION/NATURE_PAYSAGE_BIODIVERSITE/N_INVENTAIRE_NATURE_BIODIVERSITE/N_ZICO_ZINF_S_R24.shp',
      geometryName: 'the_geom',
      originType: 'BOC',
      services: {
        wms: {
          serviceType: 'WMS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZICO_ZINF_S_R24',
          serviceVersion: '1.3.0',
        },
        wfs: {
          serviceType: 'WFS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZICO_ZINF_S_R24',
          serviceVersion: '1.0.0',
        },
        wmts: {
          serviceType: 'WMTS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZICO_ZINF_S_R24',
          serviceVersion: '1.0.0',
        },
        vectorTiles: null,
      },
      serviceType: 'WMS',
      dataSourceType: 'SHAPEFILE',
      dataSourceDataType: 'VECTOR',
      dataSourceGeometryType: 'POLYGON',
      supportsEncoding: true,
      characterEncoding: 'UTF-8',
      serviceVersion: '1.3.0',
      baseUrl:
        'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZICO_ZINF_S_R24',
      crsCode: 'EPSG:2154',
      boundingBox: null,
      minScale: null,
      maxScale: null,
      wmsQueryable: null,
      requestProjection: 'EPSG:3857',
      possibleRenderingProjections: null,
      defaultStyleId: '76f4cbdb-0bb8-4953-b291-568bffc1c513',
      attributes: [
        {
          id: 900000259,
          view: 900000233,
          name: 'GB_VERROU',
          label: '',
          type: 'String',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
        {
          id: 900000258,
          view: 900000233,
          name: 'GB_DATVER',
          label: '',
          type: 'Date',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
        {
          id: 900000257,
          view: 900000233,
          name: 'GB_DDMOD',
          label: '',
          type: 'Date',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
      ],
    },
  ],
}
const znieff: LayerModel = {
  mapElementType: 'Layer',
  id: 2873,
  title: 'N_ZNIEFF2_ZINF_S_R24',
  description: null,
  opacity: 1,
  visible: true,
  isGroup: false,
  rank: 7,
  groupId: null,
  queryable: false,
  minScale: null,
  maxScale: null,
  views: [
    {
      id: 2877,
      dataSourceId: '13151b7a-95f7-4b83-be59-d727ffb8988a',
      layerName: 'N_ZNIEFF2_ZINF_S_R24',
      title: 'N_ZNIEFF2_ZINF_S_R24',
      origin:
        '/mnt/geobase/DEPT041A/CONSULTATION/NATURE_PAYSAGE_BIODIVERSITE/N_INVENTAIRE_NATURE_BIODIVERSITE/N_ZNIEFF2_ZINF_S_R24.shp',
      relativeOrigin:
        '/DEPT041A/CONSULTATION/NATURE_PAYSAGE_BIODIVERSITE/N_INVENTAIRE_NATURE_BIODIVERSITE/N_ZNIEFF2_ZINF_S_R24.shp',
      geometryName: 'the_geom',
      originType: 'BOC',
      services: {
        wms: {
          serviceType: 'WMS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZNIEFF2_ZINF_S_R24',
          serviceVersion: '1.3.0',
        },
        wfs: {
          serviceType: 'WFS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZNIEFF2_ZINF_S_R24',
          serviceVersion: '1.0.0',
        },
        wmts: {
          serviceType: 'WMTS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZNIEFF2_ZINF_S_R24',
          serviceVersion: '1.0.0',
        },
        vectorTiles: null,
      },
      serviceType: 'WMS',
      dataSourceType: 'SHAPEFILE',
      dataSourceDataType: 'VECTOR',
      dataSourceGeometryType: 'POLYGON',
      supportsEncoding: true,
      characterEncoding: 'UTF-8',
      serviceVersion: '1.3.0',
      baseUrl:
        'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/27c1ce92-79e3-446f-8969-f6558b2d7e26/N_ZNIEFF2_ZINF_S_R24',
      crsCode: 'EPSG:2154',
      boundingBox: null,
      minScale: null,
      maxScale: null,
      wmsQueryable: null,
      requestProjection: 'EPSG:3857',
      possibleRenderingProjections: null,
      defaultStyleId: 'b7ac3678-cb85-40f8-8b4a-73a5a1732af3',
      attributes: [
        {
          id: 900000272,
          view: 900000265,
          name: 'FICHE',
          label: '',
          type: 'String',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
        {
          id: 900000271,
          view: 900000265,
          name: 'DATE_MAJ',
          label: '',
          type: 'Date',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
        {
          id: 900000270,
          view: 900000265,
          name: 'ANNEE',
          label: '',
          type: 'String',
          published: true,
          includeInTooltip: false,
          includeInFilter: false,
        },
      ],
    },
  ],
}
const geotiff: any = {
  mapElementType: 'Layer',
  id: 900000280,
  title: 'L_TYPO_CLIMAT_000',
  description: null,
  opacity: 1,
  visible: true,
  isGroup: false,
  rank: 1,
  groupId: null,
  queryable: false,
  minScale: null,
  maxScale: null,
  views: [
    {
      id: 900000284,
      dataSourceId: 'c8797782-cd91-4576-9849-e2bbff91c7f2',
      layerName: 'L_TYPO_CLIMAT_000',
      title: 'L_TYPO_CLIMAT_000',
      origin:
        '/mnt/geobase/REG082A/CONSULTATION/AIR_CLIMAT/N_METEO/L_TYPO_CLIMAT_000.tif',
      relativeOrigin:
        '/REG082A/CONSULTATION/AIR_CLIMAT/N_METEO/L_TYPO_CLIMAT_000.tif',
      geometryName: null,
      originType: 'BOC',
      services: {
        wms: {
          serviceType: 'WMS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/5017024a-9453-4145-8343-f7dd4996fcb1/L_TYPO_CLIMAT_000',
          serviceVersion: '1.3.0',
        },
        wfs: null,
        wmts: {
          serviceType: 'WMTS',
          serviceUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/5017024a-9453-4145-8343-f7dd4996fcb1/L_TYPO_CLIMAT_000',
          serviceVersion: '1.0.0',
        },
        vectorTiles: null,
      },
      serviceType: 'WMS',
      dataSourceType: 'GEOTIFF',
      dataSourceDataType: 'RASTER',
      dataSourceGeometryType: 'UNKNOWN',
      supportsEncoding: false,
      characterEncoding: null,
      serviceVersion: '1.3.0',
      baseUrl:
        'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/ows/5017024a-9453-4145-8343-f7dd4996fcb1/L_TYPO_CLIMAT_000',
      crsCode: 'UNKNOWN',
      boundingBox: null,
      minScale: null,
      maxScale: null,
      wmsQueryable: null,
      requestProjection: 'EPSG:3857',
      possibleRenderingProjections: null,
      defaultStyleId: null,
      attributes: [],
    },
  ],
}

export const specFromApi: any = {
  objectType: 'MapFull',
  protected: false,
  id: '948d8782-dbf5-4aba-8369-32a02f5cf3d2',
  orgId: 'org_38024',
  name: 'abcdefgh',
  title: 'abcdefgh',
  description: '',
  creationDate: '2022-05-24T13:41:55.595Z',
  crsCode: 'EPSG:3857',
  organizationName:
    'DDT 41 (Direction Départementale des Territoires du Loir-et-Cher)',
  bounds: {
    minx: -90004.60080579507,
    maxx: -80335.31672772027,
    miny: 5651099.379351233,
    maxy: 5658274.9053815035,
  },
  expositionStatus: {
    expositionScope: 'BOTH',
    ogcServicesExposed: true,
    owsUrl:
      'http://localhost:4200/rest-api/ows/org_38024_948d8782-dbf5-4aba-8369-32a02f5cf3d2/ows?',
    synchronised: false,
    exposed: false,
    published: false,
  },
  layers: [
    {
      mapElementType: 'Layer',
      id: 2821,
      title: 'Photographies aériennes',
      description: null,
      opacity: 1,
      visible: true,
      isGroup: false,
      rank: 4,
      groupId: null,
      queryable: false,
      minScale: 1,
      maxScale: 1.7976931348623157e308,
      views: [
        {
          id: 2822,
          dataSourceId: null,
          layerName: 'ORTHOIMAGERY.ORTHOPHOTOS',
          title: 'Photographies aériennes',
          origin: 'https://wxs.ign.fr/iqqdkoa235g0mf1gr3si0162/geoportail/wmts',
          relativeOrigin: null,
          geometryName: null,
          originType: 'REMOTE',
          services: {
            wms: null,
            wfs: null,
            wmts: {
              serviceType: 'WMTS',
              serviceUrl:
                'https://wxs.ign.fr/iqqdkoa235g0mf1gr3si0162/geoportail/wmts',
              serviceVersion: '1.0.0',
            },
            vectorTiles: null,
          },
          serviceType: 'WMTS',
          dataSourceType: null,
          dataSourceDataType: 'UNKNOWN',
          dataSourceGeometryType: null,
          supportsEncoding: false,
          characterEncoding: null,
          serviceVersion: '1.0.0',
          baseUrl:
            'https://wxs.ign.fr/iqqdkoa235g0mf1gr3si0162/geoportail/wmts',
          crsCode: 'UNKNOWN',
          boundingBox: null,
          minScale: 1,
          maxScale: 1.7976931348623157e308,
          wmsQueryable: true,
          requestProjection: 'EPSG:3857',
          possibleRenderingProjections: null,
          defaultStyleId: null,
          attributes: [],
        },
      ],
    },
    {
      mapElementType: 'Layer',
      id: 2823,
      title: 'Cartes géologiques Nouvelle calédonie 1/200 000',
      description: null,
      opacity: 1,
      visible: true,
      isGroup: false,
      rank: 2,
      groupId: null,
      queryable: false,
      minScale: 1,
      maxScale: 1.7976931348623157e308,
      views: [
        {
          id: 2824,
          dataSourceId: null,
          layerName: 'GEOL_NCL_200K',
          title: 'Cartes géologiques Nouvelle calédonie 1/200 000',
          origin: 'http://geoservices.brgm.fr/geologie',
          relativeOrigin: null,
          geometryName: null,
          originType: 'REMOTE',
          services: {
            wms: {
              serviceType: 'WMS',
              serviceUrl: 'http://geoservices.brgm.fr/geologie',
              serviceVersion: '1.3.0',
            },
            wfs: null,
            wmts: null,
            vectorTiles: null,
          },
          serviceType: 'WMS',
          dataSourceType: null,
          dataSourceDataType: 'UNKNOWN',
          dataSourceGeometryType: null,
          supportsEncoding: false,
          characterEncoding: null,
          serviceVersion: '1.3.0',
          baseUrl: 'http://geoservices.brgm.fr/geologie',
          crsCode: 'UNKNOWN',
          boundingBox: null,
          minScale: 1,
          maxScale: 1.7976931348623157e308,
          wmsQueryable: true,
          requestProjection: 'EPSG:3857',
          possibleRenderingProjections: null,
          defaultStyleId: null,
          attributes: [],
        },
      ],
    },
    {
      mapElementType: 'Layer',
      id: 2827,
      title: 'TYPE_MAREE_BDD_4326_WFS:type_maree.title',
      description: null,
      opacity: 1,
      visible: true,
      isGroup: false,
      rank: 5,
      groupId: null,
      queryable: false,
      minScale: 1,
      maxScale: 500000,
      views: [
        {
          id: 2828,
          dataSourceId: null,
          layerName: 'TYPE_MAREE_BDD_4326_WFS:type_maree',
          title: 'TYPE_MAREE_BDD_4326_WFS:type_maree.title',
          origin: 'http://services.data.shom.fr/INSPIRE/wfs',
          relativeOrigin: null,
          geometryName: 'the_geom',
          originType: 'REMOTE',
          services: {
            wms: null,
            wfs: {
              serviceType: 'WFS',
              serviceUrl: 'http://services.data.shom.fr/INSPIRE/wfs',
              serviceVersion: '2.0.0',
            },
            wmts: null,
            vectorTiles: null,
          },
          serviceType: 'WFS',
          dataSourceType: null,
          dataSourceDataType: 'UNKNOWN',
          dataSourceGeometryType: null,
          supportsEncoding: false,
          characterEncoding: null,
          serviceVersion: '2.0.0',
          baseUrl: 'http://services.data.shom.fr/INSPIRE/wfs',
          crsCode: 'UNKNOWN',
          boundingBox: null,
          minScale: 1,
          maxScale: 500000,
          wmsQueryable: true,
          requestProjection: 'EPSG:3857',
          possibleRenderingProjections: null,
          defaultStyleId: 'a6241fb3-90b7-416c-a837-75486b681354',
          attributes: [],
        },
      ],
    },
    {
      mapElementType: 'Layer',
      id: 900000008,
      title: 'FORM_Routes',
      description: null,
      opacity: 1,
      visible: true,
      isGroup: false,
      rank: 1,
      groupId: null,
      queryable: true,
      minScale: null,
      maxScale: null,
      views: [
        {
          id: 900000009,
          dataSourceId: null,
          layerName: 'FORM_Routes',
          title: null,
          origin:
            'geopackage:/etc/qgisserver/projects/exploitation/org_38024/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/martyr.gpkg',
          relativeOrigin: null,
          geometryName: 'geometry',
          originType: 'QGIS',
          services: {
            wms: {
              serviceType: 'WMS',
              serviceUrl:
                'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/qgis/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/?MAP=geopackage:/etc/qgisserver/projects/exploitation/org_38024/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/martyr.gpkg?projectName=martyr',
              serviceVersion: '1.3.0',
            },
            wfs: {
              serviceType: 'WFS',
              serviceUrl:
                'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/qgis/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/?MAP=geopackage:/etc/qgisserver/projects/exploitation/org_38024/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/martyr.gpkg?projectName=martyr',
              serviceVersion: '1.3.0',
            },
            wmts: null,
            vectorTiles: null,
          },
          serviceType: 'WMS',
          dataSourceType: null,
          dataSourceDataType: 'UNKNOWN',
          dataSourceGeometryType: 'LINE',
          supportsEncoding: false,
          characterEncoding: null,
          serviceVersion: '1.3.0',
          baseUrl:
            'https://cartov2-dev.mtes.dev.sig.cloud.camptocamp.com/rest-api/qgis/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/?MAP=geopackage:/etc/qgisserver/projects/exploitation/org_38024/5e75f7df-d3d9-4d3e-be0f-e23dd40a1c5c/martyr.gpkg?projectName=martyr',
          crsCode: 'EPSG:2154',
          boundingBox: null,
          minScale: null,
          maxScale: null,
          wmsQueryable: true,
          requestProjection: 'EPSG:2154',
          possibleRenderingProjections: null,
          defaultStyleId: null,
          attributes: [
            {
              id: 900000227,
              view: 900000209,
              name: 'GEST_ROUTE',
              label: '',
              type: 'String',
              published: false,
              includeInTooltip: false,
              includeInFilter: false,
            },
            {
              id: 900000226,
              view: 900000209,
              name: 'CLASS_ADM',
              label: '',
              type: 'String',
              published: false,
              includeInTooltip: false,
              includeInFilter: false,
            },
            {
              id: 900000225,
              view: 900000209,
              name: 'NUM_ROUTE',
              label: '',
              type: 'String',
              published: false,
              includeInTooltip: false,
              includeInFilter: false,
            },
          ],
        },
      ],
    },
    zico,
    znieff,
    {
      id: 1000,
      mapElementType: 'LayerGroup',
      title: 'sample group 1',
      isGroup: true,
      children: [
        {
          id: 1001,
          ...zico,
          views: [
            {
              id: 10010,
              ...zico.views[0],
            },
          ],
        },
        {
          id: 1002,
          ...znieff,
          views: [
            {
              id: 10020,
              ...znieff.views[0],
            },
          ],
        },
      ],
    },
    geotiff,
  ],
}

export const specFull = parseApiMap(specFromApi)

export const bocWMS: Layer = {
  ...(specFull.elementsById['2841'] as Layer),
}
export const bocViewWMS: LayerView = {
  ...bocWMS.views[0],
}

export const remoteWMS: Layer = {
  ...(specFull.elementsById['2823'] as Layer),
}
export const remoteViewWMS: LayerView = {
  ...remoteWMS.views[0],
}

export const remoteWFS: Layer = {
  ...(specFull.elementsById['2827'] as Layer),
}

export const remoteViewWFS: LayerView = {
  ...remoteWFS.views[0],
}

export const remoteWMTS: Layer = {
  ...(specFull.elementsById['2821'] as Layer),
}

export const viewWMTS: LayerView = {
  ...remoteWMTS.views[0],
}

export const qgisWMS: Layer = {
  ...(specFull.elementsById['900000008'] as Layer),
}

export const qgisViewWMS: LayerView = {
  ...qgisWMS.views[0],
}

export const bocGeotiff: Layer = {
  ...(specFull.elementsById['900000280'] as Layer),
}

export const bocGeotiffView: LayerView = {
  ...bocGeotiff.views[0],
}

export const layerGroup: Group = {
  ...(specFull.elementsById['1000'] as Group),
}

export const specInfo = {
  name: 'test_name',
  title: 'Test Name',
  id: 'map-001',
}

export const specExpositionStatus: MapExpositionStatus = {
  expositionScope: 'BOTH',
  ogcServicesExposed: true,
  owsUrl:
    'http://localhost:4200/rest-api/ows/org_38024_948d8782-dbf5-4aba-8369-32a02f5cf3d2/ows?',
}

export const specPublicationReport: PublicationReport = {
  success: true,
}

export const createdLayer: LayerModel = {
  mapElementType: 'Layer',
  id: 6445,
  title: 'N_ELEMENT2010_MAET_S_041',
  description: null,
  opacity: 1.0,
  visible: true,
  isGroup: false,
  rank: 6,
  groupId: 6433,
  queryable: false,
  minScale: null,
  maxScale: null,
  views: [
    {
      id: 6449,
      dataSourceId: 'ac3bdf70-35a0-4225-9a56-343584583398',
      layerName: 'N_ELEMENT2010_MAET_S_041',
      title: 'N_ELEMENT2010_MAET_S_041',
      origin:
        '/mnt/geobase/DEPT041A/CONSULTATION/AGRICULTURE/N_AGRI_ENVIRONNEMENT/N_ELEMENT2010_MAET_S_041.shp',
      relativeOrigin:
        '/CONSULTATION/AGRICULTURE/N_AGRI_ENVIRONNEMENT/N_ELEMENT2010_MAET_S_041.shp',
      geometryName: 'the_geom',
      originType: 'BOC',
      services: {
        wms: {
          serviceType: 'WMS',
          serviceUrl:
            'http://localhost:8181/rest-api/ows/7cbdebac-09e3-4337-911a-bdc1492664b3/N_ELEMENT2010_MAET_S_041',
          serviceVersion: '1.3.0',
        },
        wfs: {
          serviceType: 'WFS',
          serviceUrl:
            'http://localhost:8181/rest-api/ows/7cbdebac-09e3-4337-911a-bdc1492664b3/N_ELEMENT2010_MAET_S_041',
          serviceVersion: '1.1.0',
        },
        wmts: {
          serviceType: 'WMTS',
          serviceUrl:
            'http://localhost:8181/rest-api/ows/7cbdebac-09e3-4337-911a-bdc1492664b3/N_ELEMENT2010_MAET_S_041',
          serviceVersion: '1.0.0',
        },
        vectorTiles: null,
      },
      serviceType: 'WMS',
      serviceVersion: '1.3.0',
      baseUrl:
        'http://localhost:8181/rest-api/ows/7cbdebac-09e3-4337-911a-bdc1492664b3/N_ELEMENT2010_MAET_S_041',
      crsCode: null,
      dataSourceType: 'SHAPEFILE',
      dataSourceDataType: 'VECTOR',
      dataSourceGeometryType: 'POLYGON',
      boundingBox: null,
      minScale: null,
      maxScale: null,
      defaultStyleId: null,
      supportsEncoding: true,
      characterEncoding: 'UTF-8',
    },
  ],
}
export const createdGroup: LayerGroupModel = {
  mapElementType: 'LayerGroup',
  id: 6452,
  title: 'Nouveau groupe',
  description: 'null',
  opacity: 1.0,
  visible: true,
  isGroup: true,
  rank: 8,
  groupId: 6433,
  children: [],
}

export const viewAttributes = [
  {
    name: 'the_geom',
    label: '',
    type: 'MultiPolygon',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'ID',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'LAB_NOM',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'PtDefini',
    label: '',
    type: 'Long',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'TYP_BATIME',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'TYPE_ICPE',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'ID_MAP',
    label: '',
    type: 'Long',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'N_PACAGE',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'N_SIRET',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'N_EDE',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'GB_IDUSER',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'GB_DDMOD',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'GB_DATVER',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
  {
    name: 'GB_VERROU',
    label: '',
    type: 'String',
    published: true,
    includeInTooltip: false,
    includeInFilter: false,
  },
]
