import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  Optional,
  Inject,
} from '@angular/core'
import { LayerViewAttributeModel } from '@ui/data-access-carto-map-production'
import {
  ATTRIBUTE_LABEL_MAX_LENGTH,
  ATTRIBUTE_LABEL_PATTERN,
  MAX_OBJECTS_COUNT_FILTER,
} from '@ui/feature/shared'

const logicOrder = ['published', 'includeInTooltip', 'includeInFilter']

@Component({
  selector: 'ui-attribute-right-grid',
  templateUrl: './attribute-right-grid.component.html',
  styleUrls: ['./attribute-right-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeRightGridComponent {
  @Input() attributes: LayerViewAttributeModel[]
  @Output() labelInvalid = new EventEmitter<boolean>()

  ATTRIBUTE_LABEL_MAX_LENGTH = ATTRIBUTE_LABEL_MAX_LENGTH
  ATTRIBUTE_LABEL_PATTERN = ATTRIBUTE_LABEL_PATTERN

  constructor(
    @Optional() @Inject(MAX_OBJECTS_COUNT_FILTER) public maxObjectsCountFilter
  ) {}

  onAllClick(attribute: LayerViewAttributeModel, event): void {
    const { checked } = event.target
    logicOrder.forEach((prop) => (attribute[prop] = checked))
  }

  onHeaderColumnClick(attributeName: string, index: number, event) {
    const { checked } = event.target
    this.attributes.forEach((attr) => {
      if (!attr.invalid) {
        attributeName === 'all'
          ? logicOrder.forEach((prop) => (attr[prop] = checked))
          : this.doCheckAttribute(attr, index, checked)
      }
    })
  }

  onCheck(attribute: LayerViewAttributeModel, index: number, event) {
    const { checked } = event.target
    this.doCheckAttribute(attribute, index, checked)
  }

  doCheckAttribute(
    attribute: LayerViewAttributeModel,
    index: number,
    checked: boolean
  ) {
    if (checked) {
      attribute[logicOrder[index]] = true
      attribute['published'] = true
    } else {
      attribute[logicOrder[index]] = false
      if (index === 0) {
        logicOrder.forEach((prop) => (attribute[prop] = false))
      }
    }
  }

  isAttributeAll(attribute): boolean {
    return logicOrder.every((prop) => attribute[prop] === true)
  }

  isColumnAllChecked(attributeName: string) {
    return this.attributes
      .filter((attribute) => !attribute.invalid)
      .every((attr) =>
        attributeName === 'all'
          ? logicOrder.every((prop) => attr[prop] === true)
          : attr[attributeName]
      )
  }

  getFiltrableTooltipText() {
    return `Attention : dans la carte diffusée, une liste de valeur sera proposée uniquement si le nombre d'objets de la couche est inférieur à ${this.maxObjectsCountFilter}`
  }

  getDisabledAttributeTooltip(attr: LayerViewAttributeModel): string {
    return attr?.invalid
      ? "Ne peut être interrogeable car le nom n'est pas conforme"
      : ''
  }
}
