import { EventEmitter, Injectable } from '@angular/core'
import { DataSourceGeometryTypeModel } from '@ui/data-access-carto-map-production'
import { Layer, MapElement } from '@ui/feature/shared'
import { BehaviorSubject } from 'rxjs'
import { scan } from 'rxjs/operators'

// if the same id is sent twice, emit value 'null'
const toggleOperator = scan(
  (acc, value) => (value === acc ? null : value),
  null
)

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor() {}
  editStyle$ = new EventEmitter<number>()
  associateJdd$ = new EventEmitter<{ viewId: number; layer: Layer }>()
  createStyle$ = new EventEmitter<{
    viewId: number
    geometryType: DataSourceGeometryTypeModel
  }>()
  openThematicStyling$ = new EventEmitter<number>()
  removeStyle$ = new EventEmitter<number>()
  editLayer$ = new EventEmitter<MapElement>()
  editAttributes$ = new EventEmitter<MapElement>()
  addChildLayerGroup$ = new EventEmitter<number>() // value is the parent group id
  addChildLayer$ = new EventEmitter<number>() // value is the parent group id
  duplicateLayer$ = new EventEmitter<MapElement>() // value is the parent group id
  associate = new EventEmitter<{
    viewId: number
    geometryType: DataSourceGeometryTypeModel
  }>()
  duplicateAndApplyStyle$ = new EventEmitter<{ layerId; viewId; styleId }>()

  private editTitleSubject$ = new BehaviorSubject<number>(null)
  editTitle$ = this.editTitleSubject$.pipe(toggleOperator)

  private activeLayerIdEmitter$ = new EventEmitter<number>()
  activeLayerId$ = this.activeLayerIdEmitter$.pipe(toggleOperator)

  editTitle(layerId: number | null) {
    this.editTitleSubject$.next(layerId)
  }

  editStyle(viewId: number): void {
    this.editStyle$.emit(viewId)
  }

  createStyle(viewId: number, geometryType: DataSourceGeometryTypeModel): void {
    this.createStyle$.emit({ viewId, geometryType })
  }

  associateJdd(viewId: number, layer: Layer): void {
    this.associateJdd$.emit({ viewId, layer })
  }

  editAttributes(layer: MapElement): void {
    this.editAttributes$.emit(layer)
  }

  openThematicStyling(viewId: number): void {
    this.openThematicStyling$.emit(viewId)
  }

  removeStyle(viewId: number): void {
    this.removeStyle$.emit(viewId)
  }

  editLayer(layer: MapElement): void {
    this.editLayer$.emit(layer)
  }

  setActiveLayerId(id: number | null) {
    this.activeLayerIdEmitter$.emit(id)
  }

  addChildLayerGroup(parentId: number) {
    this.addChildLayerGroup$.emit(parentId)
  }

  addChildLayer(parentId: number) {
    this.addChildLayer$.emit(parentId)
  }

  duplicateLayer(layer: MapElement) {
    this.duplicateLayer$.emit(layer)
  }

  duplicateAndApplyStyle(layerId: number, viewId: number, styleId: string) {
    this.duplicateAndApplyStyle$.emit({ layerId, viewId, styleId })
  }
}
