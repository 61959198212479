<div
  class="position-relative pr-1 pt-1 mr-2 icon-root"
  *ngIf="!!state"
  [title]="tooltip"
>
  <span class="fa fa-bookmark"></span>
  <span
    *ngIf="isError"
    class="fa fa-exclamation-circle text-danger icon-overlay icon-error"
  ></span>
  <span
    *ngIf="isLoading"
    class="fa fa-spin fa-spinner text-black-50 icon-overlay icon-loading"
  ></span>
  <span
    *ngIf="isPublished"
    class="fa fa-check text-success icon-overlay icon-published"
  ></span>
  <span
    *ngIf="isNotPublished"
    class="fa fa-eye-slash text-black-50 icon-overlay icon-not-published"
  ></span>
  <span
    *ngIf="isNotFound"
    class="fa fa-question text-warning icon-overlay icon-not-found"
  ></span>
</div>
