import { Injectable } from '@angular/core'
import { PointSymbolizer } from 'geostyler-style'
import { PointSymbol } from '../model'

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  constructor() {}

  isFontGlyphSymbol(symbol: PointSymbol): boolean {
    return (
      'wellKnownName' in symbol && symbol.wellKnownName.startsWith('ttf://')
    )
  }

  isShapeSymbol(symbol: PointSymbol | PointSymbolizer): boolean {
    return (
      'wellKnownName' in symbol && symbol.wellKnownName.startsWith('shape://')
    )
  }

  isXSymbol(symbol: PointSymbol | PointSymbolizer) {
    return 'wellKnownName' in symbol && symbol.wellKnownName === 'x'
  }

  isCrossSymbol(symbol: PointSymbol | PointSymbolizer) {
    return 'wellKnownName' in symbol && symbol.wellKnownName === 'cross'
  }
}
