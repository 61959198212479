import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions'
import { NotificationActions } from '../actions'

export type NotificationType = 'danger' | 'warning' | 'success'

export interface NotificationModel {
  id: number
  type: NotificationType
  message: string
  timeout?: number
}

export interface AppNotificationState {
  notifications: NotificationModel[]
}

export const initialState: AppNotificationState = {
  notifications: [],
}

export function reducer(
  state = initialState,
  action: NotificationActions
): AppNotificationState {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.concat({
          ...action.payload,
        }),
      }

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.notificationId
        ),
      }
  }
  return state
}
