import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core'
import { DataSchema } from '@ui/feature/shared'

export enum SYMBOLIZER_FIELD_TYPE {
  COLOR,
  WIDTH,
  DASH_ARRAY,
  SYMBOL,
  OPACITY,
  LABEL,
  FONT,
  SIZE,
  OFFSET,
  ROTATE,
  HALO_COLOR,
  HALO_WIDTH,
  ANCHOR,
  MARGIN,
}

@Component({
  selector: 'ui-symbolizer-field',
  templateUrl: './symbolizer-field.component.html',
  styleUrls: ['./symbolizer-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolizerFieldComponent implements OnInit {
  @Input() fieldType: SYMBOLIZER_FIELD_TYPE
  @Input() label: string
  @Input() value: any
  @Input() minValue?: any
  @Input() locked = false
  @Input() schema?: DataSchema
  @Output() fieldUpdated = new EventEmitter<any>()

  objectKeys = Object.keys

  private dashArrays = [
    { label: 'plein', value: null },
    { label: 'pointillé fin', value: '3' },
    { label: 'pointillé moyen', value: '6' },
    { label: 'pointillé large', value: '10' },
    { label: 'pointillé mixte', value: '10 5 5 5' },
    { label: 'pointillé espacé', value: '5 10' },
    { label: 'pointillé rapproché', value: '10 5' },
  ]

  private symbols = [
    { label: 'cercle', value: 'circle' },
    { label: 'carré', value: 'square' },
    { label: 'triangle', value: 'triangle' },
    { label: 'étoile', value: 'star' },
    { label: 'croix', value: 'cross' },
    { label: '×', value: 'x' },
  ]

  private fonts = [
    'Arial',
    'Verdana',
    'Sans-serif',
    'Courier New',
    'Lucida Console',
    'Monospace',
    'Times New Roman',
    'Georgia',
    'Serif',
  ]

  private anchors = [
    { value: 'center', label: 'centre' },
    { value: 'left', label: 'gauche' },
    { value: 'right', label: 'droite' },
    { value: 'top', label: 'haut' },
    { value: 'bottom', label: 'bas' },
    { value: 'top-left', label: 'haut-gauche' },
    { value: 'top-right', label: 'haut-droite' },
    { value: 'bottom-left', label: 'bas-gauche' },
    { value: 'bottom-right', label: 'bas-droite' },
  ]

  parseFloat = parseFloat

  ngOnInit() {
    if (this.isLabel()) {
      this.value = this.stringifyLabel(this.value)
    } else if (this.isFont() && this.value) {
      this.value = this.value[0]
    }
  }

  isWidth() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.WIDTH
  }
  isColor() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.COLOR
  }
  isDashArray() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.DASH_ARRAY
  }
  isSymbol() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.SYMBOL
  }
  isOpacity() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.OPACITY
  }
  isFont() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.FONT
  }
  isSize() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.SIZE
  }
  isMargin() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.MARGIN
  }
  isOffset() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.OFFSET
  }
  isRotate() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.ROTATE
  }
  isHaloColor() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.HALO_COLOR
  }
  isHaloWidth() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.HALO_WIDTH
  }
  isLabel() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.LABEL
  }
  isAnchor() {
    return this.fieldType === SYMBOLIZER_FIELD_TYPE.ANCHOR
  }
  toDashArray(stringArray) {
    return stringArray ? stringArray.split(' ').map((s) => parseInt(s, 10)) : []
  }
  formatLabelAttribute(attributeName) {
    return `{{${attributeName}}}`
  }
  stringifyLabel(value) {
    const matches = value.match(/{{([^}]+)}}/)
    return matches ? matches[1] : value
  }
}
