<ui-modal
  #modal
  title="Gestion des couches non supprimables"
  successMessage="La suppression des couches non réparables est terminée"
  infoMessage="Les couches listées ci-dessous n'ont pas pu être supprimées."
  cancelLabel="Fermer"
  [cancelHandler]="refreshPage()"
  confirmDisabled="true"
  [confirmButtonVisible]="false"
>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Couche</th>
        <th scope="col">Cause</th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let layer of uncleanableLayers">
        <td>{{ layer.title }}</td>
        <td>{{ layer.message || '' }}</td>
      </tr>
    </tbody>
  </table>
</ui-modal>
