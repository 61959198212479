import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import {
  MapBriefModel,
  MapCompositionService,
} from '@ui/data-access-carto-map-production'
import { MapFacade } from '@ui/feature/mapstate'
import { DialogsService } from '@ui/ui/layout'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Observable, of, Subscription } from 'rxjs'
import { catchError, first, map, mergeMap, switchMap } from 'rxjs/operators'
import { MAP_PRODUCTION_ROUTE } from '../../../routes'
import { RouterFacade } from '../../../store/facade'
import { DeleteMapModalComponent } from './delete-map-modal/delete-map-modal.component'
import { HttpErrorResponse } from '@angular/common/http'
import { ResourceFacade } from '@ui/ui/resources'
import { SseService } from '@ui/data-access-carto-async'

export interface CreateMapFormContent {
  title: string
  name: string
  description: string
}

@Component({
  selector: 'ui-delete-map',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<void>
  modalRef: BsModalRef
  sub = new Subscription()

  constructor(
    public routerFacade_: RouterFacade,
    public mapFacade_: MapFacade,
    private modalService_: BsModalService,
    private dialogsService: DialogsService,
    private mapComposition_: MapCompositionService,
    private resourceFacade_: ResourceFacade,
    private sseService_: SseService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$
        .pipe(
          mergeMap(() =>
            this.mapComposition_
              .findMaps(0, 500)
              .pipe(catchError((error) => of(error)))
          )
        )

        .subscribe((response) => {
          this.openModal()

          if (Array.isArray(response)) {
            this.modalRef.content.mapList = response
          } else if (response instanceof HttpErrorResponse) {
            this.dispatchLoadError()
          }
        })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  openModal() {
    this.modalRef = this.modalService_.show(DeleteMapModalComponent, {
      ignoreBackdropClick: true,
    })

    this.modalRef.content.save.subscribe((formValues: CreateMapFormContent) =>
      this.submit(formValues)
    )
  }

  dispatchLoadError(): void {
    this.modalRef.content.errorMsg =
      'Une erreur est survenue lors du chargement de la liste des cartes'
  }

  dispatchError(error: HttpErrorResponse): void {
    this.modalRef.content.errorMsg = error.message
      ? `Une erreur est survenue lors de la suppression de la carte : ${error.message}`
      : 'Une erreur non spécifiée est survenue lors de la suppression de la carte'
  }

  submit(mapResponse: MapBriefModel) {
    const { exposed } = mapResponse as any
    if (exposed) {
      this.dialogsService
        .confirm(
          'Confirmation',
          `Êtes-vous sûrs de vouloir supprimer la carte "${name}" ?`
        )
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        .subscribe((confirmed) => {
          this.deleteMap(mapResponse)
        })
    } else {
      this.deleteMap(mapResponse)
    }
  }

  deleteMap(mapResponse: MapBriefModel) {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { id: idToDelete, name } = mapResponse

    this.mapFacade_.info$
      .pipe(
        first(),
        map((info) => info.id),
        switchMap((currentMapId) =>
          this.sseService_
            .wrapApiCall('END_DELETE', () =>
              this.mapComposition_.deleteMap(idToDelete)
            )
            .pipe(map(() => currentMapId))
        )
      )
      .subscribe({
        next: (currentMapId) => {
          this.modalRef.hide()
          this.resourceFacade_.refreshMaps()
          if (currentMapId === idToDelete) {
            this.routerFacade_.go({
              path: [`${MAP_PRODUCTION_ROUTE}`],
            })
          }
        },
        error: (error) => this.dispatchError(error),
      })
  }
}
