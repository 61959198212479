import { EventEmitter, Injectable } from '@angular/core'
import { CreateRemoteServerRequestModel } from '@ui/data-access-carto-map-production'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { tap } from 'rxjs/operators'
import { ApiClientService } from './services/api-client.service'

@Injectable({
  providedIn: 'root',
})
export class ResourceFacade {
  resfreshTreeLevel$ = new EventEmitter<string>()
  constructor(private apiClient: ApiClientService) {}

  refreshTreeLevel(resourcePath?: string): void {
    this.resfreshTreeLevel$.emit(resourcePath)
  }
  refreshServices(): void {
    this.refreshTreeLevel('/services')
  }
  refreshMaps(): void {
    this.refreshTreeLevel('/maps')
  }
  refreshMapResources(mapId: string): void {
    this.refreshTreeLevel(`/maps/${mapId}`)
  }
  removeService(uuid: string) {
    return this.apiClient
      .removeService(uuid)
      .pipe(tap(() => this.refreshServices()))
  }
  createService(request: CreateRemoteServerRequestModel) {
    return this.apiClient
      .createService(request)
      .pipe(tap(() => this.refreshServices()))
  }
  modifyService(service: RemoteServerModel) {
    return this.apiClient
      .modifyService(service)
      .pipe(tap(() => this.refreshServices()))
  }
}
