import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import {
  AssociateLayerRequestModel,
  CreateLayerRequestModel,
  MapCompositionService,
  MapElementModel,
  QGisProjectService,
  StyleManagementService,
} from '@ui/data-access-carto-map-production'
import {
  RemoteServerModel,
  ResourceExplorationService,
  TreeItemModel,
} from '@ui/data-access-carto-resource-management'
import {
  EventService as MapStateEventService,
  MapElementAdd,
  MapElementUpdate,
  MapFacade,
} from '@ui/feature/mapstate'
import {
  DataSchemaService,
  getViewIsVector,
  isMapElementGroup,
  Layer,
  MapExpositionStatus,
  parseApiMap,
  parseApiMapElement,
  PublicationReport,
} from '@ui/feature/shared'
import {
  dataSourceGeometryTypeToElementTypeMapper,
  EventService as FeatureStylingUiEventService,
  Style,
} from '@ui/feature/styling'
import { DialogsService, PanelComponent } from '@ui/ui/layout'
import { EventService, EventService as MapUiEventService } from '@ui/ui/map'
import {
  EventService as ResourcesEventService,
  ResourceSelectEvent,
  ResourceTypeEnum,
} from '@ui/ui/resources'
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { DuplicateMapService } from '../../../services/duplicate-map.service'
import { ModalEventService } from '../../../services/modal-event.service'
import { RepairMapService } from '../../../services/repair-map.service'
import {
  NotificationFacade,
  RouterFacade,
  StyleFacade,
} from '../../../store/facade'
import { DEFAULT_STYLES } from '../../components/style-panel/default-styles'
import { SseService } from '@ui/data-access-carto-async'
import { getExposedMapUrl } from '../../../helpers/map.helpers'
import ServerTypeModelEnum = RemoteServerModel.ServerTypeModelEnum

export const DEFAULT_GEOMETRY_NAME = 'the_geom'

@Component({
  selector: 'ui-map-production',
  templateUrl: './map-production.component.html',
  styleUrls: ['./map-production.component.scss'],
})
export class MapProductionComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  ResourceTypeEnum = ResourceTypeEnum
  height = 400
  private lastHeight = this.height
  resizeSubject = new Subject<void>()
  private heightChanged = false
  private selectedResource: ResourceSelectEvent = null
  rules = new EventEmitter()
  resourceType: ResourceTypeEnum = ResourceTypeEnum.DATASET
  sub: Subscription = new Subscription()
  pendingParentGroupId: number = null
  associateLayer: Layer = null
  directAccessPath: string = null

  @ViewChild('container', { static: true }) private container_: ElementRef
  @ViewChild('stylePanel', { static: true }) private stylePanel_: PanelComponent
  @ViewChild('thematicPanel', { static: true })
  private thematicPanel_: PanelComponent

  mapTitle$: Observable<string>
  mapId$: Observable<string>
  mapExpositionStatus$: Observable<MapExpositionStatus>
  mapPublicationReport$: Observable<PublicationReport>
  disabled$: Observable<boolean>
  noMapLoaded$: Observable<boolean>
  mapSpecLayersInOriginError$: Observable<string[]>
  isThematicFormValid$: BehaviorSubject<boolean>

  openEditMap$ = new EventEmitter()
  openEditMetadata$ = new EventEmitter()
  openEditExpositionOptions$ = new EventEmitter()

  isDefaultMap$ = this.mapFacade_.isDefault$
  mapQgisInfo$ = this.mapFacade_.qgisInfo$

  defaultResourceItems: TreeItemModel[] = []

  get resourcesFocused() {
    return this.pendingParentGroupId !== null || this.isAssociating
  }

  get isAssociating() {
    return this.associateLayer !== null
  }

  constructor(
    private mapComposition_: MapCompositionService,
    private resourceExploration_: ResourceExplorationService,
    private styleManagement_: StyleManagementService,
    public mapFacade_: MapFacade,
    public routerFacade_: RouterFacade,
    public styleFacade_: StyleFacade,
    public notificationFacade_: NotificationFacade,
    private resourcesEventService_: ResourcesEventService,
    public modalEventService_: ModalEventService,
    public eventService_: EventService,
    public dataSchemaService: DataSchemaService,
    private duplicateMapService_: DuplicateMapService,
    private dialogsService_: DialogsService,
    public mapUiEventService_: MapUiEventService,
    private mapStateEventService_: MapStateEventService,
    public repairMapService_: RepairMapService,
    private qgisProjectService_: QGisProjectService,
    private sseService_: SseService,
    private featureStylingUiEventService_: FeatureStylingUiEventService
  ) {}

  ngOnInit() {
    this.isThematicFormValid$ =
      this.featureStylingUiEventService_.isThematicFormValid$

    // note: creating this observable is necessary to avoid an error related to
    // the async pipe generating wrapped values, preventing using this in the template:
    // panelTitle={{(facade.info$ | async).title}}
    // see: https://github.com/angular/angular/issues/18129#issuecomment-326801192
    this.mapTitle$ = this.mapFacade_.info$.pipe(map((info) => info.title))
    this.mapId$ = this.mapFacade_.info$.pipe(
      map((info) => info.id),
      distinctUntilChanged()
    )
    this.mapExpositionStatus$ = this.mapFacade_.expositionStatus$
    this.mapPublicationReport$ = this.mapFacade_.publicationReport$

    this.disabled$ = combineLatest([this.isDefaultMap$, this.mapId$]).pipe(
      map(([isDefaultMap, mapId]) => isDefaultMap || !mapId)
    )

    this.noMapLoaded$ = this.mapId$.pipe(map((id) => !id))
    this.mapSpecLayersInOriginError$ =
      this.mapFacade_.mapSpecLayersInOriginError$.pipe(
        map((layers) => layers.map((layer) => layer.title))
      )

    this.handleRoute_()

    this.sub.add(
      combineLatest([
        this.styleFacade_.currentViewId$,
        this.styleFacade_.thematic$,
      ]).subscribe(([styleId, thematic]) => {
        this.stylePanel_.collapsed = !(styleId && !thematic)
        this.thematicPanel_.collapsed = !(styleId && thematic)
      })
    )

    this.sub.add(
      this.resourcesEventService_.updateService$.subscribe((remoteServer) =>
        this.updateRemoteServer(remoteServer)
      )
    )

    // subscribe to events from the layer tree (ui/map)
    this.sub.add(
      this.mapUiEventService_.removeStyle$.subscribe((viewStyleRef) => {
        this.styleFacade_.removeStyle(viewStyleRef)
      })
    )
    this.sub.add(
      this.mapUiEventService_.createStyle$.subscribe(
        ({ viewId, geometryType }) =>
          this.styleFacade_.createStyle({
            viewId,
            style:
              DEFAULT_STYLES[
                dataSourceGeometryTypeToElementTypeMapper(geometryType)
              ],
          })
      )
    )
    this.sub.add(
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      this.mapUiEventService_.associateJdd$.subscribe(({ viewId, layer }) => {
        this.resourceType = ResourceTypeEnum.DATASET
        this.associateLayer = layer
        if (layer && layer.views[0]) {
          this.directAccessPath = layer.views[0].relativeOrigin
          // select resource when leaf is already deployed
          this.resourcesEventService_.selectResource(
            layer.views[0].dataSourceId
          )
        }
      })
    )
    this.sub.add(
      this.mapUiEventService_.editStyle$.subscribe((styleId) =>
        this.styleFacade_.setCurrentStyle(styleId)
      )
    )
    this.sub.add(
      this.mapUiEventService_.openThematicStyling$.subscribe((viewId) =>
        this.styleFacade_.setCurrentThematic(viewId)
      )
    )
    this.sub.add(
      this.mapUiEventService_.addChildLayer$.subscribe((parentId) =>
        this.addLayer(parentId)
      )
    )
    this.sub.add(
      this.mapUiEventService_.duplicateLayer$
        .pipe(
          withLatestFrom(this.mapId$),
          tap(() =>
            this.dialogsService_.showLoadingModal(
              'Duplication de la couche en cours...'
            )
          ),
          switchMap(([layer, mapId]) =>
            this.mapComposition_
              .duplicateLayer(mapId, layer.id)
              .pipe(finalize(() => this.dialogsService_.hideLoadingModal()))
          )
        )
        .subscribe({
          next: (created) => {
            this.addMapElementModelToMap(created, created.groupId || undefined)
            this.notificationFacade_.showNotification(
              'success',
              'La couche a bien été dupliquée'
            )
          },
          error: () =>
            this.notificationFacade_.showNotification(
              'danger',
              'La duplication de la couche a échoué',
              5000
            ),
        })
    )
    this.sub.add(
      this.mapUiEventService_.addChildLayerGroup$.subscribe((parentId) =>
        this.addLayerGroup(parentId)
      )
    )
    this.sub.add(
      this.mapUiEventService_.duplicateAndApplyStyle$.subscribe(
        ({ layerId, viewId, styleId }) => {
          this.styleFacade_.duplicateAndApplyStyle(layerId, viewId, styleId)
        }
      )
    )

    this.sub.add(
      this.duplicateMapService_.loading$
        .pipe(skip(1))
        .subscribe((loading) =>
          loading
            ? this.dialogsService_.showLoadingModal(
                'Duplication de la carte en cours...'
              )
            : this.dialogsService_.hideLoadingModal()
        )
    )

    this.sub.add(
      this.mapStateEventService_.warnOnDuplicatedStyleError$.subscribe(() => {
        this.notificationFacade_.showNotification(
          'danger',
          "Attention, la couche n'a pas pu être rafraîchie : style incompatible",
          5000
        )
      })
    )

    // any time a vector layer is added on the map, load its style if any
    this.sub.add(
      this.mapFacade_.addedMapElement$
        .pipe(
          filter(
            (element) =>
              !isMapElementGroup(element) &&
              'views' in element &&
              element.views.length > 0
          ),
          map((layer) => 'views' in layer && layer.views[0]),
          filter(getViewIsVector),
          filter((view) => !!view.defaultStyleId),
          withLatestFrom(this.mapFacade_.info$),
          mergeMap(([view, mapInfo]) =>
            this.styleManagement_.getStyleBody(mapInfo.id, view.id).pipe(
              map((response) => ({
                viewId: view.id,
                style: JSON.parse(response.geoStyle) as Style,
              }))
            )
          )
        )
        .subscribe(({ viewId, style }) => {
          this.mapFacade_.updateStyle({
            viewId,
            style,
          })
        }, console.error)
    )

    this.sub.add(
      this.repairMapService_.repairLoading$
        .pipe(skip(1))
        .subscribe((loading) =>
          loading
            ? this.dialogsService_.showLoadingModal(
                'Réparation de la carte en cours...'
              )
            : this.dialogsService_.hideLoadingModal()
        )
    )

    this.sub.add(
      this.repairMapService_.cleanLoading$
        .pipe(skip(1))
        .subscribe((loading) =>
          loading
            ? this.dialogsService_.showLoadingModal(
                'Suppression des couches non réparables en cours...'
              )
            : this.dialogsService_.hideLoadingModal()
        )
    )

    this.sub.add(
      this.mapStateEventService_.hasSpecErrors$
        .pipe(filter((hasError) => hasError))
        .subscribe(() =>
          this.notificationFacade_.showNotification(
            'warning',
            'Attention, des erreurs ont été détectées dans la configuration de la carte',
            5000
          )
        )
    )

    this.handleResourceManagerNotifications()
    this.recomputeHeight()
  }

  getPanelTitle(): string {
    if (this.resourcesFocused) {
      if (this.isAssociating) {
        return 'Associer une source de données'
      } else {
        return 'Ajouter une couche'
      }
    } else {
      return 'Patrimoine'
    }
  }

  recomputeHeight() {
    const topOffset = this.container_.nativeElement.getBoundingClientRect().top
    this.lastHeight = this.height
    this.height = window.innerHeight - topOffset - window.scrollY
    if (this.lastHeight !== this.height) {
      this.heightChanged = true
    }
  }

  ngAfterViewChecked() {
    if (this.heightChanged) {
      this.heightChanged = false
      this.resizeSubject.next()
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  addLayer(parentGroupId?: number) {
    this.pendingParentGroupId = parentGroupId || 0
    this.associateLayer = null
    this.directAccessPath = null
  }

  associateResource() {
    const resource = this.selectedResource
    const layer = this.associateLayer
    this.resourceType = ResourceTypeEnum.DATASET

    if (!resource || !resource.resourceId || !layer) {
      console.warn('No layer or associate resource selected')
      return
    }
    const resourceToChange: AssociateLayerRequestModel = {
      resourceId: resource.resourceId,
    }

    this.dialogsService_
      .confirm(
        `Confirmation`,
        `La nouvelle source de données remplacera l'ancienne. Le style associé sera conservé mais pourrait ne pas être compatible avec le nouveau JDD (type de géométrie, structure attributaire). Êtes vous sûr ?`
      )
      .subscribe((confirmed) => {
        if (!confirmed) {
          return
        }
        this.dialogsService_.showLoadingModal(
          'Re-association de la couche en cours...'
        )
        this.onAssociateLayerConfirm(layer, resourceToChange)
      })
  }

  addResource() {
    const resource = this.selectedResource

    if (!resource || !resource.resourceId) {
      console.warn('No resource selected')
      return
    }
    const resourceToAdd: CreateLayerRequestModel = {
      resourceId: resource.resourceId,
      nativeName: resource.layer ? resource.layer.name : null,
      owsTitle: resource.layer ? resource.layer.title : null,
    }
    if (this.pendingParentGroupId > 0) {
      resourceToAdd.parentGroupId = this.pendingParentGroupId
    }
    this.pendingParentGroupId = null

    let obs$ = of('true')
    // WFS Remote Layer
    if (
      resourceToAdd.nativeName &&
      resource.layer.type === ServerTypeModelEnum.WFS &&
      !resourceToAdd.geometryName
    ) {
      obs$ = this.dataSchemaService
        .queryDataSchema(
          resource.layer.url,
          resourceToAdd.nativeName,
          resource.layer.version
        )
        .pipe(
          map((res) =>
            'error' in res ? DEFAULT_GEOMETRY_NAME : res.geometryName
          ),
          tap(
            (geometryName: string) =>
              (resourceToAdd.geometryName = geometryName)
          )
        )
    }
    obs$
      .pipe(
        tap(() =>
          this.dialogsService_.showLoadingModal(
            'Création de la couche en cours...'
          )
        )
      )
      .subscribe(() => this.onAddLayerConfirm(resourceToAdd))
  }

  canConfirmResource() {
    return (
      this.resourceType !== ResourceTypeEnum.GEOPLATEFORME &&
      this.selectedResource &&
      this.selectedResource.resourceId &&
      this.selectedResource.layer?.type !==
        RemoteServerModel.ServerTypeModelEnum.VECTORTILES
    )
  }

  getConfirmTitle() {
    return this.resourceType === ResourceTypeEnum.GEOPLATEFORME
      ? "L'ajout de couches à partir des services de la Géoplateforme n'est pas encore supporté."
      : ''
  }

  confirmResource() {
    if (this.isAssociating) {
      this.associateResource()
    } else {
      this.addResource()
    }
  }

  cancelResourceSelection() {
    this.pendingParentGroupId = null
    this.associateLayer = null
    this.directAccessPath = null
  }

  onAddLayerConfirm(resourceToAdd: CreateLayerRequestModel) {
    this.mapId$
      .subscribe((mapId) =>
        this.mapComposition_
          .createLayer(mapId, resourceToAdd)
          .pipe(finalize(() => this.dialogsService_.hideLoadingModal()))
          .subscribe({
            next: (layerModel) => {
              this.addMapElementModelToMap(
                layerModel,
                resourceToAdd.parentGroupId
              )
              this.notificationFacade_.showNotification(
                'success',
                'Nouvelle couche enregistrée'
              )
            },
            error: () =>
              this.notificationFacade_.showNotification(
                'danger',
                'La création de la couche a échoué',
                5000
              ),
          })
      )
      .unsubscribe()
  }

  onAssociateLayerConfirm(
    layer: Layer,
    resourceToAssociate: AssociateLayerRequestModel
  ) {
    this.mapId$
      .subscribe((mapId) =>
        this.mapComposition_
          .associateLayer(mapId, layer.id, resourceToAssociate)
          .pipe(finalize(() => this.dialogsService_.hideLoadingModal()))
          .subscribe({
            next: (layerModel) => {
              this.updateMapElementModelToMap(layerModel)
              this.mapFacade_.refreshLayer(layerModel.views[0].id)
              this.notificationFacade_.showNotification(
                'success',
                'Nouvelle association enregistrée'
              )
            },
            error: () =>
              this.notificationFacade_.showNotification(
                'danger',
                "L'association de la couche a échoué",
                5000
              ),
          })
      )
      .unsubscribe()
  }
  addLayerGroup(parentGroupId?: number) {
    this.mapId$
      .subscribe((mapId) =>
        this.mapComposition_
          .createLayerGroup(mapId, {
            title: 'Nouveau groupe',
            visible: true,
            parentGroupId,
          })
          .subscribe((groupModel) =>
            this.addMapElementModelToMap(groupModel, parentGroupId)
          )
      )
      .unsubscribe()
  }

  private addMapElementModelToMap(
    mapElementModel: MapElementModel,
    parentId: number
  ): void {
    const mapElement = parseApiMapElement(mapElementModel)
    const mapElementAdd: MapElementAdd = {
      mapElement,
      parentId,
      index: mapElementModel.rank - 1,
    }
    this.mapFacade_.addMapElement(mapElementAdd)
    this.mapUiEventService_.editTitle(mapElementAdd.mapElement.id)
  }
  private updateMapElementModelToMap(mapElementModel: MapElementModel): void {
    const mapElement = parseApiMapElement(mapElementModel)
    const mapElementUpdate: MapElementUpdate = {
      mapElement,
    }
    this.mapFacade_.updateMapElement(mapElementUpdate)
  }

  editMap() {
    this.openEditMap$.emit()
  }

  editMetadata() {
    this.openEditMetadata$.emit()
  }

  editExpositionOptions() {
    this.openEditExpositionOptions$.emit()
  }

  handleRoute_(): void {
    const mapSpec$ = this.routerFacade_.pathParams$.pipe(
      map((pathParams) => pathParams.id),
      tap(() =>
        this.dialogsService_.showLoadingModal(
          'Chargement de la carte en cours...'
        )
      ),
      switchMap((mapId) =>
        (mapId
          ? this.mapComposition_.findMap(mapId)
          : this.mapComposition_.getDefaultMapTemplate()
        ).pipe(
          map(parseApiMap),
          catchError(this.handleRouteError_.bind(this)),
          finalize(() => this.dialogsService_.hideLoadingModal())
        )
      ),
      filter((spec) => !!spec)
    )

    // set map spec & exposition status at the same time in the state
    this.sub.add(
      mapSpec$.subscribe((spec) => {
        this.mapFacade_.fromSpec(spec)
      })
    )
  }

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  handleRouteError_(error): Observable<null> {
    this.notificationFacade_.showNotification(
      'danger',
      'Une erreur est survenue lors du chargement de la carte. Vérifiez que cette carte existe.',
      5000
    )
    return of(null)
  }

  saveStyle() {
    this.styleFacade_.saveCurrentStyle()
  }

  cancelStyleEdition() {
    this.styleFacade_.clearCurrentStyle()
  }

  cancelThematicStyling() {
    this.styleFacade_.clearCurrentStyle()
  }

  handleResourceManagerNotifications() {
    this.sub.add(
      this.resourcesEventService_.resourceSelected$.subscribe((event) => {
        this.selectedResource = event
      })
    )

    this.sub.add(
      this.resourcesEventService_.apiError$.subscribe((message) => {
        this.notificationFacade_.showNotification('danger', message, 5000)
      })
    )
    this.sub.add(
      this.resourcesEventService_.apiSuccess$.subscribe((message) => {
        this.notificationFacade_.showNotification('success', message, 5000)
      })
    )
  }

  onResourcesTypeChanged(event: ResourceTypeEnum) {
    this.resourceType = event
  }

  createRemoteServer(): void {
    this.modalEventService_.openRemoteServerModal()
  }
  updateRemoteServer(service: RemoteServerModel): void {
    this.modalEventService_.openRemoteServerModal(service)
  }

  handleSyncExposedWithCurrent() {
    this.dialogsService_
      .confirm(
        'Confirmation',
        'Etes-vous sûr de vouloir appliquer les dernières modifications à la version diffusée de la carte ?'
      )
      .subscribe((confirmed) => {
        if (!confirmed) return
        this.dialogsService_.showLoadingModal(
          'Diffusion de la carte en cours...'
        )

        this.mapFacade_.expositionStatus$
          .pipe(
            take(1),
            filter((expositionStatus) => expositionStatus.published),
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            switchMap((_) => this.sseService_.watchEvent('END_PUBLICATION')),
            map((message) => message.published)
          )
          .subscribe({
            next: () => this.mapFacade_.setPublicationReport({ success: true }),
            error: (error) =>
              this.mapFacade_.setPublicationReport({
                success: false,
                message: error,
              }),
          })

        combineLatest([
          this.mapId$.pipe(
            take(1),
            switchMap((mapId) =>
              this.sseService_.wrapApiCall('END_SYNCHRO_EXPOSITION', () =>
                this.mapComposition_.syncWithWorking(mapId)
              )
            ),
            map((message) => parseApiMap(message.map).expositionStatus),
            finalize(() => this.dialogsService_.hideLoadingModal())
          ),
          this.mapId$.pipe(take(1)),
        ]).subscribe({
          next: ([expositionStatusResult, mapId]) => {
            this.mapFacade_.setExpositionStatus(expositionStatusResult)

            this.notificationFacade_.showNotification(
              'success',
              `La carte diffusée a été mise à jour avec les dernières modifications : <a href="${getExposedMapUrl(
                mapId
              )}" target="_blank">Ouvrir la carte diffusée</a>`,
              5000
            )
          },
          error: () => {
            this.notificationFacade_.showNotification(
              'danger',
              'Une erreur est survenue lors de la synchronisation de la carte.',
              5000
            )
          },
        })
      })
  }
  handleSyncCurrentWithExposed() {
    this.dialogsService_
      .confirm(
        'Confirmation',
        'Etes-vous sûr de vouloir réaligner la carte courante sur sa dernière version diffusée ?'
      )
      .subscribe((confirmed) => {
        if (!confirmed) return

        this.dialogsService_.showLoadingModal(
          'Synchronisation de la carte en cours...'
        )

        this.mapFacade_.expositionStatus$
          .pipe(
            take(1),
            filter((expositionStatus) => expositionStatus.published),
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            switchMap((_) => this.sseService_.watchEvent('END_PUBLICATION')),
            map((message) => message.published)
          )
          .subscribe({
            next: () => this.mapFacade_.setPublicationReport({ success: true }),
            error: (error) =>
              this.mapFacade_.setPublicationReport({
                success: false,
                message: error,
              }),
          })

        this.mapId$
          .pipe(
            take(1),
            switchMap((mapId) =>
              this.sseService_.wrapApiCall('END_ROLLBACK_TO_EXPOSED', () =>
                this.mapComposition_.syncWithExposed(mapId)
              )
            ),
            map((message) => parseApiMap(message.map)),
            finalize(() => this.dialogsService_.hideLoadingModal())
          )
          .subscribe({
            next: (synchedMap) => {
              this.mapFacade_.fromSpec(synchedMap)
              this.notificationFacade_.showNotification(
                'success',
                'La carte courante a été réalignée avec sa dernière version diffusée.',
                5000
              )
            },
            error: () => {
              this.notificationFacade_.showNotification(
                'danger',
                'Une erreur est survenue lors de la synchronisation de la carte.',
                5000
              )
            },
          })
      })
  }
  handlePublish() {
    this.dialogsService_
      .confirm('Confirmation', 'Etes-vous sûr de vouloir publier la carte ?')
      .subscribe((confirmed) => {
        if (!confirmed) return
        this.mapFacade_.setExpositionStatusPublished(true)
        this.mapId$
          .pipe(
            take(1),
            switchMap((mapId) =>
              this.sseService_.wrapApiCall('END_PUBLICATION', () =>
                this.mapComposition_.publish(mapId)
              )
            )
          )
          .subscribe({
            next: () => {
              this.mapFacade_.setPublicationReport({ success: true })
            },
            error: (error) => {
              this.mapFacade_.setPublicationReport({
                success: false,
                message: error,
              })
              this.notificationFacade_.showNotification(
                'danger',
                'Une erreur est survenue lors de la demande de publication de la carte.',
                5000
              )
            },
          })
      })
  }
  handleUnpublish() {
    this.dialogsService_
      .confirm('Confirmation', 'Etes-vous sûr de vouloir dépublier la carte ?')
      .subscribe((confirmed) => {
        if (!confirmed) return
        this.mapFacade_.setExpositionStatusPublished(false)
        this.mapId$
          .pipe(
            take(1),
            switchMap((mapId) =>
              this.sseService_.wrapApiCall('END_PUBLICATION', () =>
                this.mapComposition_.unpublish(mapId)
              )
            )
          )
          .subscribe({
            next: () => {
              this.mapFacade_.setPublicationReport({ success: true })
            },
            error: (error) => {
              this.mapFacade_.setPublicationReport({
                success: false,
                message: error,
              })
              this.notificationFacade_.showNotification(
                'danger',
                'Une erreur est survenue lors de la demande de dépublication de la carte.',
                5000
              )
            },
          })
      })
  }

  handleRepair() {
    this.dialogsService_
      .confirm('Confirmation', 'Etes-vous sûr de vouloir réparer la carte ?')
      .subscribe((confirmed) => {
        if (!confirmed) return
        this.repairMapService_.repairMap()
      })
  }

  handleResetQgisMap() {
    this.dialogsService_
      .confirm(
        'Confirmation',
        'Etes-vous sûr de vouloir réinitialiser la carte ? Toutes les données QGIS de la carte seront réalignées' +
          ' avec le geopackage uploadé en dernier.'
      )
      .subscribe((confirmed) => {
        if (!confirmed) return
        this.mapId$
          .pipe(
            switchMap((mapId) => {
              this.dialogsService_.showLoadingModal(
                'Réinitialisation de la carte en cours...'
              )
              return this.qgisProjectService_.resetQGisProject(mapId)
            }),
            take(1)
          )
          .subscribe({
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            next: (mapResponse) => {
              this.dialogsService_.hideLoadingModal()
              this.notificationFacade_.showNotification(
                'success',
                'La carte a bien été réinitialisée et va se recharger automatiquement',
                null
              )
              setTimeout(() => window.location.reload(), 3000)
            },
            error: () => {
              this.notificationFacade_.showNotification(
                'danger',
                'Une erreur est survenue lors de la demande de réinitialisation de la carte.',
                5000
              )
            },
          })
      })
  }
}
