import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'
import { FILL_PATTERN_ENUM } from '../model'

@Component({
  selector: 'ui-pattern-fill',
  templateUrl: './pattern-fill.component.html',
  styleUrls: ['./pattern-fill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternFillComponent implements OnInit {
  @Input() pattern: FILL_PATTERN_ENUM
  @Input() color: string

  constructor() {}
  ngOnInit(): void {}

  get needOverlay() {
    return (
      this.pattern === FILL_PATTERN_ENUM.TIMES ||
      this.pattern === FILL_PATTERN_ENUM.PLUS
    )
  }

  getRotate(): number | [number, number] {
    switch (this.pattern) {
      case FILL_PATTERN_ENUM.SLASH:
        return -45
      case FILL_PATTERN_ENUM.BACKSLASH:
        return 45
      case FILL_PATTERN_ENUM.HORLINE:
        return 0
      case FILL_PATTERN_ENUM.VERTLINE:
        return 90
      case FILL_PATTERN_ENUM.PLUS:
        return [90, 0]
      case FILL_PATTERN_ENUM.TIMES:
        return [-45, 45]
    }
  }

  get backgroundStyle() {
    const rotate = this.needOverlay ? this.getRotate()[0] : this.getRotate()
    return this._computeBackground(rotate, this.color)
  }

  get backgroundStyleOverlay() {
    const rotate = this.getRotate()[1]
    return this._computeBackground(rotate, this.color)
  }

  private _computeBackground(rotate: number, color: string): string {
    return `repeating-linear-gradient(${rotate}deg, transparent 0px 9px, ${color} 0 12px)`
  }
}
