import { Inject, Injectable, Optional } from '@angular/core'
import { EMPTY, fromEvent, Observable, Subject } from 'rxjs'
import ReconnectingEventSource from 'reconnecting-eventsource'
import {
  BASE_PATH,
  LayerModel,
  MapExpositionStatusModel,
  MapFullModel,
} from '@ui/data-access-carto-map-production'
import { catchError, first, map } from 'rxjs/operators'

interface MessageData {
  status: boolean
  message: string
  targetMapId?: string
  mapExpositionStatus?: MapExpositionStatusModel
  map?: MapFullModel
  published?: boolean
  irreparableLayers?: LayerModel[]
  processHandle?: string
  uncleanableLayers?: LayerModel[]
}

@Injectable({
  providedIn: 'root',
})
export class SseService {
  eventSource: ReconnectingEventSource = null
  refCount = 0

  constructor(@Optional() @Inject(BASE_PATH) private apiBasePath: string) {}

  private getEventSource() {
    if (!this.eventSource) {
      this.eventSource = new ReconnectingEventSource(
        `${this.apiBasePath}/asynchronousReport`,
        { withCredentials: true }
      )
    }
    this.refCount++
    return this.eventSource
  }

  private releaseEventSource() {
    this.refCount--
    if (this.refCount < 0)
      throw new Error('An unexpected error occured: negative ref count')
    else if (this.refCount === 0) {
      this.eventSource.close()
      this.eventSource = null
    }
  }

  wrapApiCall(
    eventType: string,
    wrapped: () => Observable<unknown>
  ): Observable<MessageData> {
    const subject = new Subject<any>()
    const eventSource = this.getEventSource()
    const mainListener = (event: MessageEvent) => {
      release()
      const data = JSON.parse(event.data) as MessageData
      if (data.status) {
        subject.next(data)
        subject.complete()
      } else {
        subject.error(new Error(data.message))
      }
    }
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const errorListener = (event: Event) => {
      release()
      subject.error(new Error('Server Sent Events source creation failed'))
    }
    eventSource.addEventListener(eventType, mainListener)
    eventSource.addEventListener('error', errorListener)

    const release = () => {
      eventSource.removeEventListener(eventType, mainListener)
      eventSource.removeEventListener('error', errorListener)
      this.releaseEventSource()
    }

    // this is necessary to ensure that the HTTP request is done _after_ the SSE event stream is opened
    setTimeout(() => {
      wrapped()
        .pipe(
          catchError((e) => {
            subject.error(e)
            release()
            return EMPTY
          })
        )
        .subscribe()
    })
    return subject
  }

  watchEvent(eventType: string): Observable<MessageData> {
    return fromEvent<MessageEvent>(this.getEventSource(), eventType).pipe(
      first(),
      map((event) => {
        this.releaseEventSource()
        const data = JSON.parse(event.data) as MessageData
        if (data.status) {
          return data
        } else {
          throw new Error(data.message)
        }
      })
    )
  }
}
