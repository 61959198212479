/**
 * Map Production API
 * This API covers functions related to cartographic composition (creation of maps, styles)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface MapExpositionOptionsModel {
  messageActivated?: boolean
  messageContent?: string
  messageNotify?: boolean
  messageType?: MapExpositionOptionsModel.MessageTypeModelEnum
}
export namespace MapExpositionOptionsModel {
  export type MessageTypeModelEnum = 'INFORMATION' | 'CONSEIL' | 'RESTRICTION'
  export const MessageTypeModelEnum = {
    INFORMATION: 'INFORMATION' as MessageTypeModelEnum,
    CONSEIL: 'CONSEIL' as MessageTypeModelEnum,
    RESTRICTION: 'RESTRICTION' as MessageTypeModelEnum,
  }
}
