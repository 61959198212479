import { EventEmitter, Injectable } from '@angular/core'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { OgcLayer, ResourceSelectEvent } from '../model'

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor() {}
  apiError$ = new EventEmitter<string>()
  apiSuccess$ = new EventEmitter<string>()
  resourceSelected$ = new EventEmitter<ResourceSelectEvent>()
  updateService$ = new EventEmitter<RemoteServerModel>()

  selectResource(resourceId?: string, layer?: OgcLayer): void {
    this.resourceSelected$.emit({
      resourceId,
      layer,
    })
  }
  emitError(message: string): void {
    this.apiError$.emit(message)
  }
  emitSuccess(message: string): void {
    this.apiSuccess$.emit(message)
  }
  updateService(service: RemoteServerModel): void {
    this.updateService$.next(service)
  }
}
