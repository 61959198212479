import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CreateMapRequestModel } from '@ui/data-access-carto-map-production'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { BusinessRulesService } from '@ui/feature/shared'

@Component({
  selector: 'ui-create-map-modal',
  templateUrl: './create-map-modal.component.html',
  styleUrls: ['./create-map-modal.component.scss'],
})
export class CreateMapModalComponent implements OnInit {
  createmapForm: FormGroup
  errorMsg: string
  @Input() showLoading = false // FIXME: this should be part of a larger refactoring of all modals
  @Output() save = new EventEmitter<CreateMapRequestModel>()
  @Output() cancel = new EventEmitter<boolean>()

  constructor(
    private fb: FormBuilder,
    private modalRef: BsModalRef,
    private businessRulesService: BusinessRulesService
  ) {
    this.createmapForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      abstract: ['', [Validators.maxLength(255)]],
    })
  }

  ngOnInit() {
    const controls = this.createmapForm.controls
    controls['title'].valueChanges.subscribe((value) =>
      controls['name'].setValue(
        this.businessRulesService.normalizeMapName(value)
      )
    )
    controls['name'].valueChanges.subscribe((value) => {
      const normalizedName = this.businessRulesService.normalizeMapName(value)
      if (normalizedName !== value) {
        controls['name'].setValue(normalizedName)
      }
    })
  }

  get f() {
    return this.createmapForm.controls
  }

  onSubmit() {
    this.save.emit(this.createmapForm.getRawValue())
  }

  onCancel() {
    this.cancel.emit()
    this.cancel.complete()
    this.modalRef.hide()
  }
}
