import { Injectable } from '@angular/core'
import {
  CreateRemoteServerRequestModel,
  RemoteServerManagementService,
} from '@ui/data-access-carto-map-production'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'

@Injectable({
  providedIn: 'root',
})
export class ApiClientService {
  constructor(private remoteServerManager: RemoteServerManagementService) {}

  removeService(uuid: string) {
    return this.remoteServerManager.deleteRemoteServer(uuid)
  }
  createService(request: CreateRemoteServerRequestModel) {
    return this.remoteServerManager.createRemoteServer(request)
  }
  modifyService(service: RemoteServerModel) {
    return this.remoteServerManager.modifyRemoteServer(service.id, service)
  }
}
