import { Extent } from 'ol/extent'
import { BoundingBox } from '@ui/feature/shared'

export const fromOlExtent = function (extent: Extent): BoundingBox {
  return {
    minx: extent[0],
    miny: extent[1],
    maxx: extent[2],
    maxy: extent[3],
  }
}

export const toOlExtent = function (bounds: BoundingBox): Extent {
  return [bounds.minx, bounds.miny, bounds.maxx, bounds.maxy]
}

export const toString = function (bounds: BoundingBox): string {
  return `${bounds.minx},${bounds.miny},${bounds.maxx},${bounds.maxy}`
}

export const fromString = function (text: string): BoundingBox {
  const extent = text.split(',').map((bound) => Number(bound)) as Extent
  return fromOlExtent(extent)
}
