import { ELEMENT_TYPE, Style } from '@ui/feature/styling'

/** Geostyler object from Geoserver default SLDs **/
export const DEFAULT_STYLES: { [K in ELEMENT_TYPE]: Style } = {
  [ELEMENT_TYPE.POLYGON]: {
    _elementType: ELEMENT_TYPE.POLYGON,
    name: 'Style par défaut',
    rules: [
      {
        name: '',
        symbolizers: [
          {
            kind: 'Fill',
            color: '#AAAAAA',
            opacity: 1,
            fillOpacity: 1,
            outlineColor: '#000000',
            outlineWidth: 1,
            outlineOpacity: 1,
          },
        ],
      },
    ],
  },
  [ELEMENT_TYPE.LINE]: {
    _elementType: ELEMENT_TYPE.LINE,
    name: 'default',
    rules: [
      {
        name: '',
        symbolizers: [
          {
            kind: 'Line',
            color: '#0000ff',
            opacity: 1,
            width: 2,
          },
        ],
      },
    ],
  },
  [ELEMENT_TYPE.POINT]: {
    _elementType: ELEMENT_TYPE.POINT,
    name: 'default',
    rules: [
      {
        name: '',
        symbolizers: [
          {
            kind: 'Mark',
            color: '#ff0000',
            wellKnownName: 'square',
            fillOpacity: 1,
            radius: 3,
          },
        ],
      },
    ],
  },
}
