<div
  class="card panel-container position-relative h-100 ml-2"
  [ngClass]="{
    collapsed: collapsed && !focused,
    focused: focused,
    wider: wider
  }"
>
  <div class="card-header d-flex flex-row align-items-center p-2 flex-shrink-0">
    <div class="flex-shrink-0">
      <ng-content select="[extra-left]"></ng-content>
    </div>
    <div class="flex-grow-1 overflow-hidden align-self-center">
      <div title="{{ panelTitle }}" class="title text-truncate">
        {{ panelTitle }}
      </div>
      <div
        *ngIf="panelSubtitle"
        title="{{ panelSubtitle }}"
        class="subtitle text-truncate"
      >
        {{ panelSubtitle }}
      </div>
    </div>
    <div class="flex-shrink-0"><ng-content select="[extra]"></ng-content></div>
    <button
      *ngIf="collapsible && !focused"
      type="button"
      class="btn btn-sm btn-light flex-shrink-0 close-panel"
      aria-label="Collapse"
      title="Fermer le panneau"
      (click)="toggleCollapsed()"
    >
      <span class="fa fa-times"></span>
    </button>
  </div>

  <div class="card-body p-0"><ng-content select="[body]"></ng-content></div>
  <div class="card-footer text-right">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

<div *ngIf="collapsible" class="toggle position-absolute">
  <button
    type="button"
    class="btn btn-outline-dark btn-sm"
    [ngClass]="{ active: !collapsed }"
    (click)="toggleCollapsed()"
  >
    {{ toggleLabel }}
  </button>
</div>
