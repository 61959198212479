import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  DatasetPublicationStatusModel,
  MapCompositionService,
  QGisProjectService,
} from '@ui/data-access-carto-map-production'
import { EMPTY, from, of } from 'rxjs'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import {
  ADD_MAP_ELEMENT,
  AddMapElement,
  SET_EXPOSITION_STATUS,
  SET_FROM_SPEC,
  SET_GROUP_FOLDING,
  SET_GROUP_VISIBILITY,
  SET_LAYER_ATTRIBUTES,
  SET_LAYER_ERROR,
  SET_LAYER_OPACITY,
  SET_LAYER_VISIBILITY,
  SetExpositionStatus,
  SetFromSpec,
  SetLayerOpacity,
  SetLayerPublicationStatus,
  SetMapPublicationReport,
  SetMapQgisInfo,
  UpdateGroup,
  UpdateLayer,
} from './map.actions'
import { MapFacade } from './map.facade'
import {
  hasMapSpecErrors,
  isLayerInternal,
  isMapDefault,
  isMapElementGroup,
} from '@ui/feature/shared'
import { EventService } from '../service/event.service'

@Injectable()
export class MapEffects {
  constructor(
    private actions$: Actions,
    private eventService_: EventService,
    private facade: MapFacade,
    private mapComposition: MapCompositionService,
    private qgisProjectService: QGisProjectService
  ) {}
  updateLayerNormalizer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SET_LAYER_OPACITY,
        SET_LAYER_VISIBILITY,
        SET_LAYER_ATTRIBUTES,
        SET_LAYER_ERROR
      ),
      map((action: SetLayerOpacity) => new UpdateLayer(action.payload))
    )
  )

  updateGroupNormalizer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_GROUP_VISIBILITY, SET_GROUP_FOLDING),
      map((action: UpdateGroup) => new UpdateGroup(action.payload))
    )
  )

  /**
   * This effect sets the publication status of internal layers as loading
   * when new layers are added
   */
  showLayerPublicationStatusLoading$ = createEffect(() =>
    this.facade.addedMapElement$.pipe(
      filter((mapElement) => !isMapElementGroup(mapElement)),
      filter((layer) => isLayerInternal(layer)),
      map(
        (layer) =>
          new SetLayerPublicationStatus({
            layerId: layer.id,
            state: 'LOADING',
          })
      )
    )
  )

  /**
   * This effect loads the publication statuses of layers which have
   * a BOC view, on layer creation
   */
  loadNewLayerPublicationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddMapElement>(ADD_MAP_ELEMENT),
      map((action) => action.payload.mapElement),
      filter((mapElement) => !isMapElementGroup(mapElement)),
      filter((layer) => isLayerInternal(layer)),
      withLatestFrom(this.facade.info$),
      mergeMap(([layer, mapSpec]) =>
        this.mapComposition
          .getDatasetPublicationStatus(mapSpec.id, layer.id)
          .pipe(
            map((statuses) => statuses[0]),
            filter((status) => !!status),
            catchError(() =>
              of({ state: 'ERROR_PROBABLE' } as DatasetPublicationStatusModel)
            ),
            map(
              (status) =>
                new SetLayerPublicationStatus({
                  layerId: layer.id,
                  state: status.state,
                  scope: status.networkScope,
                })
            )
          )
      )
    )
  )

  /**
   * This effect loads the publication statuses of layers which have
   * a BOC view, on layer creation
   */
  loadMapLayersPublicationStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetFromSpec>(SET_FROM_SPEC),
      map((action) => action.payload),
      switchMap((mapSpec) =>
        this.mapComposition
          .getDatasetsPublicationStatus(mapSpec.id)
          .pipe(catchError(() => of([] as DatasetPublicationStatusModel[])))
      ),
      switchMap((statuses) =>
        statuses.length
          ? from(
              statuses.map(
                (status) =>
                  new SetLayerPublicationStatus({
                    layerId: status.layerid,
                    state: status.state,
                    scope: status.networkScope,
                  })
              )
            )
          : EMPTY
      )
    )
  )

  resetPublicationReportOnSetExpositionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetExpositionStatus>(SET_EXPOSITION_STATUS),
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      map((_) => new SetMapPublicationReport({}))
    )
  )

  resetPublicationReportOnFromSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetFromSpec>(SET_FROM_SPEC),
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      map((_) => new SetMapPublicationReport({}))
    )
  )

  loadQgisInfoOnFromSpec$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetFromSpec>(SET_FROM_SPEC),
      map((action) => action.payload),
      filter((mapSpec) => !isMapDefault(mapSpec)),
      switchMap((mapSpec) =>
        this.qgisProjectService.getQGisProjectInfo(mapSpec.id)
      ),
      map((qgisInfo) => new SetMapQgisInfo(qgisInfo))
    )
  )

  checkMapSpecErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SetFromSpec>(SET_FROM_SPEC),
        tap((action) =>
          this.eventService_.hasSpecErrors(hasMapSpecErrors(action.payload))
        )
      ),
    { dispatch: false }
  )
}
