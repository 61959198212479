import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { PasswordError } from '@ui/feature/shared'

@Component({
  selector: 'ui-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input() parentForm: FormGroup

  hidden = true
  timeoutID

  constructor() {}

  ngOnInit() {}

  displayInvalid() {
    const passwordControl = this.parentForm.controls.password
    return passwordControl.errors
  }

  displayValid() {
    const passwordControl = this.parentForm.controls.password
    return !passwordControl.errors && this.parentForm.controls.password.value
  }

  displayRules() {
    const protectedControl = this.parentForm.controls.protected
    const passwordControl = this.parentForm.controls.password
    return (
      protectedControl.value &&
      (passwordControl.invalid || passwordControl.dirty)
    )
  }

  displayMinLengthInValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.errors &&
      passwordControl.errors[PasswordError.MinLength] &&
      passwordControl.touched
    )
  }

  displayMinLengthValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.valid ||
      (passwordControl.errors &&
        !passwordControl.errors[PasswordError.MinLength])
    )
  }

  displayDigitInValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.errors &&
      passwordControl.errors[PasswordError.AtLeastOneDigit] &&
      passwordControl.touched
    )
  }

  displayDigitValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.valid ||
      (passwordControl.errors &&
        !passwordControl.errors[PasswordError.AtLeastOneDigit])
    )
  }

  displayLetterInValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.errors &&
      passwordControl.errors[PasswordError.AtLeastOneLetter] &&
      passwordControl.touched
    )
  }

  displayLetterValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.valid ||
      (passwordControl.errors &&
        !passwordControl.errors[PasswordError.AtLeastOneLetter])
    )
  }

  displaySpecialCharacterInValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.errors &&
      passwordControl.errors[PasswordError.AtLeastOneSpecialCharacter] &&
      passwordControl.touched
    )
  }

  displaySpecialCharacterValid() {
    const passwordControl = this.parentForm.controls.password
    return (
      passwordControl.valid ||
      (passwordControl.errors &&
        !passwordControl.errors[PasswordError.AtLeastOneSpecialCharacter])
    )
  }

  toggleVisibilityTemporarily() {
    if (!this.hidden) {
      this.hidden = true
      clearTimeout(this.timeoutID)
    } else {
      this.hidden = false
      this.timeoutID = setTimeout(() => {
        this.hidden = true
      }, 3000)
    }
  }
}
