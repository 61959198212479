import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { MapExpositionScopeModel } from '@ui/data-access-carto-map-production'
import { fromOlExtent, Info, toString } from '@ui/feature/mapstate'
import { BusinessRulesService } from '@ui/feature/shared'
import { merge, Observable, of, Subscription } from 'rxjs'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import {
  minMaxScaleValidator,
  minMaxScaleNoEmptyValidator,
} from '../../min-max-scale-group/min-max-scale-group.component'
import { get as getProj } from 'ol/proj'
import { getExposedMapUrl } from '../../../../../helpers/map.helpers'

export const knownProjectionValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null =>
    getProj(control.value) ? null : { unknownProjection: true }
}

export const FRONT_OFFICE_PATH = new InjectionToken<string>('frontOfficePath')

@Component({
  selector: 'ui-edit-map-modal',
  templateUrl: './edit-map-modal.component.html',
  styleUrls: ['./edit-map-modal.component.scss'],
})
export class EditMapModalComponent implements OnInit, OnDestroy {
  editmapForm: FormGroup
  errorMsg: string
  subscription_ = new Subscription()
  @Input() info: Info
  @Input() expositionScope: MapExpositionScopeModel
  @Input() extent: number[]
  @Output() save = new EventEmitter<FormGroup>()
  @Output() cancel = new EventEmitter<boolean>()
  isExposedToIntranet$: Observable<boolean>
  isExposedToInternet$: Observable<boolean>
  isExposed$: Observable<boolean>

  constructor(
    private fb: FormBuilder,
    private businessRulesService: BusinessRulesService,
    @Optional() @Inject(FRONT_OFFICE_PATH) private frontOfficePath
  ) {}

  ngOnInit() {
    this.editmapForm = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.maxLength(255)]],
      bounds: [''],
      crsCode: ['EPSG:3857', [Validators.required, knownProjectionValidator()]],
      customName: [false],
      expositionScope: ['', [Validators.required]],
      customizableScale: [false],
      minScale: new FormControl<number>(null, {
        validators: null,
        updateOn: 'blur',
      }),
      maxScale: new FormControl<number>(null, {
        validators: null,
        updateOn: 'blur',
      }),
      protected: [false],
      password: [
        { value: '', disabled: true },
        [Validators.required, this.businessRulesService.passwordValidator()],
      ],
    })
    ;['minScale', 'maxScale'].forEach((inputName) => {
      this.editmapForm
        .get(inputName)
        .setValidators([
          minMaxScaleValidator(this.editmapForm),
          minMaxScaleNoEmptyValidator(this.editmapForm),
          Validators.min(1),
        ])
    })

    this.editmapForm.patchValue({
      ...this.info,
      bounds: this.info.bounds ? toString(this.info.bounds) : null,
      expositionScope: this.expositionScope,
      customizableScale: !(!this.info.minScale && !this.info.maxScale),
    })

    const controls = this.editmapForm.controls
    const values = this.editmapForm.getRawValue()

    this.subscription_.add(
      controls['customName'].valueChanges.subscribe((customName) => {
        customName ? controls['name'].enable() : controls['name'].disable()
        if (!customName) {
          controls['name'].setValue(
            this.businessRulesService.normalizeMapName(controls['title'].value)
          )
        }
      })
    )

    this.subscription_.add(
      controls['title'].valueChanges
        .pipe(
          withLatestFrom(controls['customName'].valueChanges),
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          filter(([title, custom]) => !custom)
        )
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        .subscribe(([title, custom]) =>
          controls['name'].setValue(
            this.businessRulesService.normalizeMapName(title)
          )
        )
    )
    controls.customName.setValue(
      this.businessRulesService.normalizeMapName(values.title) !== values.name
    )

    this.subscription_.add(
      controls['name'].valueChanges.subscribe((value) => {
        const normalizedName = this.businessRulesService.normalizeMapName(value)
        if (normalizedName !== value) {
          controls['name'].setValue(normalizedName)
        }
      })
    )

    this.subscription_.add(
      controls['protected'].valueChanges.subscribe((isProtected) => {
        isProtected
          ? controls['password'].enable()
          : controls['password'].disable()
        if (!isProtected) {
          controls['password'].setValue('')
        }
      })
    )

    this.isExposedToIntranet$ = merge(
      of(this.expositionScope),
      controls['expositionScope'].valueChanges
    ).pipe(
      map(
        (scope: MapExpositionScopeModel) =>
          scope === MapExpositionScopeModel.INTRANET ||
          scope === MapExpositionScopeModel.BOTH
      )
    )

    this.isExposedToInternet$ = merge(
      of(this.expositionScope),
      controls['expositionScope'].valueChanges
    ).pipe(
      map(
        (scope: MapExpositionScopeModel) =>
          scope === MapExpositionScopeModel.INTERNET ||
          scope === MapExpositionScopeModel.BOTH
      )
    )
  }

  get f() {
    return this.editmapForm.controls
  }

  setExtent() {
    this.editmapForm.patchValue({
      // FIXME: use an alias for this import once we migrate to angular 8 (!)
      bounds: toString(fromOlExtent(this.extent)),
    })
  }

  onSubmit() {
    this.save.emit(this.editmapForm)
  }

  onCancel() {
    this.cancel.emit()
  }

  ngOnDestroy() {
    this.subscription_.unsubscribe()
    this.save.complete()
    this.cancel.complete()
  }

  toggleExposeToIntranet() {
    let expositionScope
    switch (this.editmapForm.value.expositionScope) {
      case MapExpositionScopeModel.BOTH:
        expositionScope = MapExpositionScopeModel.INTERNET
        break
      case MapExpositionScopeModel.INTRANET:
        expositionScope = MapExpositionScopeModel.NONE
        break
      case MapExpositionScopeModel.INTERNET:
        expositionScope = MapExpositionScopeModel.BOTH
        break
      default:
        expositionScope = MapExpositionScopeModel.INTRANET
        break
    }
    this.editmapForm.patchValue({
      expositionScope,
    })
  }

  toggleExposeToInternet() {
    let expositionScope
    switch (this.editmapForm.value.expositionScope) {
      case MapExpositionScopeModel.BOTH:
        expositionScope = MapExpositionScopeModel.INTRANET
        break
      case MapExpositionScopeModel.INTRANET:
        expositionScope = MapExpositionScopeModel.BOTH
        break
      case MapExpositionScopeModel.INTERNET:
        expositionScope = MapExpositionScopeModel.NONE
        break
      default:
        expositionScope = MapExpositionScopeModel.INTERNET
        break
    }
    this.editmapForm.patchValue({
      expositionScope,
    })
  }

  isActuallyExposed() {
    return this.expositionScope !== MapExpositionScopeModel.NONE
  }

  getExposedMapUrl() {
    return getExposedMapUrl(this.info.id)
  }

  getExposedMapOGCServiceUrl() {
    return this.info.owsUrl
  }

  getExposedMapQGisOwsServiceUrl() {
    return this.info.qGisOwsUrl
  }
}
