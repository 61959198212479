import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import { GlyphSymbol, PointSymbol, StyleService } from '@ui/feature/shared'

const BUILTIN_SYMBOLS = {
  circle: '●',
  square: '■',
  triangle: '▴',
  star: '★',
  cross: '+',
  x: '⨯',
}

function hasFont(name: string) {
  const fonts = (document as any).fonts
  return fonts.load(`32px ${name}`).then(
    (fontFaces) => fontFaces.length > 0,
    () => false
  )
}

@Component({
  selector: 'ui-symbol-preview',
  templateUrl: './symbol-preview.component.html',
  styleUrls: ['./symbol-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SymbolPreviewComponent implements OnInit, OnChanges {
  @Input() symbol: PointSymbol
  @Input() color?: string
  @Input() variant: 'l' | 's' = 'l'

  error: string = null

  get cssColor() {
    return this.color || (this.symbol as GlyphSymbol).color || '#505050'
  }

  get isFontGlyph() {
    return this.styleService.isFontGlyphSymbol(this.symbol)
  }

  get isShape() {
    return this.styleService.isShapeSymbol(this.symbol)
  }

  get fontFamily() {
    if (!('wellKnownName' in this.symbol) || !this.isFontGlyph) return ''
    const font = this.symbol.wellKnownName.split('#')[0].replace('ttf://', '')
    return `'${font}'`
  }

  get character() {
    if (!('wellKnownName' in this.symbol)) return ''

    if (this.isFontGlyph) {
      const char = this.symbol.wellKnownName.split('#')[1]
      return String.fromCharCode(parseInt(char, 16))
    } else {
      return BUILTIN_SYMBOLS[this.symbol.wellKnownName] || '?'
    }
  }

  get pattern() {
    if (!('wellKnownName' in this.symbol)) return ''
    if (this.isShape) {
      const regExpMatchArray = this.symbol.wellKnownName.match(/shape:\/\/(.*)/)
      if (regExpMatchArray) {
        return regExpMatchArray[1]
      }
    }
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private styleService: StyleService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['symbol']) {
      const font = this.fontFamily
      if (font) {
        this.error = null
        hasFont(font).then((has) => {
          if (!has) {
            this.error = `La police de caractère suivante n'est pas disponible: ${font}
L'aperçu du symbole peut être erroné.`
            this.changeDetector.detectChanges()
          }
        })
      }
    }
  }
}
