import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { PanelComponent } from './panel/panel.component'
import { ConfirmPopupComponent } from './dialogs/confirm/confirm.component'
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal'
import { DialogsService } from './dialogs/dialogs.service'
import { ItemGridComponent } from './item-grid/item-grid.component'
import { LoadingComponent } from './dialogs/loading/loading.component'
import { SelectItemListComponent } from './select-item-list/select-item-list.component'
import { ModalComponent } from './modal/modal.component'
import { AlertModule } from 'ngx-bootstrap/alert'
import { ProgressBarComponent } from './progress-bar/progress-bar.component'
import { ProgressbarModule } from 'ngx-bootstrap/progressbar'
import { FilterSearchInputComponent } from './filter-search-input/filter-search-input.component'
import { IconTooltipComponent } from './icon-tooltip/icon-tooltip.component'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    AlertModule,
    ProgressbarModule,
    TooltipModule.forRoot(),
  ],
  declarations: [
    PanelComponent,
    ConfirmPopupComponent,
    ItemGridComponent,
    LoadingComponent,
    SelectItemListComponent,
    ModalComponent,
    ProgressBarComponent,
    FilterSearchInputComponent,
    IconTooltipComponent,
  ],
  exports: [
    PanelComponent,
    ItemGridComponent,
    SelectItemListComponent,
    ModalComponent,
    ProgressBarComponent,
    FilterSearchInputComponent,
    IconTooltipComponent,
  ],
  providers: [DialogsService, BsModalService, BsModalRef],
})
export class UiLayoutModule {}
export { PanelComponent }
