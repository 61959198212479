import { InjectionToken } from '@angular/core'
import { DataSchema as GsDataSchema } from 'geostyler-data'
import {
  BoundingBoxModel,
  DatasetPublicationStatusModel,
  LayerViewModel,
  LayerViewOriginTypeModel,
  LayerViewTypeModel,
  MapBriefModel,
  MapExpositionScopeModel,
  MapExpositionStatusModel,
} from '@ui/data-access-carto-map-production'

export interface WfsReadParams {
  url: string
  version: string
  typeName: string
  featureID?: string
  propertyName?: string[]
  srsName?: string
  maxFeatures?: number
  fetchParams?: Object
  extent?: number[]
  outputFormat?: string
}

export const GETLEGENDGRAPHIC_URL = new InjectionToken<string>(
  'getLegendGraphicUrl'
)
export const GETLEGENDGRAPHIC_LAYER = new InjectionToken<string>(
  'getLegendGraphicLayer'
)

export const MAX_OBJECTS_COUNT_FILTER = new InjectionToken<string>(
  'maxObjectsCountFilter'
)

interface CartoDataSchema extends GsDataSchema {
  geometryName: string
}

export type DataSchema = CartoDataSchema | { error: string }

export type LayerView = LayerViewModel
export const LayerViewOriginType = LayerViewOriginTypeModel
export const LayerViewType = LayerViewTypeModel

export interface Layer {
  id: number
  title?: string
  description?: string
  opacity?: number
  visible?: boolean
  views: LayerView[]
  queryable?: boolean
  minScale?: number
  maxScale?: number
  _version?: number
  _resourceId?: string // TODO: this should not be stored on the layer but on the view?
  _error?: boolean
}
export interface Group {
  id: number
  title?: string
  description?: string
  opacity?: number
  visible?: boolean
  childrenId: number[]
  collapsed?: boolean
  _version?: number
  _error?: boolean
}
export type MapElement = Layer | Group

export interface MapElementsById {
  [id: string]: MapElement
}

export type MapExpositionStatus = MapExpositionStatusModel
export type MapExpositionScope = MapExpositionScopeModel
export interface PublicationReport {
  success?: boolean
  message?: string
}
export type BoundingBox = BoundingBoxModel

export interface Map {
  id?: string
  name?: string
  orgId?: string
  title?: string
  description?: string
  creationDate?: Date
  crsCode?: string
  organizationName?: string
  bounds?: BoundingBox
  expositionStatus?: MapExpositionStatus
  elementsById: MapElementsById
  rootElementId: number
  minScale?: number
  maxScale?: number
  protected?: boolean
}

export type MapSummary = MapBriefModel

export interface GlyphSymbol {
  label?: string
  type: 'glyph'
  wellKnownName: string
  color?: string
}

export interface ShapeSymbol {
  label?: string
  type: 'shape'
  wellKnownName: string
  color?: string
}

export interface ImageSymbol {
  label?: string
  type: 'image'
  path: string
  format: string
}

export type PointSymbol = GlyphSymbol | ImageSymbol | ShapeSymbol
export type LayerPublicationStatusEnum =
  | DatasetPublicationStatusModel.StateModelEnum
  | 'LOADING'
export type LayerPublicationScopeEnum =
  DatasetPublicationStatusModel.NetworkScopeModelEnum

export interface LayerPublicationStatus {
  layerId: number
  state: LayerPublicationStatusEnum
  scope?: LayerPublicationScopeEnum
}
