import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { MapCompositionService } from '@ui/data-access-carto-map-production'
import {
  Info,
  MapFacade,
  MOVE_LAYER,
  MoveLayer,
  REMOVE_GROUP,
  REMOVE_LAYER,
  RemoveGroup,
  RemoveLayer,
  SET_LAYER_ATTRIBUTES,
  SET_LAYER_OPACITY,
  SET_LAYER_VISIBILITY,
  SetLayerAttributes,
  SetLayerOpacity,
  SetLayerVisibility,
  UPDATE_GROUP,
  UpdateGroup,
} from '@ui/feature/mapstate'
import { isMapElementGroup, Layer } from '@ui/feature/shared'
import { of } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import * as notificationAction from '../actions/notification.action'
import { StyleFacade } from '../facade'

@Injectable()
export class LayerEffects {
  constructor(
    private actions$: Actions,
    private mapFacade: MapFacade,
    private mapComposition: MapCompositionService,
    private styleFacade: StyleFacade
  ) {}

  moveLayer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MOVE_LAYER),
        withLatestFrom(this.mapFacade.info$),
        switchMap(([action, info]: [MoveLayer, Info]) =>
          this.mapComposition
            .reorderMapElement(info.id, action.payload.id, {
              parentGroupId: action.payload.newParentId,
              newRank: action.payload.index + 1,
            })
            .pipe(
              filter(() => false), // stop the stream cause does not return action
              // eslint-disable-next-line  @typescript-eslint/no-unused-vars
              catchError((error) => of(this.sendMapSaveFailureNotification()))
            )
        )
      ),
    { dispatch: false }
  )

  closeStyleEditorOnLayerRemove$ = createEffect(
    () =>
      this.mapFacade.removedMapElement$.pipe(
        filter(isMapElementGroup),
        withLatestFrom(this.styleFacade.currentViewId$),
        tap(([layer, viewId]: [Layer, number]) => {
          if (layer && 'views' in layer && layer.views[0].id === viewId)
            this.styleFacade.clearCurrentStyle()
        }),
        filter(() => false) // stop the stream cause does not return action
      ),
    { dispatch: false }
  )

  deleteLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REMOVE_LAYER),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([action, info]: [RemoveLayer, Info]) =>
        this.mapComposition.deleteLayer(info.id, action.id).pipe(
          filter(() => false), // stop the stream cause does not return action
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          catchError((error) => of(this.sendMapSaveFailureNotification()))
        )
      )
    )
  )

  deleteGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(REMOVE_GROUP),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([action, info]: [RemoveGroup, Info]) =>
        this.mapComposition.deleteLayerGroup(info.id, action.id).pipe(
          filter(() => false), // stop the stream cause does not return action
          // eslint-disable-next-line  @typescript-eslint/no-unused-vars
          catchError((error) => of(this.sendMapSaveFailureNotification()))
        )
      )
    )
  )

  updateLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LAYER_ATTRIBUTES, SET_LAYER_VISIBILITY),
      withLatestFrom(this.mapFacade.info$),
      switchMap(
        ([action, info]: [SetLayerAttributes | SetLayerVisibility, Info]) =>
          this.mapComposition
            .updateLayerById(info.id, action.payload.id, action.payload)
            .pipe(
              filter(() => false), // stop the stream cause does not return action
              // eslint-disable-next-line  @typescript-eslint/no-unused-vars
              catchError((error) => of(this.sendMapSaveFailureNotification()))
            )
      )
    )
  )

  updateGroup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UPDATE_GROUP),
        withLatestFrom(this.mapFacade.info$),
        switchMap(([action, info]: [UpdateGroup, Info]) =>
          this.mapComposition
            .updateLayerGroup(info.id, action.payload.id, action.payload)
            .pipe(
              filter(() => false), // stop the stream cause does not return action
              // eslint-disable-next-line  @typescript-eslint/no-unused-vars
              catchError((error) => of(this.sendMapSaveFailureNotification()))
            )
        )
      ),
    { dispatch: false }
  )

  updateLayerDebounced$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LAYER_OPACITY),
      withLatestFrom(this.mapFacade.info$),
      debounceTime(500),
      switchMap(([action, info]: [SetLayerOpacity, Info]) =>
        this.mapComposition
          .updateLayerById(info.id, action.payload.id, action.payload)
          .pipe(
            filter(() => false), // stop the stream cause does not return action
            // eslint-disable-next-line  @typescript-eslint/no-unused-vars
            catchError((error) => of(this.sendMapSaveFailureNotification()))
          )
      )
    )
  )

  private sendMapSaveFailureNotification() {
    return new notificationAction.AddNotification({
      message: "L'enregistrement de la carte a échoué",
      type: 'danger',
      timeout: 5000,
      id: Date.now(),
    })
  }
}
