import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  AbstractControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'
import { merge, of, Subscription } from 'rxjs'
import { MinMax } from '../min-max-scale-input/min-max-scale-input.component'

export const DEFAULT_MINSCALE = 1000
export const DEFAULT_MAXSCALE = 1000000

@Component({
  selector: 'ui-min-max-scale-group',
  templateUrl: './min-max-scale-group.component.html',
  styleUrls: ['./min-max-scale-group.component.scss'],
})
export class MinMaxScaleGroupComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup
  @Input() tipMax?: string
  @Input() tipMin?: string
  @Input() minScaleMapValue?: number
  @Input() maxScaleMapValue?: number

  MinMax = MinMax
  subscription_ = new Subscription()

  constructor() {}

  get formControls() {
    return this.parentForm.controls
  }

  ngOnInit() {
    if (
      this.formControls['minScale'].value ||
      this.formControls['maxScale'].value
    ) {
      this.formControls.customizableScale.setValue(true)
    }

    this.subscription_.add(
      merge(
        of(true),
        this.formControls['customizableScale'].valueChanges
      ).subscribe(() => this.toggleScaleCustomization())
    )
  }

  ngOnDestroy() {
    this.subscription_.unsubscribe()
  }

  toggleScaleCustomization() {
    const controls = this.formControls

    if (controls['customizableScale'].value) {
      if (!controls['minScale'].value && !controls['maxScale'].value) {
        controls['minScale'].setValue(this.minScaleMapValue || DEFAULT_MINSCALE)
        controls['maxScale'].setValue(this.maxScaleMapValue || DEFAULT_MAXSCALE)
      }
      controls['minScale'].enable()
      controls['maxScale'].enable()
    } else {
      controls['minScale'].setValue('')
      controls['maxScale'].setValue('')
      controls['minScale'].disable()
      controls['maxScale'].disable()
    }
  }

  isInputInvalid() {
    const form = this.formControls
    const controlMin = form['minScale']
    const controlMax = form['maxScale']

    controlMin.updateValueAndValidity()
    controlMax.updateValueAndValidity()

    return (
      controlMin.errors?.['errorValue'] || controlMax.errors?.['errorValue']
    )
  }
}

export function minMaxScaleValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlMin = form.controls['minScale']
    const controlMax = form.controls['maxScale']
    const hasError =
      parseInt(controlMin.value, 10) > parseInt(controlMax.value, 10)

    return hasError ? { errorValue: control.value } : null
  }
}

export function minMaxScaleNoEmptyValidator(form: FormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const customizableScaleActive = form.controls['customizableScale'].value
    const hasError = customizableScaleActive && !control.value

    return hasError ? { forbiddenEmptyValue: { value: control.value } } : null
  }
}
