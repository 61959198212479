<ui-modal
  #modal
  title="Gestion des couches irréparables"
  successMessage="La réparation de la carte est terminée"
  infoMessage="Les couches listées ci-dessous ne sont pas
  réparables et ne seront pas exploitables. Vous pouvez choisir soit de les
  supprimer, soit de les conserver afin d'identifier et de corriger la cause du
  problème et de retenter une réparation ultérieurement.
  Attention : la suppression des couches non réparables est
  définitive, leur configuration ne pourra plus être restaurée (les données ne
  sont pas impactées)"
  cancelLabel="Conserver les couches"
  confirmLabel="Supprimer les couches"
  [cancelHandler]="refreshPage()"
  [confirmHandler]="onConfirm.bind(this)"
>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">Couche</th>
        <th scope="col">Cause</th>
      </tr>
    </thead>
    <tbody>
      <tr class="tableRow" *ngFor="let layer of irreparableLayers">
        <td>{{ layer.title }}</td>
        <td>{{ layer.message || '' }}</td>
      </tr>
    </tbody>
  </table>
</ui-modal>
