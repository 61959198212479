import { CommonModule, DecimalPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { LayertreeElementComponent } from './components/layertree/layertree-element.component'
import { LayertreeComponent } from './components/layertree/layertree.component'
import { MousePositionComponent } from './components/mouse-position/mouse-position.component'
import { ScaleLineComponent } from './components/scale-line/scale-line.component'
import { ZoomComponent } from './components/zoom/zoom.component'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { FullscreenComponent } from './components/fullscreen/fullscreen.component'
import { NgArrayPipesModule } from 'ngx-pipes'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { UiCartoModule } from '@ui/ui/carto'
import { DragDropModule } from '@angular/cdk/drag-drop'

@NgModule({
  declarations: [
    MousePositionComponent,
    ScaleLineComponent,
    ZoomComponent,
    LayertreeComponent,
    LayertreeElementComponent,
    FullscreenComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    NgArrayPipesModule,
    CollapseModule.forRoot(),
    UiCartoModule,
    FormsModule,
    DragDropModule,
  ],
  exports: [
    MousePositionComponent,
    ScaleLineComponent,
    ZoomComponent,
    LayertreeComponent,
    LayertreeElementComponent,
    FullscreenComponent,
  ],
  providers: [DecimalPipe],
})
export class UiMapModule {}
