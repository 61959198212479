import { HttpClient } from '@angular/common/http'
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  EventEmitter,
} from '@angular/core'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, shareReplay, startWith } from 'rxjs/operators'
import { OgcLayer } from '../model'
import { GetCapabilitiesFn, UtilsService } from '../services/utils.service'
import { ProxyService } from '@ui/feature/shared'
import { FilterService } from '../services/wildcard-filter.service'

@Component({
  selector: 'ui-remote-service-content',
  templateUrl: './remote-service-content.component.html',
  styleUrls: ['./remote-service-content.component.scss'],
})
export class RemoteServiceContentComponent implements OnInit, OnChanges {
  @Input() remoteServer: RemoteServerModel
  @Input() layersSelectable = false
  @Output() closePanel = new EventEmitter<void>()
  layers$: Observable<OgcLayer[]>
  error$: Observable<string>
  loading$: Observable<boolean>
  originLayers: OgcLayer[] = []
  filterValue = ''
  filteredLayers: OgcLayer[]
  placeholderValue = 'Rechercher une couche'
  protected getCapabilitiesParserFn: GetCapabilitiesFn

  constructor(
    protected http: HttpClient,
    protected utils: UtilsService,
    protected proxy: ProxyService,
    protected wildcardFilterService_: FilterService
  ) {
    this.getCapabilitiesParserFn = this.utils.parseGetCapabilities
  }

  getHttpRequest(): Observable<string> {
    throw new Error(
      'This getHttpRequest() should be implemented in the inherited class.'
    )
  }

  ngOnInit() {
    this.layers$ = this.getHttpRequest().pipe(
      map(
        (response) =>
          this.getCapabilitiesParserFn(response, this.remoteServer.serverType)
            .layers
      ),
      shareReplay()
    )

    this.layers$.subscribe((layers) => {
      this.originLayers = layers
      this.filteredLayers = this.getFilteredLayers(this.filterValue)
    })

    this.error$ = this.layers$.pipe(
      filter(() => false),
      catchError((error) => of(error.message || 'unknown error'))
    )

    this.loading$ = this.layers$.pipe(
      map(() => false),
      catchError(() => of(false)),
      startWith(true)
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layersSelectable) {
      this.onFilterValueChange('')
    }
  }

  onFilterValueChange(filterValue: string) {
    this.filterValue = filterValue
    this.filteredLayers = this.getFilteredLayers(this.filterValue)
  }

  public filterLayersRecursive(
    layer: OgcLayer,
    filterValue: string
  ): OgcLayer | null {
    if (!layer.layers || layer.layers.length === 0) {
      const isMatch =
        this.wildcardFilterService_.filterWildcard(
          [layer],
          ['name', 'title', 'abstract'],
          filterValue
        ).length > 0

      return isMatch ? { ...layer } : null
    }
    const isMatch =
      this.wildcardFilterService_.filterWildcard(
        [layer],
        ['name', 'title', 'abstract'],
        filterValue
      ).length > 0

    if (isMatch && layer.layers) {
      return layer
    }

    const filteredChildren = layer.layers
      .map((child) => this.filterLayersRecursive(child, filterValue))
      .filter((child) => !!child)

    if (!isMatch && filteredChildren.length > 0) {
      return { ...layer, layers: filteredChildren }
    }

    return null
  }

  public getFilteredLayers(filterValue: string): OgcLayer[] {
    const filteredLayers: OgcLayer[] = []

    this.originLayers.forEach((layer) => {
      const filteredLayer = this.filterLayersRecursive(layer, filterValue)
      if (filteredLayer) {
        filteredLayers.push(filteredLayer)
      }
    })

    return filteredLayers
  }
}
