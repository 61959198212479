import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  LayerViewModel,
  LayerViewTypeModel,
} from '@ui/data-access-carto-map-production'
import { TreeItemModel } from '@ui/data-access-carto-resource-management'
import {
  MapFacade,
  OpenlayersService,
  RefreshLayer,
  UtilsService,
  IMAGE_WMS_LOAD_ERROR,
} from '@ui/feature/mapstate'
import {
  DataSchemaService,
  GETLEGENDGRAPHIC_LAYER,
  GETLEGENDGRAPHIC_URL,
  getViewServiceUrl,
  Group,
  isLayerExternal,
  isLayerExternalWFS,
  isLayerExternalRaster,
  isLayerInternal,
  isLayerQgis,
  isMapElementGroup,
  isRasterSource,
  LayerPublicationStatus,
  MapElement,
  ViewUtilsService,
  hasMapElementErrors,
  Layer,
} from '@ui/feature/shared'
import { DialogsService } from '@ui/ui/layout'
import { combineLatest, Observable, of, Subscription } from 'rxjs'
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeWhile,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators'
import { EventService } from '../../services/event.service'

export const WFS_THEMATIC_MAX_FEATURES = new InjectionToken<string>(
  'wfsThematicMaxFeatures'
)

@Component({
  selector: 'ui-layertree-element',
  templateUrl: './layertree-element.component.html',
  styleUrls: ['./layertree-element.component.scss'],
})
export class LayertreeElementComponent implements OnInit, OnDestroy {
  @Input() layer: MapElement
  @Input() depth: number
  active$ = this.eventService_.activeLayerId$.pipe(
    map((id) => id === this.layer.id)
  )
  activeOnce$ = this.active$.pipe(takeWhile((active) => !active, true))
  @Input() readonly: boolean
  title = new FormControl('', { updateOn: 'blur' })
  @ViewChild('layertitle', { static: false }) layertitle: ElementRef
  @ViewChild('rootEl', { static: true }) rootEl: ElementRef
  mapScaleDenominator$ = this.facade.scaleDenominator$
  getAncestorElementVisible$: Observable<boolean>
  legendUrl$: Observable<string>
  legendNextFallback = false
  outOfRange$ = this.mapScaleDenominator$.pipe(
    map((denom) => this.isOutOfRange(denom))
  )
  getVisibilityIconDisabledReason$: Observable<string>
  isVisibilityIconDisabled: Observable<boolean>
  canEditThematicStyle$: Observable<boolean>
  canEditThematicStyleTitle$: Observable<string>
  groupExpanded = true
  canEditStyle = false
  isLayerExternal: Boolean
  isLayerQgis: Boolean
  hasPublicationStatus: Boolean
  hasSpecError: Boolean
  imageLoadResponse: IMAGE_WMS_LOAD_ERROR

  titleEditable$ = this.eventService_.editTitle$.pipe(
    map((id) => id === this.layer.id)
  )

  publicationStatus$: Observable<LayerPublicationStatus>

  sub: Subscription = new Subscription()

  // will be changed for unit tests
  popoverContainer = 'body'

  isDraggedOver = false
  counterDragEnter = 0

  constructor(
    @Optional() @Inject(GETLEGENDGRAPHIC_URL) private getLegendGraphicUrl,
    @Optional() @Inject(GETLEGENDGRAPHIC_LAYER) private getLegendGraphicLayer,
    @Optional()
    @Inject(WFS_THEMATIC_MAX_FEATURES)
    private wfsThematicMaxFeatures,
    private facade: MapFacade,
    private dialogsService: DialogsService,
    private eventService_: EventService,
    private dataSchemaService: DataSchemaService,
    private utilsService: UtilsService,
    private viewUtilsService: ViewUtilsService,
    private olService: OpenlayersService
  ) {}

  get view(): LayerViewModel {
    return 'views' in this.layer && this.layer.views.length > 0
      ? this.layer.views[0]
      : null
  }

  get hasStyle() {
    return this.view && !!this.view.defaultStyleId
  }

  opacityEmitter = new EventEmitter<number>()

  get opacity() {
    return this.layer?.opacity
  }

  set opacity(value: number) {
    this.opacityEmitter.emit(value)
  }

  ngOnInit() {
    this.groupExpanded = !(this.isGroup() && (this.layer as Group).collapsed)
    if (this.layer) this.title.setValue(this.layer.title)
    this.getAncestorElementVisible$ = this.facade.getAncestorElementVisible(
      this.layer.id
    )
    this.getVisibilityIconDisabledReason$ = combineLatest([
      this.outOfRange$,
      this.getAncestorElementVisible$,
    ]).pipe(
      map(
        ([isOutOfRange, hasAncestorVisible]) =>
          `${isOutOfRange ? '(Hors plage de visibilité)\n' : ''}${
            hasAncestorVisible ? '' : '(Groupe parent non visible)\n'
          }`
      )
    )

    this.isVisibilityIconDisabled = combineLatest([
      this.outOfRange$,
      this.getAncestorElementVisible$,
    ]).pipe(
      map(
        ([isOutOfRange, hasAncestorVisible]) =>
          isOutOfRange || !hasAncestorVisible
      )
    )

    this.legendUrl$ = this.isGroup()
      ? of('')
      : combineLatest([
          this.view.serviceType === LayerViewTypeModel.WFS
            ? this.facade.getStyleAsSldByViewId(this.view.id)
            : of(''),
          this.facade.refreshLayer$.pipe(
            filter((action: RefreshLayer) => action.viewId === this.view.id),
            startWith(true)
          ),
        ]).pipe(
          switchMap(([sld]) => {
            let wxsUrl: URL
            switch (this.view.serviceType) {
              case LayerViewTypeModel.WMS:
              case LayerViewTypeModel.WMTS:
                // for internal layer, build legend URL without a getcapabilities call
                wxsUrl = new URL(getViewServiceUrl(this.view))
                wxsUrl.searchParams.set('SERVICE', this.view.serviceType)
                wxsUrl.searchParams.set('REQUEST', 'GetLegendGraphic')
                wxsUrl.searchParams.set('FORMAT', 'image/png')
                wxsUrl.searchParams.set('LAYER', this.view.layerName)
                wxsUrl.searchParams.set('VERSION', this.view.serviceVersion)
                wxsUrl.searchParams.set(
                  '_random',
                  Math.floor(Math.random() * 100000).toString(10)
                )
                return of(wxsUrl.toString())
              case LayerViewTypeModel.WFS:
                wxsUrl = new URL(
                  this.getLegendGraphicUrl,
                  window.location.origin
                )
                wxsUrl.searchParams.set('SERVICE', 'WMS')
                wxsUrl.searchParams.set('REQUEST', 'GetLegendGraphic')
                wxsUrl.searchParams.set('FORMAT', 'image/png')
                wxsUrl.searchParams.set('LAYER', this.getLegendGraphicLayer)
                wxsUrl.searchParams.set('VERSION', '1.1.0')
                wxsUrl.searchParams.set('SLD_BODY', sld)
                return of(wxsUrl.toString())
              default:
                return of('')
            }
          }),
          // this will append a parameter to force labels on single-rule (BOC views only)
          map((legendUrl) => {
            if (!legendUrl || !isLayerInternal(this.layer)) return legendUrl
            const urlObj = new URL(legendUrl)
            urlObj.searchParams.set('LEGEND_OPTIONS', 'forceLabels:on')
            return urlObj.toString()
          })
        )

    const featureCount$ = isLayerExternalWFS(this.layer)
      ? this.dataSchemaService
          .queryFeatureCount(
            getViewServiceUrl(this.view),
            this.view.layerName,
            this.view.serviceVersion
          )
          .pipe(catchError(() => of(-1)))
      : of(0)

    this.canEditThematicStyle$ = featureCount$.pipe(
      map(
        (featureCount) =>
          featureCount >= 0 && featureCount <= this.wfsThematicMaxFeatures
      )
    )
    this.canEditThematicStyleTitle$ = this.canEditThematicStyle$.pipe(
      withLatestFrom(featureCount$),
      map(([allowed, featureCount]) => {
        if (!allowed && featureCount >= 0)
          return `Le nombre d'objets est trop important pour réaliser une analyse thématique sur cette couche.`
        else if (!allowed)
          return `Une erreur est survenue lors du chargement du nombre d'objet.`
        else return ''
      }),
      startWith(`Chargement du nombre d'objets en cours...`)
    )

    this.sub.add(
      this.opacityEmitter
        .pipe(throttleTime(50, undefined, { leading: true, trailing: true }))
        .subscribe((opacity) =>
          this.facade.setLayerOpacity({
            id: this.layer.id,
            opacity,
          })
        )
    )
    this.sub.add(
      this.title.valueChanges.subscribe((title) => {
        const patch = {
          id: this.layer.id,
          title,
        }
        if (this.isGroup()) {
          this.facade.updateGroup(patch)
        } else {
          this.facade.setLayerAttributes(patch)
        }
      })
    )
    this.sub.add(
      this.titleEditable$
        .pipe(distinctUntilChanged(), filter(Boolean), delay(1))
        .subscribe(() => {
          if (!this.layertitle) {
            return
          }
          this.layertitle.nativeElement.focus({ preventScroll: true })
          this.rootEl.nativeElement.scrollIntoView({
            block: 'end',
            behavior: 'smooth',
          })
        })
    )
    this.canEditStyle = this.canEditStyleFn()
    this.isLayerExternal = isLayerExternal(this.layer)
    this.isLayerQgis = isLayerQgis(this.layer)
    this.hasPublicationStatus =
      isLayerInternal(this.layer) && !isLayerQgis(this.layer)

    this.publicationStatus$ = this.facade.getPublicationStatusByLayerId(
      this.layer.id
    )
    this.hasSpecError = hasMapElementErrors(this.layer)

    const olLayerLoadingObservable$ =
      this.olService.getLayerLoadingStatusObservable(this.layer.id)

    if (olLayerLoadingObservable$) {
      this.sub.add(
        olLayerLoadingObservable$
          .pipe(distinctUntilChanged())
          .subscribe((response) => (this.imageLoadResponse = response))
      )
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  toggleActive() {
    if (this.isGroup()) {
      this.groupExpanded = !this.groupExpanded
      this.facade.setGroupFolding({
        id: this.layer.id,
        collapsed: !this.groupExpanded,
      })
    } else {
      this.eventService_.setActiveLayerId(this.layer.id)
    }
  }

  setVisibility(visible?: boolean) {
    const payload = {
      id: this.layer.id,
      visible,
    }
    if (this.isGroup()) {
      this.facade.setGroupVisibility(payload)
    } else {
      this.facade.setLayerVisibility(payload)
    }
  }

  remove() {
    this.isGroup() ? this.removeAsGroup() : this.removeAsLayer()
  }

  removeAsLayer() {
    this.dialogsService
      .confirm(
        'Confirmation',
        `Êtes-vous sûr de vouloir supprimer "${this.layer.title}" ?`
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.facade.removeLayer(this.layer.id)
        }
      })
  }

  removeAsGroup() {
    this.facade
      .getMapElementDescendantsCount(this.layer.id)
      .subscribe((counts) => {
        const { layerCount, groupCount } = counts

        const cascadeDelete = layerCount > 0 || groupCount > 0
        const layerWarning =
          layerCount && `${layerCount} couche${layerCount > 1 ? 's' : ''}`
        const groupWarning =
          groupCount && `${groupCount} groupe${groupCount > 1 ? 's' : ''}`
        const allWarning =
          layerCount && groupCount
            ? `${layerWarning} et ${groupWarning}`
            : layerWarning || groupWarning

        const message =
          `Êtes-vous sûr de vouloir supprimer "${this.layer.title}" ?` +
          (cascadeDelete
            ? `
<div class="alert alert-warning mt-3 d-flex flex-row py-2 px-3 align-items-center">
  <span class="fa fa-exclamation-triangle mr-3"></span>
  <div><strong>Attention :</strong> cette action entraînera également la suppression de ${allWarning}.</div>
</div>`
            : '')

        this.dialogsService
          .confirm('Confirmation', message)
          .subscribe((confirmed) => {
            if (confirmed) {
              this.facade.removeGroup(this.layer.id)
            }
          })
      })
      .unsubscribe()
  }

  editStyle() {
    this.eventService_.editStyle(this.view.id)
  }

  editLayer() {
    this.eventService_.editLayer(this.layer)
  }

  removeStyle() {
    this.dialogsService
      .confirm(
        'Confirmation',
        `Êtes-vous sûrs de vouloir supprimer le style global de la couche "${this.layer.title}" ?`
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.eventService_.removeStyle(this.view.id)
        }
      })
  }

  createStyle() {
    const viewId = this.view.id
    const geometryType = this.view.dataSourceGeometryType
    this.eventService_.createStyle(viewId, geometryType)
  }

  associateJdd() {
    const viewId = this.view.id
    this.eventService_.associateJdd(viewId, this.layer as Layer)
  }

  editAttributes() {
    this.eventService_.editAttributes(this.layer)
  }

  openThematicStyling() {
    this.eventService_.openThematicStyling(this.view.id)
  }

  editTitle() {
    this.eventService_.editTitle(this.layer.id)
  }

  commitNewTitle() {
    this.eventService_.editTitle(null)
  }

  context() {
    return {
      remove: this.remove.bind(this),
      editTitle: this.editTitle.bind(this),
    }
  }

  isOutOfRange(scaleDenominator): boolean {
    if (
      !this.layer ||
      !('views' in this.layer) ||
      !this.layer.views ||
      !this.layer.views.length
    ) {
      return false
    }
    const minScale = this.viewUtilsService.getLayerViewMinScaleDenominator(
      this.view
    )
    const maxScale = this.viewUtilsService.getLayerViewMaxScaleDenominator(
      this.view
    )
    return (
      (maxScale ? maxScale < scaleDenominator : false) ||
      (minScale ? minScale > scaleDenominator : false)
    )
  }

  canEditStyleFn() {
    return (
      !this.isGroup() &&
      !isRasterSource(this.view) &&
      !isLayerExternalRaster(this.layer) &&
      !isLayerQgis(this.layer)
    )
  }

  isGroup() {
    return isMapElementGroup(this.layer)
  }

  hasError() {
    return '_error' in this.layer && this.layer._error
  }

  hasLoadingError() {
    return this.imageLoadResponse !== undefined
  }

  getLoadingErrorText() {
    let text: string

    switch (this.imageLoadResponse) {
      case undefined:
        text = undefined
        break
      case IMAGE_WMS_LOAD_ERROR.NotFound404:
        text = 'Erreur de chargement de la couche (404)'
        break
      case IMAGE_WMS_LOAD_ERROR.GatewayTimeout504:
        text = 'Impossible de charger la couche dans le temps imparti (504)'
        break
      case IMAGE_WMS_LOAD_ERROR.GeoserverErrorMissingData:
        text = 'Jeu de données associé à la couche non trouvé'
        break
      case IMAGE_WMS_LOAD_ERROR.GeoserverErrorTimeout:
        text = "Impossible d'afficher la couche dans le temps imparti"
        break
      case IMAGE_WMS_LOAD_ERROR.GeoserverErrorStyle:
        text = "Le style n'est pas applicable au jeu de données"
        break
      case IMAGE_WMS_LOAD_ERROR.ErrorDefault200:
      default:
        text = 'Erreur technique'
    }

    return text
  }

  get typeLabel() {
    return this.isGroup() ? 'le groupe' : 'la couche'
  }

  addLayerGroup() {
    this.eventService_.addChildLayerGroup(this.layer.id)
  }

  addLayer() {
    this.eventService_.addChildLayer(this.layer.id)
  }

  canDuplicate() {
    return !this.isGroup() && !isLayerQgis(this.layer)
  }

  duplicate() {
    this.eventService_.duplicateLayer(this.layer)
  }

  handleDragOver(event) {
    event.preventDefault()
  }

  handleDragEnter() {
    if (this.canReceiveStyleDrop()) {
      this.counterDragEnter++
      this.isDraggedOver = true
    }
  }

  handleDragLeave() {
    if (this.canReceiveStyleDrop()) {
      this.counterDragEnter--
      if (this.counterDragEnter === 0) {
        this.isDraggedOver = false
      }
    }
  }

  canReceiveStyleDrop() {
    return !(
      this.view.originType === 'REMOTE' &&
      (this.view.serviceType === 'WMS' || this.view.serviceType === 'WMTS')
    )
  }

  handleDrop(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.canReceiveStyleDrop()) {
      this.counterDragEnter = 0
      this.isDraggedOver = false

      const styleTreeItem: TreeItemModel = JSON.parse(
        event.dataTransfer.getData('text/plain')
      )

      if (this.hasStyle) {
        this.dialogsService
          .confirm(
            'Confirmation',
            `Êtes-vous sûrs de vouloir remplacer le style global de la couche "${this.layer.title}" ?`
          )
          .subscribe((confirmed) => {
            if (confirmed) {
              this.eventService_.duplicateAndApplyStyle(
                this.layer.id,
                this.view.id,
                styleTreeItem.contentId
              )
            }
          })
      } else {
        this.eventService_.duplicateAndApplyStyle(
          this.layer.id,
          this.view.id,
          styleTreeItem.contentId
        )
      }
    }
  }

  /**
   * error on load img from the legend
   * if never failed, getLegendGraphic failed so try to get it from getCap
   * if already failed, load fallback img
   * @param $event
   */
  fallbackLegendGraphic() {
    if (!this.legendNextFallback) {
      this.legendNextFallback = true
      this.legendUrl$ = this.utilsService.queryGetCapabilities(this.view).pipe(
        map((capabilitiesResponse) => {
          const legendUrl = this.utilsService.getWxsLayerLegendUrl(
            capabilitiesResponse,
            this.view.layerName,
            this.view.serviceType
          )
          if (!legendUrl) {
            return 'assets/img/LEGEND.png'
          }
          return legendUrl
        })
      )
    } else {
      this.legendUrl$ = of('assets/img/LEGEND.png')
    }
  }
}
