<div class="modal-header">
  <h5 class="modal-title pull-left">Métadonnées de publication</h5>
</div>
<alert *ngIf="errorMsg" type="danger"> {{ errorMsg }} </alert>
<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-body pb-0">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label
              >Mots clés
              <ui-icon-tooltip
                tooltip="Mots clés décrivant la carte séparés par une virgule ','."
              ></ui-icon-tooltip>
              <input
                type="text"
                class="form-control"
                placeholder="mot-clé 1, mot-clé 2,..."
                formControlName="keywords"
              />
            </label>
          </div>
          <div class="form-group">
            <label
              >Email du contact
              <input
                type="email"
                class="form-control"
                formControlName="contactEmail"
                [ngClass]="{ 'is-invalid': f.contactEmail.errors }"
              />
            </label>
          </div>
          <div class="form-group" *ngIf="!hasConstraintsList">
            <alert type="danger">
              La liste des contraintes n'a pas pu être chargée.
            </alert>
          </div>
          <div class="form-group" *ngIf="hasConstraintsList">
            <label
              >Contraintes
              <select class="form-control" formControlName="accessConstraints">
                <option
                  *ngFor="let constraint of constraintsList"
                  [value]="constraint"
                >
                  {{ constraint }}
                </option>
              </select>
            </label>
          </div>
          <div class="form-group" *ngIf="otherConstraintVisible$ | async">
            <label
              >Autre contrainte:
              <textarea
                rows="5"
                formControlName="otherConstraintDetail"
                class="form-control"
              ></textarea>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Annuler
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="editForm.invalid">
      Enregistrer
    </button>
  </div>
</form>
