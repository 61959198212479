import { EventEmitter, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor() {}

  hasSpecErrors$ = new BehaviorSubject<boolean>(false)
  warnOnDuplicatedStyleError$ = new EventEmitter<void>()

  hasSpecErrors(hasError: boolean) {
    this.hasSpecErrors$.next(hasError)
  }

  warnOnDuplicatedStyleError(): void {
    this.warnOnDuplicatedStyleError$.emit()
  }
}
