import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { StyleManagementService } from '@ui/data-access-carto-map-production'
import * as fromMapState from '@ui/feature/mapstate'
import { MapFacade } from '@ui/feature/mapstate'
import {
  DataSchemaService,
  GeoserverSldStyleParser as SldStyleParser,
  getViewIsVector,
  getViewWfsUrl,
  getViewWfsVersion,
} from '@ui/feature/shared'
import { DialogsService } from '@ui/ui/layout'
import { ResourceFacade } from '@ui/ui/resources'
import { combineLatest, EMPTY, from, merge, of } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'

import * as notificationAction from '../actions/notification.action'
import * as styleAction from '../actions/style.action'
import { RemoveStyle, SetDataSchema } from '../actions/style.action'
import { StyleFacade } from '../facade'

@Injectable()
export class StyleEffects {
  sldParser: SldStyleParser

  constructor(
    private actions$: Actions,
    private styleManagement_: StyleManagementService,
    private facade: StyleFacade,
    private mapFacade: MapFacade,
    private schemaService: DataSchemaService,
    private resourceFacade: ResourceFacade,
    private dialogsService: DialogsService
  ) {
    this.sldParser = new SldStyleParser()
  }

  createStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(styleAction.CREATE_STYLE),
      map((action: styleAction.CreateStyle) => action.payload),
      switchMap(({ viewId, style }) => {
        return from(this.sldParser.writeStyle(style)).pipe(
          map(({ output: sld }) => ({ viewId, style, sld }))
        )
      }),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([{ viewId, style, sld }, mapSpec]) =>
        this.styleManagement_
          .createStyleForLayerView(mapSpec.id, viewId, {
            name: style.name,
            geoStyle: JSON.stringify(style),
            sld,
          })
          .pipe(
            mergeMap((createdStyle) => {
              const parsedStyle = JSON.parse(createdStyle.geoStyle)
              return [
                new styleAction.CreateStyleSuccess(parsedStyle),
                new styleAction.SetCurrentStyle(viewId),
                new fromMapState.UpdateVectorStyle({
                  viewId,
                  style: parsedStyle,
                }),
                new fromMapState.SetLayerViewStyle(viewId, createdStyle.id),
                new fromMapState.RefreshLayer(viewId),
                new notificationAction.AddNotification({
                  message: 'Nouveau style enregistr\u00E9',
                  type: 'success',
                  timeout: 2000,
                  id: Date.now(),
                }),
              ]
            }),
            catchError((error) =>
              of(
                new styleAction.CreateStyleFail({
                  message: error,
                }),
                new notificationAction.AddNotification({
                  message: 'La cr\u00E9ation du style a \u00E9chou\u00E9',
                  type: 'danger',
                  timeout: 5000,
                  id: Date.now(),
                })
              )
            )
          )
      )
    )
  )

  removeStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(styleAction.REMOVE_STYLE),
      map((action: RemoveStyle) => action.viewId),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([viewId, mapSpec]) =>
        this.styleManagement_.deleteStyle(mapSpec.id, viewId).pipe(
          mergeMap(() => [
            new styleAction.RemoveStyleSuccess(),
            new fromMapState.RemoveVectorStyle(viewId),
            new fromMapState.ClearLayerViewStyle(viewId),
            new fromMapState.RefreshLayer(viewId),
          ]),
          catchError((error) =>
            of(
              new styleAction.RemoveStyleFail({
                message: error,
              })
            )
          )
        )
      )
    )
  )

  saveStyle$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(styleAction.SAVE_STYLE)),
      this.facade.currentStyle$.pipe(filter((style) => !!style)),
    ]).pipe(
      distinctUntilChanged(
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        ([action, style], [prevAction, prevStyle]) => action === prevAction
      ),
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      map(([action, style]) => style),
      withLatestFrom(this.facade.currentViewId$),
      switchMap(([style, viewId]) => {
        return from(this.sldParser.writeStyle(style)).pipe(
          map(({ output: sld }) => ({ viewId, style, sld }))
        )
      }),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([{ style, sld, viewId }, mapSpec]) =>
        this.styleManagement_
          .createStyleForLayerView(mapSpec.id, viewId, {
            name: style.name,
            geoStyle: JSON.stringify(style),
            sld: sld,
          })
          .pipe(
            mergeMap((createdStyle) => [
              new styleAction.SaveStyleSuccess(),
              new fromMapState.RefreshLayer(viewId),
              ...(createdStyle !== null
                ? [new fromMapState.SetLayerViewStyle(viewId, createdStyle.id)]
                : []),
              new notificationAction.AddNotification({
                message: 'Le style a été sauvegardé',
                type: 'success',
                timeout: 2000,
                id: Date.now(),
              }),
            ]),
            catchError((error) =>
              of(
                new styleAction.SaveStyleFail({
                  message: error,
                }),
                new notificationAction.AddNotification({
                  message: "L'enregistrement du style a échoué",
                  type: 'danger',
                  timeout: 5000,
                  id: Date.now(),
                })
              )
            )
          )
      )
    )
  )

  /**
   * This effect requests loading the new style when the current layerview id changes in the state
   */
  triggerNewStyleLoad$ = createEffect(() =>
    this.actions$
      .pipe(ofType<styleAction.SetCurrentStyle>(styleAction.SET_CURRENT_STYLE))
      .pipe(map((action) => new styleAction.LoadStyle(action.viewId)))
  )

  /**
   * This effect actually loads the style
   */
  loadStyle$ = createEffect(() =>
    this.actions$
      .pipe(ofType<styleAction.LoadStyle>(styleAction.LOAD_STYLE))
      .pipe(
        withLatestFrom(this.mapFacade.info$.pipe(map((info) => info.id))),
        switchMap(([action, mapId]) =>
          this.styleManagement_.getStyleBody(mapId, action.viewId).pipe(
            map(
              (response) =>
                new styleAction.LoadStyleSuccess(JSON.parse(response.geoStyle))
            ),
            catchError((error) =>
              of(new styleAction.LoadStyleFail({ message: error.toString() }))
            )
          )
        )
      )
  )

  // this emits the last opened viewid
  viewIdWhenPanelOpened$ = this.actions$.pipe(
    ofType<styleAction.SetCurrentStyle>(
      styleAction.SET_CURRENT_STYLE,
      styleAction.SET_CURRENT_THEMATIC
    ),
    map((action) => action.viewId)
  )

  // this emits the initial vector style in the editor panel
  styleWhenPanelOpened$ = merge(
    this.viewIdWhenPanelOpened$.pipe(
      switchMap((viewId) =>
        this.mapFacade.getStyleByViewId(viewId).pipe(take(1))
      )
    ),
    this.actions$.pipe(
      ofType<styleAction.LoadStyleSuccess>(styleAction.LOAD_STYLE_SUCCESS),
      map((action) => action.payload)
    )
  )

  /**
   * This effect handles the case where the user starts editing a style but
   * then presses cancel; the style of the layer in the map then has to be
   * restored to its state before the editing started
   * (for vector layer views only)
   */
  restoreStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(styleAction.CLEAR_CURRENT_STYLE),
      withLatestFrom(this.styleWhenPanelOpened$, this.viewIdWhenPanelOpened$),
      switchMap(([, style, viewId]) =>
        this.mapFacade.getViewById(viewId).pipe(
          take(1),
          filter(getViewIsVector),
          map((view) =>
            style
              ? new fromMapState.UpdateVectorStyle({
                  viewId: view.id,
                  style,
                })
              : new fromMapState.RemoveVectorStyle(view.id)
          )
        )
      )
    )
  )

  /**
   * This effect will load the data schema for the current layer view
   * This happens when the view id changes (so whether in style editor or
   * thematic editor)
   */
  loadSchema$ = createEffect(() =>
    this.facade.currentViewId$.pipe(
      distinctUntilChanged(),
      filter((viewId) => viewId !== undefined),
      switchMap((viewId) => this.mapFacade.getViewById(viewId)),
      switchMap((view) => {
        return this.schemaService
          .queryDataSchema(
            getViewWfsUrl(view),
            view.layerName,
            getViewWfsVersion(view)
          )
          .pipe(
            map((schema) => new SetDataSchema(schema)),
            catchError((error) =>
              of(
                new SetDataSchema({
                  error,
                })
              )
            )
          )
      })
    )
  )

  duplicateAndApplyStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(styleAction.DUPLICATE_APPLY_STYLE),
      withLatestFrom(this.mapFacade.info$),
      switchMap(([{ layerId, viewId, styleId }, mapSpec]) =>
        this.styleManagement_
          .canDuplicateStyleForLayerViewWithNoSupervision(
            mapSpec.id,
            viewId,
            styleId
          )
          .pipe(
            switchMap(({ status: canDuplicate }) => {
              if (!canDuplicate) {
                return this.dialogsService.confirm(
                  'Confirmation',
                  `Les ressources d'origine et de destination sont différentes.
Le style pourra demander une reconfiguration s'il exploite les données attributaires.`
                )
              } else {
                return of(true)
              }
            }),
            switchMap((confirmed) => {
              if (confirmed) {
                return this.styleManagement_.duplicateStyleForLayerView(
                  mapSpec.id,
                  viewId,
                  styleId
                )
              } else {
                return EMPTY
              }
            }),
            mergeMap((style) => [
              new styleAction.DuplicateAndApplyStyleSuccess(),
              new fromMapState.UpdateLayerOnDuplicatedStyle(
                layerId,
                viewId,
                style.id
              ),
              new fromMapState.RefreshLayer(viewId),
              new notificationAction.AddNotification({
                message: 'Le style a été dupliqué et appliqué',
                type: 'success',
                timeout: 2000,
                id: Date.now(),
              }),
            ]),
            catchError((error) =>
              of(
                new styleAction.DuplicateAndApplyStyleFail({
                  message: error,
                }),
                new notificationAction.AddNotification({
                  message: "La duplication et l'application du style a échoué",
                  type: 'danger',
                  timeout: 5000,
                  id: Date.now(),
                })
              )
            )
          )
      )
    )
  )

  /**
   * This effect will ask for a refresh of the styles list in the
   * resource manager when a style is created/updated/deleted
   */
  updateStylesInResourceManager$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          styleAction.CREATE_STYLE_SUCCESS,
          styleAction.REMOVE_STYLE_SUCCESS,
          styleAction.SAVE_STYLE_SUCCESS,
          styleAction.DUPLICATE_APPLY_STYLE_SUCCESS
        ),
        withLatestFrom(this.mapFacade.info$),
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        tap(([_, mapInfo]) => {
          this.resourceFacade.refreshMapResources(mapInfo.id)
        })
      ),
    { dispatch: false }
  )
}
