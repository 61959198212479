<div class="position-relative pr-1 pt-1 mr-2" [title]="tooltip">
  <span class="fa fa-bookmark"></span>
  <span
    *ngIf="isNotPublished"
    class="fa fa-eye-slash text-black-50 icon-overlay icon-not-published"
  ></span>
  <span
    *ngIf="isPublished"
    class="fa fa-eye text-black-50 icon-overlay icon-published"
  ></span>
  <span
    *ngIf="isPublishedError"
    class="fa fa-exclamation-circle text-danger icon-overlay icon-published-error"
  ></span>
  <span
    *ngIf="isPublishedSuccess"
    class="fa fa-check text-success icon-overlay icon-published-success"
  ></span>
</div>
