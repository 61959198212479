import { Injectable } from '@angular/core'
import {
  LayerModel,
  MapCompositionService,
} from '@ui/data-access-carto-map-production'
import { MapFacade } from '@ui/feature/mapstate'
import { BehaviorSubject } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'
import { NotificationFacade } from '../store'
import { DialogsService } from '@ui/ui/layout'
import { ModalEventService } from './modal-event.service'
import { SseService } from '@ui/data-access-carto-async'

@Injectable({
  providedIn: 'root',
})
export class RepairMapService {
  repairLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  cleanLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  mapId: string
  repairProcessHandle: string
  irreparableLayers: LayerModel[]
  uncleanableLayers: LayerModel[]
  mapCleaningProcessHandle: string

  constructor(
    private mapFacade_: MapFacade,
    private notificationFacade_: NotificationFacade,
    private mapComposition_: MapCompositionService,
    private dialogsService: DialogsService,
    public modalEventService_: ModalEventService,
    private sseService_: SseService
  ) {}

  repairMap() {
    return this.mapFacade_.info$
      .pipe(
        take(1),
        switchMap((info) => {
          this.mapId = info.id
          this.repairLoading$.next(true)
          return this.sseService_.wrapApiCall('END_REPAIR', () =>
            this.mapComposition_.repairMap(info.id)
          )
        })
      )
      .subscribe({
        next: (message) => {
          this.repairLoading$.next(false)
          this.mapCleaningProcessHandle = message.processHandle
          this.handleSuccess('repair', message.irreparableLayers)
        },
        error: (error) => {
          this.handleError(error)
        },
      })
  }

  handleError(error) {
    this.repairLoading$.next(false)
    this.cleanLoading$.next(false)
    this.notificationFacade_.showNotification(
      'danger',
      error.message || 'Une erreur inconnue est survenue',
      null
    )
  }

  handleSuccess(processName: 'repair' | 'clean', errorLayers: LayerModel[]) {
    if (errorLayers.length === 0 || !errorLayers) {
      if (processName === 'repair') {
        this.showSuccessNotification(
          'La carte a bien été réparée et va se recharger automatiquement.'
        )
      } else if (processName === 'clean') {
        this.showSuccessNotification(
          'Les couches corrompues ont bien été supprimées. La carte va se recharger automatiquement.'
        )
      }
      setTimeout(() => location.reload(), 4000)
    } else {
      if (processName === 'repair') {
        this.irreparableLayers = errorLayers
        this.modalEventService_.openIrreparableLayersModal()
      } else if (processName === 'clean') {
        this.uncleanableLayers = errorLayers
        this.modalEventService_.openUncleanableLayersModal()
      }
    }
  }

  cleanMap() {
    this.dialogsService
      .confirm(
        'Confirmation',
        'Êtes vous sûr.e de vouloir supprimer les couches non réparables ? Cette action est définitive.'
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.cleanLoading$.next(true)
          this.sseService_
            .wrapApiCall('END_REPAIR', () =>
              this.mapComposition_.cleanIrreparable(
                this.mapId,
                this.mapCleaningProcessHandle
              )
            )
            .subscribe({
              next: (message) => {
                this.cleanLoading$.next(false)
                this.handleSuccess('clean', message.uncleanableLayers)
              },
              error: (error) => {
                this.handleError(error)
              },
            })
        } else {
          this.modalEventService_.openIrreparableLayersModal()
        }
      })
  }

  showSuccessNotification(message: string) {
    this.notificationFacade_.showNotification('success', message, 4000)
  }

  getIrreparableLayers() {
    return this.irreparableLayers
  }

  getUncleanableLayers() {
    return this.uncleanableLayers
  }

  getProcessHandle() {
    return this.repairProcessHandle
  }

  getMapId() {
    return this.mapId
  }
}
