import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DataSchema } from '@ui/feature/shared'
import { TextSymbolizer } from '../../style.model'
import { SYMBOLIZER_FIELD_TYPE } from '../../symbolizer-field/symbolizer-field.component'

@Component({
  selector: 'ui-text-symbolizer',
  templateUrl: './text-symbolizer.component.html',
  styleUrls: ['./text-symbolizer.component.scss'],
})
export class TextSymbolizerComponent implements OnInit {
  defaultSymbolizer: TextSymbolizer
  @Input() symbolizer: TextSymbolizer
  @Input() schema: DataSchema
  @Output() symbolizerUpdated = new EventEmitter<object>()

  fieldTypes = SYMBOLIZER_FIELD_TYPE
  constructor() {}

  ngOnInit() {
    this.defaultSymbolizer = this.symbolizer
  }

  handlePropChange(name, value) {
    const changes = { [name]: value }
    if (name === 'haloWidth') {
      if (value === 0) {
        changes['haloColor'] = undefined
      } else if (!this.symbolizer.haloColor) {
        changes['haloColor'] = this.defaultSymbolizer.haloColor
      }
    }
    this.symbolizerUpdated.emit(changes)
  }
}
