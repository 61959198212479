<div class="modal-header">
  <h5 class="modal-title pull-left">
    {{ creation ? 'Ajouter' : 'Modifier' }} un serveur OGC
  </h5>
</div>
<alert *ngIf="errorMsg" type="danger"> {{ errorMsg }} </alert>
<alert *ngIf="f.serverVersion.errors?.msg" type="danger">
  {{ f.serverVersion.errors.msg }}
</alert>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label
        >Nom
        <input
          type="text"
          class="form-control"
          formControlName="serverName"
          [ngClass]="{ 'is-invalid': f.serverName.errors }"
        />
      </label>
    </div>
    <div class="form-group">
      <label
        >URL
        <input
          type="url"
          class="form-control"
          formControlName="serverURL"
          [ngClass]="{ 'is-invalid': f.serverURL.errors }"
          (change)="clearVersion()"
        />
      </label>
    </div>
    <div class="form-group">
      <label
        >Type
        <select
          class="custom-select"
          formControlName="serverType"
          [ngClass]="{ 'is-invalid': f.serverType.errors }"
          (change)="clearVersion()"
        >
          <option *ngFor="let type of serverTypes" [ngValue]="type">
            {{ type }}
          </option>
        </select>
      </label>
    </div>
    <div class="form-group">
      <label
        >Version
        <div class="version-group">
          <input
            type="text"
            class="form-control"
            formControlName="serverVersion"
            [ngClass]="{ 'is-invalid': f.serverVersion.errors }"
          />
          <button
            type="button"
            class="btn btn-secondary"
            (click)="requestVersion()"
            [disabled]="isRequestDisabled()"
          >
            Récupérer
          </button>
        </div>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Annuler
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="form.invalid">
      {{ creation ? 'Créer' : 'Modifier' }}
    </button>
  </div>
</form>
