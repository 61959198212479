import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'
import {
  MapCompositionService,
  MapBriefModel,
} from '@ui/data-access-carto-map-production'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { catchError, mergeMap } from 'rxjs/operators'
import { MAP_PRODUCTION_ROUTE } from '../../../routes'
import { RouterFacade } from '../../../store/facade'
import { OpenMapModalComponent } from './open-map-modal/open-map-modal.component'
import { Observable, of, Subscription } from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http'

export interface CreateMapFormContent {
  title: string
  name: string
  description: string
}

@Component({
  selector: 'ui-open-map',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<void>
  modalRef: BsModalRef
  sub = new Subscription()

  constructor(
    public routerFacade_: RouterFacade,
    private modalService_: BsModalService,
    private mapComposition_: MapCompositionService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$
        .pipe(
          mergeMap(() =>
            this.mapComposition_
              .findMaps(0, 500)
              .pipe(catchError((error) => of(error)))
          )
        )

        .subscribe((response) => {
          this.openModal()

          if (Array.isArray(response)) {
            this.modalRef.content.mapList = response
          } else if (response instanceof HttpErrorResponse) {
            this.dispatchError(response.message)
          }
        })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  openModal() {
    this.modalRef = this.modalService_.show(OpenMapModalComponent, {
      ignoreBackdropClick: true,
    })

    this.modalRef.content.save.subscribe((formValues: CreateMapFormContent) =>
      this.openMap(formValues)
    )
  }

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  dispatchError(error: string): void {
    const message =
      'Une erreur est survenue lors du chargement de la liste des cartes'
    this.modalRef.content.errorMsg = message
  }

  openMap(mapResponse: MapBriefModel) {
    const { id } = mapResponse
    this.routerFacade_.go({
      path: [`${MAP_PRODUCTION_ROUTE}/${id}`],
    })
  }
}
