import * as fromNgrxRouter from '@ngrx/router-store'
import { ActionReducerMap } from '@ngrx/store'

import * as fromRouter from './router.reducer'
import * as fromStyle from './style.reducer'
import * as fromNotification from './notification.reducer'
import * as fromUi from './ui.reducer'
import * as fromLoading from './loading.reducer'

export interface State {
  router: fromNgrxRouter.RouterReducerState<fromRouter.RouterStateUrl>
  style: fromStyle.AppStyleState
  notification: fromNotification.AppNotificationState
  ui: fromUi.UiState
  loading: fromLoading.LoadingState
}

export const reducers: ActionReducerMap<State> = {
  router: fromNgrxRouter.routerReducer,
  style: fromStyle.reducer,
  notification: fromNotification.reducer,
  ui: fromUi.reducer,
  loading: fromLoading.reducer,
}

export * from './router.reducer'
export { AppStyleState } from './style.reducer'
export { AppNotificationState } from './notification.reducer'
export { UiState, UI_FEATURE_KEY } from './ui.reducer'
export { LoadingState } from './loading.reducer'
