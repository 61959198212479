<alert
  class="schema-loading"
  type="info"
  *ngIf="!hasSchemaError()"
  [collapse]="!!dataSchema"
  [isAnimated]="true"
>
  <span class="fa fa-spinner fa-spin"></span> Chargement du schéma de donnée...
</alert>
<alert class="schema-error" type="danger" *ngIf="hasSchemaError()">
  Une erreur est survenue lors du chargement du schéma de donnée.
</alert>

<div class="form-group px-3 pt-3">
  <input
    type="text"
    class="form-control name-input"
    [value]="style.name"
    (input)="handleNameChange($event.target.value)"
  />
</div>
<div class="form-group px-3 row">
  <label class="col-sm-4 col-form-label text-muted">Géométrie</label>
  <div class="col-sm-8">
    <select
      class="form-control form-control-sm geometry-type"
      [(ngModel)]="geometryType"
    >
      <option [value]="elementTypes.POLYGON">Polygone</option>
      <option [value]="elementTypes.LINE">Ligne</option>
      <option [value]="elementTypes.POINT">Point</option>
    </select>
  </div>
</div>

<ul
  class="list-group list-group-flush"
  cdkDropList
  (cdkDropListDropped)="
    handleRuleOrderChange(
      rules.length - 1 - $event.item.data,
      rules.length - 1 - $event.currentIndex
    )
  "
>
  <ui-style-editor-rule
    class="list-group-item list-group-item-action p-0"
    *ngFor="let rule of rules | reverse; index as i; trackBy: ruleTracker"
    [rule]="rule"
    [index]="i"
    [active]="activeRule === rule._id"
    [deletable]="rules.length > 1"
    cdkDrag
    [cdkDragData]="i"
    [layerView]="layerView"
    (toggled)="toggleActiveRule(rule._id)"
    (changed)="handleRuleChange(getIndex(rule), $event)"
    (deleted)="handleRuleDelete(getIndex(rule))"
    [elementType]="style._elementType"
    [dataSchema]="dataSchema"
    [activeImageGeometryPolygon]="activeImageGeometryPolygon"
  ></ui-style-editor-rule>
</ul>
<ul class="list-group list-group-flush">
  <li class="list-group-item">
    <button class="btn btn-outline-primary btn-sm" (click)="handleRuleAdd()">
      <i class="fa fa-plus-circle"></i> Ajouter une règle
    </button>
  </li>
</ul>
