import { Injectable } from '@angular/core'
import { from, Observable, of } from 'rxjs'
import { JSONSchema4TypeName } from 'json-schema'
import { catchError, map, switchMap } from 'rxjs/operators'
import { parseStringPromise } from 'xml2js'
import { SchemaProperty } from 'geostyler-data'
import { HttpClient } from '@angular/common/http'
import { ProxyService } from './proxy.service'
import { DataSchema } from '../model'
import { get } from 'lodash'
import {
  buildWfsDescribeFeatureTypeUrl,
  buildWfsGetFeatureUrl,
} from '../helpers/ogc.helpers'

@Injectable({
  providedIn: 'root',
})
export class DataSchemaService {
  constructor(
    private http: HttpClient,
    private proxy: ProxyService
  ) {}

  /**
   * Map XSD datatypes (SimpleType) to json schema data stypes
   *
   * @param qualifiedXsdDataType The XSD datatype as string (including namespace)
   * @returns {JSONSchema4TypeName} The corresponding JSON schema type name
   */
  mapXsdTypeToJsonDataType(qualifiedXsdDataType: string): JSONSchema4TypeName {
    const xsdDataType =
      qualifiedXsdDataType.indexOf(':') > -1
        ? qualifiedXsdDataType.split(':')[1]
        : qualifiedXsdDataType

    if (qualifiedXsdDataType.startsWith('gml:')) {
      return 'object'
    }

    switch (xsdDataType) {
      case 'string':
        return 'string'
      case 'boolean':
        return 'boolean'
      case 'float':
      case 'double':
      case 'long':
      case 'byte':
      case 'decimal':
      case 'integer':
      case 'int':
      case 'positiveInteger':
      case 'negativeInteger':
      case 'nonPositiveInteger':
      case 'nonNegativeInteger':
      case 'short':
      case 'unsignedLong':
      case 'unsignedInt':
      case 'unsignedShort':
      case 'unsignedByte':
        return 'number'
      default:
        return 'string'
    }
  }

  queryDataSchema(
    serviceUrl: string,
    featureType: string,
    serviceVersion = '1.1.0'
  ): Observable<DataSchema> {
    const url = buildWfsDescribeFeatureTypeUrl(
      serviceUrl,
      featureType,
      serviceVersion
    )

    if (url === null) {
      return of({
        error: 'Pas de service WFS disponible pour cette vue',
      })
    }

    const parseOptions = {
      tagNameProcessors: [
        (name: string) => {
          const prefixMatch = new RegExp(/(?!xmlns)^.*:/)
          return name.replace(prefixMatch, '')
        },
      ],
    }

    return this.http
      .get(this.proxy.getProxiedUrl(url), { responseType: 'text' })
      .pipe(
        switchMap((response) =>
          from(parseStringPromise(response, parseOptions))
        ),
        map((result) => {
          let geometryName = ''
          const attributePath =
            'schema.complexType[0].complexContent[0].extension[0].sequence[0].element'
          const attributes: any = get(result, attributePath)

          const properties: { [name: string]: SchemaProperty } = {}
          attributes.forEach((attr: any) => {
            const { name, type } = get(attr, '$')
            const propertyType: SchemaProperty = {
              type: this.mapXsdTypeToJsonDataType(type),
            }
            if (propertyType.type === 'object') {
              geometryName = name
            } else {
              properties[name] = propertyType
            }
          })

          const title = get(result, 'schema.element[0].$.name')

          return {
            type: 'object',
            title,
            geometryName,
            properties,
          }
        }),
        catchError((error) => of({ error: error.message }))
      )
  }

  queryFeatureCount(
    serviceUrl: string,
    featureType: string,
    serviceVersion = '1.1.0'
  ): Observable<number> {
    let getFeatureUrl = buildWfsGetFeatureUrl({
      url: serviceUrl,
      typeName: featureType,
      version: serviceVersion,
    })
    const urlObj = new URL(getFeatureUrl)
    urlObj.searchParams.set('resulttype', 'hits')
    getFeatureUrl = urlObj.toString()

    // the regex expects either WFS 1.1 or WFS 2 format, as well as escaped quotes in the response
    const hitRegex = /(?:numberOfFeatures|numberMatched)=\\?"([0-9]*)\\?"/

    return this.http
      .get(this.proxy.getProxiedUrl(getFeatureUrl), { responseType: 'text' })
      .pipe(
        map((response) =>
          hitRegex.test(response)
            ? parseInt(hitRegex.exec(response)[1], 10)
            : -1
        )
      )
  }
}
