import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import ServerTypeModelEnum = RemoteServerModel.ServerTypeModelEnum

export interface OgcLayer {
  type?: ServerTypeModelEnum
  name?: string
  title: string
  url: string
  version?: string
  layers?: OgcLayer[]
  abstract?: string
}

export interface OgcServiceContent {
  layers: OgcLayer[]
}

export enum ResourceTypeEnum {
  DATASET = 'datasets',
  SERVICE = 'services',
  STYLE = 'styles',
  GEOPLATEFORME = 'geoplateforme',
}

export interface ResourceSelectEvent {
  resourceId?: string
  layer?: OgcLayer
}
