import { Injectable } from '@angular/core'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { WMSCapabilities, WMTSCapabilities } from 'ol/format'
import { OgcLayer, OgcServiceContent } from '../model'
import ServerTypeModelEnum = RemoteServerModel.ServerTypeModelEnum

export type GetCapabilitiesFn = (
  responseText: string,
  serviceType?: RemoteServerModel.ServerTypeModelEnum
) => OgcServiceContent

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Will parse the GetCapabilities document according to the service type
   * @param responseText GetCapabilities document as full text
   * @param serviceType Service type
   * @return a JSON object representing the GetCapabilities response (TODO: add typing?)
   */
  parseGetCapabilities(
    responseText: string,
    serviceType: ServerTypeModelEnum = ServerTypeModelEnum.WMS
  ): OgcServiceContent {
    switch (serviceType) {
      case 'WFS': {
        const parser = new DOMParser()
        const xml = parser.parseFromString(responseText, 'text/xml')
        const operations = xml.querySelectorAll('[name=GetFeature] > * > * > *')
        let url: string
        for (let i = 0; i < operations.length; i++) {
          if (operations[i].localName === 'Get') {
            url = operations[i].getAttribute('xlink:href')
            break
          }
        }
        const versionNode = xml.querySelector(' ServiceTypeVersion')
        const types = xml.querySelectorAll('FeatureTypeList FeatureType')
        const layers: OgcLayer[] = []
        types.forEach((type) => {
          const layer: OgcLayer = {
            type: 'WFS',
            url,
            title: type.querySelector('Title').textContent,
          }
          if (versionNode) {
            layer.version = versionNode.textContent
          }
          if (type.querySelector('Name')) {
            layer.name = type.querySelector('Name').textContent
          }
          if (type.querySelector('Abstract')) {
            layer.abstract = type.querySelector('Abstract').textContent
          }
          layers.push(layer)
        })
        return { layers }
      }
      case 'WMS': {
        const json = new WMSCapabilities().read(responseText)
        const url =
          json.Capability.Request.GetMap.DCPType[0].HTTP.Get.OnlineResource
        const layers: OgcLayer[] = []
        const lookupNode = (node: any, array: OgcLayer[]) => {
          if (Array.isArray(node)) {
            for (const layer of node) {
              lookupNode(layer, array)
            }
          } else {
            const layer: OgcLayer = {
              title: node.Title,
              url,
              type: 'WMS',
            }
            if (node.Name) layer.name = node.Name
            if (node.Abstract) layer.abstract = node.Abstract
            array.push(layer)

            if (node.Layer) {
              layer.layers = []
              lookupNode(node.Layer, layer.layers)
            }
          }
        }
        lookupNode(json.Capability.Layer, layers)
        return { layers }
      }
      case 'WMTS': {
        const json = new WMTSCapabilities().read(responseText)
        const url = json.OperationsMetadata.GetCapabilities.DCP.HTTP.Get[0].href
        const layers: OgcLayer[] = []
        const lookupNode = (node: any, array: OgcLayer[]) => {
          if (Array.isArray(node)) {
            for (const layer of node) {
              lookupNode(layer, array)
            }
          } else {
            const layer: OgcLayer = {
              name: node.Identifier,
              title: node.Title,
              url,
              type: 'WMTS',
            }
            if (node.Abstract) layer.abstract = node.Abstract
            array.push(layer)

            if (node.Layer) {
              layer.layers = []
              lookupNode(node.Layer, layer.layers)
            }
          }
        }
        lookupNode(json.Contents.Layer, layers)
        return { layers }
      }
      default: {
        throw new Error(`Type de service invalide : ${serviceType}`)
      }
    }
  }

  parseCapabilitiesTMS(
    responseText: string,
    serviceType: ServerTypeModelEnum = ServerTypeModelEnum.WMS
  ): OgcServiceContent {
    if (serviceType !== ServerTypeModelEnum.VECTORTILES) {
      throw new Error(`Type de service invalide : ${serviceType}`)
    }

    const xml = new DOMParser().parseFromString(responseText, 'text/xml')
    const tilemapNodes = xml.querySelectorAll('TileMap[extension="pbf"]')
    const version = xml.querySelector('TileMapService').getAttribute('version')
    const layers: OgcLayer[] = Array.from(tilemapNodes).map((tilemap) => ({
      type: ServerTypeModelEnum.VECTORTILES,
      name: tilemap.getAttribute('title'),
      title: tilemap.getAttribute('title'),
      url: tilemap.getAttribute('href'),
      version,
    }))

    return { layers }
  }
}
