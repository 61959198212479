<ui-modal
  #modal
  title="Éditer les attributs de la couche"
  confirmLabel="Enregistrer"
  cancelLabel="Annuler"
  [large]="true"
  [confirmDisabled]="isFormInvalid"
  [confirmHandler]="onSubmit.bind(this)"
  class="modal"
>
  <ui-attribute-right-grid
    *ngIf="attributes"
    [attributes]="sortAttributes(attributes)"
    (labelInvalid)="attributesInvalid = $event"
  ></ui-attribute-right-grid>
</ui-modal>
