<div [formGroup]="parentForm">
  <label class="form-check form-check-label d-inline-block">
    <input
      class="form-check-input"
      type="checkbox"
      formControlName="customizableScale"
    />
    Plage de visibilité
  </label>
  <div *ngIf="isInputInvalid()" class="text-danger small mb-2">
    Pour pouvoir activer la plage de visibilité, le dénominateur de la plus
    petite échelle doit être plus grand que celui de l'échelle la plus grande.
  </div>
  <ui-min-max-scale-input
    [parentForm]="parentForm"
    [tip]="tipMin"
    [type]="MinMax.Max"
  ></ui-min-max-scale-input>
  <ui-min-max-scale-input
    [parentForm]="parentForm"
    [tip]="tipMax"
    [type]="MinMax.Min"
  ></ui-min-max-scale-input>
</div>
