import {
  CLEAR_CURRENT_STYLE,
  CREATE_STYLE,
  CREATE_STYLE_FAIL,
  CREATE_STYLE_SUCCESS,
  DUPLICATE_APPLY_STYLE,
  DUPLICATE_APPLY_STYLE_FAIL,
  DUPLICATE_APPLY_STYLE_SUCCESS,
  FINISH_THEMATIC_EDITION,
  LOAD_STYLE,
  LOAD_STYLE_FAIL,
  LOAD_STYLE_SUCCESS,
  REMOVE_STYLE,
  REMOVE_STYLE_FAIL,
  REMOVE_STYLE_SUCCESS,
  SAVE_STYLE,
  SAVE_STYLE_FAIL,
  SAVE_STYLE_SUCCESS,
  SET_CURRENT_STYLE,
  SET_CURRENT_THEMATIC,
  SET_DATA_SCHEMA,
  StyleActions,
  UPDATE_CURRENT_STYLE,
} from '../actions'
import { DataSchema } from '@ui/feature/shared'
import { Style } from '@ui/feature/styling'

export interface AppStyleState {
  viewId?: number
  error?: string
  loading: boolean
  thematic?: boolean
  schema?: DataSchema
  currentStyle?: Style
}

export const initialState: AppStyleState = {
  loading: false,
}

export function reducer(
  state = initialState,
  action: StyleActions
): AppStyleState {
  switch (action.type) {
    case CREATE_STYLE: {
      return {
        loading: true,
      }
    }
    case SAVE_STYLE:
    case REMOVE_STYLE:
    case DUPLICATE_APPLY_STYLE: {
      return {
        ...state,
        loading: true,
      }
    }
    case SAVE_STYLE_FAIL:
    case REMOVE_STYLE_FAIL:
    case DUPLICATE_APPLY_STYLE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      }
    }
    case SAVE_STYLE_SUCCESS:
    case REMOVE_STYLE_SUCCESS:
    case DUPLICATE_APPLY_STYLE_SUCCESS: {
      return {
        loading: false,
      }
    }
    case CREATE_STYLE_FAIL: {
      return {
        loading: false,
        error: action.payload.message,
      }
    }
    case CREATE_STYLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentStyle: action.payload,
      }
    }
    case SET_CURRENT_STYLE: {
      return {
        loading: false,
        viewId: action.viewId,
        thematic: false,
      }
    }
    case CLEAR_CURRENT_STYLE: {
      return {
        loading: false,
      }
    }
    case SET_CURRENT_THEMATIC: {
      return {
        loading: false,
        viewId: action.viewId,
        thematic: true,
      }
    }
    case SET_DATA_SCHEMA: {
      return {
        ...state,
        schema: action.payload,
      }
    }
    case LOAD_STYLE:
      return {
        ...state,
        loading: true,
      }
    case LOAD_STYLE_FAIL: {
      return {
        loading: false,
        error: action.payload.message,
      }
    }
    case LOAD_STYLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        currentStyle: action.payload,
      }
    }
    case UPDATE_CURRENT_STYLE: {
      return {
        ...state,
        currentStyle: action.payload,
      }
    }
    case FINISH_THEMATIC_EDITION: {
      return {
        ...state,
        thematic: false,
      }
    }
  }
  return state
}
