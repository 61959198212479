<ui-tree-item
  *ngFor="let item of filteredItems$ | async; trackBy: treeItemTracker"
  [item]="item"
  [selectable]="selectable"
  [directAccessPath]="directAccessPath"
  [editable]="editable"
></ui-tree-item>
<div *ngIf="loading" class="loading text-muted p-3">
  <i class="fa fa-spinner fa-spin mr-1"></i><span>Chargement...</span>
</div>
<div *ngIf="empty" class="empty-warning text-muted p-3">
  <i class="fa fa-exclamation-circle mr-1"></i><span>Aucun résultat.</span>
</div>
<div
  *ngIf="error$ | async as error"
  class="alert alert-danger m-2 small"
  role="alert"
>
  <i class="fa fa-exclamation-triangle mr-1"></i><span>{{ error }}</span>
</div>
