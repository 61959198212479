import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import {
  QGisProjectService,
  MapFullModel,
} from '@ui/data-access-carto-map-production'

import { LoadingFacade, NotificationFacade } from '../../../store'
import { Observable, Subscription, throwError } from 'rxjs'
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators'
import { HttpEventType, HttpResponse } from '@angular/common/http'
import { FormBuilder, Validators } from '@angular/forms'
import { ModalComponent } from '@ui/ui/layout'
import { Location } from '@angular/common'
import { MapFacade } from '@ui/feature/mapstate'
import { QGisProjectUploadService } from '../../../services/q-gis-project-upload.service'

@Component({
  selector: 'ui-update-qgis-map',
  templateUrl: './update-qgis-map.component.html',
  styleUrls: ['./update-qgis-map.component.css'],
})
export class UpdateQgisMapComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<any>
  @ViewChild('modal') modal: ModalComponent

  importQgisForm = this.formBuilder.group({
    file: ['', [Validators.required]],
  })
  sub = new Subscription()

  get f() {
    return this.importQgisForm.controls
  }

  constructor(
    protected qGisProjectService: QGisProjectService,
    protected location: Location,
    protected loadingFacade: LoadingFacade,
    protected notificationFacade: NotificationFacade,
    protected formBuilder: FormBuilder,
    protected mapFacade: MapFacade,
    protected qgisMapModalService: QGisProjectUploadService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.showModal$.subscribe(() => {
        this.qgisMapModalService.resetForm(this.importQgisForm)
        this.modal.open()
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  updateMap() {
    if (!this.qgisMapModalService.currentFile)
      return throwError(this.qgisMapModalService.errorMsg)

    this.loadingFacade.setProgressStartDate(Date.now())
    this.modal.close()
    this.loadingFacade.setIsProcessRunning(true)

    return this.mapFacade.info$.pipe(
      take(1),
      switchMap((info) =>
        this.qGisProjectService.updateQGisProject(
          info.id,
          this.qgisMapModalService.currentFile,
          'events',
          true
        )
      ),
      tap((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.loadingFacade.updateProgressValue(
            Math.round((100 * event.loaded) / event.total)
          )
        }
      }),
      filter((eventOrResponse) => eventOrResponse instanceof HttpResponse),
      tap<HttpResponse<MapFullModel>>((response) => {
        if (!response.ok) {
          throw new Error()
        } else {
          this.handleSuccessfulUpload()
        }
      }),
      catchError(
        (e) =>
          new Observable(() => {
            this.loadingFacade.setIsProcessRunning(false)
            this.loadingFacade.updateProgressValue(0)
            this.notificationFacade.showNotification(
              'danger',
              e.error || e.message || 'Erreur lors du téléversement du fichier',
              5000
            )
          })
      )
    )
  }

  handleSuccessfulUpload() {
    this.loadingFacade.setIsProcessRunning(false)
    this.loadingFacade.updateProgressValue(0)
    this.notificationFacade.showNotification(
      'success',
      `La carte a été mise à jour avec succès et va se recharger automatiquement`,
      null
    )
    setTimeout(() => window.location.reload(), 3000)
  }

  selectFile(event: Event) {
    return this.getQgisMapModalService().selectFile(event)
  }

  getQgisMapModalService() {
    return this.qgisMapModalService
  }
}
