<ui-modal
  #modal
  title="Éditer la couche"
  confirmLabel="Enregistrer"
  cancelLabel="Annuler"
  [large]="true"
  [confirmDisabled]="isFormInvalid"
  [confirmHandler]="onSubmit.bind(this)"
  [infoMessage]="infoMessage"
>
  <form *ngIf="layer" [formGroup]="editlayerForm">
    <div class="modal-body pb-0">
      <div class="form-group">
        <label
          >Titre
          <input type="text" class="form-control" formControlName="title" />
        </label>
      </div>
      <div class="form-group">
        <label
          >Nom
          <input
            type="text"
            class="form-control name-input"
            formControlName="name"
            [ngClass]="{ 'is-invalid': f.name.errors }"
            (keydown)="handleNameKeyDown($event)"
          />
        </label>
        <label
          class="form-check form-check-label text-muted"
          *ngIf="isLayerNameEditable"
        >
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="customizableName"
          />
          Personnaliser le nom de la couche
        </label>
      </div>
      <div class="form-group">
        <label [title]="relativeOrigin"
          >Origine
          <input
            type="text"
            class="form-control"
            [value]="relativeOrigin"
            disabled
          />
        </label>
      </div>
      <ui-min-max-scale-group
        [parentForm]="editlayerForm"
        tipMin="Echelle minimale en deçà de laquelle la couche ne sera plus visible à un niveau de zoom inférieur ( - )"
        tipMax="Echelle maximale au delà de laquelle la couche ne sera plus visible à un niveau de zoom supérieur ( + )"
        minScaleMapValue="{{ minScaleMapValue$ | async }}"
        maxScaleMapValue="{{ maxScaleMapValue$ | async }}"
      ></ui-min-max-scale-group>
      <div class="form-group external-wms" *ngIf="isLayerExternalWMS">
        <label class="form-check form-check-label">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="wmsQueryable"
          />Couche interrogeable</label
        >
      </div>
      <div class="form-group" *ngIf="view.supportsEncoding">
        <label
          >Encodage
          <select
            class="custom-select"
            formControlName="characterEncoding"
            [ngClass]="{ 'is-invalid': f.characterEncoding.errors }"
          >
            <option
              *ngFor="let encoding of viewCharsetEncodings"
              [ngValue]="encoding"
            >
              {{ encoding }}
            </option>
          </select>
        </label>
        <label class="form-check form-check-label text-muted">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="enableEncoding"
          />
          Personnaliser l'encodage
        </label>
      </div>
      <div class="form-group" *ngIf="isLayerInternal || isLayerExternalWMS">
        <label *ngIf="isLayerInternal">
          Projection native des données :
          {{ view.crsCode || 'inconnue' }}
        </label>
        <label> Projection d'affichage de la couche : EPSG:3857 </label>
        <label
          >Projection de rendu
          <ui-icon-tooltip [tooltip]="projectionTip"></ui-icon-tooltip>
          <select class="custom-select" formControlName="requestProjection">
            <optgroup
              *ngFor="let group of sortProjectionOrder(projectionOptions)"
              [label]="group.label"
            >
              <option
                *ngFor="
                  let projection of sortProjectionOrder(
                    filterProjectionValue(group.children)
                  )
                "
                [value]="projection.value"
              >
                {{ projection.label }}
              </option>
            </optgroup>
          </select>
        </label>
        <ng-template
          [ngIf]="isNativeProjectionKnownAndNotSupported()"
          [ngIfElse]="canOptimize"
        >
          <alert type="info" class="alert-native-projection mt-1">
            <span class="fa fa-info-circle mr-1"></span>La projection native de
            la couche n'est pas prise en charge par l'application. Il est donc
            impossible d'activer l'optimisation.
          </alert>
        </ng-template>
        <ng-template #canOptimize>
          <label class="form-check form-check-label text-muted optimize-label">
            <input
              class="form-check-input"
              type="checkbox"
              formControlName="optimizeRequestProjection"
            />
            Optimiser le temps de rendu en utilisant une projection différente
            de la projection d'affichage
          </label>
        </ng-template>
      </div>
    </div>
  </form>
</ui-modal>
