import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ELEMENT_TYPE, MarkSymbolizer } from '../../style.model'
import { SYMBOLIZER_FIELD_TYPE } from '../../symbolizer-field/symbolizer-field.component'
import { PointSymbol, StyleService } from '@ui/feature/shared'
import { PopoverDirective } from 'ngx-bootstrap/popover'
import { graphicFillDefaultSymbolizer } from '../fill-symbolizer/fill-symbolizer.component'

@Component({
  selector: 'ui-mark-symbolizer',
  templateUrl: './mark-symbolizer.component.html',
  styleUrls: ['./mark-symbolizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkSymbolizerComponent implements OnInit {
  @Input() symbolizer: MarkSymbolizer
  @Input() colorsLocked = false
  @Input() geometryType: ELEMENT_TYPE = ELEMENT_TYPE.POINT
  @Input() activeImageGeometryPolygon = true
  @Output() symbolizerUpdated = new EventEmitter<object>()
  @ViewChild('symbolPopover', { static: true }) symbolPopover: PopoverDirective

  fieldTypes = SYMBOLIZER_FIELD_TYPE
  constructor(private sharedStyleService: StyleService) {}

  get pointSymbol() {
    const { wellKnownName, color } = this.symbolizer
    return {
      type: this.sharedStyleService.isShapeSymbol(this.symbolizer)
        ? 'shape'
        : 'glyph',
      wellKnownName,
      color,
    } as PointSymbol
  }

  get isShape(): boolean {
    return this.sharedStyleService.isShapeSymbol(this.symbolizer)
  }

  get minWidthValue() {
    const shouldHaveMinWidth =
      this.sharedStyleService.isXSymbol(this.symbolizer) ||
      this.sharedStyleService.isCrossSymbol(this.symbolizer)
    return shouldHaveMinWidth ? 1 : undefined
  }

  ngOnInit() {}

  handlePropChange(name, value) {
    const changes = { [name]: value }
    if (name === 'strokeWidth') {
      if (value === 0) {
        changes['strokeColor'] = undefined
        changes['strokeOpacity'] = undefined
      } else if (!this.symbolizer.strokeColor) {
        changes['strokeColor'] =
          this.symbolizer.color || graphicFillDefaultSymbolizer.strokeColor
        changes['strokeOpacity'] = graphicFillDefaultSymbolizer.strokeOpacity
      }
    }
    this.symbolizerUpdated.emit(changes)
  }
  handleSymbolSelect(symbol) {
    const { color, wellKnownName } = symbol
    const symbolPatch: any = {}

    const isShape = this.sharedStyleService.isShapeSymbol(symbol)
    const colorKey = isShape ? 'strokeColor' : 'color'
    const previousColor = this.symbolizer[colorKey]

    if (previousColor !== color) {
      symbolPatch[colorKey] = color
    }
    if (isShape && !this.symbolizer.strokeWidth) {
      symbolPatch.strokeWidth = graphicFillDefaultSymbolizer.strokeWidth
    }
    if (wellKnownName !== this.symbolizer.wellKnownName) {
      symbolPatch.wellKnownName = wellKnownName
      this.symbolPopover.hide()
    }
    this.symbolizerUpdated.emit(symbolPatch)
  }
}
