import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import {
  MapExpositionOptionsModel,
  VirtualLinkModel,
} from '@ui/data-access-carto-map-production'
import { Subscription } from 'rxjs'

export const BACK_OFFICE_PATH = new InjectionToken<string>('backOfficePath')

@Component({
  selector: 'ui-edit-exposition-options-modal',
  templateUrl: './edit-exposition-options-modal.component.html',
  styleUrls: ['./edit-exposition-options-modal.component.scss'],
})
export class EditExpositionOptionsModalComponent implements OnInit, OnDestroy {
  editForm: FormGroup
  errorMsg: string
  subscription_ = new Subscription()
  @Input() options: MapExpositionOptionsModel
  @Input() linkedMaps: VirtualLinkModel[]
  @Output() save = new EventEmitter<{
    formValues: any
    mapLinks: VirtualLinkModel[]
  }>()
  @Output() cancel = new EventEmitter<void>()

  editorConfig = {
    base_url: `${this.backOfficePath}/tinymce`,
    suffix: '.min',
    plugins: [
      'autolink',
      'charmap',
      'fullscreen',
      'help',
      'image',
      'insertdatetime',
      'lists',
      'link',
      'nonbreaking',
      'preview',
      'quickbars',
      'searchreplace',
      'table',
    ],
    menubar: false,
    toolbar:
      'undo redo preview | fontfamily fontsize blocks | \
bold italic underline strikethrough | \
alignleft aligncenter alignright alignjustify | outdent indent |  \
numlist bullist | forecolor backcolor removeformat | \
link image table | charmap hr nonbreaking insertdatetime | help',
    image_advtab: true,
    image_caption: true,
    quickbars_selection_toolbar: 'quicklink blockquote',
    toolbar_mode: 'sliding',
    contextmenu: 'link image imagetools table',
  }

  EXPOSITION_MESSAGE_TYPE = MapExpositionOptionsModel.MessageTypeModelEnum

  linkedMapsModified: VirtualLinkModel[]
  get linkedMapsFormatted() {
    return (this.linkedMapsModified || this.linkedMaps).map((link) => ({
      item: {
        id: link.targetMapId,
        name: link.targetMapName,
        title: link.targetMapTitle,
      },
      enabled: link.linked,
    }))
  }

  constructor(
    private fb: FormBuilder,
    @Optional() @Inject(BACK_OFFICE_PATH) private backOfficePath
  ) {}

  ngOnInit(): void {
    this.editForm = this.fb.group({
      messageActivated: [false],
      messageContent: [''],
      messageNotify: [{ value: false, disabled: true }],
      messageType: [
        { value: this.EXPOSITION_MESSAGE_TYPE.INFORMATION, disabled: true },
      ],
    })

    const controls = this.editForm.controls

    this.subscription_.add(
      controls['messageActivated'].valueChanges.subscribe((activated) => {
        activated
          ? this.enableControls(controls, ['messageNotify', 'messageType'])
          : this.disableControls(controls, ['messageNotify', 'messageType'])
      })
    )

    this.editForm.patchValue({
      ...this.options,
      messageType:
        this.options.messageType || this.EXPOSITION_MESSAGE_TYPE.INFORMATION,
    })
  }

  enableControls(controls, names) {
    for (const name of names) {
      controls[name].enable()
    }
  }

  disableControls(controls, names) {
    for (const name of names) {
      controls[name].disable()
    }
  }

  get f() {
    return this.editForm.controls
  }

  onSubmit() {
    this.save.emit({
      formValues: this.editForm.getRawValue(),
      mapLinks: this.linkedMapsModified,
    })
  }

  onCancel() {
    this.cancel.emit()
  }

  ngOnDestroy() {
    this.subscription_.unsubscribe()
    this.save.complete()
    this.cancel.complete()
  }

  handleLinkedMapsUpdate(selectedMaps: { id: string }[]) {
    const linkedIds = selectedMaps.map((map) => map.id)
    this.linkedMapsModified = this.linkedMaps.map((link) => ({
      ...link,
      linked: linkedIds.indexOf(link.targetMapId) > -1,
    }))
  }
}
