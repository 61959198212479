import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core'

@Component({
  selector: 'ui-map-exposition-status',
  templateUrl: './map-exposition-status.component.html',
  styleUrls: ['./map-exposition-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapExpositionStatusComponent implements OnInit {
  @Input() exposed: boolean
  @Input() synchronized: boolean

  constructor() {}

  get isExposedUnsynchronized() {
    return this.exposed && !this.synchronized
  }
  get isExposedSynchronized() {
    return this.exposed && this.synchronized
  }
  get isUnexposed() {
    return !this.exposed
  }

  ngOnInit() {}

  get tooltip() {
    if (this.isExposedUnsynchronized)
      return `Cette carte est diffusée mais des différences existent entre les versions de travail et diffusée.`
    if (this.isExposedSynchronized)
      return 'Cette carte est diffusée et les versions de travail et diffusée sont identiques.'
    if (this.isUnexposed) return "Cette carte n'est pas diffusée."
  }
}
