import {
  Group,
  Layer,
  LayerView,
  LayerViewType,
  Map,
  MapElement,
  MapElementsById,
} from '../model'
import {
  DataSourceDataTypeModel,
  LayerGroupModel,
  LayerViewModel,
  LayerViewOriginTypeModel,
  LayerViewTypeModel,
  MapElementModel,
  MapFullModel,
} from '@ui/data-access-carto-map-production'

// Internal layers are served by the Carto2 ecosystem
export function isLayerInternal(layer: MapElement): boolean {
  return (
    'views' in layer &&
    layer.views[0] &&
    (layer.views[0].originType === LayerViewOriginTypeModel.BOC ||
      layer.views[0].originType === LayerViewOriginTypeModel.LOCAL ||
      layer.views[0].originType === LayerViewOriginTypeModel.QGIS)
  )
}

function isLayer(layer: MapElement | LayerView): layer is Layer {
  return 'views' in layer && (layer as Layer).views[0] !== undefined
}

// External layers are served by third parties
export function isLayerExternal(layer: MapElement): boolean
export function isLayerExternal(layer: LayerView): boolean
export function isLayerExternal(layer: MapElement | LayerView): boolean {
  const layerView = isLayer(layer) ? layer.views[0] : <LayerView>layer
  return layerView.originType === LayerViewOriginTypeModel.REMOTE
}

export function isLayerExternalWMS(layer: MapElement): boolean {
  return (
    isLayerExternal(layer) &&
    'views' in layer &&
    layer.views[0] &&
    layer.views[0].serviceType === LayerViewTypeModel.WMS
  )
}

export function isLayerExternalWMTS(layer: MapElement): boolean {
  return (
    isLayerExternal(layer) &&
    'views' in layer &&
    layer.views[0] &&
    layer.views[0].serviceType === LayerViewTypeModel.WMTS
  )
}

export function isLayerExternalRaster(layer: MapElement): boolean {
  return isLayerExternalWMS(layer) || isLayerExternalWMTS(layer)
}

export function isLayerExternalWFS(layer: MapElement): boolean
export function isLayerExternalWFS(layer: LayerView): boolean
export function isLayerExternalWFS(layer: MapElement | LayerView): boolean {
  const layerView = isLayer(layer) ? layer.views[0] : <LayerView>layer
  return (
    isLayerExternal(layer) && layerView.serviceType === LayerViewTypeModel.WFS
  )
}

export function isLayerQgis(layer: MapElement): boolean {
  return (
    'views' in layer &&
    layer.views[0] &&
    layer.views[0].originType === LayerViewOriginTypeModel.QGIS
  )
}

export function getViewWfsVersion(view: LayerView): string | null {
  let serviceVersion =
    view.serviceType === LayerViewType.WFS ? view.serviceVersion : null
  if (!serviceVersion && view.services) {
    const wfsService = view.services.wfs
    if (wfsService) {
      serviceVersion = wfsService.serviceVersion || null
    }
  }
  return serviceVersion
}

export function getViewServiceUrl(view: LayerView): string {
  if (view.originType === 'BOC') {
    switch (view.serviceType) {
      case 'WMTS':
        return view.services.wmts.serviceUrl
      case 'WMS':
        return view.services.wms.serviceUrl
      case 'WFS':
        return view.services.wfs.serviceUrl
      case 'VECTOR_TILES':
        return view.services.vectorTiles.serviceUrl
      default:
        throw new Error(`Unknown origin type: ${view.originType}`)
    }
  } else if (view.originType === 'QGIS') {
    return view.baseUrl
  } else {
    return view.origin
  }
}

export function getViewWfsUrl(view: LayerView): string | null {
  if (view.services && view.services.wfs) {
    return view.services.wfs.serviceUrl || null
  } else if (view.serviceType === 'WFS') {
    return view.origin || null
  }
  return null
}

export function getViewIsVector(view: LayerView): boolean {
  return view.serviceType === 'WFS' || view.serviceType === 'VECTOR_TILES'
}

export function isMapElementGroup(mapElement: MapElement) {
  return 'childrenId' in mapElement
}

export function parseApiMapElement(element: MapElementModel): MapElement {
  if (element.isGroup) {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { isGroup, children, mapElementType, ...elProps } =
      element as LayerGroupModel
    return {
      ...elProps,
      id: element.id || -1,
      childrenId: children.map((e) => e.id),
    }
  } else {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { isGroup, mapElementType, ...elProps } = <any>element
    return {
      ...elProps,
    }
  }
}

export function parseApiMap(map: MapFullModel): Map {
  const { layers, creationDate, ...mapProps } = map
  const elementsById: MapElementsById = {
    0: {
      id: 0,
      visible: true,
      title: 'racine',
      childrenId: layers.map((l) => l.id),
    },
  }

  function addElement(e: MapElementModel) {
    elementsById[e.id] = parseApiMapElement(e)
    if (e.isGroup) {
      const { children } = e as LayerGroupModel
      for (let i = 0; i < children.length; i++) {
        addElement(children[i])
      }
    }
  }
  layers.forEach(addElement)

  return {
    ...mapProps,
    expositionStatus: mapProps.expositionStatus || {},
    creationDate: new Date(creationDate),
    elementsById,
    rootElementId: 0,
  }
}

export function getParentElement(
  spec: Map,
  elementId: number
): MapElement | null {
  for (const id in spec.elementsById) {
    if (elementId === parseInt(id, 10)) {
      continue
    }
    const element = spec.elementsById[id]
    const isParent =
      isMapElementGroup(element) &&
      (element as Group).childrenId.includes(elementId)
    if (isParent) {
      return element
    }
  }
  return null
}

export function isRootElement(element: MapElement): boolean {
  return isMapElementGroup(element) && element.id === 0
}

export function isRasterSource(view: LayerViewModel) {
  return view.dataSourceDataType === DataSourceDataTypeModel.RASTER
}

export function isMapDefault(mapSpec: Map) {
  return mapSpec.name === 'Default map'
}

export function hasMapSpecErrors(mapSpec: Map) {
  return Object.values(mapSpec.elementsById).some(hasMapElementErrors)
}

export function hasMapElementErrors(element: MapElement) {
  if (!isMapElementGroup(element)) {
    const view = (element as Layer).views[0]

    return (
      view?.originType === LayerViewOriginTypeModel.BOC &&
      (!view?.origin || !view?.relativeOrigin)
    )
  }

  return false
}
