import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { GlyphSymbol, PointSymbol } from '@ui/feature/shared'
import { fillPatterns } from '@ui/ui/styling'
import { BehaviorSubject, of } from 'rxjs'
import { map, switchMap, withLatestFrom } from 'rxjs/operators'
import { ELEMENT_TYPE } from '../style.model'
import { StyleService } from '../style.service'
import { SYMBOLIZER_FIELD_TYPE } from '../symbolizer-field/symbolizer-field.component'

export const BUILTIN_SYMBOLS: PointSymbol[] = [
  {
    label: 'cercle',
    type: 'glyph',
    wellKnownName: 'circle',
  },
  {
    label: 'carré',
    type: 'glyph',
    wellKnownName: 'square',
  },
  {
    label: 'triangle',
    type: 'glyph',
    wellKnownName: 'triangle',
  },
  {
    label: 'étoile',
    type: 'glyph',
    wellKnownName: 'star',
  },
  {
    label: 'croix',
    type: 'glyph',
    wellKnownName: 'cross',
  },
  {
    label: 'x',
    type: 'glyph',
    wellKnownName: 'x',
  },
]

export const BUILTIN_SHAPES = fillPatterns.map((name) => {
  return {
    label: name,
    type: 'shape',
    wellKnownName: `shape://${name}`,
  }
})

type Tab = 'builtin' | 'images' | 'chart' | 'builtin_shapes'

@Component({
  selector: 'ui-symbol-selector',
  templateUrl: './symbol-selector.component.html',
  styleUrls: ['./symbol-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SymbolSelectorComponent implements OnInit {
  @Input() symbol: PointSymbol
  @Input() colorsLocked: false
  @Input() geometryType: ELEMENT_TYPE = ELEMENT_TYPE.POINT
  @Input() activeImageGeometryPolygon = true
  @Output() symbolSelected = new EventEmitter<PointSymbol>()

  fieldTypes = SYMBOLIZER_FIELD_TYPE
  currentTab$ = new BehaviorSubject<Tab>('builtin')

  symbolFonts = ['Mapkey', 'Font Awesome']
  selectedFont$ = new BehaviorSubject(this.symbolFonts[0])

  remoteSymbols$ = this.service.symbolsList$
  filteredRemoteSymbols$ = this.selectedFont$.pipe(
    switchMap(() => this.remoteSymbols$),
    withLatestFrom(this.selectedFont$),
    map(([symbols, font]) =>
      symbols.filter(
        (s: PointSymbol) =>
          'wellKnownName' in s && s.wellKnownName.indexOf(font) > -1
      )
    )
  )
  symbolsList$ = this.currentTab$.pipe(
    switchMap((tab) => {
      switch (tab) {
        case 'builtin':
          return of(BUILTIN_SYMBOLS)
        case 'builtin_shapes':
          return of(BUILTIN_SHAPES)
        case 'images':
          return this.filteredRemoteSymbols$
      }
    })
  )

  get currentColor() {
    return 'color' in this.symbol ? this.symbol.color : '#000000'
  }

  get selectedTab(): Tab {
    if (
      this.symbol &&
      BUILTIN_SYMBOLS.some(
        (s) =>
          'wellKnownName' in s &&
          'wellKnownName' in this.symbol &&
          s.wellKnownName === this.symbol.wellKnownName
      )
    ) {
      return 'builtin'
    } else if (
      this.symbol &&
      BUILTIN_SHAPES.some(
        (s) =>
          'wellKnownName' in s &&
          'wellKnownName' in this.symbol &&
          s.wellKnownName === this.symbol.wellKnownName
      )
    ) {
      return 'builtin_shapes'
    } else {
      return 'images'
    }
  }

  constructor(private service: StyleService) {}

  ngOnInit() {
    // switch to the tab where the input symbol is
    this.currentTab$.next(this.selectedTab)

    // preload symbols
    this.remoteSymbols$.subscribe()
  }

  setColor(color) {
    if (!('color' in this.symbol)) {
      return
    }
    this.symbolSelected.emit({
      ...this.symbol,
      color,
    })
  }

  switchTab(tab: Tab) {
    this.currentTab$.next(tab)
  }

  isSameAsInput(symbol: PointSymbol) {
    if (symbol.type !== this.symbol.type) return false
    switch (symbol.type) {
      case 'glyph': {
        return (
          symbol.wellKnownName === (this.symbol as GlyphSymbol).wellKnownName
        )
      }
      default:
        return false
    }
  }

  selectSymbol(symbol: PointSymbol) {
    switch (symbol.type) {
      case 'glyph':
      case 'shape':
        this.symbolSelected.emit({
          ...symbol,
          color: this.currentColor,
        })
        break
      default:
        this.symbolSelected.emit({
          ...symbol,
        })
    }
  }

  isGeometryPoint() {
    return this.geometryType === ELEMENT_TYPE.POINT
  }
  isGeometryPolygon() {
    return this.geometryType === ELEMENT_TYPE.POLYGON
  }
  isGeometryLine() {
    return this.geometryType === ELEMENT_TYPE.LINE
  }

  showImageTab() {
    return (
      (this.isGeometryPolygon() && this.activeImageGeometryPolygon) ||
      this.isGeometryPoint()
    )
  }
}
