import * as actions from '../actions/ui.action'

export const UI_FEATURE_KEY = 'ui'

export type UiState = any
export interface UiPartialState {
  readonly [UI_FEATURE_KEY]: UiState
}

export const initialState: UiState = {}

export function reducer(
  state = initialState,
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  action: actions.UiActions
): UiState {
  // empty for now

  return state
}
