import { Inject, Injectable, InjectionToken, Optional } from '@angular/core'

export const PROXY_PATH = new InjectionToken<string>('proxyPath')

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  constructor(@Optional() @Inject(PROXY_PATH) private proxyPath: string) {}

  /**
   * Will apply the proxy on the url if it:
   * * is cross domain
   * * or is mixing protocols (http/https)
   * Note: proxy is reached through the relative '/proxy' url,
   * using the encoded final url as the full query string, ie:
   * http://test.com/abc -> /proxy?http%3A%2F%2Ftest.com%2Fabc
   * @param url
   */
  getProxiedUrl(url: string): string {
    const urlObj = new URL(url)
    const current = window.location
    let result = url
    if (
      current.hostname !== urlObj.hostname ||
      current.protocol !== urlObj.protocol
    ) {
      const path =
        this.proxyPath || `${current.protocol}//${current.host}/proxy?`
      result = `${path}${url}`
    }
    return result
  }
}
