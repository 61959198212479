import { GeoserverSldStyleParser as SldStyleParser } from '@ui/feature/shared'
import {
  AnalysisDataFields,
  ClassesInterpolationSet,
  ClassificationOptions,
  ClassificationResult,
  ELEMENT_TYPE,
  FIELD_ANALYSIS_REJECT_REASON,
  Rule,
  Style,
  SYMBOLS_LIST_URL,
  ValuesInterpolationSet,
  ValuesTemplateSet,
} from './style.model'
import * as chroma from 'chroma-js'
import { ckmeans } from 'simple-statistics'
import { Feature } from 'geojson'
import { JSONSchema4TypeName } from 'json-schema'
import { PointSymbol } from '@ui/feature/shared'
import { shareReplay } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'

const sldParser = new SldStyleParser()

@Injectable({
  providedIn: 'root',
})
export class StyleService {
  // this simply caches the symbols list for future usage
  symbolsList$ = this.http
    .get<PointSymbol[]>(this.symbolsListUrl || 'assets/symbols.json')
    .pipe(shareReplay())

  constructor(
    private http: HttpClient,
    @Optional() @Inject(SYMBOLS_LIST_URL) private symbolsListUrl
  ) {}

  convertToSld(jsonStyle: Style) {
    return sldParser
      .writeStyle(jsonStyle as any) // FIXME: remove 'as any'
      .then(({ output: sld }) => sld)
  }

  getSymbolizerFilter(elementType: ELEMENT_TYPE) {
    return (symbolizer) => {
      if (symbolizer.kind === 'Text') {
        return true
      }
      switch (elementType) {
        case ELEMENT_TYPE.POLYGON:
          return symbolizer.kind === 'Fill'
        case ELEMENT_TYPE.LINE:
          return symbolizer.kind === 'Line'
        case ELEMENT_TYPE.POINT:
          return symbolizer.kind === 'Mark'
        default:
          return false
      }
    }
  }

  filterRuleSymbolizers(rule: Rule, elementType: ELEMENT_TYPE): Rule {
    const filter = this.getSymbolizerFilter(elementType)

    if (rule.symbolizers.every(filter)) return rule

    return {
      ...rule,
      symbolizers: rule.symbolizers.filter(filter),
    }
  }

  getRuleElementTypeMapper(elementType: ELEMENT_TYPE) {
    return (rule) => this.filterRuleSymbolizers(rule, elementType)
  }

  classify(options: ClassificationOptions): ClassificationResult {
    const { data, method, classCount, properties, rounding } = options

    let steps: number[]
    if (method === 'k') {
      const clusters = ckmeans(data, classCount)
      steps = clusters.map((cluster) => cluster[0]).concat(clusters.pop().pop())
    } else {
      steps = chroma.limits(data, method, classCount)
    }

    steps = this.roundSteps(steps, rounding)

    const propsInterpolators = Object.keys(properties).reduce((prev, curr) => {
      const prop = properties[curr]
      if (
        typeof prop.start !== typeof prop.end &&
        prop.start !== undefined &&
        prop.end !== undefined
      ) {
        throw new Error(
          `Value type mismatch between start(${typeof prop.start}) and end(${typeof prop.end})`
        )
      }
      switch (typeof prop.start) {
        case 'number': {
          const start = prop.start as number
          const end = prop.end as number
          return {
            ...prev,
            [curr]: (value: number) => {
              const ratio = chroma
                .scale([`rgba(1,1,1,0)`, `rgba(1,1,1,1)`])
                .classes(steps)(value)
                .alpha()
              return ratio * (end - start) + start
            },
          }
        }
        // assume color
        case 'string': {
          const start = prop.start as string
          const end = prop.end as string
          return {
            ...prev,
            [curr]: (value: number) =>
              chroma
                .scale([start, end])
                .mode('lab')
                .classes(steps)(value)
                .hex(),
          }
        }
        default:
          return new Error('Unexpected value type')
      }
    }, {})

    return Array.from(steps.entries())
      .map(([index, value]) => {
        const props = Object.keys(properties).reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: propsInterpolators[curr](value),
          }
        }, {})
        return {
          properties: props,
          start: value,
          end: index === steps.length - 1 ? undefined : steps[index + 1],
        }
      })
      .filter((e) => e.end !== undefined)
  }

  roundSteps(steps: number[], rounding?: number) {
    let stepsResult: number[]

    if (rounding) {
      const stepsRounded = [
        ...new Set(steps.map((val) => Math.round(val / rounding) * rounding)),
      ]

      // - if rounding was too high, there is only one distinct value in stepsRounded array,
      // this cannot be used, thus we create a single step with min and max limit values
      // - if rounding is ok, replace first and last limit by real values
      stepsResult =
        stepsRounded.length === 1
          ? [Math.min(...steps), Math.max(...steps)]
          : [
              steps[0],
              ...stepsRounded.slice(1, stepsRounded.length - 1),
              steps[steps.length - 1],
            ]
    }

    return stepsResult || steps
  }

  analyzeFields(
    features: Feature[],
    numbersOnly: boolean,
    maxAllowedValues?: number
  ): { available: AnalysisDataFields; unavailable: AnalysisDataFields } {
    const available: AnalysisDataFields = {}
    const unavailable: AnalysisDataFields = {}
    function add(name, value, rejectReason?: FIELD_ANALYSIS_REJECT_REASON) {
      // ignore null values altogether
      if (value === null) return

      const isUnavailable =
        rejectReason !== undefined || unavailable[name] !== undefined
      const dict = isUnavailable ? unavailable : available
      if (!dict[name]) {
        dict[name] = {
          name,
          type: typeof value as JSONSchema4TypeName,
          uniqueValues: [],
        }
        if (rejectReason !== undefined) {
          dict[name].rejectReason = rejectReason
        }
      }
      if (dict[name].uniqueValues.indexOf(value) === -1) {
        dict[name].uniqueValues.push(value)
      }
      if (
        maxAllowedValues &&
        dict === available &&
        dict[name].uniqueValues.length > maxAllowedValues
      ) {
        unavailable[name] = {
          ...available[name],
          rejectReason: FIELD_ANALYSIS_REJECT_REASON.TOO_MANY_VALUES,
        }
        delete available[name]
      }
    }
    features.forEach((feature) => {
      const props = Object.keys(feature.properties)
      let value
      for (let i = 0; i < props.length; i++) {
        value = feature.properties[props[i]]
        if (typeof value !== 'number' && numbersOnly) {
          add(props[i], value, FIELD_ANALYSIS_REJECT_REASON.INCOMPATIBLE_TYPE)
        } else {
          add(props[i], value)
        }
      }
    })
    return {
      unavailable,
      available,
    }
  }

  generateRulesFromClassesInterpolationSet(
    interpolationSet: ClassesInterpolationSet
  ): Rule[] {
    const classes = this.classify(interpolationSet)

    return classes.map((class_, i) => ({
      name: interpolationSet.ruleName(class_.start, class_.end),
      filter: [
        '&&',
        ['>=', interpolationSet.fieldName, class_.start.toString()],
        [
          i + 1 === classes.length ? '<=' : '<',
          interpolationSet.fieldName,
          class_.end.toString(),
        ],
      ],
      symbolizers: [
        {
          ...interpolationSet.baseSymbolizer,
          ...class_.properties,
        },
      ],
    }))
  }

  generateRulesFromValuesInterpolationSet(
    interpolationSet: ValuesInterpolationSet
  ): Rule[] {
    const props = interpolationSet.properties
    const values = interpolationSet.data
      .filter((v) => v !== undefined)
      .sort((a, b) => a - b)
    const min = values[0]
    const max = values[values.length - 1]

    const propsInterpolators = Object.keys(props).reduce((prev, curr) => {
      const prop = props[curr]
      if (typeof prop.start !== typeof prop.end)
        throw new Error(
          `Value type mismatch between start(${typeof prop.start}) and end(${typeof prop.end})`
        )
      switch (typeof prop.start) {
        case 'number': {
          return {
            ...prev,
            [curr]: (value: number) =>
              ((value - min) / (max - min)) * (prop.end - prop.start) +
              prop.start,
          }
        }
        // assume color
        case 'string': {
          const start = prop.start as string
          const end = prop.end as string
          const color = chroma
            .scale([start, end])
            .mode('lab')
            .domain([0, values.length])
          return {
            ...prev,
            [curr]: (value: number) => color(values.indexOf(value)).hex(),
          }
        }
        default:
          return new Error('Unexpected value type')
      }
    }, {})

    const rules = values.map((value) => {
      const properties = Object.keys(props).reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: propsInterpolators[curr](value),
        }
      }, {})
      return {
        properties,
        value: value,
      }
    })

    return rules.map((rule) => ({
      name: interpolationSet.ruleName(rule.value),
      filter: ['==', interpolationSet.fieldName, rule.value.toString()],
      symbolizers: [
        {
          ...interpolationSet.baseSymbolizer,
          ...rule.properties,
        },
      ],
    }))
  }

  // this will apply random colors to the template
  generateRulesFromValuesTemplateSet(templateSet: ValuesTemplateSet): Rule[] {
    const values = templateSet.data.filter((v) => v !== undefined)

    // a start color is chosen, other ones will be generated from it to
    // insure colors are not too similar
    let color = chroma.hsl(Math.random() * 360, 1, 0.6)
    const hueShift = 360 / values.length

    return values.map((value) => {
      const symbolizer: any = {
        ...templateSet.symbolizerTemplate,
      }
      const hue = color.get('hsl.h') + hueShift
      color = color.set('hsl.h', hue)
      ;['color', 'outlineColor'].forEach((colorProp) => {
        if (colorProp in templateSet.symbolizerTemplate) {
          const lightness = chroma
            .hex(templateSet.symbolizerTemplate[colorProp])
            .luminance()
          symbolizer[colorProp] = color.luminance(lightness).hex()
        }
      })
      return {
        name: templateSet.ruleName(value),
        filter: ['==', templateSet.fieldName, value],
        symbolizers: [symbolizer],
      }
    })
  }

  getClassesRuleNameGenerator(template: string, attributeName: string) {
    return (min: number, max: number) =>
      template
        .replace(/\$ATTR/g, attributeName)
        .replace(/\$MIN_VALUE/g, min.toString())
        .replace(/\$MAX_VALUE/g, max.toString())
  }

  getValuesRuleNameGenerator(template: string, attributeName: string) {
    return (value: number | string) =>
      template
        .replace(/\$ATTR/g, attributeName)
        .replace(/\$VALUE/g, value.toString())
  }

  getIsSymbolizerPropertyInterpolatable(propertyName: string) {
    const interpolatable = [
      'color',
      'fillColor',
      'outlineColor',
      'opacity',
      'fillOpacity',
      'outlineOpacity',
      'width',
      'outlineWidth',
      'radius',
    ]
    return interpolatable.indexOf(propertyName) > -1
  }

  forceOpacityValid(object, attrName: string) {
    if (!(attrName in object)) return
    object[attrName] = Math.max(0, Math.min(1, object[attrName]))
  }

  getSymbolizerWithCorrectedWidth(symb) {
    if (
      symb.kind === 'Mark' &&
      (symb.wellKnownName === 'x' || symb.wellKnownName === 'cross')
    ) {
      if (!symb.strokeWidth) symb.strokeWidth = 1
      symb.strokeOpacity = symb.fillOpacity
      symb.strokeColor = symb.strokeColor ?? symb.color
    }
    return symb
  }

  getSymbolizerWithCorrectedOpacity(symb) {
    const updated = symb.graphicFill
      ? { ...symb, graphicFill: { ...symb.graphicFill } }
      : { ...symb }

    this.forceOpacityValid(updated, 'opacity')
    this.forceOpacityValid(updated, 'fillOpacity')
    this.forceOpacityValid(updated, 'strokeOpacity')
    this.forceOpacityValid(updated, 'outlineOpacity')
    if (updated.graphicFill) {
      this.forceOpacityValid(updated.graphicFill, 'fillOpacity')
      this.forceOpacityValid(updated.graphicFill, 'outlineOpacity')
      this.forceOpacityValid(updated.graphicFill, 'strokeOpacity')
      this.forceOpacityValid(updated.graphicFill, 'opacity')
    }
    return updated
  }

  getSymbolizerWithCorrectedWellKnownName(symb) {
    if (
      symb.kind === 'Mark' &&
      symb.wellKnownName &&
      symb.wellKnownName.length > 0
    ) {
      return {
        ...symb,
        wellKnownName:
          symb.wellKnownName[0].toLowerCase() + symb.wellKnownName.slice(1),
      }
    } else {
      return symb
    }
  }

  ruleCorrector(rule: Rule) {
    return {
      ...rule,
      symbolizers: rule.symbolizers
        .map((symb) => this.getSymbolizerWithCorrectedOpacity(symb))
        .map((symb) => this.getSymbolizerWithCorrectedWellKnownName(symb))
        .map((symb) => this.getSymbolizerWithCorrectedWidth(symb)),
    } as Rule
  }
}
