<ui-modal
  #modal
  title="Importer une carte Qgis"
  confirmLabel="Créer"
  cancelLabel="Annuler"
  [confirmDisabled]="importQgisForm.invalid"
  [confirmHandler]="createMap.bind(this)"
>
  <form [formGroup]="importQgisForm">
    <div class="modal-body">
      <div class="form-group">
        <label
          >Titre de la nouvelle carte
          <input
            type="text"
            class="form-control"
            formControlName="title"
            [ngClass]="{ 'is-invalid': f.title.errors }"
          />
        </label>
      </div>
      <div class="form-group">
        <label
          >Nom de la nouvelle carte
          <input
            type="text"
            class="form-control"
            formControlName="name"
            [ngClass]="{ 'is-invalid': f.name.errors }"
          />
        </label>
      </div>
      <div class="form-group">
        <label
          >Projet Qgis
          <span class="text-muted">(Format geopackage, 1GO max)</span>
          <input
            type="file"
            accept=".gpkg"
            class="form-control"
            formControlName="file"
            (change)="selectFile($event)"
            [ngClass]="{ 'is-invalid': f.file.errors }"
          />
        </label>
      </div>
      <div class="alert alert-warning d-flex align-items-center" role="alert">
        <span class="fa fa-exclamation-triangle mr-3"></span>
        Le fichier .gpkg ne doit pas être ouvert dans QGis
      </div>
    </div>
  </form>
</ui-modal>
