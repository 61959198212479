import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core'
import {
  ELEMENT_TYPE,
  FillSymbolizer,
  LineSymbolizer,
  MarkSymbolizer,
  Rule,
  Symbolizer,
} from '../style.model'
import { StyleService } from '../style.service'

type SymbolizerDict = { [type in ELEMENT_TYPE]: Symbolizer }

const DEFAULT_SYMBOLIZERS: SymbolizerDict = {
  [ELEMENT_TYPE.POINT]: {
    kind: 'Mark',
    wellKnownName: 'circle',
    radius: 8,
    color: '#AAAAAA',
    fillOpacity: 1,
  } as MarkSymbolizer,
  [ELEMENT_TYPE.LINE]: {
    kind: 'Line',
    width: 1,
    color: '#AAAAAA',
    opacity: 1,
  } as LineSymbolizer,
  [ELEMENT_TYPE.POLYGON]: {
    kind: 'Fill',
    color: '#bbbbbb',
    fillOpacity: 0.5,
    outlineColor: '#333333',
    outlineOpacity: 1,
    outlineWidth: 1,
  } as FillSymbolizer,
}

/**
 * This component shows a UI for generating an array of style rules
 * based on several parameters, with randomized colors
 * and dynamic filtering on a specified field.
 * To be used for any kind of values (mainly string)
 */
@Component({
  selector: 'ui-ruleset-generator-random',
  templateUrl: './ruleset-generator-random.component.html',
  styleUrls: ['./ruleset-generator-random.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetGeneratorRandomComponent implements OnInit, OnChanges {
  @Input() elementType: ELEMENT_TYPE

  symbolizerTemplates: SymbolizerDict = { ...DEFAULT_SYMBOLIZERS }

  get symbolizerTemplate() {
    return this.symbolizerTemplates[this.elementType]
  }
  set symbolizerTemplate(value) {
    this.symbolizerTemplates[this.elementType] = value
  }

  // this is only used to render the rules in the UI, not for generating the ruleset
  get rule() {
    return {
      name: 'Modèle',
      symbolizers: [
        {
          ...this.symbolizerTemplate,
          _id: 'template',
        },
      ],
    }
  }

  ruleOpened = false

  constructor(private service: StyleService) {}

  ngOnInit() {}

  ngOnChanges() {}

  handleRuleChange(rule: Rule) {
    const symbolizer = rule.symbolizers[0]
    this.symbolizerTemplate = {
      ...symbolizer,
    }
  }

  generateUsingClasses() {
    // do nothing
    return []
  }

  generateUsingValues(
    fieldName: string,
    values: any[],
    ruleNameGenerator: (value: any) => string
  ): Rule[] {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { _id, ...symbolizer } = this.symbolizerTemplate // do not pass _id around
    return this.service.generateRulesFromValuesTemplateSet({
      symbolizerTemplate: symbolizer,
      data: values,
      fieldName,
      ruleName: ruleNameGenerator,
    })
  }
}
