<div
  *ngIf="!isOffset()"
  class="small d-flex flex-row align-items-center text-muted root"
  [ngClass]="{ locked: locked }"
>
  <input
    *ngIf="isWidth() || isHaloWidth() || isSize() || isRotate() || isMargin()"
    type="number"
    class="form-control form-control-sm"
    [min]="minValue ?? 0"
    [value]="value"
    [disabled]="locked"
    (change)="
      fieldUpdated.emit(parseFloat($event.target.value));
      $event.stopPropagation()
    "
  />

  <input
    uiConstrainMinMaxValue
    *ngIf="isOpacity()"
    type="number"
    class="form-control form-control-sm"
    min="0"
    max="1"
    step="0.01"
    [value]="value"
    [disabled]="locked"
    (change)="
      fieldUpdated.emit(parseFloat($event.target.value));
      $event.stopPropagation()
    "
  />

  <input
    *ngIf="isColor() || isHaloColor()"
    type="color"
    class="form-control form-control-sm"
    [value]="value"
    [disabled]="locked"
    (change)="fieldUpdated.emit($event.target.value); $event.stopPropagation()"
  />

  <div *ngIf="isDashArray()" dropdown class="btn-group" [dropup]="true">
    <button
      class="btn btn-outline-secondary btn-sm dropdown-toggle"
      type="button"
      dropdownToggle
      aria-haspopup="true"
      aria-expanded="false"
    >
      <ng-container
        *ngTemplateOutlet="lineDashPreview; context: { dashArray: value }"
      ></ng-container>
    </button>
    <div *dropdownMenu class="dropdown-menu" role="menu">
      <button
        *ngFor="let dashArray of dashArrays"
        type="button"
        [title]="dashArray.label"
        class="dropdown-item"
        (click)="fieldUpdated.emit(toDashArray(dashArray.value))"
      >
        <ng-container
          *ngTemplateOutlet="
            lineDashPreview;
            context: { dashArray: dashArray.value, wide: true }
          "
        ></ng-container>
      </button>
    </div>
  </div>

  <select
    *ngIf="isSymbol()"
    class="form-control form-control-sm w-auto"
    (change)="fieldUpdated.emit($event.target.value); $event.stopPropagation()"
  >
    <option
      *ngFor="let symbol of symbols"
      [value]="symbol.value"
      [selected]="symbol.value === value"
    >
      {{ symbol.label }}
    </option>
  </select>

  <select
    *ngIf="isAnchor()"
    class="form-control form-control-sm w-auto"
    (change)="fieldUpdated.emit($event.target.value); $event.stopPropagation()"
  >
    <option
      *ngFor="let anchor of anchors"
      [value]="anchor.value"
      [selected]="anchor.value === value"
    >
      {{ anchor.label }}
    </option>
  </select>

  <select
    *ngIf="isLabel() && schema && schema.properties"
    class="form-control form-control-sm w-auto"
    (change)="
      fieldUpdated.emit(formatLabelAttribute($event.target.value));
      $event.stopPropagation()
    "
  >
    <option></option>
    <option
      *ngFor="let attribute of objectKeys(schema.properties)"
      [value]="attribute"
      [selected]="value === attribute"
    >
      {{ attribute }}
    </option>
  </select>

  <select
    *ngIf="isFont()"
    class="form-control form-control-sm w-auto"
    (change)="
      fieldUpdated.emit([$event.target.value]); $event.stopPropagation()
    "
  >
    <option
      *ngFor="let font of fonts"
      [value]="font"
      [selected]="font === value"
    >
      {{ font }}
    </option>
  </select>

  <span class="text-muted text-nowrap ml-2">{{ label }}</span>
  <span class="text-muted ml-1" *ngIf="isWidth()">(px)</span>
  <span class="text-muted ml-1" *ngIf="isOpacity()">(de 0 à 1)</span>

  <span class="fa fa-fw fa-lock ml-1 locked-icon" *ngIf="locked"></span>
</div>

<div *ngIf="isOffset()" class="small d-flex flex-row align-items-center">
  <input
    type="number"
    class="form-control form-control-sm"
    [value]="value[0]"
    (change)="
      fieldUpdated.emit([parseFloat($event.target.value), value[1]]);
      $event.stopPropagation()
    "
  />
  <span class="text-muted text-nowrap ml-2">offset X</span>
</div>
<div *ngIf="isOffset()" class="small d-flex flex-row align-items-center">
  <input
    type="number"
    class="form-control form-control-sm"
    [value]="value[1]"
    (change)="
      fieldUpdated.emit([value[0], parseFloat($event.target.value)]);
      $event.stopPropagation()
    "
  />
  <span class="text-muted text-nowrap ml-2">offset Y</span>
</div>

<ng-template
  #lineDashPreview
  let-dashArray="dashArray"
  let-width="wide ? '70px' : '40px'"
>
  <svg [attr.width]="width" height="10px">
    <line
      x1="0"
      x2="100%"
      y1="50%"
      y2="50%"
      stroke="black"
      stroke-width="3"
      [attr.stroke-dasharray]="dashArray"
    ></line>
  </svg>
</ng-template>
