<div
  class="grid-container"
  [ngStyle]="{
    'grid-template-columns': 'repeat(' + colCount + ', ' + itemWidthCss + ')',
    'grid-template-rows': 'repeat(' + rowCount + ', ' + itemHeightCss + ')',
    'max-height': maxHeight,
    'grid-gap': gridGap,
    padding: gridGap
  }"
>
  <div
    *ngFor="let item of data"
    [ngStyle]="{ width: itemWidthCss, height: itemHeightCss }"
  >
    <ng-container
      [ngTemplateOutletContext]="{
        item: item,
        shared: sharedContext
      }"
      [ngTemplateOutlet]="itemTemplate"
    ></ng-container>
  </div>
</div>
