import { Action } from '@ngrx/store'
import { DataSchema } from '@ui/feature/shared'
import { Style } from '@ui/feature/styling'

export interface CreateStyleActionModel {
  viewId: number
  style: Style
}

export interface StyleError {
  message: string
}

export const CREATE_STYLE = '[App] Create Style'
export const CREATE_STYLE_SUCCESS = '[App] Create Style Success'
export const CREATE_STYLE_FAIL = '[App] Create Style Fail'

export class CreateStyle implements Action {
  readonly type = CREATE_STYLE
  constructor(public payload: CreateStyleActionModel) {}
}

export class CreateStyleFail implements Action {
  readonly type = CREATE_STYLE_FAIL
  constructor(public payload: StyleError) {}
}

export class CreateStyleSuccess implements Action {
  readonly type = CREATE_STYLE_SUCCESS
  constructor(public payload: Style) {}
}

export const REMOVE_STYLE = '[App] Remove Style'
export const REMOVE_STYLE_SUCCESS = '[App] Remove Style Success'
export const REMOVE_STYLE_FAIL = '[App] Remove Style Fail'

export class RemoveStyle implements Action {
  readonly type = REMOVE_STYLE
  constructor(public viewId: number) {}
}

export class RemoveStyleFail implements Action {
  readonly type = REMOVE_STYLE_FAIL
  constructor(public payload: StyleError) {}
}

export class RemoveStyleSuccess implements Action {
  readonly type = REMOVE_STYLE_SUCCESS
}

export const SET_CURRENT_STYLE = '[App] Set Current Style'
export const CLEAR_CURRENT_STYLE = '[App] Clear Current Style'
export const SET_CURRENT_THEMATIC = '[App] Set Current Thematic Style'
export const FINISH_THEMATIC_EDITION = '[App] Finish Thematic Edition'

export class SetCurrentStyle implements Action {
  readonly type = SET_CURRENT_STYLE
  constructor(public viewId: number) {}
}

export class ClearCurrentStyle implements Action {
  readonly type = CLEAR_CURRENT_STYLE
}

export class SetCurrentThematic implements Action {
  readonly type = SET_CURRENT_THEMATIC
  constructor(public viewId: number) {}
}

export class FinishThematicEdition implements Action {
  readonly type = FINISH_THEMATIC_EDITION
}

export const SAVE_STYLE = '[App] Save Style'
export const SAVE_STYLE_SUCCESS = '[App] Save Style Success'
export const SAVE_STYLE_FAIL = '[App] Save Style Fail'

export class SaveStyle implements Action {
  readonly type = SAVE_STYLE
}

export class SaveStyleFail implements Action {
  readonly type = SAVE_STYLE_FAIL
  constructor(public payload: StyleError) {}
}

export class SaveStyleSuccess implements Action {
  readonly type = SAVE_STYLE_SUCCESS
}

export const SET_DATA_SCHEMA = '[App] Set Data Schema'

export class SetDataSchema implements Action {
  readonly type = SET_DATA_SCHEMA
  constructor(public payload: DataSchema) {}
}

export const DUPLICATE_APPLY_STYLE = '[App] Duplicate And Apply Style'
export const DUPLICATE_APPLY_STYLE_SUCCESS =
  '[App] Duplicate And Apply Style Success'
export const DUPLICATE_APPLY_STYLE_FAIL = '[App] Duplicate And Apply Style Fail'

export class DuplicateAndApplyStyle implements Action {
  readonly type = DUPLICATE_APPLY_STYLE
  constructor(
    public layerId: number,
    public viewId: number,
    public styleId: string
  ) {}
}

export class DuplicateAndApplyStyleFail implements Action {
  readonly type = DUPLICATE_APPLY_STYLE_FAIL
  constructor(public payload: StyleError) {}
}

export class DuplicateAndApplyStyleSuccess implements Action {
  readonly type = DUPLICATE_APPLY_STYLE_SUCCESS
}

export const LOAD_STYLE = '[App] Load Style'
export const LOAD_STYLE_SUCCESS = '[App] Load Style Success'
export const LOAD_STYLE_FAIL = '[App] Load Style Fail'

export class LoadStyle implements Action {
  readonly type = LOAD_STYLE
  constructor(public viewId: number) {}
}

export class LoadStyleFail implements Action {
  readonly type = LOAD_STYLE_FAIL
  constructor(public payload: StyleError) {}
}

export class LoadStyleSuccess implements Action {
  readonly type = LOAD_STYLE_SUCCESS
  constructor(public payload: Style) {}
}

export const UPDATE_CURRENT_STYLE = '[App] Update Current Style'

export class UpdateCurrentStyle implements Action {
  readonly type = UPDATE_CURRENT_STYLE
  constructor(public payload: Style) {}
}

export type StyleActions =
  | CreateStyle
  | CreateStyleFail
  | CreateStyleSuccess
  | RemoveStyle
  | RemoveStyleFail
  | RemoveStyleSuccess
  | SetCurrentStyle
  | ClearCurrentStyle
  | SetCurrentThematic
  | SaveStyle
  | SaveStyleFail
  | SaveStyleSuccess
  | SetDataSchema
  | DuplicateAndApplyStyle
  | DuplicateAndApplyStyleFail
  | DuplicateAndApplyStyleSuccess
  | LoadStyle
  | LoadStyleFail
  | LoadStyleSuccess
  | UpdateCurrentStyle
  | FinishThematicEdition
