<div class="form-group" [formGroup]="parentForm">
  <label
    >{{ getLabel() }}
    <ui-icon-tooltip *ngIf="tip" [tooltip]="tip"></ui-icon-tooltip>
    <div class="d-flex">
      <span class="mt-2 pr-2">1/</span>
      <div>
        <input
          step="5000"
          type="number"
          class="form-control"
          [formControlName]="getFormControlName()"
          [ngClass]="{ 'is-invalid': isInvalid() }"
          (keydown)="onKeydownFilterNumber($event)"
        />
        <div
          *ngIf="(getControl().dirty || getControl().touched) && getControl().errors?.['forbiddenEmptyValue']"
        >
          <span class="text-danger small">
            L'échelle ne doit pas être vide.
          </span>
        </div>
      </div>
    </div>
  </label>
</div>
