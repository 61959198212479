import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core'
import {
  ResourceExplorationService,
  TreeItemModel,
} from '@ui/data-access-carto-resource-management'
import { merge, Observable, of } from 'rxjs'
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  shareReplay,
  tap,
} from 'rxjs/operators'
import { geoplateformeServices as geoplateformeServicesFixture } from '@ui/feature/shared'
import { ResourceFacade } from '../resource.facade'
import { FilterService } from '../services/wildcard-filter.service'
import { DirectAccessService } from '../services/direct-access.service'

@Component({
  selector: 'ui-tree-level',
  templateUrl: './tree-level.component.html',
  styleUrls: ['./tree-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeLevelComponent implements OnInit {
  @Input() path: string
  @Input() selectable = false
  @Input() editable = true
  @Input() filterValue?: string
  @Input() filterOn: string[] = []
  @Input() directAccessPath: string
  items$: Observable<TreeItemModel[]>
  filteredItems$: Observable<TreeItemModel[]>
  count$: Observable<number>
  error$: Observable<string>
  loading = true
  empty = false

  constructor(
    private resourceExploration_: ResourceExplorationService,
    private facade_: ResourceFacade,
    private wildcardFilterService_: FilterService,
    private directAccessService_: DirectAccessService
  ) {}

  ngOnInit() {
    this.items$ = merge(
      this.facade_.resfreshTreeLevel$.pipe(
        filter((path) => {
          return path === this.path
        })
      ),
      of(this.path)
    ).pipe(
      tap(() => (this.loading = true)),
      mergeMap(() =>
        merge(
          this.path !== '/geoplateforme'
            ? this.resourceExploration_.navigateResources(this.path)
            : of(geoplateformeServicesFixture)
        ).pipe(
          tap((resources) => (this.empty = resources.length === 0)),
          finalize(() => (this.loading = false))
        )
      ),
      shareReplay()
    )

    this.filteredItems$ = this.getFilteredItems(this.filterValue, this.filterOn)

    this.error$ = this.items$.pipe(
      filter(() => false),
      catchError((error) => of(error.message || 'Erreur inconnue'))
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filterValue'] && this.items$) {
      const filterValue = changes['filterValue'].currentValue
      this.filteredItems$ = this.getFilteredItems(filterValue, this.filterOn)
    }
  }

  getFilteredItems(filterValue: string, filterOn: string[]) {
    return this.items$.pipe(
      map(
        (items: TreeItemModel[]) =>
          this.wildcardFilterService_.filterWildcard(
            items,
            filterOn,
            filterValue
          ) as TreeItemModel[]
      )
    )
  }

  treeItemTracker(index: number, item: TreeItemModel) {
    return item.contentId || item
  }
}
