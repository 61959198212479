<progressbar
  #progressbar
  class="progress-bar-custom"
  [value]="progressValue"
  [striped]="true"
  [hidden]="!isVisible"
  >{{ progressValue }}%
</progressbar>
<div class="progress-desc" [hidden]="!isVisible">
  {{
    'Chargement de la carte... ' + formatTime(getRemainingTimeSec(getDateNow()))
  }}
</div>
