import { Inject, Injectable, InjectionToken } from '@angular/core'

export const AUTHENTICATION_REDIRECT_PATH = new InjectionToken<string>(
  'authenticationRedirectPath'
)
export const IS_AUTHENTICATED = new InjectionToken<boolean>('isAuthenticated')

@Injectable()
export class AuthGuardService {
  constructor(
    @Inject(AUTHENTICATION_REDIRECT_PATH) private authRedirectPath,
    @Inject(IS_AUTHENTICATED) private isAuthenticated
  ) {}

  // this will redirect the user to the authentication form if required
  canActivate(): boolean {
    if (!this.isAuthenticated) {
      window.location.href = this.authRedirectPath
      return false
    }
    return true
  }
}
