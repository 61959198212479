import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import SETTINGS from '../../../../settings'
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  urls = SETTINGS.externalUrls
  versionLabel = SETTINGS.projectVersion
  versionInfo = `Backoffice UI:
Version : ${SETTINGS.projectVersion}
Commit : ${SETTINGS.commitHash}
Message : ${SETTINGS.commitMessage}

Carto API:
Version : ${SETTINGS.apiVersion.version}
Commit : ${SETTINGS.apiVersion.commitHash}
Message : ${SETTINGS.apiVersion.commitMessage}`

  constructor(private http: HttpClient) {}

  ngOnInit() {}
}
