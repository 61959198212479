import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor() {}

  public filterWildcard(
    elements: Array<any>,
    filterOn: Array<string>,
    filterValue: string
  ) {
    return filterValue && filterOn.length
      ? elements.filter(this.filterFactory(filterValue.toLowerCase(), filterOn))
      : elements
  }

  private filterFactory(filterValue: string, filterOn: Array<string>) {
    let filterFn: (text: string) => boolean

    if (filterValue.startsWith('*')) {
      filterFn = (text: string) => text.endsWith(filterValue.slice(1))
    } else if (filterValue.endsWith('*')) {
      filterFn = (text: string) => text.startsWith(filterValue.slice(0, -1))
    } else {
      const filterValueSanitized = filterValue
        .toLowerCase()
        .replace(/[.+?^$|(){}[\]\\]/g, '\\$&')
      filterFn = (text: string) =>
        new RegExp(filterValueSanitized.replace(/\*/g, '.*'), 'gi').test(text)
    }

    return (element: any) =>
      filterOn.some(
        (attribute) =>
          element[attribute] && filterFn(element[attribute].toLowerCase())
      )
  }
}
