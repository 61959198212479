import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { DataSchema } from '@ui/feature/shared'
import {
  CombinationFilter,
  CombinationOperator,
  ComparisonFilter,
  ComparisonOperator,
  Filter,
} from 'geostyler-style'

export const FILTER_TYPE_AND = '&&'
export const FILTER_TYPE_OR = '||'
export const FILTER_TYPE_NOT = '!'
export const FILTER_TYPE_COMPARISON = 'COMP' // arbitrary constant value

const OPERATOR_INDEX = 0
const ATTRIBUTE_INDEX = 1
const VALUE_INDEX = 2

export const OPERATORS = {
  string: ['==', '*=', '!='],
  number: ['==', '!=', '<', '<=', '>', '>='],
  boolean: ['==', '!='],
  all: ['==', '!=', '<', '<=', '>', '>=', '*='],
}

@Component({
  selector: 'ui-rule-filter',
  templateUrl: './rule-filter.component.html',
  styleUrls: ['./rule-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleFilterComponent implements OnInit {
  @Input() schema: DataSchema
  @Input() filter: Filter
  @Input() canDelete = false
  @Output() filterChange = new EventEmitter<Filter>()
  @Output() deleteClicked = new EventEmitter()

  objectKeys = Object.keys

  OPERATOR_INDEX = OPERATOR_INDEX
  ATTRIBUTE_INDEX = ATTRIBUTE_INDEX
  VALUE_INDEX = VALUE_INDEX

  filterTypes = [
    {
      value: FILTER_TYPE_AND,
      name: 'Et',
    },
    {
      value: FILTER_TYPE_OR,
      name: 'Ou',
    },
    {
      value: FILTER_TYPE_NOT,
      name: 'Inversion',
    },
    {
      value: FILTER_TYPE_COMPARISON,
      name: 'Comparaison',
    },
  ]

  get operators(): string[] {
    if (this.filterType === FILTER_TYPE_COMPARISON) {
      const attr = (this.filter as ComparisonFilter)[ATTRIBUTE_INDEX]
      if (
        !this.schema ||
        'error' in this.schema ||
        !this.schema.properties ||
        !this.filter[this.ATTRIBUTE_INDEX] ||
        !(typeof attr === 'string') ||
        !this.schema.properties[attr]
      ) {
        return this.operatorsMap['all']
      }
      return this.operatorsMap[this.schema.properties[attr].type]
    }
    return [this.filterType]
  }

  get filterType(): string {
    return this.getFilterType(this.filter)
  }

  get filterComponents(): Array<Filter> {
    return (this.filter as CombinationFilter).slice(1) as Array<Filter>
  }

  get filterTypeName(): string {
    for (let i = 0; i < this.filterTypes.length; i++) {
      if (this.filterTypes[i].value === this.filterType)
        return this.filterTypes[i].name
    }
  }

  private operatorsMap = OPERATORS

  constructor() {}

  ngOnInit() {}

  setAttribute(attribute: string) {
    this.updateComparisonFilter(this.ATTRIBUTE_INDEX, attribute)
  }
  setOperator(operator: string) {
    this.updateComparisonFilter(OPERATOR_INDEX, operator)
  }
  setValue(value: any) {
    this.updateComparisonFilter(VALUE_INDEX, value)
  }

  updateComparisonFilter(index: number, value: any) {
    if (!Array.isArray(this.filter)) {
      throw new Error('Not implemented')
    }
    this.filter = this.filter.map((v, i) => (i === index ? value : v)) as Filter

    // this is to make sure the operator is valid
    this.filter[OPERATOR_INDEX] =
      this.operators.indexOf(this.filter[OPERATOR_INDEX]) > -1
        ? this.filter[OPERATOR_INDEX]
        : (this.operators[0] as '!' | ComparisonOperator | CombinationOperator)

    if (this.isFilterValid()) {
      this.filterChange.emit(this.filter)
    }
  }

  setFilterType(type: string) {
    this.filter = this.getNewFilterOfType(type)

    if (this.isFilterValid()) {
      this.filterChange.emit(this.filter)
    }
  }

  // index is the index of the component, so excluding the filter operator
  setNestedFilter(filter: Filter, index: number) {
    if (!Array.isArray(this.filter)) {
      throw new Error('Not implemented')
    }
    this.filter = this.filter.map((v, i) =>
      i - 1 === index ? filter : v
    ) as Filter
    if (this.isFilterValid()) {
      this.filterChange.emit(this.filter)
    }
  }

  removeNestedFilter(index: number) {
    this.filter = (this.filter as CombinationFilter).filter(
      (v, i) => i - 1 !== index
    ) as CombinationFilter
    if (this.isFilterValid()) {
      this.filterChange.emit(this.filter)
    }
  }

  addNestedFilter() {
    if (!Array.isArray(this.filter)) {
      throw new Error('Not implemented')
    }
    this.filter = this.filter.slice() as CombinationFilter
    this.filter.push(this.newComparisonFilter())
    if (this.isFilterValid()) {
      this.filterChange.emit(this.filter)
    }
  }

  canAddNestedFilter() {
    switch (this.filterType) {
      case FILTER_TYPE_COMPARISON:
      case FILTER_TYPE_NOT:
        return false
      case FILTER_TYPE_AND:
      case FILTER_TYPE_OR:
        return true
    }
  }

  canRemoveNestedFilter() {
    if (!Array.isArray(this.filter)) {
      throw new Error('Not implemented')
    }
    switch (this.filterType) {
      case FILTER_TYPE_COMPARISON:
      case FILTER_TYPE_NOT:
        return false
      case FILTER_TYPE_AND:
      case FILTER_TYPE_OR:
        return this.filter.length > 3
    }
  }

  getIsNestedFilter(type: string): boolean {
    switch (type) {
      case FILTER_TYPE_COMPARISON:
        return false
      case FILTER_TYPE_NOT:
      case FILTER_TYPE_AND:
      case FILTER_TYPE_OR:
        return true
    }
  }

  getFilterType(filter: Filter): string {
    const operator = filter[OPERATOR_INDEX]
    if (
      operator === FILTER_TYPE_AND ||
      operator === FILTER_TYPE_OR ||
      operator === FILTER_TYPE_NOT
    ) {
      return operator
    }
    return FILTER_TYPE_COMPARISON
  }

  getNewFilterOfType(type: string): Filter {
    if (!Array.isArray(this.filter)) {
      throw new Error('Not implemented')
    }

    const prev = this.filterType
    const next = type

    // new type and old types are both nested filters
    if (this.getIsNestedFilter(next) && this.getIsNestedFilter(prev)) {
      const length = next === FILTER_TYPE_NOT ? 2 : 3
      return new Array(length).fill('').map((v, i) => {
        if (!Array.isArray(this.filter)) {
          throw new Error('Not implemented')
        }
        if (i === OPERATOR_INDEX) return next
        return i >= this.filter.length
          ? this.newComparisonFilter()
          : this.filter[i]
      }) as Filter
    }

    // new type is nested and old type is comparison
    if (this.getIsNestedFilter(next) && !this.getIsNestedFilter(prev)) {
      const result = [next, this.filter.slice()]
      if (next === FILTER_TYPE_AND || next === FILTER_TYPE_OR) {
        result.push(this.newComparisonFilter())
      }
      return result as Filter
    }

    // new type is comparison and old is nested
    if (!this.getIsNestedFilter(next) && this.getIsNestedFilter(prev)) {
      const firstNested = this.filterComponents[0] as Filter
      return this.getIsNestedFilter(this.getFilterType(firstNested))
        ? this.newComparisonFilter()
        : firstNested
    }

    // new & old type is comparison: don't change anything
    return this.filter.slice() as Filter
  }

  isFilterValid(): boolean {
    if (!Array.isArray(this.filter)) {
      return false
    }
    const validOperator =
      this.operators.indexOf(this.filter[OPERATOR_INDEX]) > -1

    let validLength = true
    let validTypes = true
    switch (this.filterType) {
      case FILTER_TYPE_COMPARISON:
        validLength = this.filter.length === 3
        validTypes = this.filterComponents.every(
          (item) => typeof item === 'string'
        )
        break
      case FILTER_TYPE_NOT:
        validLength = this.filter.length === 2
        validTypes = this.filterComponents.every((item) => Array.isArray(item))
        break
      case FILTER_TYPE_AND:
      case FILTER_TYPE_OR:
        validLength = this.filter.length >= 3
        validTypes = this.filterComponents.every((item) => Array.isArray(item))
        break
    }

    return validOperator && validLength && validTypes
  }

  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  filterTracker(index: number, filter: Filter) {
    return index
  }

  newComparisonFilter(): ComparisonFilter {
    let attribute = ''
    // Set an attribute so that comparator will be correctly initialized
    if (this.schema && 'properties' in this.schema) {
      attribute = Object.keys(this.schema.properties)[0] || ''
    }
    return ['==', attribute, '']
  }
}
