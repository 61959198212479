import { Component, OnDestroy, OnInit } from '@angular/core'
import { CreateRemoteServerRequestModel } from '@ui/data-access-carto-map-production'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'
import { ResourceFacade } from '@ui/ui/resources'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subscription } from 'rxjs'
import { ModalEventService } from '../../../services/modal-event.service'
import { NotificationFacade } from '../../../store/facade'
import { RemoteServerModalComponent } from './remote-service-modal/remote-service-modal.component'

@Component({
  selector: 'ui-remote-service',
  template: '',
})
export class RemoteServerComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef
  sub: Subscription = new Subscription()
  constructor(
    private modalService_: BsModalService,
    private modalEventService_: ModalEventService,
    private resourceManagerFacade: ResourceFacade,
    private notificationFacade_: NotificationFacade
  ) {}

  ngOnInit() {
    this.sub.add(
      this.modalEventService_.editRemoteServer$.subscribe((remoteServer) =>
        this.openModal(remoteServer)
      )
    )
  }

  openModal(remoteServer: RemoteServerModel) {
    this.modalRef = this.modalService_.show(RemoteServerModalComponent, {
      ignoreBackdropClick: true,
    })
    if (remoteServer) {
      this.modalRef.content.remoteServer = remoteServer
    }
    this.sub.add(
      this.modalRef.content.submitCreate.subscribe(
        (formValues: CreateRemoteServerRequestModel) =>
          this.createRemoteServer(formValues)
      )
    )
    this.sub.add(
      this.modalRef.content.submitUpdate.subscribe((service) =>
        this.updateRemoteServer(service)
      )
    )
    this.sub.add(
      this.modalRef.content.cancel.subscribe(() => this.closeModal())
    )
  }

  closeModal() {
    this.modalRef.hide()
  }
  dispatchError(message: string): void {
    this.modalRef.content.errorMsg = message
  }

  createRemoteServer(request: CreateRemoteServerRequestModel) {
    this.sub.add(
      this.resourceManagerFacade.createService(request).subscribe({
        next: () => {
          this.notificationFacade_.showNotification(
            'success',
            `Le service a bien été créé.`
          )
          this.closeModal()
        },
        error: (err) => this.dispatchError(err.error),
      })
    )
  }

  updateRemoteServer(service: RemoteServerModel) {
    this.sub.add(
      this.resourceManagerFacade.modifyService(service).subscribe({
        next: () => {
          this.notificationFacade_.showNotification(
            'success',
            `Le service a bien été mis à jour.`
          )
          this.closeModal()
        },
        error: (err) => this.dispatchError(err.error),
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
