<!-- avoid missing borders on list group flush -->
<span hidden></span>
<ul class="list-group list-group-flush">
  <ui-style-editor-rule
    class="list-group-item list-group-item-action p-0"
    *ngFor="let rule of rules; index as i; trackBy: ruleTracker"
    [rule]="rule"
    [index]="i"
    [active]="activeRule === i"
    [draggable]="false"
    [filterable]="false"
    [deletable]="false"
    [nameEditable]="false"
    [fixedSymbolizers]="true"
    (toggled)="activeRule = activeRule === i ? null : i"
    (changed)="handleRuleChange(i, $event)"
    [elementType]="elementType"
  ></ui-style-editor-rule>
</ul>
<span hidden></span>
