<div class="position-relative pr-1 pt-1 mr-2" [title]="tooltip">
  <span class="fa fa-globe-europe"></span>
  <span
    *ngIf="isExposedSynchronized"
    class="fa fa-arrow-up text-success icon-overlay icon-exposed"
  ></span>
  <span
    *ngIf="isExposedUnsynchronized"
    class="fa fa-exclamation-circle text-warning icon-overlay icon-exposed-unsync"
  ></span>
  <span
    *ngIf="isUnexposed"
    class="fa fa-times text-black-50 icon-overlay icon-unexposed"
  ></span>
</div>
