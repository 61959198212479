<div class="h-100">
  <div class="d-flex flex-row align-items-center text-secondary header-line">
    <small>
      {{ selectedCount }} sélectionné{{ selectedCount > 1 ? 's' : '' }}
    </small>
    <div class="flex-grow-1"></div>
    <button type="button" class="btn btn-sm btn-link" (click)="selectAll()">
      Tout sélectionner
    </button>
    <small>-</small>
    <button type="button" class="btn btn-sm btn-link" (click)="selectNone()">
      Tout désélectionner
    </button>
  </div>
  <div class="border rounded p-1 overflow-auto list-body">
    <div
      *ngFor="let datum of data; trackBy: itemTracker"
      class="d-flex flex-row"
    >
      <div
        class="rounded border-0 list-group-item list-group-item-action d-flex flex-row align-items-center overflow-hidden item"
        (click)="toggleItem(datum.item)"
      >
        <input
          class="checkbox-selected mr-2"
          type="checkbox"
          aria-label="Sélectionner cet élément"
          [checked]="datum.enabled"
        />
        <ng-container
          [ngTemplateOutletContext]="{
            item: datum.item
          }"
          [ngTemplateOutlet]="itemTemplate"
        ></ng-container>
      </div>
    </div>
  </div>
</div>
