import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { isEqual } from 'lodash'
import {
  MapPublishingService,
  PublicationMetadataModel,
} from '@ui/data-access-carto-map-publishing'
import { MapFacade } from '@ui/feature/mapstate'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { forkJoin, Observable, of, Subscription } from 'rxjs'
import { catchError, map, withLatestFrom } from 'rxjs/operators'
import { NotificationFacade } from '../../../../store'
import {
  EditMetadataModalComponent,
  KEYWORD_DELIMITER,
} from './edit-metadata-modal/edit-metadata-modal.component'

@Component({
  selector: 'ui-edit-metadata',
  template: '',
})
export class EditMetadataComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<undefined>
  modalRef: BsModalRef
  sub: Subscription = new Subscription()

  constructor(
    private modalService_: BsModalService,
    private mapPublishingService: MapPublishingService,
    private mapFacade_: MapFacade,
    private notificationFacade_: NotificationFacade
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$
        .pipe(withLatestFrom(this.mapFacade_.info$))
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        .subscribe(([_, info]) => this.openModal(info.id))
    )
  }

  clearError(): void {
    this.modalRef.content.errorMsg = null
  }

  dispatchError(): void {
    const message = 'L’enregistrement des métadonnées a échoué'
    this.modalRef.content.errorMsg = message
  }

  handleFetchError() {
    this.notificationFacade_.showNotification(
      'danger',
      "Les métadonnées de publication n'ont pas pu être chargées",
      5000
    )
  }

  openModal(mapId: string) {
    this.sub.add(
      forkJoin([
        this.mapPublishingService
          .getMapPublicationMetadata(mapId, 'response')
          .pipe(
            map((response) => (response.status === 204 ? {} : response.body))
          ),
        this.mapPublishingService
          .getAccessConstraintsList()
          .pipe(catchError(() => of(undefined))),
      ]).subscribe({
        next: ([metadata, constraintsList]) => {
          if (metadata) {
            this.modalRef = this.modalService_.show(
              EditMetadataModalComponent,
              {
                ignoreBackdropClick: true,
                initialState: { metadata, constraintsList },
              }
            )
            this.sub.add(
              this.modalRef.content.save.subscribe((formValues) => {
                this.clearError()
                this.saveMetadata(mapId, formValues, metadata)
              })
            )
            this.sub.add(
              this.modalRef.content.cancel.subscribe(() => this.modalRef.hide())
            )
          } else {
            this.handleFetchError()
          }
        },
        error: () => this.handleFetchError(),
      })
    )
  }

  saveMetadata(
    mapId: string,
    formValues,
    currentMetadata: PublicationMetadataModel
  ) {
    // eslint-disable-next-line prefer-const
    let { otherConstraintDetail, keywords, ...metadata } = formValues

    if (keywords) {
      metadata = {
        ...metadata,
        keywords: keywords
          .split(KEYWORD_DELIMITER)
          .map((keyword) => keyword.trim()),
      }
    }
    const accessConstraints =
      otherConstraintDetail || metadata.accessConstraints
    if (accessConstraints) {
      metadata = {
        ...metadata,
        accessConstraints,
      }
    }

    const metadataChanged = !isEqual(metadata, currentMetadata)

    this.mapPublishingService
      .setMapPublicationMetadata(mapId, metadata)
      .subscribe({
        next: () => {
          this.modalRef.hide()
          this.notificationFacade_.showNotification(
            'success',
            'Les métadonnées ont été sauvegardées',
            2000
          )
          if (metadataChanged) {
            this.mapFacade_.setExpositionStatus({
              synchronised: false,
            })
          }
        },
        error: () => {
          this.dispatchError()
        },
      })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
