<div class="fill-type-select small d-flex flex-row align-items-center">
  <select
    class="form-control form-control-sm w-auto"
    [(ngModel)]="fillType"
    (ngModelChange)="onFillTypeChange($event)"
  >
    <option *ngFor="let type of getAllFillTypes()" [ngValue]="type">
      {{ getFillTypeLabel(type) }}
    </option>
  </select>
  <span class="text-muted text-nowrap ml-2">Type de remplissage</span>
  <span
    *ngIf="!activeImageGeometryPolygon && isTypeTrame()"
    class="fa fa-info-circle text-warning ml-2"
    [title]="getTitle()"
  ></span>
</div>

<div class="bg-light" *ngIf="isTypeTrame() && symbolizer.graphicFill">
  <ui-mark-symbolizer
    [symbolizer]="symbolizer.graphicFill"
    [colorsLocked]="colorsLocked"
    [activeImageGeometryPolygon]="activeImageGeometryPolygon"
    geometryType="polygon"
    (symbolizerUpdated)="handleGraphicChange('graphicFill', $event)"
  ></ui-mark-symbolizer>
</div>

<ui-symbolizer-field
  *ngIf="isTypeUni()"
  label="couleur remplissage"
  [fieldType]="fieldTypes.COLOR"
  [value]="symbolizer.color"
  [locked]="colorsLocked"
  (fieldUpdated)="handlePropChange('color', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  *ngIf="isTypeTrame()"
  label="espacement trame"
  [fieldType]="fieldTypes.MARGIN"
  [value]="symbolizer['graphic-margin']"
  [locked]="colorsLocked"
  (fieldUpdated)="handlePropChange('graphic-margin', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="opacité remplissage"
  [fieldType]="fieldTypes.OPACITY"
  [value]="symbolizer.fillOpacity"
  (fieldUpdated)="handlePropChange('fillOpacity', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="couleur contour"
  [fieldType]="fieldTypes.COLOR"
  [value]="symbolizer.outlineColor"
  [locked]="colorsLocked"
  (fieldUpdated)="handlePropChange('outlineColor', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="ép. contour"
  [fieldType]="fieldTypes.WIDTH"
  [value]="symbolizer.outlineWidth"
  (fieldUpdated)="handlePropChange('outlineWidth', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="opacité contour"
  [fieldType]="fieldTypes.OPACITY"
  [value]="symbolizer.outlineOpacity"
  (fieldUpdated)="handlePropChange('outlineOpacity', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="type contour"
  [fieldType]="fieldTypes.DASH_ARRAY"
  [value]="symbolizer.outlineDasharray"
  (fieldUpdated)="handlePropChange('outlineDasharray', $event)"
>
</ui-symbolizer-field>
