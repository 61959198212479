import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppStyleState } from '../reducers/style.reducer'

export const getStyleState = createFeatureSelector<AppStyleState>('style')

export const getStyleLoading = createSelector(
  getStyleState,
  (state: AppStyleState) => state.loading
)
export const getThematicStyling = createSelector(
  getStyleState,
  (state: AppStyleState): boolean => !!state.thematic
)
export const getViewId = createSelector(
  getStyleState,
  (state: AppStyleState) => state.viewId
)
export const getDataSchema = createSelector(
  getStyleState,
  (state: AppStyleState) => state.schema
)
export const getCurrentStyle = createSelector(
  getStyleState,
  (state: AppStyleState) => state.currentStyle
)
