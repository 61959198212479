<div>
  <div
    class="small text-muted"
    [title]="
      remoteServer.serverType === 'VECTOR_TILES'
        ? 'L\'ajout de couches de type Vector Tiles n\'est pas encore supporté.'
        : ''
    "
  >
    Type : {{ remoteServer.serverType }}
  </div>
  <div class="small text-truncate">
    <a
      class="text-muted"
      [href]="remoteServer.serverURL"
      [title]="remoteServer.serverURL"
      >{{ remoteServer.serverURL }}</a
    >
  </div>
</div>
<div *ngIf="originLayers.length > 0" class="filter-search-input">
  <ui-filter-search-input
    [filterValue]="filterValue"
    (filterValueChange)="onFilterValueChange($event)"
    [placeholder]="placeholderValue"
    [isSearch]="true"
  ></ui-filter-search-input>
</div>
<div
  class="card overflow-auto flex-grow-1"
  [ngClass]="
    (error$ | async) ? 'bg-danger text-white' : 'text-white bg-secondary'
  "
>
  <div class="card-body py-2 px-3">
    <div *ngIf="loading$ | async" class="loading">
      <span class="fa fa-spinner fa-spin mr-1"></span> Chargement...
    </div>
    <div
      *ngIf="
        filteredLayers &&
        (loading$ | async) === false &&
        (error$ | async) === null
      "
      class="layers"
    >
      <ui-ogc-layer
        *ngFor="let layer of filteredLayers"
        [layer]="layer"
        [resourceId]="remoteServer.id"
        [selectable]="layersSelectable"
      ></ui-ogc-layer>
      <div *ngIf="filteredLayers.length === 0" class="layers">
        <span class="flex text-center"
          >Aucune couche ne correspond au filtre.</span
        >
      </div>
    </div>
    <div *ngIf="error$ | async as error" class="error">{{ error }}</div>
  </div>
</div>
