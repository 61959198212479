import { Component, OnChanges, OnInit } from '@angular/core'
import { RemoteServiceContentComponent } from './remote-service-content.component'
import { HttpClient } from '@angular/common/http'
import { ProxyService } from '@ui/feature/shared'
import { UtilsService } from '../services/utils.service'
import { FilterService } from '../services/wildcard-filter.service'

@Component({
  selector: 'ui-tms-service-content',
  templateUrl: './remote-service-content.component.html',
  styleUrls: ['./remote-service-content.component.scss'],
})
export class TmsServiceContentComponent
  extends RemoteServiceContentComponent
  implements OnInit, OnChanges
{
  constructor(
    protected http: HttpClient,
    protected utils: UtilsService,
    protected proxy: ProxyService,
    protected wildcardFilterService_: FilterService
  ) {
    super(http, utils, proxy, wildcardFilterService_)

    this.getCapabilitiesParserFn = this.utils.parseCapabilitiesTMS
  }

  override getHttpRequest() {
    const service = this.remoteServer
    const url = new URL(`${service.serverURL}/${service.serverVersion}`)

    return this.http.get(this.proxy.getProxiedUrl(url.toString()), {
      responseType: 'text',
    })
  }
}
