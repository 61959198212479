<label>Publication des attributs</label>
<div class="card bg-light p-2">
  <div class="grid-container">
    <div class="header mb-2 justify-start">Nom</div>
    <div class="header mb-2">Libellé</div>
    <div class="header">
      <input
        id="header-published"
        type="checkbox"
        class="form-check-input mr-1"
        [checked]="isColumnAllChecked('published')"
        (change)="onHeaderColumnClick('published', 0, $event)"
      /><label for="header-published"> Interrogeable</label>
    </div>
    <div class="header">
      <input
        id="header-includeInTooltip"
        type="checkbox"
        class="form-check-input mr-1"
        [checked]="isColumnAllChecked('includeInTooltip')"
        (change)="onHeaderColumnClick('includeInTooltip', 1, $event)"
      /><label for="header-includeInTooltip"> Info-bulle</label>
    </div>
    <div class="header">
      <input
        id="header-includeInFilter"
        type="checkbox"
        class="form-check-input mr-1"
        [checked]="isColumnAllChecked('includeInFilter')"
        (change)="onHeaderColumnClick('includeInFilter', 2, $event)"
      /><label for="header-includeInFilter">
        Filtrable
        <span
          class="fa fa-exclamation-triangle text-danger"
          [title]="getFiltrableTooltipText()"
        ></span
      ></label>
    </div>
    <div class="header">
      <input
        id="header-all"
        type="checkbox"
        class="form-check-input mr-1"
        [checked]="isColumnAllChecked('all')"
        (change)="onHeaderColumnClick('all', 3, $event)"
      /><label for="header-all"> Tout</label>
    </div>
  </div>

  <div *ngFor="let attr of attributes">
    <div
      class="grid-container grid-attributes"
      [title]="getDisabledAttributeTooltip(attr)"
    >
      <div
        class="justify-start text-break"
        [ngClass]="{ 'disabled-row': attr.invalid }"
      >
        {{ attr.name }}
      </div>
      <div>
        <input
          [disabled]="attr.invalid"
          type="text"
          class="form-control input-label"
          [ngModel]="attr.label"
          (ngModelChange)="
            attr.label = $event; labelInvalid.next(label.errors !== null)
          "
          [maxlength]="ATTRIBUTE_LABEL_MAX_LENGTH"
          [pattern]="ATTRIBUTE_LABEL_PATTERN"
          #label="ngModel"
          [ngClass]="{ 'is-invalid': label.errors }"
          title='Le libellé est une chaîne de 1 à 64 caractères.
        Les seuls caractères autorisés sont les caractères
        - alphanumériques (lettres minuscules et majuscules)
        - accentués
        - "espace"
        - spéciaux : "_" , ".", "-" , "+", ":" , "*" , "@" "&", "%"'
        />
      </div>
      <div>
        <input
          type="checkbox"
          [disabled]="attr.invalid"
          class="form-check-input input-published"
          [checked]="attr.published && !attr.valid"
          (change)="onCheck(attr, 0, $event)"
        />
      </div>
      <div>
        <input
          type="checkbox"
          [disabled]="attr.invalid"
          class="form-check-input input-includeInTooltip"
          [checked]="attr.includeInTooltip"
          (change)="onCheck(attr, 1, $event)"
        />
      </div>
      <div>
        <input
          type="checkbox"
          [disabled]="attr.invalid"
          class="form-check-input input-includeInFilter"
          [checked]="attr.includeInFilter"
          (change)="onCheck(attr, 2, $event)"
        />
      </div>
      <div>
        <input
          type="checkbox"
          [disabled]="attr.invalid"
          class="form-check-input input-all"
          [checked]="isAttributeAll(attr)"
          (change)="onAllClick(attr, $event)"
        />
      </div>
    </div>
  </div>
</div>
