import { Action } from '@ngrx/store'
import {
  LayerPublicationStatus,
  Map,
  MapExpositionStatus,
  PublicationReport,
} from '@ui/feature/shared'
import {
  GroupFolding,
  GroupPatch,
  Info,
  LayerAttributes,
  LayerError,
  LayerMove,
  LayerOpacity,
  LayerPatch,
  LayerViewStyle,
  MapElementAdd,
  MapElementUpdate,
  MapElementVisibility,
  View,
  ViewPatch,
} from '../map.model'
import { QGisProjectInfoModel } from '@ui/data-access-carto-map-production'

export const SET_VIEW = '[Map] Set view'
export const SET_ZOOM = '[Map] Set zoom'
export const SET_ACTUAL_CENTER = '[Map] Set actual center'
export const SET_ACTUAL_ZOOM = '[Map] Set actual zoom'
export const SET_ACTUAL_EXTENT = '[Map] Set actual extent'
export const SET_FROM_SPEC = '[Map] Set from spec'
export const SET_LAYER_VISIBILITY = '[Map] Set layer visibility'
export const SET_LAYER_OPACITY = '[Map] Set layer opacity'
export const SET_LAYER_ATTRIBUTES = '[Map] Set layer attributes'
export const SET_LAYER_ERROR = '[Map] Set layer error'
export const REMOVE_LAYER = '[Map] Remove layer'
export const REMOVE_GROUP = '[Map] Remove group'
export const MOVE_LAYER = '[Map] Move layer'
export const ADD_MAP_ELEMENT = '[Map] Add map element'
export const UPDATE_MAP_ELEMENT = '[Map] Update map element'
export const UPDATE_LAYER = '[Map] Update layer'
export const UPDATE_LAYER_VIEW = '[Map] Update layer view'
export const UPDATE_LAYER_ON_DUPLICATED_STYLE =
  '[Map] Update layer on duplicated style'
export const UPDATE_GROUP = '[Map] Update group'
export const SET_GROUP_VISIBILITY = '[Map] Set group visibility'
export const SET_GROUP_FOLDING = '[Map] Set group folding'
export const UPDATE_VECTOR_STYLE = '[Map] Update vector style'
export const REMOVE_VECTOR_STYLE = '[Map] Remove vector style'
export const UPDATE_MAP = '[Map] Update'
export const REFRESH_LAYER = '[Map] Refresh a layer'
export const SET_EXPOSITION_STATUS = '[Map] Set exposition status'
export const SET_EXPOSITION_STATUS_PUBLISHED =
  '[Map] Set exposition status published'
export const SET_LAYER_PUBLICATION_STATUS = '[Map] Set layer publication status'
export const SET_MAP_PUBLICATION_REPORT = '[Map] Set map publication report'
export const SET_LAYER_VIEW_STYLE = '[Map] Set layer view style'
export const CLEAR_LAYER_VIEW_STYLE = '[Map] Clear layer view style'
export const SET_MAP_QGIS_INFO = '[Map] Set map qgis info'

export class SetView implements Action {
  readonly type = SET_VIEW

  constructor(public payload: View) {}
}

export class SetZoom implements Action {
  readonly type = SET_ZOOM

  constructor(public payload: number) {}
}

export class SetActualCenter implements Action {
  readonly type = SET_ACTUAL_CENTER

  constructor(public payload: [number, number]) {}
}

export class SetActualZoom implements Action {
  readonly type = SET_ACTUAL_ZOOM

  constructor(public payload: number) {}
}

export class SetActualExtent implements Action {
  readonly type = SET_ACTUAL_EXTENT

  constructor(public payload: number[]) {}
}

export class SetFromSpec implements Action {
  readonly type = SET_FROM_SPEC

  constructor(public payload: Map) {}
}

export class UpdateLayer implements Action {
  readonly type = UPDATE_LAYER
  constructor(public payload: LayerPatch) {}
}

export class UpdateGroup implements Action {
  readonly type = UPDATE_GROUP
  constructor(public payload: GroupPatch) {}
}

export class SetGroupVisibility implements Action {
  readonly type = SET_GROUP_VISIBILITY
  constructor(public payload: MapElementVisibility) {}
}

export class SetGroupFolding implements Action {
  readonly type = SET_GROUP_FOLDING
  constructor(public payload: GroupFolding) {}
}

export class UpdateLayerView implements Action {
  readonly type = UPDATE_LAYER_VIEW
  constructor(public payload: ViewPatch) {}
}

export class UpdateLayerOnDuplicatedStyle implements Action {
  readonly type = UPDATE_LAYER_ON_DUPLICATED_STYLE
  constructor(
    public layerId: number,
    public viewId: number,
    public defaultStyleId: string
  ) {}
}

export class SetLayerVisibility implements Action {
  readonly type = SET_LAYER_VISIBILITY

  constructor(public payload: MapElementVisibility) {}
}

export class SetLayerOpacity implements Action {
  readonly type = SET_LAYER_OPACITY

  constructor(public payload: LayerOpacity) {}
}

export class SetLayerError implements Action {
  readonly type = SET_LAYER_ERROR

  constructor(public payload: LayerError) {}
}

export class RemoveLayer implements Action {
  readonly type = REMOVE_LAYER

  constructor(public id: number) {}
}

export class RemoveGroup implements Action {
  readonly type = REMOVE_GROUP

  constructor(public id: number) {}
}

export class SetLayerAttributes implements Action {
  readonly type = SET_LAYER_ATTRIBUTES

  constructor(public payload: LayerAttributes) {}
}

export class MoveLayer implements Action {
  readonly type = MOVE_LAYER

  constructor(public payload: LayerMove) {}
}

export class AddMapElement implements Action {
  readonly type = ADD_MAP_ELEMENT
  constructor(public payload: MapElementAdd) {}
}

export class UpdateMapElement implements Action {
  readonly type = UPDATE_MAP_ELEMENT
  constructor(public payload: MapElementUpdate) {}
}

export class UpdateVectorStyle implements Action {
  readonly type = UPDATE_VECTOR_STYLE
  constructor(public payload: LayerViewStyle) {}
}

export class RemoveVectorStyle implements Action {
  readonly type = REMOVE_VECTOR_STYLE
  constructor(public viewId: number) {}
}

export class UpdateMap implements Action {
  readonly type = UPDATE_MAP

  constructor(public payload: Info) {}
}
export class RefreshLayer implements Action {
  readonly type = REFRESH_LAYER
  constructor(public viewId: number) {}
}

export class SetExpositionStatus implements Action {
  readonly type = SET_EXPOSITION_STATUS
  constructor(public payload: MapExpositionStatus) {}
}

export class SetExpositionStatusPublished implements Action {
  readonly type = SET_EXPOSITION_STATUS_PUBLISHED
  constructor(public published: boolean) {}
}

export class SetLayerPublicationStatus implements Action {
  readonly type = SET_LAYER_PUBLICATION_STATUS
  constructor(public payload: LayerPublicationStatus) {}
}

export class SetMapPublicationReport implements Action {
  readonly type = SET_MAP_PUBLICATION_REPORT
  constructor(public payload: PublicationReport) {}
}

export class SetLayerViewStyle implements Action {
  readonly type = SET_LAYER_VIEW_STYLE
  constructor(public viewId: number, public defaultStyleId: string) {}
}

export class ClearLayerViewStyle implements Action {
  readonly type = CLEAR_LAYER_VIEW_STYLE
  constructor(public viewId: number) {}
}

export class SetMapQgisInfo implements Action {
  readonly type = SET_MAP_QGIS_INFO
  constructor(public qgisInfo: QGisProjectInfoModel) {}
}

export type MapActions =
  | SetView
  | SetZoom
  | SetActualCenter
  | SetActualZoom
  | SetActualExtent
  | SetFromSpec
  | UpdateLayer
  | UpdateGroup
  | SetGroupVisibility
  | SetGroupFolding
  | UpdateLayerView
  | UpdateLayerOnDuplicatedStyle
  | SetLayerVisibility
  | SetLayerOpacity
  | SetLayerAttributes
  | SetLayerError
  | RemoveLayer
  | RemoveGroup
  | MoveLayer
  | AddMapElement
  | UpdateMapElement
  | UpdateVectorStyle
  | RemoveVectorStyle
  | UpdateMap
  | RefreshLayer
  | SetExpositionStatus
  | SetExpositionStatusPublished
  | SetLayerPublicationStatus
  | SetMapPublicationReport
  | SetLayerViewStyle
  | ClearLayerViewStyle
  | SetMapQgisInfo
