import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PublicationMetadataModel } from '@ui/data-access-carto-map-publishing'
import { EMAIL_VALIDATOR_PATTERN } from '@ui/feature/shared'
import { Observable, Subscription } from 'rxjs'
import { map, startWith, tap } from 'rxjs/operators'

const otherConstraintLabel = 'Autres ...'
export const KEYWORD_DELIMITER = ','

@Component({
  selector: 'ui-edit-metadata-modal',
  templateUrl: './edit-metadata-modal.component.html',
  styleUrls: ['./edit-metadata-modal.component.scss'],
})
export class EditMetadataModalComponent implements OnInit, OnDestroy {
  editForm: FormGroup
  errorMsg: string
  subscription_ = new Subscription()
  otherConstraintVisible$: Observable<boolean>
  @Input() metadata: PublicationMetadataModel
  @Input() constraintsList: string[] = []
  @Output() save = new EventEmitter<FormGroup>()
  @Output() cancel = new EventEmitter<boolean>()
  hasConstraintsList: boolean

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.editForm = this.fb.group({
      keywords: [''],
      contactEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_VALIDATOR_PATTERN),
        ],
      ],
      accessConstraints: [''],
      otherConstraintDetail: [''],
    })

    this.hasConstraintsList = this.constraintsList.length > 0
    this.constraintsList.push(otherConstraintLabel)
    let accessConstraints =
      this.metadata.accessConstraints || this.constraintsList[0]

    const isOtherConstraints = !this.constraintsList.some(
      (constraint) =>
        constraint !== otherConstraintLabel && constraint === accessConstraints
    )
    let otherConstraintDetail = ''
    if (isOtherConstraints) {
      otherConstraintDetail = accessConstraints
      accessConstraints = otherConstraintLabel
    }

    this.editForm.patchValue({
      ...this.metadata,
      otherConstraintDetail,
      accessConstraints,
      keywords: this.metadata.keywords
        ? this.metadata.keywords.join(KEYWORD_DELIMITER)
        : '',
    })

    this.otherConstraintVisible$ = this.editForm.controls[
      'accessConstraints'
    ].valueChanges.pipe(
      startWith(accessConstraints),
      map((constraint) => this.isOtherConstraint(constraint)),
      tap((isOther) => {
        if (!isOther) {
          this.editForm.controls['otherConstraintDetail'].setValue('')
        }
      })
    )
  }

  get f() {
    return this.editForm.controls
  }

  onSubmit() {
    this.save.emit(this.editForm.getRawValue())
  }

  onCancel() {
    this.cancel.emit()
  }

  isOtherConstraint(constraint: string): boolean {
    return constraint === otherConstraintLabel
  }

  ngOnDestroy() {
    this.subscription_.unsubscribe()
    this.save.complete()
    this.cancel.complete()
  }
}
