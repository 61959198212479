<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHtml]="message"></div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close(false)">
    Annuler
  </button>
  <button type="button" class="btn btn-primary" (click)="close(true)">
    OK
  </button>
</div>
