import * as fromNgrxRouter from '@ngrx/router-store'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromRouter from '../reducers/router.reducer'

export const getRouterState =
  createFeatureSelector<
    fromNgrxRouter.RouterReducerState<fromRouter.RouterStateUrl>
  >('router')

export const getQueryParams = createSelector(
  getRouterState,
  (router) => router && router.state.queryParams
)

export const getPathParams = createSelector(
  getRouterState,
  (router) => router && router.state.params
)

export const getFullUrl = createSelector(
  getRouterState,
  (router) => router && location.origin + router.state.url
)
