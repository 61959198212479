<div class="small d-flex flex-row align-items-center">
  <button
    #symbolPopover="bs-popover"
    type="button"
    class="btn btn-light btn-sm symbol-open"
    placement="right"
    container="body"
    [outsideClick]="true"
    [popover]="popTemplate"
    [adaptivePosition]="false"
    style="padding: 2px"
  >
    <ui-symbol-preview [symbol]="pointSymbol" variant="s"></ui-symbol-preview>
  </button>
  <span class="text-muted text-nowrap ml-2">symbole</span>
</div>

<ui-symbolizer-field
  label="opacité remplissage"
  *ngIf="!isShape"
  [fieldType]="fieldTypes.OPACITY"
  [value]="symbolizer.fillOpacity"
  (fieldUpdated)="handlePropChange('fillOpacity', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="ép. trait"
  [fieldType]="fieldTypes.WIDTH"
  [value]="symbolizer.strokeWidth"
  [minValue]="minWidthValue"
  (fieldUpdated)="handlePropChange('strokeWidth', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="couleur du trait"
  *ngIf="!isShape && symbolizer.strokeColor"
  [fieldType]="fieldTypes.COLOR"
  [value]="symbolizer.strokeColor"
  (fieldUpdated)="handlePropChange('strokeColor', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="opacité du trait"
  *ngIf="symbolizer.strokeOpacity"
  [fieldType]="fieldTypes.OPACITY"
  [value]="symbolizer.strokeOpacity"
  (fieldUpdated)="handlePropChange('strokeOpacity', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="taille"
  [fieldType]="fieldTypes.WIDTH"
  [value]="symbolizer.radius"
  (fieldUpdated)="handlePropChange('radius', $event)"
>
</ui-symbolizer-field>
<ui-symbolizer-field
  label="orientation"
  [fieldType]="fieldTypes.ROTATE"
  [value]="symbolizer.rotate"
  (fieldUpdated)="handlePropChange('rotate', $event)"
>
</ui-symbolizer-field>

<ng-template
  #popTemplate
  let-change="handlePropChange"
  let-symbol="pointSymbol"
>
  <ui-symbol-selector
    [symbol]="pointSymbol"
    [colorsLocked]="colorsLocked"
    [geometryType]="geometryType"
    [activeImageGeometryPolygon]="activeImageGeometryPolygon"
    (symbolSelected)="handleSymbolSelect($event)"
  ></ui-symbol-selector>
</ng-template>
