import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MapBriefModel } from '@ui/data-access-carto-map-production'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { BehaviorSubject } from 'rxjs'
import { FilterService } from '@ui/ui/resources'

@Component({
  selector: 'ui-delete-map-modal',
  templateUrl: './delete-map-modal.component.html',
  styleUrls: ['./delete-map-modal.component.scss'],
})
export class DeleteMapModalComponent implements OnInit {
  @Input() loading: boolean
  @Input() errorMsg: string
  @Output() save = new EventEmitter<MapBriefModel>()
  @Output() cancel = new EventEmitter<boolean>()
  selection: MapBriefModel
  deletePending = false
  filterValue = ''
  filteredMapList: MapBriefModel[]
  private mapList$ = new BehaviorSubject<MapBriefModel[]>([])

  @Input() set mapList(value: MapBriefModel[]) {
    this.mapList$.next(value)
  }

  get mapList() {
    return this.mapList$.getValue()
  }

  constructor(
    private modalRef: BsModalRef,
    private wildcardFilterService_: FilterService
  ) {}

  ngOnInit() {
    this.mapList$.subscribe(
      () => (this.filteredMapList = this.getFilteredMaps(this.filterValue))
    )
  }

  onCancel() {
    this.cancel.emit()
    this.cancel.complete()
    this.modalRef.hide()
  }

  onFilterValueChange(filterValue: string) {
    this.filteredMapList = this.getFilteredMaps(filterValue)
  }

  getFilteredMaps(filterValue: string) {
    return this.wildcardFilterService_.filterWildcard(
      this.mapList,
      ['name', 'title'],
      filterValue
    ) as MapBriefModel[]
  }

  onSubmit(): void {
    this.deletePending = true
    this.save.emit(this.selection)
    this.save.complete()
  }

  onSelect(map: MapBriefModel): MapBriefModel {
    if (this.selection === map) {
      this.selection = undefined
    } else {
      this.selection = map
    }
    return this.selection
  }
}
