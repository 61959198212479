/**
 * Map Production API
 * This API covers functions related to cartographic composition (creation of maps, styles)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type DataSourceDataTypeModel = 'RASTER' | 'VECTOR' | 'UNKNOWN'

export const DataSourceDataTypeModel = {
  RASTER: 'RASTER' as DataSourceDataTypeModel,
  VECTOR: 'VECTOR' as DataSourceDataTypeModel,
  UNKNOWN: 'UNKNOWN' as DataSourceDataTypeModel,
}
