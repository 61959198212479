import { NavigationExtras } from '@angular/router'
import { Action } from '@ngrx/store'

export const GO = '[Router] Go'
export const BACK = '[Router] Back'
export const FORWARD = '[Router] Forward'

export interface RouterGoActionPayload {
  path: any[]
  query?: object
  extras?: NavigationExtras
}

export class Go implements Action {
  readonly type = GO
  constructor(public payload: RouterGoActionPayload) {}
}

export class Back implements Action {
  readonly type = BACK
}

export class Forward implements Action {
  readonly type = FORWARD
}

export type Actions = Go | Back | Forward
