import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  AtlasCompositionService,
  MapCompositionService,
  VirtualLinkModel,
} from '@ui/data-access-carto-map-production'
import { MapFacade } from '@ui/feature/mapstate'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs'
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { NotificationFacade } from '../../../../store'
import { EditExpositionOptionsModalComponent } from './edit-exposition-options-modal/edit-exposition-options-modal.component'

@Component({
  selector: 'ui-edit-exposition-options',
  template: '',
})
export class EditExpositionOptionsComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<undefined>
  modalRef: BsModalRef
  sub: Subscription = new Subscription()

  constructor(
    private modalService_: BsModalService,
    private mapComposition_: MapCompositionService,
    private mapFacade_: MapFacade,
    private notificationFacade_: NotificationFacade,
    private atlasService_: AtlasCompositionService
  ) {}

  ngOnInit() {
    this.sub.add(
      this.showModal$
        .pipe(withLatestFrom(this.mapFacade_.info$))
        // eslint-disable-next-line  @typescript-eslint/no-unused-vars
        .subscribe(([_, info]) => this.openModal(info.id))
    )
  }

  clearError(): void {
    this.modalRef.content.errorMsg = null
  }

  handleOptionsSaveError(): void {
    this.modalRef.content.errorMsg =
      'L’enregistrement des options d’exposition a échoué'
  }

  handleAtlasSaveError(): void {
    this.notificationFacade_.showNotification(
      'warning',
      "L'enregistrement des cartes liées à échoué",
      5000
    )
  }

  handleFetchError() {
    this.notificationFacade_.showNotification(
      'danger',
      "Les options d'exposition n'ont pas pu être chargées",
      5000
    )
  }

  openModal(mapId: string) {
    const mapExpositionOptions$ = this.mapComposition_
      .getMapExpositionOptions(mapId, 'response')
      .pipe(map((response) => (response.status === 204 ? {} : response.body)))
    const mapLinks$ = this.atlasService_.getVirtualAtlas(mapId)

    this.sub.add(
      combineLatest([mapExpositionOptions$, mapLinks$]).subscribe({
        next: ([expOptions, linkedMaps]) => {
          if (expOptions) {
            this.modalRef = this.modalService_.show(
              EditExpositionOptionsModalComponent,
              {
                ignoreBackdropClick: true,
                initialState: {
                  options: expOptions,
                  linkedMaps,
                },
                class: 'modal-xl',
              }
            )
            this.sub.add(
              this.modalRef.content.save.subscribe(
                ({ formValues, mapLinks }) => {
                  this.saveExpositionOptions(mapId, formValues, mapLinks)
                }
              )
            )
            this.sub.add(
              this.modalRef.content.cancel.subscribe(() => this.modalRef.hide())
            )
          } else {
            this.handleFetchError()
          }
        },
        error: () => this.handleFetchError(),
      })
    )
  }

  saveExpositionOptions(
    mapId: string,
    formValues,
    mapLinks: VirtualLinkModel[]
  ) {
    this.mapComposition_
      .setMapExpositionOptions(mapId, formValues)
      .pipe(
        catchError(() => throwError(Error('options'))),
        tap((mapSpec) => {
          this.modalRef.hide()
          this.mapFacade_.setExpositionStatus(mapSpec.expositionStatus)
        }),
        switchMap(() =>
          !!mapLinks
            ? this.atlasService_
                .patchVirtualAtlas(mapId, mapLinks)
                .pipe(catchError(() => throwError(Error('atlas'))))
            : of(false)
        )
      )
      .subscribe({
        next: () => {
          this.notificationFacade_.showNotification(
            'success',
            "Les options d'exposition ont été sauvegardées",
            2000
          )
        },
        error: (error) => {
          if (error.message === 'options') {
            this.handleOptionsSaveError()
          } else if (error.message === 'atlas') {
            this.handleAtlasSaveError()
          }
        },
      })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
