<div
  [ngClass]="isSearch ? 'pl-1' : 'px-3'"
  class="filter-group d-flex align-items-center py-2 section"
>
  <span
    [ngClass]="
      isSearch ? 'fa-solid fa-magnifying-glass mr-3' : 'fa fa-filter mr-3'
    "
    [class.text-primary]="filterValue"
    [class.text-black-50]="!filterValue"
  ></span>

  <div
    [ngClass]="isSearch ? '' : 'mr-2'"
    class="filter-input-group position-relative"
  >
    <input
      [tooltip]="isSearch ? null : popTemplate"
      placement="bottom"
      data-boundary="window"
      type="text"
      [placeholder]="placeholder"
      class="form-control pr-2"
      [ngClass]="isSearch ? 'filter-search' : ''"
      [(ngModel)]="filterValue"
      (ngModelChange)="filterValueChange.emit($event)"
    />
    <button
      *ngIf="filterValue.length"
      class="filter-reset btn text-black-50 position-absolute fa fa-times"
      role="button"
      title="Réinitialiser le filtre"
      (click)="clearFilterValue()"
    ></button>
  </div>
  <ng-template #popTemplate>
    <div class="tooltip-template">
      Comment utiliser la recherche : <br />
      <ul>
        <li>'abc' : contient 'abc'</li>
        <li>'abc*' : commence par 'abc'</li>
        <li>'*abc' : se termine par 'abc'</li>
        <li>'abc*xyz' : contient 'abc' et 'xyz' dans cet ordre</li>
      </ul>
    </div>
  </ng-template>
</div>
