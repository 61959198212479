import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core'
import { OgcLayer } from '../model'
import { EventService } from '../services/event.service'
import { map } from 'rxjs/operators'

@Component({
  selector: 'ui-ogc-layer',
  templateUrl: './ogc-layer.component.html',
  styleUrls: ['./ogc-layer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OgcLayerComponent implements OnInit {
  @Input() layer: OgcLayer
  @Input() resourceId: string
  @Input() selectable = false
  opened = false
  isActive$ = this.eventService.resourceSelected$.pipe(
    map(
      (event) =>
        event &&
        event.resourceId === this.resourceId &&
        event.layer.url === this.layer.url &&
        event.layer.name === this.layer.name
    )
  )

  constructor(private eventService: EventService) {}

  ngOnInit() {}

  getTooltip() {
    return this.layer.abstract
      ? `${this.layer.title} \n${this.layer.abstract}`
      : `${this.layer.title}`
  }

  handleClick() {
    this.eventService.selectResource(this.resourceId, this.layer)
  }
}
