<header class="d-none d-sm-block">
  <nav
    class="navbar navbar-light navbar-expand-md title-bar p-3"
    role="navigation"
    aria-labelledby="menu_accessibilite"
    style="white-space: nowrap"
  >
    <div class="d-flex flex-row align-items-center position-relative">
      <img
        class="img-fluid"
        src="assets/img/marianne.png"
        alt="Marianne"
        style=""
      />
      <div class="text-secondary ml-3">
        <div class="app-title"><strong>Géo-IDE</strong> • Carto2</div>
        <div class="app-subtitle font-italic">Composition de cartes</div>
      </div>
      <h2 id="menu_accessibilite" class="sr-only">Menu d'accessibilité</h2>
    </div>
    <ul class="inline_access">
      <a
        href="/acairegles-webapp/accessibilite.action"
        accesskey="0"
        title="Comment ce site est accessible ?"
      >
        <li class="aide_accessibilite">
          <span class="sr-only">Politique d'accessibilité</span>
        </li>
      </a>
      <a href="#contenu" accesskey="s" title="Aller au contenu">
        <li class="contenu_accessibilite">
          <span class="sr-only">Aller au contenu</span>
        </li>
      </a>
      <a href="#recherche" accesskey="1" title="Aller à la recherche">
        <li class="recherche_accessibilite">
          <span class="sr-only">Aller à la recherche</span>
        </li>
      </a>
      <a href="#" title="Aller à la page d'accueil">
        <li class="accueil_accessibilite">
          <span class="sr-only">Aller à la page d'accueil</span>
        </li>
      </a>
    </ul>
  </nav>
  <nav
    class="navbar navbar-expand-sm navbar-dark bg-secondary"
    role="navigation"
    data-toggle="sticky-onscroll"
  >
    <h2 class="sr-only">Menu principal</h2>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target=".navbar_menu-principal"
      aria-controls="navbar_menu-principal"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse navbar_menu-principal">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" dropdown container="body">
          <a
            dropdownToggle
            class="nav-link dropdown-toggle"
            href
            (click)="(false)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Géo-IDE
          </a>
          <div class="dropdown-menu" *dropdownMenu>
            <a
              class="dropdown-item"
              [href]="urls.GotoCatalogueBackoffice"
              target="_blank"
              rel="noreferrer noopener"
              >Cataloguer mes données</a
            >
            <a
              class="dropdown-item"
              [href]="urls.GotoCatalogueFrontoffice"
              target="_blank"
              rel="noreferrer noopener"
              >Consulter le catalogue public</a
            >
            <a class="dropdown-item" href="/map-production"
              >Composer une carte</a
            >
          </div>
        </li>
        <li class="nav-item" dropdown container="body">
          <a
            dropdownToggle
            href
            (click)="(false)"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Cartes
          </a>
          <div class="dropdown-menu" *dropdownMenu>
            <a
              class="dropdown-item"
              href
              (click)="modalEventService_.openCreateMapModal(); (false)"
              >Créer une carte
            </a>
            <a
              class="dropdown-item"
              *ngIf="!disableImportQgis"
              href
              (click)="modalEventService_.openImportQgisModal(); (false)"
              >Importer une carte QGIS
            </a>
            <a
              class="dropdown-item"
              *ngIf="(duplicateMapService.isDisabled() | async) === false"
              href
              (click)="duplicateMapService.duplicateMap(); (false)"
              >Dupliquer la carte courante
            </a>
            <a
              class="dropdown-item"
              href
              (click)="modalEventService_.openLoadMapModal(); (false)"
              >Ouvrir une carte
            </a>
            <a
              class="dropdown-item"
              href
              (click)="modalEventService_.openDeleteMapModal(); (false)"
              >Supprimer une carte
            </a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li
          class="nav-item dropdown organizations-list"
          dropdown
          [container]="dropdownContainer"
        >
          <ng-template #currentOrg>
            <svg
              class="Icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="10 10 512 512"
            >
              <path
                d="M283.4 19.83c-3.2 0-31.2 5.09-31.2 5.09-1.3 41.61-30.4 78.48-90.3 84.88l-12.8-23.07-25.1 2.48 11.3 60.09-113.79-4.9 12.2 41.5C156.3 225.4 150.7 338.4 124 439.4c47 53 141.8 47.8 186 43.1 3.1-62.2 52.4-64.5 135.9-32.2 11.3-17.6 18.8-36 44.6-50.7l-46.6-139.5-27.5 6.2c11-21.1 32.2-49.9 50.4-63.4l15.6-86.9c-88.6-6.3-146.4-46.36-199-96.17z"
              ></path>
            </svg>
            {{ organizationName }}
          </ng-template>
          <span
            class="navbar-text organization"
            *ngIf="organizations.length === 0"
          >
            <ng-template [ngTemplateOutlet]="currentOrg"></ng-template>
          </span>
          <a
            dropdownToggle
            class="nav-link dropdown-toggle"
            href
            (click)="(false)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            *ngIf="organizations.length > 0"
          >
            <span class="organization">
              <ng-template [ngTemplateOutlet]="currentOrg"></ng-template>
            </span>
          </a>
          <div class="dropdown-menu" *dropdownMenu>
            <button
              class="dropdown-item organization-link"
              [ngClass]="{ active: org.name === organizationName }"
              title="{{ org.name }}"
              *ngFor="let org of organizations"
              (click)="setCurrentOrganization(org.businessId)"
            >
              {{ org.name }}
            </button>
          </div>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a
            dropdownToggle
            class="nav-link dropdown-toggle"
            href
            (click)="(false)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="fa fa-user" title="Utilisateur"></span>
            <span class="sr-only">Utilisateur</span>&nbsp;{{ userName }}
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <a
              class="dropdown-item"
              href="{{ logoutUrl }}"
              title="Se déconnecter"
            >
              <span class="fa fa-power-off"></span> Se déconnecter</a
            >
          </div>
        </li>
        <li class="nav-item dropdown" dropdown>
          <a
            dropdownToggle
            class="nav-link dropdown-toggle"
            href
            (click)="(false)"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="fa fa-question-circle" title="Aide"></span> Aide
          </a>
          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <a
              class="dropdown-item"
              [href]="urls.UserManual"
              target="_blank"
              rel="noreferrer noopener"
              >Manuel d'utilisation</a
            >
            <a
              class="dropdown-item"
              [href]="urls.AssistancePage"
              target="_blank"
              rel="noreferrer noopener"
              >Assistance</a
            >
            <a
              class="dropdown-item"
              [href]="urls.AboutPage"
              target="_blank"
              rel="noreferrer noopener"
              >A propos de Géo-IDE Carto2</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
