import 'whatwg-fetch'
import { projection, ProjectionOption } from './projections'

class Settings {
  public cartoApiPath = ''
  public mapProxyPath = ''
  public projectVersion = ''
  public commitHash = ''
  public commitMessage = ''
  public getLegendGraphicUrl = ''
  public getLegendGraphicLayer = ''
  public backOfficePath = ''
  public frontOfficePath = ''
  public disableQgisMapImport = false
  public maxObjectsCountFilter = 50
  public wfsMaxFeatures = ''
  public wfsThematicMaxFeatures = ''
  public viewCharsetEncodings = ''
  public geoplateformePrivatePatternUrl = ''
  public gpkgMaxSize = '1024MB'
  public apiVersion = {
    version: 'defaultApiVersion',
    commitHash: 'defaultApiCommitHash',
    commitMessage: 'defaultApiCommitMessage',
  }
  public projections = new Array<projection>()
  public projectionOptions = new Array<ProjectionOption>()
  public projectionFormats = new Map<String, String>()
  public externalUrls: { [key: string]: string } = {}

  init() {
    return fetch('assets/settings.json')
      .then((response) => response.json())
      .then((json) => {
        Object.assign(this, json)
      })
      .then(() => {
        return fetch(this.cartoApiPath + '/properties')
          .then((resp) => resp.json())
          .then((json) => {
            Object.assign(this, json)
            this.externalUrls = {
              ...this.externalUrls,
              ...json.backoffice.externalUrls,
            }
          })
      })
  }
}

const SETTINGS = new Settings()
export default SETTINGS
