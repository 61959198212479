import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import {
  ClearCurrentStyle,
  CreateStyle,
  CreateStyleActionModel,
  DuplicateAndApplyStyle,
  FinishThematicEdition,
  RemoveStyle,
  SaveStyle,
  SetCurrentStyle,
  SetCurrentThematic,
  UpdateCurrentStyle,
} from '../actions'
import { AppStyleState } from '../reducers'
import {
  getCurrentStyle,
  getDataSchema,
  getStyleLoading,
  getThematicStyling,
  getViewId,
} from '../selectors/style.selectors'
import { distinctUntilChanged, filter } from 'rxjs/operators'
import { Style } from '@ui/feature/styling'

@Injectable({
  providedIn: 'root',
})
export class StyleFacade {
  currentViewId$ = this.store.pipe(select(getViewId), distinctUntilChanged())
  loading$ = this.store.pipe(select(getStyleLoading))
  thematic$ = this.store.pipe(select(getThematicStyling))
  currentStyle$ = this.store.pipe(select(getCurrentStyle))

  // do not emit `undefined` values
  currentDataSchema$ = this.store.pipe(select(getDataSchema), filter(Boolean))

  constructor(private store: Store<AppStyleState>) {}

  createStyle(request: CreateStyleActionModel) {
    this.store.dispatch(new CreateStyle(request))
  }

  removeStyle(viewId: number) {
    this.store.dispatch(new RemoveStyle(viewId))
  }

  setCurrentStyle(viewId: number) {
    this.store.dispatch(new SetCurrentStyle(viewId))
  }

  setCurrentThematic(viewId: number) {
    this.store.dispatch(new SetCurrentThematic(viewId))
  }

  clearCurrentStyle() {
    this.store.dispatch(new ClearCurrentStyle())
  }

  saveCurrentStyle() {
    this.store.dispatch(new SaveStyle())
  }

  duplicateAndApplyStyle(layerId: number, viewId: number, styleId: string) {
    this.store.dispatch(new DuplicateAndApplyStyle(layerId, viewId, styleId))
  }

  updateCurrentStyle(style: Style) {
    this.store.dispatch(new UpdateCurrentStyle(style))
  }

  finishThematicEdition() {
    this.store.dispatch(new FinishThematicEdition())
  }
}
