import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core'
import SETTINGS from '../../../../settings'

@Component({
  selector: 'ui-map-options-menu',
  templateUrl: './map-options-menu.component.html',
  styleUrls: ['./map-options-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapOptionsMenuComponent implements OnInit {
  @Input() disabled = false
  @Input() outOfSync = false
  @Input() publishable = false
  @Input() published = false
  @Input() isMapBasedOnGpkg
  @Output() openSettings = new EventEmitter()
  @Output() syncExposedWithCurrent = new EventEmitter()
  @Output() syncCurrentWithExposed = new EventEmitter()
  @Output() openMetadata = new EventEmitter()
  @Output() openExpositionOptions = new EventEmitter()
  @Output() publish = new EventEmitter()
  @Output() unpublish = new EventEmitter()
  @Output() repair = new EventEmitter()
  @Output() updateQGIS = new EventEmitter()
  @Output() resetQGIS = new EventEmitter()
  disableImportQgis = SETTINGS.disableQgisMapImport

  constructor() {}

  ngOnInit() {}
}
