<div class="form-group" [formGroup]="parentForm">
  <label class="form-check form-check-label">
    <input
      class="form-check-input"
      type="checkbox"
      formControlName="protected"
    />
    Protéger la carte
    <ui-icon-tooltip
      tooltip="
        Permet de définir un mot de passe afin de protéger la consultation de la carte diffusée dans le front-office et de ses web services OGC associés.
      "
    ></ui-icon-tooltip>
  </label>
  <div class="d-flex mt-2">
    <input
      [type]="hidden ? 'password' : 'text'"
      class="form-control mb-2 password-input"
      formControlName="password"
      [ngClass]="{
        'is-invalid': displayInvalid(),
        'is-valid': displayValid()
      }"
    />
    <button
      type="button"
      class="btn btn-light btn-sm float-right ml-1"
      title="Afficher temporairement le mot de passe"
      (click)="toggleVisibilityTemporarily()"
      [disabled]="parentForm.controls.password.disabled"
    >
      <span
        class="fa fa-fw"
        [ngClass]="{ 'fa-eye': hidden, 'fa-eye-slash': !hidden }"
      ></span>
    </button>
  </div>
  <ul *ngIf="displayRules()">
    <li
      [ngClass]="{
        'text-danger': displayMinLengthInValid(),
        'text-success': displayMinLengthValid()
      }"
    >
      8 caractères minimum
    </li>
    <li
      [ngClass]="{
        'text-danger': displayDigitInValid(),
        'text-success': displayDigitValid()
      }"
    >
      Au moins 1 chiffre
    </li>
    <li
      [ngClass]="{
        'text-danger': displayLetterInValid(),
        'text-success': displayLetterValid()
      }"
    >
      Au moins 1 lettre
    </li>
    <li
      [ngClass]="{
        'text-danger': displaySpecialCharacterInValid(),
        'text-success': displaySpecialCharacterValid()
      }"
    >
      Au moins 1 caractère spécial
    </li>
  </ul>
</div>
