import { InjectionToken } from '@angular/core'
import { DataSourceGeometryTypeModel } from '@ui/data-access-carto-map-production'
import { GeoserverFillSymbolizer, WfsReadParams } from '@ui/feature/shared'
import {
  Filter,
  LineSymbolizer as GsLineSymbolizer,
  MarkSymbolizer as GsMarkSymbolizer,
  RasterSymbolizer as GsRasterSymbolizer,
  TextSymbolizer as GsTextSymbolizer,
  ScaleDenominator,
} from 'geostyler-style'
import { JSONSchema4Type, JSONSchema4TypeName } from 'json-schema'

export enum ELEMENT_TYPE {
  LINE = 'line',
  POLYGON = 'polygon',
  POINT = 'point',
}

export const ELEMENT_TYPE_LABELS: { [K in ELEMENT_TYPE]: string } = {
  [ELEMENT_TYPE.POLYGON]: 'Polygone',
  [ELEMENT_TYPE.LINE]: 'Ligne',
  [ELEMENT_TYPE.POINT]: 'Point',
}

export const dataSourceGeometryTypeToElementTypeMapper = (
  geometryType: DataSourceGeometryTypeModel
) => {
  let elementType: ELEMENT_TYPE
  switch (geometryType) {
    case DataSourceGeometryTypeModel.POLYGON:
      elementType = ELEMENT_TYPE.POLYGON
      break
    case DataSourceGeometryTypeModel.LINE:
      elementType = ELEMENT_TYPE.LINE
      break
    case DataSourceGeometryTypeModel.POINT:
    case DataSourceGeometryTypeModel.GEOMETRY:
    case DataSourceGeometryTypeModel.UNKNOWN:
      elementType = ELEMENT_TYPE.POINT
      break
    default:
      elementType = ELEMENT_TYPE.POINT
  }
  return elementType
}

interface Identified {
  _id?: string
}

export interface Style {
  _elementType: ELEMENT_TYPE
  name: string
  rules: Rule[]
}
export type FillSymbolizer = GeoserverFillSymbolizer & Identified
export type LineSymbolizer = GsLineSymbolizer & Identified
export type MarkSymbolizer = GsMarkSymbolizer & Identified
export type TextSymbolizer = GsTextSymbolizer & Identified
export type RasterSymbolizer = GsRasterSymbolizer & Identified
export type Symbolizer =
  | FillSymbolizer
  | LineSymbolizer
  | MarkSymbolizer
  | TextSymbolizer
  | RasterSymbolizer
export type Rule = {
  name: string
  filter?: Filter
  scaleDenominator?: ScaleDenominator
  symbolizers: Symbolizer[]
} & Identified

export enum THEMATIC_STYLE_TYPE {
  CLASSIFICATION = 'classification',
  VALUES = 'values',
}

export type WfsServiceInfo = WfsReadParams

export enum FIELD_ANALYSIS_REJECT_REASON {
  INCOMPATIBLE_TYPE,
  TOO_MANY_VALUES,
}
export interface AnalysisDataField {
  name: string
  uniqueValues: JSONSchema4Type[]
  type: JSONSchema4TypeName
  rejectReason?: FIELD_ANALYSIS_REJECT_REASON
}
export interface AnalysisDataFields {
  [name: string]: AnalysisDataField
}

export enum ClassificationMethod {
  EQUAL = 'e',
  QUANTILE = 'q',
  KMEANS = 'k',
}

export interface ClassificationOptions {
  data: Array<number>
  method: ClassificationMethod
  classCount: number
  properties: {
    [name in keyof SymbolizerInterpolationSet]: {
      start: string | number
      end: string | number
    }
  }
  rounding?: number
}

export type ClassificationResult = Array<{
  properties: { [name: string]: string | number }
  start: number
  end: number
}>

export interface SymbolizerInterpolationSet {
  color?: { start: string; end: string }
  width?: { start: number; end: number }
  opacity?: { start: number; end: number }
  outlineOpacity?: { start: number; end: number }
}

export interface ClassesInterpolationSet {
  baseSymbolizer: Symbolizer
  properties: SymbolizerInterpolationSet
  fieldName: string
  data: number[]
  classCount: number
  ruleName: (classMin: number, classMax: number) => string
  method: ClassificationMethod
  rounding?: number
}

export interface ValuesInterpolationSet {
  baseSymbolizer: Symbolizer
  properties: SymbolizerInterpolationSet
  fieldName: string
  data: number[]
  ruleName: (value: number) => string
}

export interface ValuesTemplateSet {
  symbolizerTemplate: Symbolizer
  fieldName: string
  data: any[]
  ruleName: (value: any) => string
}

export interface SymbolizerChange {
  name: string
  value: any
}

export const SYMBOLS_LIST_URL = new InjectionToken<string>('symbolsListUrl')
