import SldStyleParser from 'geostyler-sld-parser'
import { BaseSymbolizer, FillSymbolizer, TextSymbolizer } from 'geostyler-style'

export interface GeoserverFillSymbolizer extends FillSymbolizer {
  'graphic-margin'?: string
  'graphic-resize'?: string
}
export interface GeoserverTextSymbolizer extends TextSymbolizer {
  LabelPlacement?: object
  autoWrap?: number
  charSpacing?: number
  conflictResolution?: boolean
  followLine?: boolean
  forceLeftToRight?: boolean
  goodnessOfFit?: number
  group?: boolean
  labelAllGroup?: boolean
  maxAngleDelta?: number
  maxDisplacement?: number
  partials?: boolean
  polygonAlign?: string
  repeat?: number
  spaceAround?: number
  strikethroughText?: boolean
  underlineText?: boolean
  wordSpacing?: number
}

const VENDOR_OPTIONS_BOOLEAN = [
  'conflictResolution',
  'followLine',
  'forceLeftToRight',
  'group',
  'labelAllGroup',
  'partials',
  'strikethroughText',
  'underlineText',
]

const VENDOR_OPTIONS_NUMBER = [
  'autoWrap',
  'charSpacing',
  'goodnessOfFit',
  'maxAngleDelta',
  'maxDisplacement',
  'repeat',
  'spaceAround',
  'wordSpacing',
]

const VENDOR_OPTIONS_STRING = [
  'graphic-margin',
  'graphic-resize',
  'polygonAlign',
]

export class GeoserverSldStyleParser extends SldStyleParser {
  override getTextSymbolizerFromSldSymbolizer(
    sldSymbolizer: any
  ): GeoserverTextSymbolizer {
    const finalSymbolizer = super.getTextSymbolizerFromSldSymbolizer(
      sldSymbolizer
    ) as GeoserverTextSymbolizer
    this.assignVendorOptions_(sldSymbolizer, finalSymbolizer)
    return finalSymbolizer
  }

  override getSldTextSymbolizerFromTextSymbolizer(
    textSymbolizer: GeoserverTextSymbolizer
  ): any {
    let finalSymbolizer = super.getSldTextSymbolizerFromTextSymbolizer(
      textSymbolizer
    )
    const vendorOption = this.writeVendorOption_(textSymbolizer)
    finalSymbolizer = finalSymbolizer.concat(vendorOption)
    return finalSymbolizer
  }

  override getFillSymbolizerFromSldSymbolizer(
    sldSymbolizer: any
  ): GeoserverFillSymbolizer {
    const finalSymbolizer = super.getFillSymbolizerFromSldSymbolizer(
      sldSymbolizer
    ) as GeoserverFillSymbolizer
    this.assignVendorOptions_(sldSymbolizer, finalSymbolizer)
    return finalSymbolizer
  }

  override getSldPolygonSymbolizerFromFillSymbolizer(
    fillSymbolizer: GeoserverFillSymbolizer
  ): any {
    let finalSymbolizer = super.getSldPolygonSymbolizerFromFillSymbolizer(
      fillSymbolizer
    )
    const vendorOption = this.writeVendorOption_(fillSymbolizer)
    finalSymbolizer = finalSymbolizer.concat(vendorOption)
    return finalSymbolizer
  }

  writeVendorOption_(symbolizer: BaseSymbolizer) {
    const filtered = Object.keys(symbolizer).filter(
      (propertyName: string) =>
        VENDOR_OPTIONS_BOOLEAN.includes(propertyName) ||
        VENDOR_OPTIONS_NUMBER.includes(propertyName) ||
        VENDOR_OPTIONS_STRING.includes(propertyName)
    )
    const mapped = filtered.map((propertyName: string) => {
      return {
        VendorOption: [
          {
            '#text': (symbolizer as any)[propertyName],
          },
        ],
        ':@': { '@_name': propertyName },
      }
    })
    return mapped
  }

  assignVendorOptions_(
    sldSymbolizer: any,
    finalSymbolizer: BaseSymbolizer
  ): void {
    if (Array.isArray(sldSymbolizer)) {
      sldSymbolizer.forEach((element) => {
        if (Array.isArray(element.VendorOption)) {
          const assignOption = (option: any) => {
            const name = element[':@']['@_name']
            const { '#text': value } = option
            ;(finalSymbolizer as any)[name] = VENDOR_OPTIONS_BOOLEAN.includes(
              name
            )
              ? Boolean(value)
              : VENDOR_OPTIONS_NUMBER.includes(name)
              ? Number(value)
              : value
          }
          element.VendorOption.forEach(assignOption)
        }
      })
    }
  }
}
