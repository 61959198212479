import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'

import OlMap from 'ol/Map'
import View from 'ol/View'

import { MapFacade, MapService } from '@ui/feature/mapstate'
import { Observable } from 'rxjs'
import WMTSSource from 'ol/source/WMTS'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import TileLayer from 'ol/layer/Tile'
import { get as getProj } from 'ol/proj'
import { getWidth } from 'ol/extent'
import { projectionFormats } from '../../../../../projections'
import { ZOOM_FACTOR } from '@ui/feature/shared'

@Component({
  selector: 'ui-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss'],
})
export class MapContainerComponent implements OnInit {
  map: OlMap
  view: View
  @Input() resizeNotification$?: Observable<any>
  @ViewChild('mapTarget', { static: true }) mapTarget: ElementRef
  formatByProjection: Map<String, String>

  constructor(
    private mapService: MapService,
    public facade: MapFacade
  ) {}

  ngOnInit() {
    this.formatByProjection = projectionFormats()
    this.view = new View({ zoomFactor: ZOOM_FACTOR })

    this.map = new OlMap({
      controls: [],
      target: this.mapTarget.nativeElement,
      layers: [],
      view: this.view,
    })
    this.mapService.bindMapToState(this.map)

    // Georef WMTS params
    const levels = 14
    const georefMatrixIds = new Array(levels)
      .fill(0)
      .map((v, i) => `GoogleMapsCompatible:${i}`)
    const georefOrigins = new Array(levels).fill([
      -20037508.342789248, 20037508,
    ])
    const projExtent = getProj('EPSG:3857').getExtent()
    const georefBaseResolution = getWidth(projExtent) / 256
    const georefResolutions = new Array(levels)
      .fill(0)
      .map((v, i) => georefBaseResolution / Math.pow(2, i))

    this.map.addLayer(
      new TileLayer({
        source: new WMTSSource({
          url: 'https://georef.application.developpement-durable.gouv.fr/cache/service/wmts',
          layer: 'georefmonde:GoogleMapsCompatible',
          format: 'image/jpeg',
          matrixSet: 'GoogleMapsCompatible',
          tileGrid: new WMTSTileGrid({
            resolutions: georefResolutions,
            matrixIds: georefMatrixIds,
            origins: georefOrigins,
          }),
          style: '_null',
        }),
        zIndex: -9999,
        opacity: 0.25,
      })
    )

    // Initial view
    this.facade.setView({
      center: [653000, 5725000],
      zoom: 8.5,
    })

    // update size
    if (this.resizeNotification$) {
      this.resizeNotification$.subscribe(() => this.map.updateSize())
    }
  }
}
