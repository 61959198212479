<div
  #scrollElement
  class="resource-item p-3"
  (click)="handleClick()"
  [ngClass]="{
    selectable: isItemSelectable(),
    active: isActive$ | async,
    'voluminous-dataset': isDatasetVoluminous(),
    dragged: isDragged
  }"
  [title]="itemTooltip"
  [draggable]="isItemDraggable()"
  (dragstart)="handleDragStart($event)"
  (dragend)="handleDragEnd()"
>
  <div *ngIf="hasChildren()">
    <button
      type="button"
      class="folder-name btn btn-link p-0 d-flex flex-row align-items-center"
      (click)="handleToggle()"
    >
      <ng-container *ngIf="isGenericFolder()">
        <div class="mr-2">
          <span
            class="fa fa-fw"
            [ngClass]="isOpened ? 'fa-folder-open' : 'fa-folder'"
          ></span>
        </div>
        <span class="text-truncate">{{ item.name || '(vide)' }}</span>
      </ng-container>
      <ng-container *ngIf="isMap()">
        <div class="mr-2">
          <span
            class="fa fa-fw"
            [ngClass]="isOpened ? 'fa-chevron-down' : 'fa-chevron-right'"
          ></span>
        </div>
        <ui-map-preview [mapSummary]="item"></ui-map-preview>
      </ng-container>
    </button>
  </div>
  <div class="d-flex flex-row align-items-center">
    <span
      *ngIf="!hasChildren()"
      [class.disabled]="selectable && getDatasetRejectMessage()"
      class="resource-name flex-grow-1 flex-shrink-1 text-truncate"
    >
      {{ item.name || '(vide)' }}
    </span>
    <button
      class="btn btn-light px-2 py-1 text-nowrap"
      *ngIf="isOgcService()"
      (click)="handleToggle(); loadFullResource()"
      title="Afficher le contenu du service"
    >
      <span class="fa fa-layer-group mr-1"></span>
      <span
        class="fa"
        [ngClass]="isOpened ? 'fa-angle-up' : 'fa-angle-down'"
      ></span>
    </button>
    <button
      *ngIf="editable && isOgcService()"
      #pop="bs-popover"
      type="button"
      class="btn btn-light btn-sm"
      placement="right"
      container="body"
      [outsideClick]="true"
      [popover]="advancedMenu"
      [adaptivePosition]="false"
      title="Paramètres"
    >
      <span class="fa fa-cog"></span>
    </button>

    <ng-template #advancedMenu let-remove="remove" let-toggleEdit="toggleEdit">
      <ul class="list-group list-group-flush">
        <li class="list-group-item p-1" *ngIf="isOgcService()">
          <button
            class="btn btn-link btn-sm"
            (click)="updateService(); pop.hide()"
          >
            <span class="fa fa-fw fa-edit mr-2"></span>Modifier
          </button>
        </li>
        <li class="list-group-item p-1" *ngIf="isOgcService()">
          <button
            class="btn btn-link btn-sm"
            (click)="removeService(); pop.hide()"
          >
            <span class="fa fa-fw fa-trash mr-2"></span>Supprimer
          </button>
        </li>
      </ul>
    </ng-template>
  </div>
  <div *ngIf="isStyle()">
    <span
      class="small text-black-50 view-info"
      *ngIf="item.links?.outgoing?.length"
      [title]="item.links.outgoing[0].parentPath"
    >
      {{ item.links.outgoing[0].name }}
    </span>
  </div>

  <div
    *ngIf="openedOnce && isOgcService()"
    class="service-content overflow-hidden closed"
    [ngClass]="{ opened: isOpened }"
  >
    <div *ngIf="fullResourceLoading$ | async" class="text-muted loading">
      <span class="fa fa-spinner fa-spin mr-1"></span> Chargement...
    </div>
    <div
      *ngIf="fullResourceError$ | async as error"
      class="alert alert-danger small m-0"
      role="alert"
    >
      {{ error }}
    </div>

    <ng-container *ngIf="fullResource$ | async as resource">
      <ng-container [ngSwitch]="resource.service.serverType">
        <ui-tms-service-content
          *ngSwitchCase="'VECTOR_TILES'"
          [remoteServer]="resource.service"
          [layersSelectable]="isOgcLayersSelectable()"
          (closePanel)="handleToggle()"
        ></ui-tms-service-content>
        <ui-ogc-service-content
          *ngSwitchDefault
          [remoteServer]="resource.service"
          [layersSelectable]="isOgcLayersSelectable()"
          (closePanel)="handleToggle()"
        ></ui-ogc-service-content>
      </ng-container>
    </ng-container>
  </div>
</div>
<div
  class="child-level flex-row"
  *ngIf="item.folder && openedOnce"
  [ngClass]="isOpened ? 'd-flex' : 'd-none'"
>
  <div class="pl-3 child-level-indent"></div>
  <ui-tree-level
    class="flex-grow-1 flex-shrink-1 d-block overflow-hidden"
    [path]="getPath()"
    [directAccessPath]="getDirectAccessChild(item.name)"
    [selectable]="selectable"
  ></ui-tree-level>
</div>
