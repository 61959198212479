/**
 * Map Production API
 * This API covers functions related to cartographic composition (creation of maps, styles)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type LayerViewTypeModel =
  | 'WMS'
  | 'WFS'
  | 'WMTS'
  | 'VECTOR_TILES'
  | 'UNKNOWN'

export const LayerViewTypeModel = {
  WMS: 'WMS' as LayerViewTypeModel,
  WFS: 'WFS' as LayerViewTypeModel,
  WMTS: 'WMTS' as LayerViewTypeModel,
  VECTORTILES: 'VECTOR_TILES' as LayerViewTypeModel,
  UNKNOWN: 'UNKNOWN' as LayerViewTypeModel,
}
