import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DirectAccessService {
  constructor() {}

  isLeaf(node: string) {
    if (!node) return false
    const withoutLeadingSlash = this.removeLeadingSlash(node)
    return (
      withoutLeadingSlash.indexOf('/') === -1 &&
      withoutLeadingSlash.indexOf('.') > 0
    )
  }
  getFirstLevel(directAccessPath: string) {
    let firstLevel = ''
    if (directAccessPath) {
      const removeLeadingSlash = this.removeLeadingSlash(directAccessPath)
      firstLevel = removeLeadingSlash.substring(
        0,
        removeLeadingSlash.indexOf('/') > 0
          ? removeLeadingSlash.indexOf('/')
          : removeLeadingSlash.length
      )
    }
    return firstLevel
  }

  removeLeadingSlash(x: string) {
    if (x) {
      return x.indexOf('/') === 0 ? x.substring(1) : x
    }
  }

  getDirectAccessPathNext(currentNode: string, directAccessPath: string) {
    if (this.getFirstLevel(directAccessPath) === currentNode) {
      const withoutLeadingSlash = this.removeLeadingSlash(directAccessPath)
      return withoutLeadingSlash.substring(
        withoutLeadingSlash.indexOf('/'),
        withoutLeadingSlash.length
      )
    }
    return ''
  }
}
