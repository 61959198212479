import { Action } from '@ngrx/store'

export const UPDATE_PROGRESS = '[App] Update Progress'
export const SET_IS_PROCESS_RUNNING = '[App] Set Is Process Running'
export const SET_PROGRESS_START_DATE = '[App] Set Progress Start Date'

export class UpdateProgressValue implements Action {
  readonly type = UPDATE_PROGRESS
  constructor(public payload: number) {}
}

export class SetIsProcessRunning implements Action {
  readonly type = SET_IS_PROCESS_RUNNING
  constructor(public payload: boolean) {}
}

export class SetProgressStartDate implements Action {
  readonly type = SET_PROGRESS_START_DATE
  constructor(public payload: number) {}
}

export type LoadingActions =
  | UpdateProgressValue
  | SetIsProcessRunning
  | SetProgressStartDate
