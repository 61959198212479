import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core'

interface ItemWithId {
  id: string
  [key: string]: unknown
}

@Component({
  selector: 'ui-select-item-list',
  templateUrl: './select-item-list.component.html',
  styleUrls: ['./select-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectItemListComponent {
  @Input() data: { item: ItemWithId; enabled: boolean }[]
  @Input() itemTemplate: TemplateRef<{ item: unknown }>
  @Output() selectedItems = new EventEmitter<unknown[]>()

  get selectedCount() {
    return this.data.reduce((prev, curr) => (curr.enabled ? prev + 1 : prev), 0)
  }

  itemTracker(index: number, datum: { item: ItemWithId; enabled: boolean }) {
    return datum.item.id
  }

  toggleItem(item: ItemWithId) {
    this.selectedItems.emit(
      this.data
        .filter((datum) =>
          datum.item === item ? !datum.enabled : datum.enabled
        )
        .map((datum) => datum.item)
    )
  }

  selectAll() {
    this.selectedItems.emit(this.data.map((datum) => datum.item))
  }

  selectNone() {
    this.selectedItems.emit([])
  }
}
