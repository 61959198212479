import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { initialState, MAP_FEATURE_KEY, reducer } from './+state/map.reducer'
import { MapFacade } from './+state/map.facade'
import { MapService } from './service/map.service'
import { HttpClientModule } from '@angular/common/http'
import { EffectsModule } from '@ngrx/effects'
import { MapEffects } from './+state/map.effects'

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(MAP_FEATURE_KEY, reducer, {
      initialState,
    }),
    HttpClientModule,
    EffectsModule.forFeature([MapEffects]),
  ],
  providers: [MapService, MapFacade],
})
export class FeatureMapstateModule {}
