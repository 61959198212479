import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, Subject, timer } from 'rxjs'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ConfirmPopupComponent } from './confirm/confirm.component'
import { LoadingComponent } from './loading/loading.component'
import { map, switchMap, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  confirmRef: BsModalRef
  hideLoadingMessage = new Subject<void>()

  constructor(private modalService: BsModalService) {}

  confirm(title: string, message: string): Observable<boolean> {
    this.confirmRef = this.modalService.show(ConfirmPopupComponent, {
      initialState: {
        title,
        message,
      },
      class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    })
    return this.confirmRef.content.action
  }

  showLoadingModal(loadingMessage: string): void {
    // when a loading modal is requested, start a short lived observable
    // that will end when the modal is hidden
    of(loadingMessage)
      .pipe(
        map((message) =>
          this.modalService.show(LoadingComponent, {
            initialState: {
              message,
            },
            class: 'modal-dialog-centered',
            ignoreBackdropClick: true,
            keyboard: false,
          })
        ),
        switchMap((modalRef) =>
          // make sure at least 300ms have passed since the modal was created
          forkJoin([timer(300), this.hideLoadingMessage.pipe(take(1))]).pipe(
            map(() => modalRef)
          )
        )
      )
      .subscribe((modalRef) => modalRef.hide())
  }

  hideLoadingModal(): void {
    this.hideLoadingMessage.next()
  }
}
