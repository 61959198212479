import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core'

@Component({
  selector: 'ui-map-publication-status',
  templateUrl: './map-publication-status.component.html',
  styleUrls: ['./map-publication-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPublicationStatusComponent implements OnInit {
  @Input() published: boolean
  @Input() reportSuccess?: boolean
  @Input() reportMessage?: string

  constructor() {}

  ngOnInit() {}

  get isNotPublished() {
    return !this.published
  }
  get isPublished() {
    return this.published && this.reportSuccess === undefined
  }
  get isPublishedError() {
    return this.published && this.reportSuccess === false
  }
  get isPublishedSuccess() {
    return this.published && this.reportSuccess === true
  }

  get tooltip() {
    if (this.published)
      return `Cette carte a fait l'objet d'une demande de publication dans GI Catalogue.${
        this.reportMessage ? '\r\n' + this.reportMessage : ''
      }`
    if (!this.published)
      return "Cette carte n'est pas publiée dans GI Catalogue."
  }
}
