import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule, Routes } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { MetaReducer, StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import {
  FeatureMapstateModule,
  GPF_PRIVATE_PATTERN_URL,
  WFS_MAX_FEATURES,
} from '@ui/feature/mapstate'
import { UiMapModule, WFS_THEMATIC_MAX_FEATURES } from '@ui/ui/map'
import { UiResourcesModule } from '@ui/ui/resources'
import { FeatureStylingModule, StyleService } from '@ui/feature/styling'

import { environment } from '../environments/environment'

import { AppComponent } from './app.component'
import { MapProductionComponent } from './presentation/page/map-production/map-production.component'
import { MapContainerComponent } from './presentation/page/map-production/map-container/map-container.component'
import { MAP_PRODUCTION_ROUTE } from './routes'

import { CustomSerializer, effects, reducers } from './store'
import { UiLayoutModule } from '@ui/ui/layout'
import {
  ApiModule as MapProductionApiModule,
  BASE_PATH as MAP_PRODUCTION_BASE_PATH,
  Configuration,
} from '@ui/data-access-carto-map-production'
import {
  ApiModule as ResourceManagementApiModule,
  BASE_PATH as RESOURCE_EXPLORATION_BASE_PATH,
  ResourceExplorationService,
} from '@ui/data-access-carto-resource-management'
import {
  BASE_PATH as FUNCTIONAL_ADMINISTRATION_BASE_PATH,
  UserManagementService,
} from '@ui/data-access-carto-functional-administration'
import {
  ApiModule as MapPublishingApiModule,
  BASE_PATH as MAP_PUBLISHING_BASE_PATH,
} from '@ui/data-access-carto-map-publishing'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { ProgressbarModule, ProgressbarConfig } from 'ngx-bootstrap/progressbar'
import { CreateMapComponent } from './presentation/components/create-map/create-map.component'
import { CreateMapModalComponent } from './presentation/components/create-map/create-map-modal/create-map-modal.component'
import { AlertModule } from 'ngx-bootstrap/alert'
import { OpenMapModalComponent } from './presentation/components/open-map/open-map-modal/open-map-modal.component'
import { OpenMapComponent } from './presentation/components/open-map/open-map.component'
import { DeleteMapModalComponent } from './presentation/components/delete-map/delete-map-modal/delete-map-modal.component'
import { DeleteMapComponent } from './presentation/components/delete-map/delete-map.component'
import SETTINGS from '../settings'
import { StylePanelComponent } from './presentation/components/style-panel/style-panel.component'
import { ThematicPanelComponent } from './presentation/components/thematic-panel/thematic-panel.component'
import { RemoteServerComponent } from './presentation/components/remote-service/remote-service.component'
import { RemoteServerModalComponent } from './presentation/components/remote-service/remote-service-modal/remote-service-modal.component'
import USER_INFO from '../userinfo'
import {
  AUTHENTICATION_REDIRECT_PATH,
  AuthGuardService,
  IS_AUTHENTICATED,
} from './services/auth-guard.service'
import {
  GETLEGENDGRAPHIC_LAYER,
  GETLEGENDGRAPHIC_URL,
  MAX_OBJECTS_COUNT_FILTER,
  PROXY_PATH,
} from '@ui/feature/shared'
import { FooterComponent } from './presentation/components/footer/footer.component'
import {
  HeaderComponent,
  LOGOUT_URL,
  ORGANIZATION_NAME,
  ORGANIZATIONS,
  USER_NAME,
} from './presentation/components/header/header.component'
import { AdvancedEditModule } from './presentation/components/advanced-edit/advanced-edit.module'
import { MapOptionsMenuComponent } from './presentation/components/map-options-menu/map-options-menu.component'
import { UiCartoModule } from '@ui/ui/carto'
import { ImportQgisMapComponent } from './presentation/components/import-qgis-map/import-qgis-map.component'
import { LoadingProgressComponent } from './presentation/components/loading-progress/loading-progress.component'
import { IrreparableLayersModalComponent } from './presentation/components/irreparable-layers-modal/irreparable-layers-modal.component'
import { UncleanableLayersModalComponent } from './presentation/components/uncleanable-layers-modal/uncleanable-layers-modal.component'
import { UpdateQgisMapComponent } from './presentation/components/update-qgis-map/update-qgis-map.component'
import { EditAttributesComponent } from './presentation/components/edit-attributes/edit-attributes.component'
import { AttributeRightGridComponent } from './presentation/components/edit-attributes/attribute-right-grid/attribute-right-grid.component'

export const metaReducers: MetaReducer<any>[] = environment.production ? [] : []

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: MAP_PRODUCTION_ROUTE,
      },
      {
        path: MAP_PRODUCTION_ROUTE,
        component: MapProductionComponent,
        children: [
          {
            path: ':id',
            component: MapProductionComponent,
          },
        ],
      },
    ],
  },
]

// configuration to include credentials in all requests to carto-api
// without this, cross origin requests to carto-api will not hold user sessions
export function apiConfigurationFactory() {
  return new Configuration({
    withCredentials: true,
  })
}

@NgModule({
  declarations: [
    AppComponent,
    MapProductionComponent,
    MapContainerComponent,
    CreateMapComponent,
    CreateMapModalComponent,
    ImportQgisMapComponent,
    OpenMapModalComponent,
    OpenMapComponent,
    DeleteMapModalComponent,
    DeleteMapComponent,
    StylePanelComponent,
    ThematicPanelComponent,
    RemoteServerComponent,
    RemoteServerModalComponent,
    FooterComponent,
    HeaderComponent,
    MapOptionsMenuComponent,
    LoadingProgressComponent,
    IrreparableLayersModalComponent,
    UncleanableLayersModalComponent,
    UpdateQgisMapComponent,
    EditAttributesComponent,
    AttributeRightGridComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledNonBlocking',
    }),
    FeatureMapstateModule,
    FeatureStylingModule,
    UiLayoutModule,
    UiMapModule,
    UiResourcesModule,
    UiCartoModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    AlertModule.forRoot(),
    ResourceManagementApiModule.forRoot(apiConfigurationFactory),
    MapProductionApiModule.forRoot(apiConfigurationFactory),
    MapPublishingApiModule.forRoot(apiConfigurationFactory),
    AdvancedEditModule,
    ProgressbarModule,
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: MAP_PRODUCTION_BASE_PATH,
      useFactory: () => SETTINGS.cartoApiPath,
    },
    {
      provide: FUNCTIONAL_ADMINISTRATION_BASE_PATH,
      useFactory: () => SETTINGS.cartoApiPath,
    },
    {
      provide: RESOURCE_EXPLORATION_BASE_PATH,
      useFactory: () => SETTINGS.cartoApiPath,
    },
    {
      provide: MAP_PUBLISHING_BASE_PATH,
      useFactory: () => SETTINGS.cartoApiPath,
    },
    ProgressbarConfig,
    ResourceExplorationService,
    StyleService,
    AuthGuardService,
    UserManagementService,
    {
      provide: PROXY_PATH,
      useFactory: () => SETTINGS.mapProxyPath,
    },
    {
      provide: USER_NAME,
      useFactory: () =>
        `${USER_INFO.user && USER_INFO.user.prenom} ${
          USER_INFO.user && USER_INFO.user.nom
        }`,
    },
    {
      provide: LOGOUT_URL,
      useFactory: () => USER_INFO.logoutUrl,
    },
    {
      provide: ORGANIZATION_NAME,
      useFactory: () =>
        USER_INFO.user && USER_INFO.user.organization
          ? USER_INFO.user.organization.name
          : '(organisation inconnue)',
    },
    {
      provide: ORGANIZATIONS,
      useFactory: () =>
        USER_INFO.user && USER_INFO.user.organizations
          ? USER_INFO.user.organizations
          : {},
    },
    {
      provide: GETLEGENDGRAPHIC_URL,
      useFactory: () => SETTINGS.getLegendGraphicUrl,
    },
    {
      provide: GETLEGENDGRAPHIC_LAYER,
      useFactory: () => SETTINGS.getLegendGraphicLayer,
    },
    {
      provide: MAX_OBJECTS_COUNT_FILTER,
      useFactory: () => SETTINGS.maxObjectsCountFilter,
    },
    {
      provide: WFS_MAX_FEATURES,
      useFactory: () => SETTINGS.wfsMaxFeatures,
    },
    {
      provide: WFS_THEMATIC_MAX_FEATURES,
      useFactory: () => SETTINGS.wfsThematicMaxFeatures,
    },
    {
      provide: GPF_PRIVATE_PATTERN_URL,
      useFactory: () => SETTINGS.geoplateformePrivatePatternUrl,
    },
    {
      provide: AUTHENTICATION_REDIRECT_PATH,
      useFactory: () =>
        SETTINGS.cartoApiPath + '/AuthRedirect?url=' + window.location,
    },
    {
      provide: IS_AUTHENTICATED,
      useFactory: () => !!USER_INFO.user,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
