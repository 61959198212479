import { Directive, ElementRef, HostListener } from '@angular/core'

@Directive({
  selector: '[uiConstrainMinMaxValue]',
})
export class UiConstrainMinMaxValueDirective {
  constructor(private el: ElementRef) {}

  @HostListener('change') onChange() {
    const minValue = this.el.nativeElement.min ? this.el.nativeElement.min : 0
    const maxValue = this.el.nativeElement.max ? this.el.nativeElement.max : 1

    if (this.getValue() > maxValue) {
      this.setValue(maxValue)
    }
    if (this.getValue() < minValue) {
      this.setValue(minValue)
    }
  }
  private setValue(value: number) {
    this.el.nativeElement.value = value
  }
  private getValue() {
    return this.el.nativeElement.value
  }
}
