<div
  class="layertree-element layer-error-loading"
  [ngClass]="{
    'layer-error': hasError(),
    'layer-error-loading': hasLoadingError()
  }"
  #rootEl
  (dragover)="handleDragOver($event)"
  (dragenter)="handleDragEnter()"
  (dragleave)="handleDragLeave()"
  (drop)="handleDrop($event)"
>
  <div [ngClass]="{ overlay: isDraggedOver }"></div>
  <div class="d-flex flex-row w-100 align-items-center header">
    <ng-content></ng-content>
    <button
      type="button"
      class="visibility-toggle btn btn-sm"
      [ngClass]="
        (isVisibilityIconDisabled | async)
          ? 'btn-outline-secondary'
          : 'btn-light'
      "
      (click)="setVisibility(!layer?.visible)"
      [attr.title]="
        (getVisibilityIconDisabledReason$ | async) +
        (layer?.visible ? 'Cacher' : 'Afficher') +
        ' ' +
        typeLabel
      "
      [attr.disabled]="readonly || null"
    >
      <span
        class="fa"
        [ngClass]="layer?.visible ? 'fa-eye' : 'fa-eye-slash'"
      ></span>
    </button>
    <span
      *ngIf="hasSpecError"
      class="ml-1 fa fa-exclamation-triangle text-warning"
      title="Aucune donnée associée à cette couche"
    ></span>
    <input
      #layertitle
      *ngIf="(titleEditable$ | async) === true"
      class="flex-grow-1 flex-shrink-1 form-control form-control-sm px-1 py-0 m-0 title-input"
      [formControl]="title"
      (keyup.enter)="layertitle.blur()"
      (blur)="commitNewTitle()"
    />
    <button
      *ngIf="(titleEditable$ | async) === false"
      type="button"
      class="flex-grow-1 flex-shrink-1 btn btn-link btn-sm px-1 py-0 m-0 text-truncate title"
      title="{{ layer?.title }}"
      (click)="toggleActive()"
    >
      <span
        *ngIf="isGroup()"
        class="mr-1 fa folder-icon"
        [ngClass]="groupExpanded ? 'fa-folder-open' : 'fa-folder'"
      ></span
      ><span
        *ngIf="hasError()"
        class="mr-1 fa fa-exclamation-circle layer-error-info"
        title="Cette couche ne peut pas s'afficher correctement."
      ></span
      >{{ layer?.title }}
    </button>
    <div
      class="pr-1 pt-1 mr-1 icon-remote"
      *ngIf="isLayerExternal"
      [title]="'Service externe de type ' + view?.serviceType"
    >
      <span class="fa fa-cloud-download-alt"></span>
    </div>
    <div
      class="mr-2 icon-qgis"
      *ngIf="isLayerQgis"
      [title]="'Couche provenant d\'un projet QGIS'"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <path
          d="M17.172 17.344v4.865l-4.005-4.063v-4.661h4.776l3.802 3.859zM31.688 27.484v4.516h-4.875l-7.635-7.865v-4.734h4.469zM17.25 25.552l4.495 4.495c-1.745 0.589-3.406 0.969-5.766 0.969-9.13 0-15.979-6.693-15.979-15.594 0-8.859 6.849-15.422 15.979-15.422 9.172 0 16.021 6.604 16.021 15.422 0 2.823-0.698 5.432-1.943 7.677l-4.833-4.969c0.214-0.875 0.323-1.771 0.318-2.672 0-5.719-4.193-10.036-9.464-10.036s-9.667 4.318-9.667 10.036c0 5.724 4.443 10.135 9.667 10.135 0.729 0 0.88 0.021 1.172-0.042z"
        />
      </svg>
    </div>
    <span
      *ngIf="hasLoadingError()"
      class="icon-layer-error-loading fa fa-exclamation-triangle"
      [title]="getLoadingErrorText()"
    ></span>
    <ui-layer-publication-status
      *ngIf="
        !hasLoadingError() && hasPublicationStatus && publicationStatus$
          | async as publicationStatus
      "
      [state]="publicationStatus.state"
      [scope]="publicationStatus.scope"
    ></ui-layer-publication-status>
    <ng-template #popTemplate let-remove="remove" let-editTitle="editTitle">
      <ul class="list-group list-group-flush">
        <li class="list-group-item p-1">
          <button
            class="btn btn-link btn-sm edit-title"
            (click)="editTitle(); pop.hide()"
          >
            <span class="fa fa-fw fa-edit mr-2"></span>Renommer {{ typeLabel }}
          </button>
        </li>
        <li class="list-group-item p-1">
          <button class="btn btn-link btn-sm" (click)="remove(); pop.hide()">
            <span class="fa fa-fw fa-trash mr-2"></span>Supprimer
            {{ typeLabel }}
          </button>
        </li>
        <li class="list-group-item p-1 duplicate" *ngIf="canDuplicate()">
          <button class="btn btn-link btn-sm" (click)="duplicate(); pop.hide()">
            <span class="fa fa-fw fa-copy mr-2"></span>Dupliquer {{ typeLabel }}
          </button>
        </li>
        <li
          class="list-group-item p-1 link-data"
          *ngIf="!isGroup() && !(isLayerExternal || isLayerQgis)"
        >
          <button
            class="btn btn-link btn-sm"
            (click)="associateJdd(); pop.hide()"
          >
            <span class="fa fa-fw fa-database mr-2"></span>Remplacer la source
            de donnée
          </button>
        </li>
        <li *ngIf="canEditStyle && !hasStyle" class="list-group-item p-1">
          <button
            class="btn btn-link btn-sm"
            (click)="createStyle(); pop.hide()"
          >
            <span class="fa fa-fw fa-adjust mr-2"></span>Associer un style
          </button>
        </li>
        <li *ngIf="hasStyle" class="list-group-item p-1">
          <button class="btn btn-link btn-sm" (click)="editStyle(); pop.hide()">
            <span class="fa fa-fw fa-sliders-h mr-2"></span>Éditer le style
          </button>
        </li>
        <li *ngIf="hasStyle" class="list-group-item p-1">
          <button
            class="btn btn-link btn-sm"
            (click)="removeStyle(); pop.hide()"
          >
            <span class="fa fa-fw fa-trash mr-2"></span>Supprimer le style
          </button>
        </li>
        <li
          *ngIf="canEditStyle"
          class="list-group-item p-1 open-thematic"
          [title]="canEditThematicStyleTitle$ | async"
        >
          <button
            class="btn btn-link btn-sm"
            (click)="openThematicStyling(); pop.hide()"
            [disabled]="(canEditThematicStyle$ | async) !== true"
          >
            <span class="fa fa-fw fa-chart-pie mr-2"></span>Configurer une
            analyse thématique
          </button>
        </li>
        <li class="list-group-item p-1" *ngIf="!isLayerExternal">
          <button
            class="btn btn-link btn-sm"
            (click)="editAttributes(); pop.hide()"
          >
            <span class="fa fa-fw fa-table mr-2"></span>Gestion des attributs
          </button>
        </li>
        <li class="list-group-item p-1">
          <button
            class="btn btn-link btn-sm"
            (click)="editLayer(); pop.hide()"
            [disabled]="isGroup()"
          >
            <span class="fa fa-fw fa-cogs mr-2"></span>Paramètres avancés
          </button>
        </li>
        <li *ngIf="isGroup()" class="list-group-item p-1">
          <button class="btn btn-link btn-sm" (click)="addLayer(); pop.hide()">
            <span class="fa fa-fw fa-plus-square mr-2"></span>Ajouter une couche
          </button>
        </li>
        <li *ngIf="isGroup()" class="list-group-item p-1">
          <button
            class="btn btn-link btn-sm"
            (click)="addLayerGroup(); pop.hide()"
          >
            <span class="fa fa-fw fa-plus-square mr-2"></span>Ajouter un groupe
          </button>
        </li>
      </ul>
    </ng-template>
    <button
      #pop="bs-popover"
      type="button"
      class="btn btn-light btn-sm advanced-menu-toggle"
      placement="right"
      [container]="popoverContainer"
      [outsideClick]="true"
      [popover]="popTemplate"
      [popoverContext]="context()"
      [adaptivePosition]="false"
      title="Paramètres"
      [attr.disabled]="readonly || null"
    >
      <span class="fa fa-cog"></span>
    </button>
  </div>
  <div
    [ngClass]="{ unfolded: active$ | async }"
    *ngIf="!isGroup()"
    class="infos"
  >
    <div class="form-group row">
      <label class="col-sm-6 col-form-label">Opacité</label>
      <input
        type="range"
        class="col-sm-6 form-control-range opacity-slider"
        min="0"
        max="1"
        step="0.05"
        [(ngModel)]="opacity"
        [disabled]="readonly || null"
      />
    </div>
    <div *ngIf="(activeOnce$ | async) && (legendUrl$ | async) as legendUrl">
      <img
        [src]="legendUrl"
        (error)="fallbackLegendGraphic($event)"
        alt="légende"
        class="legend"
        #img
      />
    </div>
  </div>
</div>

<div
  *ngIf="isGroup()"
  class="pl-3"
  [collapse]="!groupExpanded"
  [isAnimated]="false"
>
  <ui-layertree [parentId]="layer.id" [depth]="this.depth"></ui-layertree>
</div>
