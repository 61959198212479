import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

export enum MinMax {
  Min = 'Min',
  Max = 'Max',
}

@Component({
  selector: 'ui-min-max-scale-input',
  templateUrl: './min-max-scale-input.component.html',
  styleUrls: ['./min-max-scale-input.component.scss'],
})
export class MinMaxScaleInputComponent implements OnInit {
  @Input() parentForm: FormGroup
  @Input() type: MinMax
  @Input() tip?: string

  MinMax = MinMax

  constructor() {}

  ngOnInit() {}

  isInvalid() {
    return this.getControl().errors
  }

  getControl() {
    const controls = this.parentForm.controls
    const control = controls[this.getFormControlName()]
    return control
  }

  getFormControlName() {
    return this.type === MinMax.Min ? 'minScale' : 'maxScale'
  }

  getLabel() {
    return `Plus ${
      this.type === MinMax.Min ? 'grande' : 'petite'
    } échelle de visualisation`
  }

  onKeydownFilterNumber(event: KeyboardEvent) {
    if (
      ![
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Delete',
        'Backspace',
        'Enter',
        'Tab',
      ].includes(event.code) &&
      /\D/.test(event.key)
    ) {
      event.preventDefault()
    }
  }
}
