<form [formGroup]="thematicStyleEditorForm">
  <div class="px-3 pt-3 form-row">
    <label class="col-5 col-form-label text-muted">Type d'analyse</label>
    <div class="col-7">
      <select
        class="form-control form-control-sm thematic-type"
        #thematicTypeSelect
      >
        <option
          [selected]="(type$ | async) === types.CLASSIFICATION"
          [value]="types.CLASSIFICATION"
        >
          Classes de valeur
        </option>
        <option
          [selected]="(type$ | async) === types.VALUES"
          [value]="types.VALUES"
        >
          Valeurs uniques
        </option>
      </select>
    </div>
  </div>

  <hr class="mb-0" />

  <alert
    type="info"
    *ngIf="(featuresError$ | async) === null"
    [collapse]="(featuresLoading$ | async) === false"
    [isAnimated]="true"
  >
    <span class="fa fa-spinner fa-spin"></span> Chargement des données...
  </alert>
  <alert type="danger" *ngIf="featuresError$ | async">
    Une erreur est survenue lors du chargement des données.
  </alert>

  <div class="mask" *ngIf="featuresError$ | async"></div>

  <div class="px-3 pt-3 pb-2 font-weight-bold small text-dark">
    PARAMETRES D'ANALYSE
  </div>

  <div class="px-3 pb-1 form-row">
    <label class="col-6 col-form-label text-muted">Nom du champ</label>
    <div class="col-6">
      <div class="btn-group btn-block" dropdown [container]="dropdownContainer">
        <button
          dropdownToggle
          type="button"
          class="btn btn-sm btn-outline-dark dropdown-toggle d-flex align-items-center w-100"
        >
          <div class="flex-grow-1 flex-shrink-1 text-truncate text-left">
            {{ selectedFieldName$ | async }}
          </div>
          <span class="caret"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <button
            type="button"
            *ngFor="let field of availableFields$ | async"
            class="dropdown-item small field-available"
            [ngClass]="
              (selectedFieldName$ | async) === field.name ? 'active' : ''
            "
            (click)="selectFieldEmitter$.emit(field)"
          >
            <strong>{{ field.name }}</strong
            ><br />
            {{ getDisplayFieldInfo(field) }}<br />
            <span class="text-success"
              ><span class="fa fa-check"></span> Utilisable dans l'analyse
              thématique</span
            >
          </button>
          <li class="divider dropdown-divider"></li>
          <button
            type="button"
            *ngFor="let field of unavailableFields$ | async"
            class="dropdown-item disabled small field-unavailable"
          >
            <strong>{{ field.name }}</strong
            ><br />
            {{ getDisplayFieldInfo(field) }}<br />
            <span class="fa fa-ban"></span> Non-utilisable :
            {{ getDisplayRejectReason(field.rejectReason) }}
          </button>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="px-3 pb-1 form-row"
    *ngIf="(type$ | async) === types.CLASSIFICATION"
  >
    <label class="col-6 col-form-label text-muted">Nombre de classe</label>
    <div class="col-3">
      <input
        class="form-control form-control-sm"
        type="number"
        step="1"
        min="1"
        [max]="maxRulesCount$ | async"
        [(ngModel)]="classesCount"
        [ngModelOptions]="{ standalone: true }"
      />
    </div>
  </div>
  <div
    class="px-3 form-row align-items-center"
    *ngIf="(type$ | async) === types.CLASSIFICATION"
  >
    <label class="col-6 col-form-label text-muted"
      >Méthode de classification</label
    >
    <div class="col-6">
      <select
        class="form-control form-control-sm"
        [(ngModel)]="classificationType"
        [ngModelOptions]="{ standalone: true }"
      >
        <option [value]="classificationTypes.EQUAL">Intervalle régulier</option>
        <option [value]="classificationTypes.QUANTILE">
          Répartition régulière
        </option>
        <option [value]="classificationTypes.KMEANS">K-means</option>
      </select>
    </div>
  </div>
  <div class="px-3 form-row" *ngIf="(type$ | async) === types.CLASSIFICATION">
    <label class="col-6 col-form-label text-muted" for="activateRounding"
      >Forcer l'arrondi
      <span
        title="Doit indiquer la position de l'arrondi.
Exemple : 1, 10, 100, 1000...
Un arrondi trop élevé réduira le nombre de classe."
        class="ml-2 fa fa-question-circle"
      ></span
    ></label>
    <div class="col-6 pt-2">
      <input
        id="activateRounding"
        type="checkbox"
        (change)="roundingClassesActive = !roundingClassesActive"
      />
      <input
        id="roundingValue"
        type="number"
        min="1"
        class="form-control form-control-sm"
        formControlName="roundingClasses"
        (keydown)="onKeydownRoundingClasses($event)"
        (paste)="onPasteRoundingClasses($event)"
        [ngClass]="{
          'is-invalid':
            thematicStyleEditorForm.controls['roundingClasses'].invalid,
          valid: thematicStyleEditorForm.controls['roundingClasses'].valid
        }"
      />
      <div
        *ngIf="
          thematicStyleEditorForm.controls['roundingClasses'].invalid &&
          thematicStyleEditorForm.dirty
        "
        class="invalid-feedback"
      >
        La valeur saisie doit être 1, 10, 100, 1000...
      </div>
    </div>
  </div>

  <hr />

  <div class="px-3 pb-2 font-weight-bold small text-dark">
    PARAMETRES DE REPRESENTATION
  </div>

  <div class="px-3 pb-1 form-row">
    <label class="col-6 col-form-label text-muted">Géométrie</label>
    <div class="col-6">
      <select
        class="form-control form-control-sm element-type"
        #elementTypeSelect
      >
        <option
          *ngFor="let type of elementTypesArray"
          [selected]="(elementType$ | async) === type"
          [value]="type"
        >
          {{ elementTypeLabels[type] }}
        </option>
      </select>
    </div>
  </div>
  <div class="px-3 pb-3 form-row">
    <label class="col-6 col-form-label text-muted">Colormap</label>
    <div class="col-6">
      <select class="form-control form-control-sm" disabled>
        <option *ngIf="(usesInterpolation$ | async) === true">Manuel</option>
        <option *ngIf="(usesInterpolation$ | async) === false">
          Aléatoire
        </option>
      </select>
    </div>
  </div>

  <ui-ruleset-generator-interpolation
    *ngIf="(usesInterpolation$ | async) === true"
    #rulesetGenerator
    [elementType]="elementType$ | async"
  ></ui-ruleset-generator-interpolation>

  <ui-ruleset-generator-random
    *ngIf="(usesInterpolation$ | async) === false"
    #rulesetGenerator
    [elementType]="elementType$ | async"
  ></ui-ruleset-generator-random>

  <hr class="m-0" />

  <div class="px-3 pt-3 pb-2 font-weight-bold small text-dark">
    PARAMETRES DE LEGENDE
  </div>

  <div class="px-3 pb-1">
    <div class="form-check">
      <label class="form-label text-muted"
        ><input
          class="form-check-input rule-name-customized"
          type="checkbox"
          [checked]="ruleNameCustomized$ | async"
          #ruleNameCustomized
        />
        Personnaliser la légende</label
      >
    </div>
    <div class="form-group">
      <input
        class="form-control form-control-sm rule-name-template"
        [disabled]="(ruleNameCustomized$ | async) === false"
        [value]="ruleNameTemplate$ | async"
        #ruleNameTemplate
        type="text"
      />
    </div>
  </div>
</form>
