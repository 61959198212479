<div
  #container
  class="container-fluid d-flex map-composer"
  [style.height.px]="height"
  (window:resize)="recomputeHeight()"
>
  <div class="alert-container mt-2 mr-2">
    <alert
      *ngFor="let alert of notificationFacade_.notifications$ | async"
      [type]="alert.type"
      dismissible="true"
    >
      <div [innerHtml]="alert.message"></div>
    </alert>
  </div>

  <div class="ui-panels">
    <ui-panel
      panelTitle="{{ mapTitle$ | async }}"
      toggleLabel="Carte"
      [collapsible]="true"
      [initiallyCollapsed]="false"
    >
      <div extra-left class="ui-statuses">
        <ui-map-exposition-status
          class="mr-1"
          [exposed]="(mapExpositionStatus$ | async).exposed"
          [synchronized]="(mapExpositionStatus$ | async).synchronised"
        ></ui-map-exposition-status>
        <ui-map-publication-status
          class="mr-1"
          [published]="(mapExpositionStatus$ | async).published"
          [reportSuccess]="(mapPublicationReport$ | async).success"
          [reportMessage]="(mapPublicationReport$ | async).message"
        ></ui-map-publication-status>
        <span
          *ngIf="(mapSpecLayersInOriginError$ | async)?.length"
          class="align-self-center fa fa-exclamation-triangle text-warning"
          title="Aucune donnée associée {{
            (mapSpecLayersInOriginError$ | async)?.length > 1
              ? 'aux couches :'
              : 'à la couche'
          }} {{ (mapSpecLayersInOriginError$ | async)?.join(', ') }}"
        ></span>
      </div>

      <ui-map-options-menu
        extra
        [disabled]="disabled$ | async"
        [outOfSync]="
          (mapExpositionStatus$ | async).exposed &&
          (mapExpositionStatus$ | async).synchronised !== true
        "
        [publishable]="(mapExpositionStatus$ | async).exposed"
        [published]="(mapExpositionStatus$ | async).published"
        [isMapBasedOnGpkg]="(mapQgisInfo$ | async).havingQGisProject"
        (openSettings)="editMap()"
        (syncExposedWithCurrent)="handleSyncExposedWithCurrent()"
        (syncCurrentWithExposed)="handleSyncCurrentWithExposed()"
        (openMetadata)="editMetadata()"
        (openExpositionOptions)="editExpositionOptions()"
        (publish)="handlePublish()"
        (unpublish)="handleUnpublish()"
        (repair)="handleRepair()"
        (updateQGIS)="modalEventService_.openUpdateQgisModal()"
        (resetQGIS)="handleResetQgisMap()"
      ></ui-map-options-menu>

      <div body>
        <div
          *ngIf="isDefaultMap$ | async"
          class="alert alert-warning readonly-info"
        >
          <span class="fa fa-exclamation-circle mr-2"></span>La carte est en
          lecture seule
        </div>
        <div *ngIf="noMapLoaded$ | async" class="alert alert-danger nomap-info">
          <span class="fa fa-exclamation-circle mr-2"></span>Aucune carte n'a
          été chargée
        </div>
        <ui-layertree></ui-layertree>
      </div>
      <div footer>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary add-layer"
          (click)="addLayer()"
          [attr.disabled]="(disabled$ | async) || null"
        >
          Ajouter une couche
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary add-layer-group"
          (click)="addLayerGroup()"
          [attr.disabled]="(disabled$ | async) || null"
        >
          Ajouter un groupe
        </button>
      </div>
    </ui-panel>

    <ui-panel
      [panelTitle]="getPanelTitle()"
      [panelSubtitle]="
        resourcesFocused ? 'Sélectionner une ressource du patrimoine' : null
      "
      [collapsible]="true"
      [initiallyCollapsed]="true"
      [focused]="resourcesFocused"
      [wider]="true"
    >
      <ui-manager
        body
        [selectable]="resourcesFocused"
        [directAccessPath]="directAccessPath"
        [defaultItems]=""
        [activableTypes]="
          isAssociating
            ? [ResourceTypeEnum.DATASET]
            : [
                ResourceTypeEnum.DATASET,
                ResourceTypeEnum.SERVICE,
                ResourceTypeEnum.STYLE,
                ResourceTypeEnum.GEOPLATEFORME
              ]
        "
        [activeType]="resourceType"
        (typeChange)="onResourcesTypeChanged($event)"
      ></ui-manager>
      <div footer *ngIf="resourcesFocused">
        <button
          type="button"
          class="btn btn-sm btn-light"
          (click)="cancelResourceSelection()"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-sm"
          [class]="
            resourceType === ResourceTypeEnum.GEOPLATEFORME
              ? 'btn-outline-secondary'
              : 'btn-outline-primary'
          "
          [disabled]="!canConfirmResource()"
          (click)="confirmResource()"
          [title]="getConfirmTitle()"
        >
          Valider
        </button>
      </div>
      <div footer *ngIf="resourceType === 'services' && !resourcesFocused">
        <button
          type="button"
          class="btn btn-sm btn-outline-primary create-remote-server"
          (click)="createRemoteServer()"
        >
          Ajouter un serveur OGC
        </button>
      </div>
    </ui-panel>

    <ui-panel
      [collapsible]="false"
      [initiallyCollapsed]="true"
      panelTitle="Éditeur de style"
      #stylePanel
    >
      <ui-style-panel body></ui-style-panel>
      <div footer>
        <button
          type="button"
          class="btn btn-sm btn-light"
          (click)="cancelStyleEdition()"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          [ngClass]="{ disabled: styleFacade_.loading$ | async }"
          (click)="saveStyle()"
        >
          <span
            class="fa fa-spinner fa-spin mr-1"
            *ngIf="styleFacade_.loading$ | async"
          ></span
          >Enregistrer
        </button>
      </div>
    </ui-panel>

    <ui-panel
      [collapsible]="false"
      [initiallyCollapsed]="true"
      panelTitle="Analyse thématique"
      #thematicPanel
    >
      <div body>
        <ui-thematic-panel #thematicPanelBody></ui-thematic-panel>
      </div>
      <div footer>
        <button
          type="button"
          class="btn btn-sm btn-light"
          (click)="cancelThematicStyling()"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary"
          (click)="thematicPanelBody.generateStyle()"
          [disabled]="(isThematicFormValid$ | async) === false"
        >
          Générer
        </button>
      </div>
    </ui-panel>
  </div>

  <ui-map-container [resizeNotification$]="resizeSubject" class="flex-grow-1">
  </ui-map-container>
  <ui-create-map [showModal$]="modalEventService_.createMap$"></ui-create-map>
  <ui-import-qgis-map
    [showModal$]="modalEventService_.importQgis$"
  ></ui-import-qgis-map>
  <ui-update-qgis-map
    [showModal$]="modalEventService_.updateQgis$"
  ></ui-update-qgis-map>
  <ui-open-map [showModal$]="modalEventService_.loadMap$"></ui-open-map>
  <ui-delete-map [showModal$]="modalEventService_.deleteMap$"></ui-delete-map>
  <ui-edit-map [showModal$]="openEditMap$"></ui-edit-map>
  <ui-edit-metadata [showModal$]="openEditMetadata$"></ui-edit-metadata>
  <ui-edit-exposition-options
    [showModal$]="openEditExpositionOptions$"
  ></ui-edit-exposition-options>
  <ui-remote-service></ui-remote-service>
  <ui-edit-layer
    [currentLayer$]="mapUiEventService_.editLayer$"
  ></ui-edit-layer>
  <ui-edit-attributes
    [currentLayer$]="mapUiEventService_.editAttributes$"
  ></ui-edit-attributes>
  <ui-loading-progress></ui-loading-progress>
  <ui-irreparable-layers-modal
    [showModal$]="modalEventService_.manageIrreparableLayers$"
  ></ui-irreparable-layers-modal>
  <ui-uncleanable-layers-modal
    [showModal$]="modalEventService_.showUncleanableLayers$"
  ></ui-uncleanable-layers-modal>
</div>
