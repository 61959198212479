import { Injectable } from '@angular/core'
import {
  RefreshLayer,
  UpdateLayerOnDuplicatedStyle,
} from '../+state/map.actions'
import OlMap from 'ol/Map'
import { map, throttleTime } from 'rxjs/operators'
import { MapFacade } from '../+state/map.facade'
import { LayerViewStyle } from '../map.model'
import { OpenlayersService } from './openlayers.service'
import { UtilsService } from './utils.service'
import { Group, isMapElementGroup, Layer } from '@ui/feature/shared'

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private facade: MapFacade,
    private utils: UtilsService,
    private olService: OpenlayersService
  ) {}

  bindMapToState(olMap: OlMap) {
    this.facade.rootMapElementId$.subscribe((rootElementId) =>
      this.olService.setRootMapElementId(olMap, rootElementId)
    )

    this.facade.requestedCenter$.subscribe((center: [number, number]) => {
      this.olService.setCenter(olMap, center)
    })

    this.facade.requestedZoom$.subscribe((zoom: number) => {
      this.olService.setZoom(olMap, zoom)
    })

    this.facade.info$.subscribe((info) => {
      this.olService.setMapScaleBounds(olMap, info.minScale, info.maxScale)
    })

    this.facade.bounds$.subscribe((bounds) => {
      if (bounds) {
        this.olService.fitExtent(olMap, bounds)
      }
    })

    this.facade.addedMapElement$.subscribe((mapElement) =>
      isMapElementGroup(mapElement)
        ? this.olService.addGroup(
            olMap,
            mapElement as Group,
            mapElement._position,
            mapElement._parentId
          )
        : this.olService.addLayer(
            olMap,
            mapElement as Layer,
            mapElement._position,
            mapElement._parentId
          )
    )
    this.facade.removedMapElement$.subscribe((mapElement) =>
      this.olService.removeMapElement(olMap, mapElement)
    )
    this.facade.updatedMapElement$.subscribe((mapElement) =>
      isMapElementGroup(mapElement)
        ? this.olService.updateGroup(olMap, mapElement as Group)
        : this.olService.updateLayer(olMap, mapElement as Layer)
    )
    this.facade.movedMapElement$.subscribe((mapElement) =>
      this.olService.setMapElementPosition(
        olMap,
        mapElement,
        mapElement._position,
        mapElement._parentId
      )
    )

    this.olService
      .getZoomObservable(olMap)
      .pipe(throttleTime(200, undefined, { leading: true, trailing: true }))
      .subscribe((zoom) => this.facade.setActualZoom(zoom))
    this.olService
      .getCenterObservable(olMap)
      .pipe(throttleTime(200, undefined, { leading: true, trailing: true }))
      .subscribe((center) => this.facade.setActualCenter(center))
    this.olService
      .getExtentObservable(olMap)
      .pipe(throttleTime(200, undefined, { leading: true, trailing: true }))
      .subscribe((extent) => this.facade.setActualExtent(extent))

    this.facade.updatedVectorStyle$.subscribe(
      (layerViewStyle: LayerViewStyle) => {
        if (layerViewStyle.style) {
          this.olService.updateLayerViewStyle(
            olMap,
            layerViewStyle.viewId,
            layerViewStyle.style
          )
        }
      }
    )
    this.facade.removedVectorStyle$.subscribe((viewId) => {
      this.olService.removeStyle(olMap, viewId)
    })

    this.facade.updateLayerOnDuplicatedStyle$
      .pipe(map((action: UpdateLayerOnDuplicatedStyle) => action.viewId))
      .subscribe((viewId: number) => {
        const layer = this.olService.getLayerByViewId(olMap, viewId)
        if (layer) {
          this.olService.warnOnDuplicatedStyleError(layer)
        }
      })

    this.facade.refreshLayer$
      .pipe(map((action: RefreshLayer) => action.viewId))
      .subscribe((viewId: number) => {
        const layer = this.olService.getLayerByViewId(olMap, viewId)
        if (layer) {
          this.olService.refreshLayer(layer)
        }
      })
  }
}
