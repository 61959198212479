import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  ADD_MAP_ELEMENT,
  MOVE_LAYER,
  REMOVE_GROUP,
  REMOVE_LAYER,
  SET_GROUP_FOLDING,
  SET_GROUP_VISIBILITY,
  SET_LAYER_ATTRIBUTES,
  SET_LAYER_OPACITY,
  SET_LAYER_VISIBILITY,
  SetExpositionStatus,
  UPDATE_LAYER_ON_DUPLICATED_STYLE,
  UPDATE_LAYER_VIEW,
  UPDATE_MAP,
  UPDATE_MAP_ELEMENT,
} from '@ui/feature/mapstate'
import { map } from 'rxjs/operators'
import {
  CREATE_STYLE_SUCCESS,
  REMOVE_STYLE_SUCCESS,
  SAVE_STYLE_SUCCESS,
} from '../actions'

@Injectable()
export class PublicationEffects {
  constructor(private actions$: Actions) {}

  markOutOfSync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UPDATE_LAYER_VIEW,
        UPDATE_LAYER_ON_DUPLICATED_STYLE,
        REMOVE_LAYER,
        REMOVE_GROUP,
        MOVE_LAYER,
        ADD_MAP_ELEMENT,
        UPDATE_MAP_ELEMENT,
        SET_LAYER_OPACITY,
        SET_LAYER_VISIBILITY,
        SET_LAYER_ATTRIBUTES,
        SET_GROUP_VISIBILITY,
        SET_GROUP_FOLDING,
        CREATE_STYLE_SUCCESS,
        REMOVE_STYLE_SUCCESS,
        SAVE_STYLE_SUCCESS,
        UPDATE_MAP
      ),
      map(() => new SetExpositionStatus({ synchronised: false }))
    )
  )
}
