import {
  LoadingActions,
  SET_IS_PROCESS_RUNNING,
  UPDATE_PROGRESS,
  SET_PROGRESS_START_DATE,
} from '../actions/loading.actions'

export const progressReducerTsFeatureKey = 'progressReducerTs'

export interface LoadingState {
  isProcessRunning: boolean
  progressValue: number
  progressStartDate: number
}

export const initialState: LoadingState = {
  isProcessRunning: false,
  progressValue: null,
  progressStartDate: null,
}

export function reducer(
  state = initialState,
  action: LoadingActions
): LoadingState {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        progressValue: action.payload,
      }
    case SET_IS_PROCESS_RUNNING:
      return {
        ...state,
        isProcessRunning: action.payload,
      }
    case SET_PROGRESS_START_DATE:
      return {
        ...state,
        progressStartDate: action.payload,
      }
  }
  return state
}
