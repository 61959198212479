import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

import { MapPreviewComponent } from './map-preview/map-preview.component'
import { MapExpositionStatusComponent } from './map-exposition-status/map-exposition-status.component'
import { LayerPublicationStatusComponent } from './layer-publication-status/layer-publication-status.component'
import { MapPublicationStatusComponent } from './map-publication-status/map-publication-status.component'

@NgModule({
  imports: [CommonModule, TooltipModule.forRoot()],
  declarations: [
    MapPreviewComponent,
    MapExpositionStatusComponent,
    LayerPublicationStatusComponent,
    MapPublicationStatusComponent,
  ],
  exports: [
    MapPreviewComponent,
    MapExpositionStatusComponent,
    LayerPublicationStatusComponent,
    MapPublicationStatusComponent,
  ],
})
export class UiCartoModule {}
