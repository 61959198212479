import { Component, Input } from '@angular/core'

@Component({
  selector: 'ui-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {
  @Input() progressValue: number
  @Input() progressStartDate: number
  @Input() isVisible: boolean

  formatTime(timeInSec: number) {
    switch (true) {
      case timeInSec === Infinity:
        return '\u221E'
      case timeInSec > 3600:
        return (
          Math.floor(timeInSec / 3600) +
          'h ' +
          Math.floor((timeInSec % 3600) / 60) +
          'min ' +
          ((timeInSec % 3600) % 60) +
          's'
        )
      case timeInSec <= 3600 && timeInSec >= 60:
        return Math.floor(timeInSec / 60) + 'min ' + (timeInSec % 60) + 's'
      case timeInSec < 60:
        return timeInSec + 's'
    }
  }

  getRemainingTimeSec(dateWhenFunctionCalled: number) {
    const msSinceUploadStarted = dateWhenFunctionCalled - this.progressStartDate
    const percentageLeft = 100 - this.progressValue
    const uploadSpeed = this.progressValue / (msSinceUploadStarted / 1000) // unit is % / s
    return Math.round(percentageLeft / uploadSpeed)
  }

  getDateNow() {
    return Date.now()
  }
}
