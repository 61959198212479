<ui-modal
  #modal
  title="Mettre à jour les couches QGIS"
  confirmLabel="Mettre à jour"
  cancelLabel="Annuler"
  infoMessage="Les couches métiers et externes seront conservées.
  Toutes ses couches QGIS seront réinitialisées avec les couches du nouveau projet mais les configurations spécifiques
  paramétrées dans Carto2 seront conservées."
  [confirmDisabled]="importQgisForm.invalid"
  [confirmHandler]="updateMap.bind(this)"
>
  <form [formGroup]="importQgisForm">
    <div class="modal-body">
      <div class="form-group">
        <label
          >Projet Qgis
          <span class="text-muted">(Format geopackage, 1GO max)</span>
          <input
            type="file"
            accept=".gpkg"
            class="form-control"
            formControlName="file"
            (change)="selectFile($event)"
            [ngClass]="{ 'is-invalid': f.file.errors }"
          />
        </label>
      </div>
    </div>
  </form>
</ui-modal>
