/**
 * Map Production API
 * This API covers functions related to cartographic composition (creation of maps, styles)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type MapExpositionScopeModel = 'NONE' | 'INTRANET' | 'INTERNET' | 'BOTH'

export const MapExpositionScopeModel = {
  NONE: 'NONE' as MapExpositionScopeModel,
  INTRANET: 'INTRANET' as MapExpositionScopeModel,
  INTERNET: 'INTERNET' as MapExpositionScopeModel,
  BOTH: 'BOTH' as MapExpositionScopeModel,
}
