<div
  class="d-flex flex-column justify-content-center align-items-center symbol-container variant-{{
    variant
  }}"
  [title]="error || symbol.label || ''"
  [ngClass]="{ 'has-error': !!error }"
>
  <span
    class="symbol-preview"
    *ngIf="symbol.type === 'glyph'"
    [ngStyle]="{
      fontFamily: isFontGlyph ? fontFamily : '',
      color: cssColor
    }"
    >{{ character }}</span
  >
  <div class="w-100 h-100" *ngIf="symbol.type === 'shape'">
    <ui-pattern-fill [pattern]="pattern" [color]="cssColor"></ui-pattern-fill>
  </div>

  <span
    class="small text-center text-truncate symbol-label"
    [ngClass]="error ? 'text-danger' : 'text-secondary'"
    >{{ symbol.label }}</span
  >

  <span
    class="fa fa-exclamation-circle text-danger symbol-error"
    *ngIf="error"
  ></span>
</div>
