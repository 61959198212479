import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core'
import { MapSummary } from '@ui/feature/shared'

@Component({
  selector: 'ui-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapPreviewComponent implements OnInit {
  @Input() mapSummary: MapSummary

  constructor() {}

  ngOnInit() {}
}
