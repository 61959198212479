export enum FILL_PATTERN_ENUM {
  VERTLINE = 'vertline',
  HORLINE = 'horline',
  SLASH = 'slash',
  BACKSLASH = 'backslash',
  PLUS = 'plus',
  TIMES = 'times',
}

export const fillPatterns = Object.values(FILL_PATTERN_ENUM)
