<div class="form-group" [formGroup]="parentForm">
  <label
    >Projection d'affichage des coordonnées
    <select
      class="form-control"
      formControlName="crsCode"
      [ngClass]="{ 'is-invalid': isInvalid() }"
    >
      <optgroup
        *ngFor="let group of sortOrder(retrievedProjectionOptions)"
        [label]="group.label"
      >
        <option
          *ngFor="let projection of sortOrder(group.children)"
          [value]="projection.value"
        >
          {{ projection.label }}
        </option>
      </optgroup>
    </select>
  </label>
</div>
