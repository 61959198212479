import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { LayerModel } from '@ui/data-access-carto-map-production'

import { Observable, of, Subscription } from 'rxjs'
import { RepairMapService } from '../../../services/repair-map.service'
import { ModalComponent } from '@ui/ui/layout'

@Component({
  selector: 'ui-irreparable-layers-modal',
  templateUrl: './irreparable-layers-modal.component.html',
  styleUrls: ['./irreparable-layers-modal.component.css'],
})
export class IrreparableLayersModalComponent implements OnInit, OnDestroy {
  @Input() showModal$: Observable<any>
  @ViewChild('modal') modal: ModalComponent

  irreparableLayers: LayerModel[]
  sub = new Subscription()

  constructor(private repairMapService_: RepairMapService) {}

  ngOnInit(): void {
    this.sub.add(
      this.showModal$.subscribe(() => {
        this.modal.loading = false
        this.irreparableLayers = this.repairMapService_.getIrreparableLayers()
        this.modal.open()
      })
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  refreshPage() {
    return () => location.reload()
  }

  onConfirm() {
    this.repairMapService_.cleanMap()
    return of(void 0)
  }
}
