import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MapFacade } from '@ui/feature/mapstate'
import { Style } from '@ui/feature/styling'
import { switchMap, take } from 'rxjs/operators'
import { StyleFacade } from '../../../store/facade'
import { getViewIsVector } from '@ui/feature/shared'

@Component({
  selector: 'ui-style-panel',
  templateUrl: './style-panel.component.html',
  styleUrls: ['./style-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StylePanelComponent {
  layerViewSpec$ = this.styleFacade_.currentViewId$.pipe(
    switchMap((viewId) => this.mapFacade_.getViewById(viewId))
  )
  currentStyle$ = this.styleFacade_.currentStyle$

  constructor(public styleFacade_: StyleFacade, public mapFacade_: MapFacade) {}

  handleStyle(style: Style) {
    this.layerViewSpec$.pipe(take(1)).subscribe((layerView) => {
      this.styleFacade_.updateCurrentStyle(style)

      if (getViewIsVector(layerView)) {
        this.mapFacade_.updateStyle({
          viewId: layerView.id,
          style,
        })
      }
    })
  }
}
