import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular'
import { UiCartoModule } from '@ui/ui/carto'
import { AlertModule } from 'ngx-bootstrap/alert'

import SETTINGS from '../../../../settings'
import {
  BACK_OFFICE_PATH,
  EditExpositionOptionsModalComponent,
} from './edit-exposition-options/edit-exposition-options-modal/edit-exposition-options-modal.component'
import { EditExpositionOptionsComponent } from './edit-exposition-options/edit-exposition-options.component'
import {
  EditLayerComponent,
  VIEW_CHARSET_ENCODINGS,
} from './edit-layer/edit-layer.component'
import {
  EditMapModalComponent,
  FRONT_OFFICE_PATH,
} from './edit-map/edit-map-modal/edit-map-modal.component'
import { PasswordInputComponent } from './edit-map/edit-map-modal/password-input/password-input.component'
import { ProjectionSelectComponent } from './edit-map/edit-map-modal/projection-select/projection-select.component'
import { EditMapComponent } from './edit-map/edit-map.component'
import { EditMetadataModalComponent } from './edit-metadata/edit-metadata-modal/edit-metadata-modal.component'
import { EditMetadataComponent } from './edit-metadata/edit-metadata.component'
import { MinMaxScaleInputComponent } from './min-max-scale-input/min-max-scale-input.component'
import { MinMaxScaleGroupComponent } from './min-max-scale-group/min-max-scale-group.component'
import { UiLayoutModule } from '@ui/ui/layout'

@NgModule({
  declarations: [
    EditLayerComponent,
    EditMapComponent,
    EditMapModalComponent,
    EditMetadataComponent,
    EditMetadataModalComponent,
    ProjectionSelectComponent,
    MinMaxScaleInputComponent,
    MinMaxScaleGroupComponent,
    PasswordInputComponent,
    EditExpositionOptionsComponent,
    EditExpositionOptionsModalComponent,
  ],
  imports: [
    CommonModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    UiCartoModule,
    UiLayoutModule,
    EditorModule,
  ],
  exports: [
    EditLayerComponent,
    EditMapComponent,
    EditMetadataComponent,
    EditExpositionOptionsComponent,
  ],
  providers: [
    {
      provide: BACK_OFFICE_PATH,
      useFactory: () => SETTINGS.backOfficePath,
    },
    {
      provide: FRONT_OFFICE_PATH,
      useFactory: () => SETTINGS.frontOfficePath,
    },
    {
      provide: VIEW_CHARSET_ENCODINGS,
      useFactory: () => SETTINGS.viewCharsetEncodings,
    },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class AdvancedEditModule {}
