import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SymbolPreviewComponent } from './symbol-preview/symbol-preview.component'
import { PatternFillComponent } from './pattern-fill/pattern-fill.component'

@NgModule({
  imports: [CommonModule],
  declarations: [SymbolPreviewComponent, PatternFillComponent],
  exports: [SymbolPreviewComponent],
})
export class UiStylingModule {}
