import { LayerMove, LayerPublicationStatuses, Styles, View } from '../map.model'
import * as fromActions from './map.actions'
import { Map, PublicationReport } from '@ui/feature/shared'
import { QGisProjectInfoModel } from '@ui/data-access-carto-map-production'

export const MAP_FEATURE_KEY = 'mapState'

export interface MapState {
  view: View
  actualView: { center: [number, number]; zoom: number; extent: number[] }
  spec?: Map
  vectorLayerStyles: Styles
  layerPublicationStatuses: LayerPublicationStatuses
  mapPublicationReport: PublicationReport
  qgisInfo: QGisProjectInfoModel
}
export interface MapPartialState {
  readonly [MAP_FEATURE_KEY]: MapState
}

export const initialState: MapState = {
  view: { center: [0, 0], zoom: 4 },
  actualView: { center: [0, 0], zoom: 4, extent: [0, 0, 0, 0] },
  spec: {
    name: 'empty',
    elementsById: {},
    rootElementId: null,
    expositionStatus: {},
  },
  vectorLayerStyles: {},
  layerPublicationStatuses: {},
  mapPublicationReport: {},
  qgisInfo: {},
}

export function reducer(
  state = initialState,
  action: fromActions.MapActions
): MapState {
  switch (action.type) {
    case fromActions.SET_VIEW: {
      const view: View = action.payload
      return {
        ...state,
        view: { ...view },
      }
    }
    case fromActions.SET_ZOOM: {
      const zoom: number = action.payload
      return {
        ...state,
        view: { ...state.view, zoom },
      }
    }
    case fromActions.SET_ACTUAL_CENTER: {
      const center: [number, number] = action.payload
      return {
        ...state,
        actualView: { ...state.actualView, center },
      }
    }
    case fromActions.SET_ACTUAL_ZOOM: {
      const zoom: number = action.payload
      return {
        ...state,
        actualView: { ...state.actualView, zoom },
      }
    }
    case fromActions.SET_ACTUAL_EXTENT: {
      const extent: number[] = action.payload
      return {
        ...state,
        actualView: { ...state.actualView, extent },
      }
    }
    case fromActions.SET_FROM_SPEC: {
      return {
        ...state,
        spec: action.payload,
      }
    }
    case fromActions.UPDATE_GROUP:
    case fromActions.UPDATE_LAYER: {
      const { id, ...patch } = action.payload
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [id]: versionReducer({
              ...state.spec.elementsById[id],
              ...patch,
            }),
          },
        },
      }
    }
    case fromActions.UPDATE_LAYER_VIEW: {
      const { layerId, viewId, ...patch } = action.payload
      const layer = state.spec.elementsById[layerId]
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [layerId]: versionReducer(
              'views' in layer
                ? {
                    ...layer,
                    views: layer.views.map((view) =>
                      view.id === viewId ? { ...view, ...patch } : view
                    ),
                  }
                : layer
            ),
          },
        },
      }
    }
    case fromActions.UPDATE_LAYER_ON_DUPLICATED_STYLE: {
      const { layerId, viewId, defaultStyleId } = action
      const layer = state.spec.elementsById[layerId]
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [layerId]: versionReducer(
              'views' in layer
                ? {
                    ...layer,
                    views: layer.views.map((view) =>
                      view.id === viewId ? { ...view, defaultStyleId } : view
                    ),
                  }
                : layer
            ),
          },
        },
      }
    }
    case fromActions.SET_LAYER_VIEW_STYLE:
    case fromActions.CLEAR_LAYER_VIEW_STYLE: {
      const { viewId } = action
      const defaultStyleId =
        'defaultStyleId' in action ? action.defaultStyleId : undefined
      const foundLayerId = Object.keys(state.spec.elementsById).find(
        (layerId) => {
          const layer = state.spec.elementsById[layerId]
          if (
            'views' in layer &&
            layer.views.some((view) => view.id === viewId)
          )
            return layer
        }
      )
      const foundLayer = state.spec.elementsById[foundLayerId]
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [foundLayer.id]: versionReducer(
              'views' in foundLayer
                ? {
                    ...foundLayer,
                    views: foundLayer.views.map((view) =>
                      view.id === viewId ? { ...view, defaultStyleId } : view
                    ),
                  }
                : foundLayer
            ),
          },
        },
      }
    }
    case fromActions.REMOVE_LAYER:
    case fromActions.REMOVE_GROUP: {
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      const { [action.id]: toRemove, ...elementsById } = state.spec.elementsById

      // current parent lookup
      let parent = state.spec.elementsById[state.spec.rootElementId]
      for (const id in state.spec.elementsById) {
        if (id in state.spec.elementsById) {
          const node = state.spec.elementsById[id]
          if (
            'childrenId' in node &&
            node.childrenId.some((childrenId) => childrenId === action.id)
          ) {
            parent = state.spec.elementsById[id]
            break
          }
        }
      }
      if (!parent || !('childrenId' in parent)) {
        return state
      }
      parent = {
        ...parent,
        childrenId: parent.childrenId.filter((id) => id !== action.id),
      }
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...elementsById,
            [parent.id]: parent,
          },
        },
      }
    }
    case fromActions.MOVE_LAYER: {
      const move: LayerMove = action.payload

      // current and new parent lookup
      // note that if any parent is undefined, the reducer exists
      let currentParent = state.spec.elementsById[state.spec.rootElementId]
      for (const id in state.spec.elementsById) {
        if (id in state.spec.elementsById) {
          const node = state.spec.elementsById[id]
          if (
            'childrenId' in node &&
            node.childrenId.some((childrenId) => childrenId === move.id)
          ) {
            currentParent = state.spec.elementsById[id]
            break
          }
        }
      }
      let newParent =
        move.newParentId !== undefined
          ? state.spec.elementsById[move.newParentId]
          : state.spec.elementsById[state.spec.rootElementId]

      if (
        !currentParent ||
        !newParent ||
        !('childrenId' in currentParent) ||
        !('childrenId' in newParent)
      ) {
        return state
      }

      currentParent = {
        ...currentParent,
        childrenId: currentParent.childrenId.filter((id) => id !== move.id),
      }
      newParent = {
        ...newParent,
        childrenId: newParent.childrenId.filter((id) => id !== move.id),
      }
      newParent.childrenId.splice(move.index, 0, move.id)

      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [currentParent.id]: currentParent,
            [newParent.id]: newParent,
          },
        },
      }
    }
    case fromActions.ADD_MAP_ELEMENT: {
      const mapElement = action.payload.mapElement
      const index = action.payload.index
      const parentId = action.payload.parentId
      let parent =
        parentId !== undefined
          ? state.spec.elementsById[parentId]
          : state.spec.elementsById[state.spec.rootElementId]

      if (!parent || !('childrenId' in parent)) {
        return state
      }

      parent = {
        ...parent,
        childrenId: [...parent.childrenId],
      }
      parent.childrenId.splice(index, 0, mapElement.id)

      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [mapElement.id]: mapElement,
            [parent.id]: parent,
          },
        },
      }
    }
    case fromActions.UPDATE_MAP_ELEMENT: {
      const mapElement = action.payload.mapElement
      return {
        ...state,
        spec: {
          ...state.spec,
          elementsById: {
            ...state.spec.elementsById,
            [mapElement.id]: mapElement,
          },
        },
      }
    }
    case fromActions.UPDATE_VECTOR_STYLE: {
      const style = state.vectorLayerStyles[action.payload.viewId]
      return {
        ...state,
        vectorLayerStyles: {
          ...state.vectorLayerStyles,
          [action.payload.viewId]: versionReducer({
            ...action.payload.style,
            _version:
              style && '_version' in style && style._version !== undefined
                ? style._version
                : undefined,
          }),
        },
      }
    }
    case fromActions.REMOVE_VECTOR_STYLE: {
      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      const { [action.viewId]: removed, ...vectorLayerStyles } =
        state.vectorLayerStyles
      return {
        ...state,
        vectorLayerStyles,
      }
    }
    case fromActions.UPDATE_MAP: {
      const payload = action.payload
      const spec = state.spec
      return {
        ...state,
        spec: {
          ...spec,
          ...payload,
        },
      }
    }
    case fromActions.SET_EXPOSITION_STATUS: {
      return {
        ...state,
        spec: {
          ...state.spec,
          expositionStatus: {
            ...state.spec.expositionStatus,
            ...action.payload,
          },
        },
      }
    }
    case fromActions.SET_EXPOSITION_STATUS_PUBLISHED: {
      const { published } = action
      return {
        ...state,
        spec: {
          ...state.spec,
          expositionStatus: {
            ...state.spec.expositionStatus,
            published,
          },
        },
      }
    }
    case fromActions.SET_LAYER_PUBLICATION_STATUS: {
      return {
        ...state,
        layerPublicationStatuses: {
          ...state.layerPublicationStatuses,
          [action.payload.layerId]: {
            ...action.payload,
          },
        },
      }
    }
    case fromActions.SET_MAP_PUBLICATION_REPORT: {
      return {
        ...state,
        mapPublicationReport: action.payload,
      }
    }
    case fromActions.SET_MAP_QGIS_INFO: {
      return { ...state, qgisInfo: action.qgisInfo }
    }
  }

  return state
}

export function versionReducer<T extends { _version?: number }>(
  objectState: T,
  increment = 1
): T {
  return {
    ...objectState,
    _version:
      objectState._version !== undefined
        ? objectState._version + increment
        : increment,
  }
}
