<div class="d-flex flex-column h-100">
  <div class="text-center section">
    <div
      class="btn-group btn-group-sm my-3"
      role="group"
      aria-label="Type de resources"
      *ngIf="activableTypes.length > 1"
    >
      <button
        type="button"
        *ngIf="activableTypes.includes(ResourceTypeEnum.DATASET)"
        (click)="filter(ResourceTypeEnum.DATASET)"
        class="btn btn-secondary"
        [ngClass]="{ active: activeType === 'datasets' }"
      >
        Jeu de données
      </button>
      <!-- <button
        type="button"
        *ngIf="activableTypes.includes(ResourceTypeEnum.GEOPLATEFORME)"
        (click)="filter(ResourceTypeEnum.GEOPLATEFORME)"
        class="btn btn-secondary"
        [ngClass]="{ active: activeType === 'geoplateforme' }"
      >
        Services<br />Géoplateforme
      </button> -->
      <button
        type="button"
        *ngIf="activableTypes.includes(ResourceTypeEnum.SERVICE)"
        (click)="filter(ResourceTypeEnum.SERVICE)"
        class="btn btn-secondary"
        [ngClass]="{ active: activeType === 'services' }"
      >
        Services Externes
      </button>
      <button
        type="button"
        *ngIf="activableTypes.includes(ResourceTypeEnum.STYLE)"
        (click)="filter(ResourceTypeEnum.STYLE)"
        class="btn btn-secondary"
        [ngClass]="{ active: activeType === 'styles' }"
      >
        Styles
      </button>
    </div>
  </div>
  <ui-filter-search-input
    *ngIf="shoudShowFilter()"
    [filterValue]="filterValue"
    (filterValueChange)="onFilterValueChange($event)"
    [placeholder]="placeholderValue"
  ></ui-filter-search-input>
  <div class="overflow-auto">
    <ui-tree-level
      path="/datasources"
      *ngIf="activableTypes.includes(ResourceTypeEnum.DATASET)"
      [directAccessPath]="directAccessPath"
      [selectable]="selectable"
      [ngClass]="{ 'd-none': activeType !== ResourceTypeEnum.DATASET }"
    ></ui-tree-level>
    <ui-tree-level
      path="/services"
      *ngIf="activableTypes.includes(ResourceTypeEnum.SERVICE)"
      [filterValue]="filterValue"
      [filterOn]="['name']"
      [selectable]="selectable"
      [ngClass]="{ 'd-none': activeType !== ResourceTypeEnum.SERVICE }"
    ></ui-tree-level>
    <ui-tree-level
      path="/maps"
      *ngIf="activableTypes.includes(ResourceTypeEnum.STYLE)"
      [filterValue]="filterValue"
      [filterOn]="['title', 'name']"
      [selectable]="selectable"
      [ngClass]="{ 'd-none': activeType !== ResourceTypeEnum.STYLE }"
    ></ui-tree-level>
    <ui-tree-level
      path="/geoplateforme"
      *ngIf="activableTypes.includes(ResourceTypeEnum.GEOPLATEFORME)"
      [filterValue]="filterValue"
      [filterOn]="['name']"
      [selectable]="selectable"
      [ngClass]="{ 'd-none': activeType !== ResourceTypeEnum.GEOPLATEFORME }"
      [editable]="false"
    ></ui-tree-level>
  </div>
</div>
