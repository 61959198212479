<div class="jumbotron">
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <div class="row">
        <h5>Ministère de la Transition Écologique et Solidaire</h5>
      </div>
      <ul class="list-inline mt-3">
        <li class="list-inline-item">
          <a [href]="urls.LegalNotice" target="_blank" rel="noreferrer noopener"
            >Mentions légales</a
          >
        </li>
        <li class="list-inline-item">
          <a
            [href]="urls.Accessibility"
            target="_blank"
            rel="noreferrer noopener"
            >Accessibilité</a
          >
        </li>
        <li class="list-inline-item">
          <a [href]="urls.Contacts" target="_blank" rel="noreferrer noopener"
            >Contacts</a
          >
        </li>
      </ul>
      <ul class="list-inline mt-3">
        <li class="list-inline-item" [title]="versionInfo">
          &copy; Version {{ versionLabel }}
        </li>
      </ul>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="row"><h5>Accès rapide</h5></div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <ul class="list-unstyled">
            <li>
              <a
                [href]="urls.Catalogue"
                target="_blank"
                rel="noreferrer noopener"
                >Géo-IDE Catalogue</a
              >
            </li>
            <li>
              <a
                [href]="urls.Geoinformations"
                target="_blank"
                rel="noreferrer noopener"
                >GéoInformations</a
              >
            </li>
            <li>
              <a
                [href]="urls.Geoportail"
                target="_blank"
                rel="noreferrer noopener"
                >Géoportail</a
              >
            </li>
          </ul>
        </div>
        <div class="col-xs-12 col-md-6">
          <ul class="list-unstyled">
            <li>
              <a
                [href]="urls.Geocatalogue"
                target="_blank"
                rel="noreferrer noopener"
                >Géocatalogue</a
              >
            </li>
            <li>
              <a
                [href]="urls.InspirePortal"
                target="_blank"
                rel="noreferrer noopener"
                >Portail INSPIRE</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center">
    <h5>Les partenaires</h5>
    <hr />
    <div
      class="d-flex flex-row justify-content-center align-items-stretch partners-list"
    >
      <a
        [href]="urls.PartnerMTES"
        title="Nouvel onglet : Ministère de la Transition écologique et Solidaire"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="assets/img/bloc-marque_MTES.png" alt="Logo MTES" />
      </a>
      <a
        [href]="urls.PartnerMAA"
        title="Nouvel onglet : Ministère de l'Agriculture et de l'Alimentation"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="assets/img/Logo_MAA.png" alt="Logo MAA" />
      </a>
      <a
        [href]="urls.PartnerMCT"
        title="Nouvel onglet : Ministère de la Cohésion des Territoires"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="assets/img/Logo_MCT.png" alt="Logo MCT" />
      </a>
    </div>
  </div>
</div>
