<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title pull-left">{{ title }}</h5>
  </div>
  <alert *ngIf="successMessage" type="success">
    <span class="fa fa-check-circle mr-1"></span>{{ successMessage }}
  </alert>
  <alert *ngIf="infoMessage" type="info">
    <span class="fa fa-info-circle mr-1"></span>{{ infoMessage }}
  </alert>
  <alert *ngIf="errorMessage" type="danger">
    <span class="fa fa-exclamation-circle mr-1"></span>{{ errorMessage }}
  </alert>
  <div class="modal-body" (keydown)="handleKeyDown($event)">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-secondary cancel"
      type="button"
      (click)="onCancel()"
      [disabled]="loading"
    >
      {{ cancelLabel }}
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="confirmDisabled || loading"
      (click)="onConfirm()"
      [hidden]="!confirmButtonVisible"
    >
      <span class="fa fa-spinner fa-spin mr-1" *ngIf="loading"></span
      >{{ confirmLabel }}
    </button>
  </div>
</ng-template>
