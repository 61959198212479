<div class="btn-group-vertical shadow-sm w-100">
  <button
    class="btn btn-outline-dark"
    type="button"
    title="Zoom in"
    (click)="zoomByDelta(+1)"
  >
    <span class="fa fa-plus small"></span>
  </button>
  <button
    class="btn btn-outline-dark"
    type="button"
    title="Zoom out"
    (click)="zoomByDelta(-1)"
  >
    <span class="fa fa-minus small"></span>
  </button>
</div>
