import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AppNotificationState } from '../reducers/notification.reducer'

export const getNotificationState =
  createFeatureSelector<AppNotificationState>('notification')

export const getNotifications = createSelector(
  getNotificationState,
  (state: AppNotificationState) => state.notifications
)
