import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'ui-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  @Input() panelTitle: string
  @Input() panelSubtitle: string
  @Input() toggleLabel: string
  @Input() collapsible = false
  @Input() initiallyCollapsed = false
  @Input() focused = false
  @Input() wider = false
  collapsed: boolean

  constructor() {}

  ngOnInit() {
    this.collapsed = this.focused ? false : this.initiallyCollapsed
    this.toggleLabel = this.toggleLabel || this.panelTitle
  }

  toggleCollapsed() {
    if (this.focused) return
    this.collapsed = !this.collapsed
  }
}
