import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import SETTINGS from './settings'
import USER_INFO from './userinfo'
import { registerCustomProjections } from './projections'

if (environment.production) {
  enableProdMode()
}

// Settings and user info are loaded before the application is bootstrapped
SETTINGS.init()
  .then(() => registerCustomProjections())
  .then(() => USER_INFO.init())
  .then(
    () => {
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err))
    },
    (error) => {
      console.error(
        `L'application n'a pas pu se lancer. Raison :\n${error.message}`,
        error.stack
      )
    }
  )
