import { Action } from '@ngrx/store'
import { NotificationModel } from '../reducers/notification.reducer'

export const ADD_NOTIFICATION = '[App] Add Notification'
export const REMOVE_NOTIFICATION = '[App] Remove Notification'

export class AddNotification implements Action {
  readonly type = ADD_NOTIFICATION
  constructor(public payload: NotificationModel) {}
}

export class RemoveNotification implements Action {
  readonly type = REMOVE_NOTIFICATION
  constructor(public notificationId: number) {}
}

export type NotificationActions = AddNotification | RemoveNotification
