<div>
  <div class="d-flex flex-row filter-header">
    <div dropdown class="position-relative">
      <a
        dropdownToggle
        href
        class="text-truncate"
        (click)="(false)"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ filterTypeName | lowercase
        }}<span class="ml-1 fa fa-caret-down"></span>
      </a>
      <div *dropdownMenu class="dropdown-menu">
        <button
          *ngFor="let type of filterTypes"
          class="dropdown-item"
          (click)="setFilterType(type.value)"
        >
          {{ type.name }}
        </button>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <a
      href
      class="text-truncate delete-filter text-danger"
      *ngIf="canDelete"
      (click)="deleteClicked.emit(); (false)"
    >
      <span class="fa fa-trash-alt mr-1"></span>supprimer
    </a>
  </div>
  <div class="mb-2 pl-3" *ngIf="getIsNestedFilter(filterType)">
    <div
      class="nested-filter d-flex flex-row"
      *ngFor="
        let filter of filterComponents;
        index as i;
        trackBy: filterTracker
      "
    >
      <ui-rule-filter
        [filter]="filter"
        (filterChange)="setNestedFilter($event, i)"
        [schema]="schema"
        [canDelete]="canRemoveNestedFilter()"
        (deleteClicked)="removeNestedFilter(i)"
      ></ui-rule-filter>
    </div>
    <button
      type="button"
      class="btn btn-sm btn-outline-primary add-nested-filter mt-1"
      (click)="addNestedFilter()"
      *ngIf="canAddNestedFilter()"
    >
      <i class="fa fa-plus-circle"></i> Ajouter un filtre
    </button>
  </div>
  <div
    class="small d-flex flex-row align-items-center mb-2"
    *ngIf="!getIsNestedFilter(filterType)"
  >
    <input
      type="text"
      class="form-control form-control-sm w-50 attribute"
      [value]="filter[ATTRIBUTE_INDEX]"
      (input)="setAttribute($event.target.value)"
      *ngIf="!schema || !schema.properties"
    />
    <select
      class="form-control form-control-sm w-50 attribute"
      *ngIf="schema && schema.properties"
      (change)="setAttribute($event.target.value)"
    >
      <option
        *ngFor="let attribute of objectKeys(schema.properties)"
        [value]="attribute"
        [selected]="this.filter[ATTRIBUTE_INDEX] === attribute"
      >
        {{ attribute }} ({{ schema.properties[attribute].type }})
      </option>
    </select>

    <select
      class="form-control form-control-sm ml-1 flex-shrink-0 operator"
      style="width: 2.8rem"
      (change)="setOperator($event.target.value)"
    >
      <option
        *ngFor="let operator of operators"
        [selected]="this.filter[OPERATOR_INDEX] === operator"
      >
        {{ operator }}
      </option>
    </select>

    <input
      type="text"
      class="form-control form-control-sm ml-1 w-50 value"
      [value]="filter[VALUE_INDEX] || ''"
      (input)="setValue($event.target.value)"
    />
  </div>
</div>
