import { Image } from 'ol'
import ImageWrapper from 'ol/Image'
import BaseEvent from 'ol/events/Event'
import ImageWMS from 'ol/source/ImageWMS'

export const ImageLoadErrorCustomEventType = 'imageloaderrorcustom'

export enum IMAGE_WMS_LOAD_ERROR {
  NotFound404,
  GatewayTimeout504,
  GeoserverErrorStyle,
  GeoserverErrorTimeout,
  GeoserverErrorMissingData,
  ErrorDefault200,
}

export class ImageLoadErrorEvent extends BaseEvent {
  errorType: IMAGE_WMS_LOAD_ERROR
  image: Image

  constructor(type: string, image: Image, errorType: IMAGE_WMS_LOAD_ERROR) {
    super(type)
    this.image = image
    this.errorType = errorType
  }
}

class ImageWMSWithErrorHandler extends ImageWMS {
  constructor(options) {
    super(options)

    this.setImageLoadFunction((image: ImageWrapper, src: string) => {
      return defaultImageLoadFunction(image, src, this)
    })
  }

  handleImageLoadStatusChange(image: Image, errorType: IMAGE_WMS_LOAD_ERROR) {
    this.dispatchEvent(
      new ImageLoadErrorEvent(ImageLoadErrorCustomEventType, image, errorType)
    )
  }
}

function defaultImageLoadFunction(
  image: ImageWrapper,
  src: string,
  source: ImageWMSWithErrorHandler
) {
  fetch(src).then((response) => {
    if (response.status === 200) {
      response.blob().then(async (myBlob) => {
        try {
          const blobText = await myBlob.text()
          const textResponse = getLoadResponse(response.status, blobText)
          if (textResponse) {
            source.handleImageLoadStatusChange(image, textResponse)
          } else {
            const objectURL = URL.createObjectURL(myBlob)
            ;(image.getImage() as HTMLImageElement).src = objectURL
            source.handleImageLoadStatusChange(image, undefined)
          }
        } catch (e) {
          source.handleImageLoadStatusChange(
            image,
            IMAGE_WMS_LOAD_ERROR.ErrorDefault200
          )
        }
      })
    } else {
      source.handleImageLoadStatusChange(
        image,
        getLoadResponse(response.status, response.statusText)
      )
    }
  })
}

function getLoadResponse(status: number, statusText?: string) {
  if (status === 404) {
    return IMAGE_WMS_LOAD_ERROR.NotFound404
  }

  if (status === 504) {
    return IMAGE_WMS_LOAD_ERROR.GatewayTimeout504
  }

  if (hasGeoserverErrorMissingData(statusText)) {
    return IMAGE_WMS_LOAD_ERROR.GeoserverErrorMissingData
  }

  if (hasGeoserverErrorStyle(statusText)) {
    return IMAGE_WMS_LOAD_ERROR.GeoserverErrorStyle
  }

  if (hasGeoserverErrorTimeout(statusText)) {
    return IMAGE_WMS_LOAD_ERROR.GeoserverErrorTimeout
  }

  if (hasGeoserverErrorUnknown(statusText)) {
    return IMAGE_WMS_LOAD_ERROR.ErrorDefault200
  }

  return undefined
}

function hasGeoserverErrorMissingData(text: string) {
  const isDataMissing =
    /No such file or directory/.test(text) ||
    /Aucun fichier ou dossier de ce type/.test(text)
  return isDataMissing
}

function hasGeoserverErrorStyle(text: string) {
  return /The requested Style can not be used with this layer/.test(text)
}

function hasGeoserverErrorTimeout(text: string) {
  return /This request used more time than allowed/.test(text)
}

function hasGeoserverErrorUnknown(text: string) {
  return /<ServiceException/.test(text)
}

export default ImageWMSWithErrorHandler
