import { Component, EventEmitter, Input, Output } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  templateUrl: './confirm.component.html',
})
export class ConfirmPopupComponent {
  @Input() title = 'Confirmation'
  @Input() message = 'Message'
  @Output() action = new EventEmitter<boolean>()

  constructor(private modalRef: BsModalRef) {}

  public close(confirmed: boolean) {
    this.modalRef.hide()
    setTimeout(() => {
      this.action.emit(confirmed)
      this.action.complete()
    }, 900) // this delay is to avoid showing things while the dialog is still fading out
  }
}
