import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import {
  Observable,
  Subscription,
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs'
import { MapElement, parseApiMapElement } from '@ui/feature/shared'
import {
  LayerModel,
  LayerViewAttributeModel,
  MapCompositionService,
  PatchLayerViewRequestModel,
} from '@ui/data-access-carto-map-production'
import { MapFacade } from '@ui/feature/mapstate'
import { ModalComponent } from '@ui/ui/layout'
import { isEqual, cloneDeep } from 'lodash'
import { ChangeDetectorRef } from '@angular/core'
import { NotificationFacade } from '../../../store'

@Component({
  selector: 'ui-edit-attributes',
  templateUrl: './edit-attributes.component.html',
  styleUrls: ['./edit-attributes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAttributesComponent implements OnDestroy, OnInit {
  modalRef: BsModalRef
  sub = new Subscription()
  @Input() currentLayer$: Observable<MapElement>
  @ViewChild('modal') modal: ModalComponent
  attributes: LayerViewAttributeModel[]
  attributesInvalid: boolean
  layer: LayerModel

  constructor(
    private mapFacade_: MapFacade,
    private mapComposition_: MapCompositionService,
    private cdr: ChangeDetectorRef,
    private notificationFacade_: NotificationFacade
  ) {}

  ngOnInit() {
    this.sub.add(
      this.currentLayer$
        .pipe(
          filter((layer) => !!layer),
          withLatestFrom(this.mapFacade_.info$),
          switchMap(([layer, mapInfo]) =>
            this.mapComposition_.findLayerById(mapInfo.id, layer.id)
          )
        )
        .subscribe({
          next: (fetchedLayer) => {
            this.layer = fetchedLayer
            this.attributes = cloneDeep(fetchedLayer.views[0].attributes)
            this.cdr.detectChanges()
            this.modal.open()
          },
        })
    )
  }

  sortAttributes(attributes) {
    const attr = attributes.sort((a, b) => {
      if (a.invalid !== b.invalid) {
        return a.invalid ? 1 : -1
      }
      return a.name.localeCompare(b.name)
    })
    return attr
  }

  get isFormInvalid() {
    return this.attributesInvalid
  }

  onSubmit() {
    const viewPatch: PatchLayerViewRequestModel = {}
    if (!isEqual(this.layer.views[0].attributes, this.attributes)) {
      viewPatch.attributes = this.attributes
    }

    return this.mapFacade_.info$.pipe(
      take(1),
      map((info) => info.id),
      switchMap((mapId) =>
        this.mapComposition_.updateLayerView(
          mapId,
          this.layer.views[0].id,
          viewPatch
        )
      ),
      map(parseApiMapElement),
      tap((layerSpec) => {
        this.mapFacade_.updateLayerView({
          layerId: this.layer.id,
          viewId: this.layer.views[0].id,
          ...layerSpec,
        })

        this.notificationFacade_.showNotification(
          'success',
          'La couche a été sauvegardée',
          2000
        )
      }),
      catchError((error) =>
        throwError(
          error.status === 409
            ? 'Une couche avec ce nom existe déjà'
            : 'L’enregistrement de la couche a échoué'
        )
      )
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }
}
