/**
 * Resource Management API
 * This API covers the functions used by the Map Production and Map Publishing domains for the management of \"cartographic\" resources and the \"basic\" resources that are useful for the map production and publication process (data series → cataloged data and services).
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * A remote server
 */
export interface RemoteServerModel {
  id?: string
  serverName?: string
  serverURL?: string
  serverType?: RemoteServerModel.ServerTypeModelEnum
  serverVersion?: string
}
export namespace RemoteServerModel {
  export type ServerTypeModelEnum =
    | 'WMS'
    | 'WFS'
    | 'WMTS'
    | 'VECTOR_TILES'
    | 'UNKNOWN'
  export const ServerTypeModelEnum = {
    WMS: 'WMS' as ServerTypeModelEnum,
    WFS: 'WFS' as ServerTypeModelEnum,
    WMTS: 'WMTS' as ServerTypeModelEnum,
    VECTORTILES: 'VECTOR_TILES' as ServerTypeModelEnum,
    UNKNOWN: 'UNKNOWN' as ServerTypeModelEnum,
  }
}
