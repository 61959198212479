import { EventEmitter, Injectable } from '@angular/core'
import { RemoteServerModel } from '@ui/data-access-carto-resource-management'

@Injectable({
  providedIn: 'root',
})
export class ModalEventService {
  editRemoteServer$ = new EventEmitter<RemoteServerModel>()
  createMap$ = new EventEmitter()
  importQgis$ = new EventEmitter()
  updateQgis$ = new EventEmitter()
  loadMap$ = new EventEmitter()
  deleteMap$ = new EventEmitter()
  manageIrreparableLayers$ = new EventEmitter()
  showUncleanableLayers$ = new EventEmitter()

  constructor() {}

  openRemoteServerModal(remoteServer?: RemoteServerModel) {
    this.editRemoteServer$.emit(remoteServer)
  }

  openCreateMapModal() {
    this.createMap$.emit()
  }

  openImportQgisModal() {
    this.importQgis$.emit()
  }
  openUpdateQgisModal() {
    this.updateQgis$.emit()
  }

  openLoadMapModal() {
    this.loadMap$.emit()
  }

  openDeleteMapModal() {
    this.deleteMap$.emit()
  }
  openIrreparableLayersModal() {
    this.manageIrreparableLayers$.emit()
  }
  openUncleanableLayersModal() {
    this.showUncleanableLayers$.emit()
  }
}
