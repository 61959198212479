import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { StyleService } from '@ui/feature/shared'
import {
  FillSymbolizer,
  MarkSymbolizer,
  SymbolizerChange,
} from '../../style.model'
import { SYMBOLIZER_FIELD_TYPE } from '../../symbolizer-field/symbolizer-field.component'

enum FillType {
  UNI,
  TRAME,
}

const fillTypeLabel = {
  [FillType.UNI]: 'Uni',
  [FillType.TRAME]: 'Trame',
}

export const graphicFillDefaultSymbolizer: MarkSymbolizer = {
  kind: 'Mark',
  wellKnownName: 'circle',
  radius: 8,
  color: '#0000FF',
  strokeColor: '#0000FF',
  rotate: 0,
  fillOpacity: 1,
  strokeWidth: 1,
  strokeOpacity: 1,
}

@Component({
  selector: 'ui-fill-symbolizer',
  templateUrl: './fill-symbolizer.component.html',
  styleUrls: ['./fill-symbolizer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FillSymbolizerComponent implements OnInit {
  @Input() symbolizer: FillSymbolizer
  @Input() colorsLocked = false
  @Input() activeImageGeometryPolygon = true
  @Output() symbolizerUpdated = new EventEmitter<SymbolizerChange>()

  fillType: FillType

  fieldTypes = SYMBOLIZER_FIELD_TYPE

  constructor(private styleService: StyleService) {}

  ngOnInit(): void {
    this.fillType = this.symbolizer.graphicFill ? FillType.TRAME : FillType.UNI
  }

  handlePropChange(name, value) {
    this.symbolizerUpdated.emit({
      name,
      value,
    })
  }

  handleGraphicChange(name, patch) {
    if (patch.hasOwnProperty('strokeColor') && this.isTrameShape()) {
      patch.color = patch.strokeColor
    }

    this.symbolizerUpdated.emit({
      name,
      value: {
        ...this.symbolizer.graphicFill,
        ...patch,
      },
    })
  }

  getFillTypeLabel(type: FillType): string {
    return fillTypeLabel[type]
  }

  getAllFillTypes() {
    return Object.keys(FillType)
      .filter((k) => typeof FillType[k as any] === 'number')
      .map((k) => FillType[k as any])
  }

  isTypeTrame() {
    return this.fillType === FillType.TRAME
  }
  isTypeUni() {
    return this.fillType === FillType.UNI
  }

  isTrameShape() {
    return (
      this.isTypeTrame() &&
      this.styleService.isShapeSymbol(this.symbolizer.graphicFill)
    )
  }

  onFillTypeChange(type): void {
    switch (type) {
      case FillType.UNI:
        this.handlePropChange('graphicFill', null)
        break
      case FillType.TRAME:
        this.symbolizer = {
          ...this.symbolizer,
          graphicFill: graphicFillDefaultSymbolizer,
        }
        this.handlePropChange('graphicFill', this.symbolizer.graphicFill)
        break
    }
  }

  getTitle() {
    return "Pour les couches WFS, les trames ne sont pas disponibles à l'impression et ne seront pas affichées lors de l'export."
  }
}
