<div class="modal-header">
  <h5 class="modal-title pull-left">Créer une nouvelle carte</h5>
</div>
<alert *ngIf="errorMsg" type="danger">
  {{ errorMsg }}
</alert>
<form [formGroup]="createmapForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label
        >Titre
        <input
          type="text"
          class="form-control"
          formControlName="title"
          [ngClass]="{ 'is-invalid': f.title.errors }"
        />
      </label>
    </div>
    <div class="form-group">
      <label
        >Nom
        <input
          type="text"
          class="form-control"
          formControlName="name"
          [ngClass]="{ 'is-invalid': f.name.errors }"
        />
      </label>
    </div>
    <div class="form-group">
      <label
        >Description
        <textarea
          rows="6"
          class="form-control"
          formControlName="abstract"
          [ngClass]="{ 'is-invalid': f.abstract.errors }"
        ></textarea>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Annuler
    </button>
    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="createmapForm.invalid || showLoading"
    >
      <span class="fa fa-spinner fa-spin mr-1" *ngIf="showLoading"></span>Créer
    </button>
  </div>
</form>
