import { Injectable } from '@angular/core'
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { TreeItemModel } from '@ui/data-access-carto-resource-management'

const MESSAGE_DATASOURCE_REJECT_COMPLEX = `Le jeu de données est trop complexe pour être utilisé dans une carte interactive:\n un pré-traitement est nécessaire afin de simplifier de JDD`
const MESSAGE_DATASOURCE_REJECT_UNKNOWN = `Les informations permettant de sécuriser l'exploitation de ce JDD \n sont manquantes : ce JDD doit être réimporté`

export enum PasswordError {
  MinLength = 'MinLength',
  AtLeastOneDigit = 'AtLeastOneDigit',
  AtLeastOneLetter = 'AtLeastOneLetter',
  AtLeastOneSpecialCharacter = 'AtLeastOneSpecialCharacter',
}

const PASSWORD_MIN_LENGTH = 8

export const EMAIL_VALIDATOR_PATTERN =
  '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'

export const ATTRIBUTE_LABEL_MAX_LENGTH = 64
export const ATTRIBUTE_LABEL_PATTERN = '[0-9a-zA-Z\u00C0-\u017F _.\\-+:*@&%]+'

@Injectable({
  providedIn: 'root',
})
export class BusinessRulesService {
  constructor() {}

  getDatasourceRejectMessage(datasource: TreeItemModel): string {
    if (datasource.dataType === 'RASTER') return undefined
    const complexity = datasource.complexity
    if (complexity === -1) {
      return MESSAGE_DATASOURCE_REJECT_UNKNOWN
    }
    if (complexity >= 4) {
      return MESSAGE_DATASOURCE_REJECT_COMPLEX
    }
  }

  normalizeMapName(name: string): string {
    if (!name) return name
    return name
      .normalize('NFD') // decompose graphemes to remove accents from letters
      .replace(/[\u0300-\u036f]/g, '') // remove accent characters
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '_') // replace all except letters & numbers
  }

  normalizeLayerName(name: string, layerType?: string): string {
    if (!name || layerType === 'QGIS') return name
    return name
      .normalize('NFD') // decompose graphemes to remove accents from letters
      .replace(/[\u0300-\u036f]/g, '') // remove accent characters
      .replace(/[^a-zA-Z0-9\-_.]/g, '') // remove all except letters, numbers & allowed chars
      .replace(/^[-.0-9]+/, '') // replace leading invalid chars
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors: { [index: string]: Object } = {}
      const password: string = control.value

      if (password.length < PASSWORD_MIN_LENGTH) {
        errors[PasswordError.MinLength] = { length: password.length }
      }

      const digitRe = /[0-9]/
      if (!digitRe.test(password)) {
        errors[PasswordError.AtLeastOneDigit] = { password }
      }

      const letterRe = /[a-zA-Z]/
      if (!letterRe.test(password)) {
        errors[PasswordError.AtLeastOneLetter] = { password }
      }

      const nonWordUnderscoreRe = /\W|_/
      if (!nonWordUnderscoreRe.test(password)) {
        errors[PasswordError.AtLeastOneSpecialCharacter] = { password }
      }

      return Object.keys(errors).length > 0 ? errors : null
    }
  }
}
