import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core'
import { ResourceTypeEnum } from '../model'
import { EventService } from '../services/event.service'
import { TreeItemModel } from '@ui/data-access-carto-resource-management'

@Component({
  selector: 'ui-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss'],
})
export class ManagerComponent implements OnInit, OnChanges {
  ResourceTypeEnum = ResourceTypeEnum
  @Input() selectable = false
  @Input() activableTypes: ResourceTypeEnum[] = [
    ResourceTypeEnum.DATASET,
    ResourceTypeEnum.SERVICE,
    ResourceTypeEnum.STYLE,
    ResourceTypeEnum.GEOPLATEFORME,
  ]
  @Output() typeChange = new EventEmitter<ResourceTypeEnum>()
  @Input() activeType: ResourceTypeEnum = ResourceTypeEnum.DATASET
  @Input() defaultItems: TreeItemModel[]
  @Input() directAccessPath: string
  filterValue: string
  placeholderValue = 'Rechercher un service'
  constructor(private eventService: EventService) {}

  ngOnInit() {
    this.filter(ResourceTypeEnum.DATASET)
  }

  ngOnChanges(changes: SimpleChanges) {
    const selectable: SimpleChange = changes['selectable']
    // Reset selection if selectable became false
    if (selectable && !selectable.currentValue) {
      this.eventService.selectResource(undefined)
    }
  }

  filter(resourceType: ResourceTypeEnum) {
    this.typeChange.emit(resourceType)
    this.clearFilterValue()
  }

  onFilterValueChange(filterValue: string) {
    this.filterValue = filterValue
  }

  shoudShowFilter() {
    return [
      ResourceTypeEnum.STYLE,
      ResourceTypeEnum.SERVICE,
      ResourceTypeEnum.GEOPLATEFORME,
    ].includes(this.activeType)
  }

  clearFilterValue() {
    this.filterValue = ''
  }
}
