import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core'

@Component({
  selector: 'ui-filter-search-input',
  templateUrl: './filter-search-input.component.html',
  styleUrls: ['./filter-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSearchInputComponent {
  @Input() filterValue = ''
  @Input() placeholder = 'Rechercher'
  @Input() isSearch = false
  @Output() filterValueChange = new EventEmitter<string>()

  clearFilterValue() {
    this.filterValue = ''
    this.filterValueChange.emit(this.filterValue)
  }
}
