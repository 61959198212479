import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core'
import {
  RemoteServerModel,
  ResourceExplorationService,
  ResourceInfoModel,
  ResourceTypeModel,
  TreeItemModel,
} from '@ui/data-access-carto-resource-management'
import {
  BusinessRulesService,
  getGeoplateformeResourceInfo,
} from '@ui/feature/shared'
import { DialogsService } from '@ui/ui/layout'
import { ResourceFacade } from '../resource.facade'
import { EMPTY, merge, Observable, of } from 'rxjs'
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators'
import { EventService } from '../services/event.service'
import { DirectAccessService } from '../services/direct-access.service'

@Component({
  selector: 'ui-tree-item',
  templateUrl: './tree-item.component.html',
  styleUrls: ['./tree-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeItemComponent implements AfterViewInit {
  @Input() item!: TreeItemModel
  @Input() selectable = false
  @Input() editable = true
  _directAccessPath: string
  @Input() set directAccessPath(value: string) {
    this._directAccessPath = value
    if (
      value &&
      this.isMatchingDirectAccess(value) &&
      !this.directAccessService_.isLeaf(value)
    ) {
      this.openedOnce = true
      this.isOpened = true
      this.scrollTo()
    }
  }

  get directAccessPath() {
    return this._directAccessPath
  }

  @ViewChild('scrollElement')
  scrollEl: ElementRef

  isOpened = false
  openedOnce = false

  isActive$: Observable<boolean> = this.eventService_.resourceSelected$.pipe(
    map((event) => {
      return (
        this.item.contentId &&
        event.resourceId === this.item.contentId &&
        !event.layer
      )
    })
  )
  isDragged = false
  fullResource$!: Observable<ResourceInfoModel>
  fullResourceError$!: Observable<string>
  fullResourceLoading$!: Observable<boolean>

  get itemTooltip() {
    if (this.selectable && this.getDatasetRejectMessage())
      return this.getDatasetRejectMessage()
    if (this.isDatasetVoluminous())
      return `${this.item.name}
Jeu de Données volumineux :
son échelle de visualisation et sa projection de rendu
seront pré-configurés automatiquement`
    return this.item.name
  }

  constructor(
    private eventService_: EventService,
    private resourceExploration_: ResourceExplorationService,
    private dialogsService: DialogsService,
    private facade_: ResourceFacade,
    private directAccessService_: DirectAccessService,
    public businessRulesService: BusinessRulesService
  ) {}

  ngAfterViewInit() {
    // Automatically select the path if necessary after initializing the view
    if (
      this.directAccessService_.isLeaf(this.directAccessPath) &&
      this.isMatchingDirectAccess(this.directAccessPath)
    ) {
      // needed at the init to not trigger the observable too early
      setTimeout(() => this.handleClick())
      this.scrollTo()
    }
  }

  scrollTo() {
    if (this.scrollEl) {
      this.scrollEl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }
  }

  handleToggle() {
    this.isOpened = !this.isOpened
    this.openedOnce = this.openedOnce || this.isOpened
  }

  handleClick() {
    if (this.selectable && this.canCreateLayer()) {
      this.eventService_.selectResource(this.item.contentId)
    }
  }

  handleDragStart(event: DragEvent) {
    this.isDragged = true
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', JSON.stringify(this.item))
      event.dataTransfer.dropEffect = 'link'
    }
  }

  handleDragEnd() {
    this.isDragged = false
  }

  getPath() {
    return `${this.item.parentPath || ''}/${
      this.isMap() ? this.item.contentId : this.item.name
    }`
  }

  isDataset() {
    return (
      this.item && this.item.resourceType === ResourceTypeModel.LocalDatasource
    )
  }

  isOgcService() {
    return this.item && this.item.resourceType === ResourceTypeModel.Service
  }

  isStyle() {
    return this.item && this.item.resourceType === ResourceTypeModel.Style
  }

  isMap() {
    return this.item && this.item.resourceType === ResourceTypeModel.Map
  }

  hasChildren() {
    return this.item && this.item.folder
  }

  isGenericFolder() {
    return this.hasChildren() && !this.item.resourceType
  }

  isItemSelectable() {
    return this.isDataset() && this.selectable
  }

  isItemDraggable() {
    return this.isStyle()
  }

  isMatchingDirectAccess(value: string) {
    return this.directAccessService_.getFirstLevel(value) === this.item.name
  }

  isOgcLayersSelectable() {
    return this.isOgcService() && this.selectable
  }

  canCreateLayer() {
    return this.isDataset() && !this.getDatasetRejectMessage()
  }

  getDatasetRejectMessage() {
    return (
      this.isDataset() &&
      this.businessRulesService.getDatasourceRejectMessage(this.item)
    )
  }

  isDatasetVoluminous() {
    return this.isDataset() && this.item.voluminous
  }

  loadFullResource() {
    if (typeof this.item.contentId !== 'string' || this.fullResource$) {
      return
    }

    // this observable will emit the full resource, or an error in case
    // the resource is missing some data or the query failed
    const load$ = (this.fullResource$ = merge(
      this.item.parentPath !== '/geoplateforme'
        ? this.resourceExploration_.consultResource(this.item.contentId)
        : of(getGeoplateformeResourceInfo(this.item.contentId))
    ).pipe(
      shareReplay(),
      tap((resource) => {
        if (
          resource.service &&
          (!resource.service.serverURL || !resource.service.serverType)
        ) {
          throw new Error(
            'Données du service manquantes (URL GetCapabilities ou type de service).'
          )
        }
      })
    ))

    // this observable will emit the full resource with the errors filtered out
    // if an error occured, it will emit nothing
    this.fullResource$ = load$.pipe(catchError(() => EMPTY))

    // this observable will emit error objects whenever something went
    // wrong when loading the full resource; otherwise it will not emit at all
    this.fullResourceError$ = load$.pipe(
      filter(() => false),
      catchError((error) => of(error.message || 'Erreur inconnue'))
    )

    // this observable will first emit true and then emit false as soon
    // as the full resource or an error is received
    this.fullResourceLoading$ = merge(
      of(true),
      load$.pipe(map(() => false))
    ).pipe(catchError(() => of(false)))
  }

  removeService() {
    this.dialogsService
      .confirm(
        'Confirmation',
        `Êtes-vous sûrs de vouloir supprimer le service "${this.item.name}" ?`
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.facade_.removeService(this.item.contentId).subscribe({
            next: () => {
              this.eventService_.emitSuccess(
                `Le service <b>${this.item.name}</b> a bien été supprimé.`
              )
            },
            error: () =>
              this.eventService_.emitError(
                `Impossible de supprimer le service <b>${this.item.name}</b>
               <br>(il est peut-être utilisé par une couche GeoIde)`
              ),
          })
        }
      })
  }

  updateService() {
    this.resourceExploration_
      .consultResource(this.item.contentId)
      .subscribe((resourceInfoModel) =>
        this.eventService_.updateService(
          resourceInfoModel.service as RemoteServerModel
        )
      )
  }

  getDirectAccessChild(currentNode: string) {
    return this.directAccessService_.getDirectAccessPathNext(
      currentNode,
      this._directAccessPath
    )
  }
}
