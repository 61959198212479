import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { ManagerComponent } from './manager/manager.component'
import { TreeItemComponent } from './tree-item/tree-item.component'
import { TreeLevelComponent } from './tree-level/tree-level.component'
import { RemoteServiceContentComponent } from './remote-service-content/remote-service-content.component'
import { TmsServiceContentComponent } from './remote-service-content/tms-service-content.component'
import { OgcServiceContentComponent } from './remote-service-content/ogc-service-content.component'
import { OgcLayerComponent } from './ogc-layer/ogc-layer.component'
import { UiCartoModule } from '@ui/ui/carto'
import { UiLayoutModule } from '@ui/ui/layout'

@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    UiCartoModule,
    UiLayoutModule,
  ],
  declarations: [
    ManagerComponent,
    TreeItemComponent,
    TreeLevelComponent,
    RemoteServiceContentComponent,
    TmsServiceContentComponent,
    OgcServiceContentComponent,
    OgcLayerComponent,
  ],
  exports: [
    ManagerComponent,
    TreeItemComponent,
    TreeLevelComponent,
    RemoteServiceContentComponent,
    TmsServiceContentComponent,
    OgcServiceContentComponent,
    OgcLayerComponent,
  ],
})
export class UiResourcesModule {}
