import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { StyleEditorComponent } from './style-editor/style-editor.component'
import { StyleEditorRuleComponent } from './style-editor-rule/style-editor-rule.component'
import { ColorComponent } from './color/color.component'
import { StyleService } from './style.service'
import { SymbolizerFieldComponent } from './symbolizer-field/symbolizer-field.component'
import { AlertModule } from 'ngx-bootstrap/alert'
import { CollapseModule } from 'ngx-bootstrap/collapse'
import { PopoverModule } from 'ngx-bootstrap/popover'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { RuleFilterComponent } from './rule-filter/rule-filter.component'
import { NgArrayPipesModule } from 'ngx-pipes'
import { ThematicStyleEditorComponent } from './thematic-style-editor/thematic-style-editor.component'
import { HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TextSymbolizerComponent } from './symbolizers/text-symbolizer/text-symbolizer.component'
import { RulesetGeneratorInterpolationComponent } from './ruleset-generator-interpolation/ruleset-generator-interpolation.component'
import { RulesetGeneratorRandomComponent } from './ruleset-generator-random/ruleset-generator-random.component'
import { SymbolSelectorComponent } from './symbol-selector/symbol-selector.component'
import { UiStylingModule } from '@ui/ui/styling'
import { UiLayoutModule } from '@ui/ui/layout'
import { MarkSymbolizerComponent } from './symbolizers/mark-symbolizer/mark-symbolizer.component'
import { FillSymbolizerComponent } from './symbolizers/fill-symbolizer/fill-symbolizer.component'
import { RuleScaleInputComponent } from './rule-scale-input/rule-scale-input.component'
import { UiConstrainMinMaxValueDirective } from './constrain-min-max.directive'
import { DragDropModule } from '@angular/cdk/drag-drop'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDropdownModule,
    NgArrayPipesModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    UiStylingModule,
    UiLayoutModule,
    DragDropModule,
  ],
  declarations: [
    StyleEditorComponent,
    StyleEditorRuleComponent,
    ColorComponent,
    SymbolizerFieldComponent,
    RuleFilterComponent,
    ThematicStyleEditorComponent,
    TextSymbolizerComponent,
    RulesetGeneratorInterpolationComponent,
    RulesetGeneratorRandomComponent,
    SymbolSelectorComponent,
    MarkSymbolizerComponent,
    FillSymbolizerComponent,
    RuleScaleInputComponent,
    UiConstrainMinMaxValueDirective,
  ],
  exports: [
    StyleEditorComponent,
    StyleEditorRuleComponent,
    ColorComponent,
    SymbolizerFieldComponent,
    ThematicStyleEditorComponent,
    RulesetGeneratorInterpolationComponent,
    RulesetGeneratorRandomComponent,
    SymbolSelectorComponent,
    MarkSymbolizerComponent,
  ],
  providers: [StyleService],
})
export class FeatureStylingModule {}
