import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { MapFacade } from '@ui/feature/mapstate'
import { Observable, Subscription } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { Layer, MapElement } from '@ui/feature/shared'
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop'
import { installPatch } from '../../../drag-drop-patch'

installPatch()

const connectedDropLists: { dropList: CdkDropList; depth: number }[] = []

interface DropValue {
  layer: Layer
  parentId: number
  originalIndex: number
}

@Component({
  selector: 'ui-layertree',
  templateUrl: './layertree.component.html',
  styleUrls: ['./layertree.component.scss'],
})
export class LayertreeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() parentId: number = undefined
  @Input() depth = 0
  layers$: Observable<MapElement[]>
  layersCount$: Observable<number>
  readonly$ = this.facade.isDefault$
  sub: Subscription = new Subscription()

  @ViewChild(CdkDropList) dropList: CdkDropList

  get connectedDropLists() {
    return connectedDropLists
      .filter((d) => d.dropList !== this.dropList)
      .map((d) => d.dropList)
  }

  constructor(private facade: MapFacade) {}

  ngOnInit() {
    this.layers$ = this.facade.getOrderedMapElements(this.parentId)
    this.layersCount$ = this.layers$.pipe(map((layers) => layers.length))
  }

  ngAfterViewInit() {
    connectedDropLists.push({ dropList: this.dropList, depth: this.depth })
  }

  ngOnDestroy() {
    connectedDropLists.splice(
      connectedDropLists.findIndex((d) => d.dropList === this.dropList),
      1
    )
  }

  layerId(index: number, layer: Layer) {
    return layer.id
  }

  onDrop(event: CdkDragDrop<DropValue, any>) {
    this.layersCount$.pipe(take(1)).subscribe((layerCount) => {
      // if (event.type !== 'drop') return
      const parentChanged = event.item.data.parentId !== this.parentId
      if (
        !parentChanged &&
        event.item.data.originalIndex === event.currentIndex
      )
        return
      const layer = event.item.data.layer
      this.facade.moveLayer({
        id: layer.id,
        index: layerCount - event.currentIndex + (parentChanged ? 0 : -1),
        newParentId: this.parentId,
      })
    })
  }
}
