import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core'

@Component({
  selector: 'ui-item-grid',
  templateUrl: './item-grid.component.html',
  styleUrls: ['./item-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemGridComponent implements OnInit {
  @Input() data: any[]
  @Input() rowCount: number
  @Input() colCount: number
  @Input() itemWidthCss: string
  @Input() itemHeightCss: string
  @Input() itemTemplate: TemplateRef<{ item: any; shared: any }>
  @Input() sharedContext?: any

  gridGap = '4px'

  get maxHeight() {
    return `calc(${this.rowCount} * ${this.itemHeightCss} + ${
      this.rowCount - 1
    } * ${this.gridGap})`
  }

  constructor() {}

  ngOnInit() {}
}
