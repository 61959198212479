<div>
  <div class="d-flex flex-row align-items-start">
    <button
      class="btn btn-secondary btn-sm p-0 mr-1 mt-1 fa"
      *ngIf="layer.layers"
      [ngClass]="opened ? 'fa-minus-square' : 'fa-plus-square'"
      (click)="opened = !opened"
    ></button>
    <div
      class="d-flex flex-column overflow-hidden layer-name"
      [ngClass]="{ selectable: selectable, active: isActive$ | async }"
      (click)="selectable && handleClick(); (false)"
      [title]="getTooltip()"
    >
      <span class="text-truncate">{{ layer.title || 'Layers' }}</span>
      <span class="text-white-50 small text-truncate">{{ layer.name }}</span>
    </div>
  </div>
  <div
    *ngIf="layer.layers"
    class="ml-2 pl-2 border-left border-light"
    [ngClass]="{ 'd-none': !opened }"
  >
    <ui-ogc-layer
      *ngFor="let childLayer of layer.layers"
      [layer]="childLayer"
      [resourceId]="resourceId"
      [selectable]="selectable"
    ></ui-ogc-layer>
    <div class="text-muted" *ngIf="layer.layers.length === 0">
      Aucune couche dans ce groupe.
    </div>
  </div>
</div>
