import { Component, OnChanges, OnInit } from '@angular/core'
import { RemoteServiceContentComponent } from './remote-service-content.component'

@Component({
  selector: 'ui-ogc-service-content',
  templateUrl: './remote-service-content.component.html',
  styleUrls: ['./remote-service-content.component.scss'],
})
export class OgcServiceContentComponent
  extends RemoteServiceContentComponent
  implements OnInit, OnChanges
{
  override getHttpRequest() {
    const service = this.remoteServer
    const url = new URL(service.serverURL)
    url.searchParams.set('version', service.serverVersion)
    url.searchParams.set('service', service.serverType)
    url.searchParams.set('request', 'GetCapabilities')

    return this.http.get(this.proxy.getProxiedUrl(url.toString()), {
      responseType: 'text',
    })
  }
}
