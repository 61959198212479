import { LayerView, LayerViewType, WfsReadParams } from '../model'
import { getViewServiceUrl } from './carto-api.helpers'

export function buildWfsGetFeatureUrl(params: WfsReadParams): string {
  const {
    url,
    version = '1.1.0',
    typeName,
    propertyName,
    outputFormat,
    extent,
    maxFeatures,
  } = params
  const typeNameLabel = version === '2.0.0' ? 'typenames' : 'typename'
  const countLabel = version === '2.0.0' ? 'count' : 'maxFeatures'

  const urlObj = new URL(url)
  urlObj.searchParams.set('SERVICE', 'WFS')
  urlObj.searchParams.set('version', version)
  urlObj.searchParams.set('request', 'GetFeature')
  urlObj.searchParams.set(typeNameLabel, typeName)
  urlObj.searchParams.set('srsname', 'EPSG:3857')
  if (extent) {
    urlObj.searchParams.set('bbox', `${extent.join(',')},EPSG:3857`)
  }
  if (propertyName && propertyName.length) {
    urlObj.searchParams.set('propertyName', propertyName.join(','))
  }
  if (maxFeatures) {
    urlObj.searchParams.set(countLabel, maxFeatures.toString())
  }
  if (outputFormat) {
    urlObj.searchParams.set('outputFormat', outputFormat)
  }
  return urlObj.href
}

export function buildGetCapabilitiesUrl(view: LayerView): string | null {
  if (
    view.serviceType !== LayerViewType.WMS &&
    view.serviceType !== LayerViewType.WMTS
  ) {
    return null
  }
  const urlObj = new URL(getViewServiceUrl(view))
  if (view.serviceType === LayerViewType.WMTS) {
    urlObj.searchParams.set('SERVICE', 'WMTS')
    urlObj.searchParams.set('VERSION', view.serviceVersion || '1.0.0')
  } else {
    urlObj.searchParams.set('SERVICE', 'WMS')
    urlObj.searchParams.set('VERSION', view.serviceVersion || '1.3.0')
  }
  urlObj.searchParams.set('REQUEST', 'GetCapabilities')
  return urlObj.href
}

export function buildWfsDescribeFeatureTypeUrl(
  serviceUrl: string,
  featureType: string,
  serviceVersion: string
): string | null {
  if (serviceUrl === null) return null

  const typeParam = serviceVersion.startsWith('2') ? 'typenames' : 'typename'

  const urlObj = new URL(serviceUrl)
  urlObj.searchParams.set('service', 'WFS')
  urlObj.searchParams.set('version', serviceVersion)
  urlObj.searchParams.set('request', 'DescribeFeatureType')
  urlObj.searchParams.set(typeParam, featureType)
  return urlObj.toString()
}
