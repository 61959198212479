<div class="modal-header">
  <h5 class="modal-title pull-left">Options de diffusion</h5>
</div>
<alert *ngIf="errorMsg" type="danger"> {{ errorMsg }} </alert>
<form [formGroup]="editForm" (ngSubmit)="onSubmit()">
  <div class="modal-body pb-0 row">
    <div class="col-7">
      <div class="form-group">
        <label class="form-check form-check-label">
          <input
            class="form-check-input checkbox-activated"
            type="checkbox"
            formControlName="messageActivated"
          />
          Configurer un message d'information
        </label>
      </div>
      <div class="form-group">
        <!-- editor not initially disabled through form control disabled initial value -->
        <editor
          [disabled]="!f.messageActivated.value"
          [init]="editorConfig"
          formControlName="messageContent"
        >
        </editor>
      </div>
      <div class="form-group">
        <label class="form-check form-check-label">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="messageNotify"
          />
          Activer les notifications d'informations interactives
        </label>
      </div>
      <fieldset class="form-group">
        <legend class="h6">Type de message :</legend>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              [value]="EXPOSITION_MESSAGE_TYPE.INFORMATION"
              name="messageType"
              formControlName="messageType"
            />
            Information simple
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              [value]="EXPOSITION_MESSAGE_TYPE.CONSEIL"
              name="messageType"
              formControlName="messageType"
            />
            Conseils d'exploitations
          </label>
        </div>
        <div class="form-check disabled">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              [value]="EXPOSITION_MESSAGE_TYPE.RESTRICTION"
              name="messageType"
              formControlName="messageType"
            />
            Restrictions d'utilisations, avertissements
          </label>
        </div>
      </fieldset>
    </div>
    <div class="col-5">
      <fieldset class="form-group h-100 map-atlas">
        <legend class="h6">Définir les cartes liées (atlas) :</legend>
        <ng-template #mapTemplate let-map="item">
          <ui-map-preview [mapSummary]="map"></ui-map-preview>
        </ng-template>
        <ui-select-item-list
          [data]="linkedMapsFormatted"
          [itemTemplate]="mapTemplate"
          (selectedItems)="handleLinkedMapsUpdate($event)"
        ></ui-select-item-list>
      </fieldset>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" type="button" (click)="onCancel()">
      Annuler
    </button>
    <button class="btn btn-primary" type="submit" [disabled]="editForm.invalid">
      Enregistrer
    </button>
  </div>
</form>
