import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AddNotification } from '../actions'
import {
  AppNotificationState,
  NotificationType,
} from '../reducers/notification.reducer'
import { getNotifications } from '../selectors/notification.selector'

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  notifications$ = this.store.pipe(select(getNotifications))

  constructor(private store: Store<AppNotificationState>) {}

  showNotification(type: NotificationType, message: string, timeout = 2000) {
    const id = Date.now()
    this.store.dispatch(
      new AddNotification({
        type,
        message,
        id,
        timeout,
      })
    )
  }
}
