<!-- avoid missing borders on list group flush -->
<span hidden></span>
<ul class="list-group list-group-flush">
  <ui-style-editor-rule
    class="list-group-item list-group-item-action p-0"
    [rule]="rule"
    [index]="0"
    [active]="ruleOpened"
    [draggable]="false"
    [filterable]="false"
    [deletable]="false"
    [fixedSymbolizers]="true"
    [nameEditable]="false"
    (toggled)="ruleOpened = !ruleOpened"
    (changed)="handleRuleChange($event)"
    [elementType]="elementType"
    [colorsLocked]="true"
  ></ui-style-editor-rule>
</ul>
<!-- avoid missing borders on list group flush -->
<span hidden></span>
