import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { MapFacade } from '@ui/feature/mapstate'
import { ThematicStyleEditorComponent } from '@ui/feature/styling'
import { StyleFacade } from '../../../store/facade'
import { combineLatest } from 'rxjs'
import { filter, map, switchMap, take } from 'rxjs/operators'
import {
  getViewIsVector,
  getViewWfsUrl,
  getViewWfsVersion,
} from '@ui/feature/shared'
import { LayerViewModel } from '@ui/data-access-carto-map-production'

@Component({
  selector: 'ui-thematic-panel',
  templateUrl: './thematic-panel.component.html',
  styleUrls: ['./thematic-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThematicPanelComponent {
  @ViewChild('thematicEditor', { static: false })
  thematicEditor: ThematicStyleEditorComponent

  currentViewId$ = this.styleFacade_.currentViewId$.pipe(
    filter((viewId) => !!viewId)
  )

  serviceInfo$ = this.currentViewId$.pipe(
    switchMap((viewId) =>
      this.mapFacade_.getViewById(viewId).pipe(
        filter<LayerViewModel>(Boolean),
        map((view) => ({
          url: getViewWfsUrl(view),
          typeName: view.layerName,
          version: getViewWfsVersion(view),
        }))
      )
    )
  )

  // make sure that the data schema is only sent to the thematic editor
  // when thematic editing is enabled, otherwise the editor will make
  // large queries which may not be necessary
  // TODO: this probably has to be reworked somehow as it is unclear and prone to bugs
  // Also the dataSchema$ observable emits many unneeded values
  dataSchema$ = combineLatest([
    this.styleFacade_.currentDataSchema$,
    this.styleFacade_.thematic$,
  ]).pipe(
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    filter(([_, isThematic]) => isThematic),
    map(([dataSchema]) => dataSchema)
  )

  constructor(
    public styleFacade_: StyleFacade,
    public mapFacade_: MapFacade
  ) {}

  updateStyle(style: any) {
    this.currentViewId$
      .pipe(
        switchMap((viewId) => this.mapFacade_.getViewById(viewId)),
        take(1),
        filter(getViewIsVector)
      )
      .subscribe((layerView) =>
        this.mapFacade_.updateStyle({
          viewId: layerView.id,
          style: style,
        })
      )
    this.styleFacade_.updateCurrentStyle(style)
    this.styleFacade_.finishThematicEdition() // switch back to normal editor
  }

  generateStyle() {
    this.thematicEditor.generate()
  }

  trackById(index: number, viewId: number) {
    return viewId
  }
}
