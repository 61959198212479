import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core'
import {
  LayerPublicationScopeEnum,
  LayerPublicationStatusEnum,
} from '@ui/feature/shared'

@Component({
  selector: 'ui-layer-publication-status',
  templateUrl: './layer-publication-status.component.html',
  styleUrls: ['./layer-publication-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayerPublicationStatusComponent implements OnInit {
  @Input() state: LayerPublicationStatusEnum
  @Input() scope: LayerPublicationScopeEnum

  constructor() {}

  ngOnInit() {}

  get isPublished() {
    return this.state === 'PUBLISHED'
  }
  get isNotPublished() {
    return this.state === 'NOT_PUBLISHED'
  }
  get isNotFound() {
    return this.state === 'NOT_FOUND'
  }
  get isLoading() {
    return this.state === 'LOADING'
  }
  get isError() {
    return this.state === 'ERROR_PROBABLE'
  }

  get scopeName() {
    if (this.scope === 'INTERNET') return 'INTERNET'
    if (this.scope === 'INTRANET') return 'INTRANET'
    if (this.scope === 'BOTH') return 'INTERNET + INTRANET'
    return '(non spécifié)'
  }

  get tooltip() {
    if (this.isPublished)
      return `Le JDD associé à la couche est publié dans GI Catalogue sur le scope ${this.scopeName}.`
    if (this.isNotPublished)
      return 'Le JDD associé à la couche est catalogué mais non publié dans GI Catalogue.'
    if (this.isNotFound)
      return "Le JDD associé à la couche n'est pas catalogué dans GI Catalogue."
    if (this.isLoading)
      return "L'état de publication du JDD est en cours de récupération."
    if (this.isError)
      return "L'état de publication du JDD associé à la couche n'a pas pu être déterminé."
  }
}
